.table-controls {
  .flyout__field {
    margin: 0;
    display: flex;
    align-items: center;
    &-label { color: $dark-grey; }
    &-input { margin: 0 8px; }
  }
  .select-holder {
    background-color: transparent;
    color: $black;
    border-color: $main-color;
    border-width: 1px;
    height: 36px;
    width: 60px;
    min-width: unset;
    &:before {
      width: 32px;
      height: 32px;
      right: -3px;
      margin-top: -16px;
    }
    select {
      padding: 0.4em !important
    }
  }
}