/* Helper Classes */

.align-left,.text-left {
   text-align:left !important;
}
.align-center,.text-center {
   text-align:center !important;
}
.align-right,.text-right {
   text-align:right !important;
}
.margin {
    margin: $small-spacing !important;
}
.padding {
    padding: $small-spacing !important;
}
.no-margin {
    margin: 0 !important;
}
.no-padding {
    padding: 0 !important;
}
.margin-top {
    margin-top: $small-spacing !important;
}
.margin-bottom {
    margin-bottom: $small-spacing !important;
}
.margin-top-lg {
    margin-top: $large-spacing !important;
}
.margin-bottom-lg {
    margin-bottom: $large-spacing !important;
}

.margin-left {
    margin-left: $small-spacing !important;
}
.margin-right {
    margin-right: $small-spacing !important;
}
.no-margin-top {
    margin-top: 0 !important;
}
.no-margin-bottom {
    margin-bottom: 0 !important;
}
.no-padding-top {
    padding-top: 0 !important;
}
.no-padding-bottom {
    padding-bottom: 0 !important;
}
.opacity-half {
    opacity: .5;
}
.full-width {
    width: 100% !important;
}
.block {
    display: block !important;
}
.inline {
    display: inline !important;
}
.inline-block {
    display: inline-block !important;
}
.float-left,.fl_l,.floatleft,.alignleft{
    float:left !important;
}
.float-right,.fl_r,.floatright,.alignright{
    float:right !important;
}

/* wp
.alignleft{
    float:left !important;
    margin:10px 15px 10px 0;
}
.alignright{
    float:right !important;
    margin:10px 0 10px 15px;
}
*/

.flex-end{
  align-self:flex-end;
}
.flex-fit{
  @include flex(1);
}

.clear-both,.clear,.clearfix{
    clear:both !important;
}
.box-center {
    margin-left: auto !important;
    margin-right: auto !important;
    float: none !important;
    width: 100%;
}
.overflow-hidden {
    overflow: hidden !important;
}
.hide{display:none !important;}

.hide-height{
  max-height:0!important;
  padding-top:0!important;
  padding-bottom:0!important;
  overflow: hidden!important;
  border:0!important;
}
.hide-opacity{
  visibility: hidden;
  opacity:0;
}

.show-opacity{
  visibility: visible;
  opacity:1;
}


.lock-position{//for ios
    height: 100%;
	overflow: hidden;
	width: 100%;
	position: fixed;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.visually-hidden-focusable:active,
.visually-hidden-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
.text-white{
  color:#fff!important;
}
.text-black{
  color:#000!important;
}
.text-blue{
  color: $main-color!important;
}


.flexbox{
    display: flex;
}

@mixin bg-icon {
    -webkit-filter: grayscale(100%) brightness(500%) brightness(40%);
    filter: grayscale(100%) brightness(500%) brightness(40%);
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin iconFilter($color: '') {
    @if $color == '' {
        color: '';
        -webkit-filter: invert(77%) sepia(10%) saturate(304%) hue-rotate(173deg) brightness(92%) contrast(88%);
        filter: invert(77%) sepia(10%) saturate(304%) hue-rotate(173deg) brightness(92%) contrast(88%);
    }
    
    @if $color == 'blue' {
        color: blue;
        -webkit-filter: invert(54%) sepia(93%) saturate(2542%) hue-rotate(162deg) brightness(97%) contrast(101%);
        filter: invert(54%) sepia(93%) saturate(2542%) hue-rotate(162deg) brightness(97%) contrast(101%);
    }
    @if $color == 'base-font-color' {
        color: black;
        -webkit-filter: invert(10%) sepia(0%) saturate(2831%) hue-rotate(288deg) brightness(97%) contrast(88%);
        filter: invert(10%) sepia(0%) saturate(2831%) hue-rotate(288deg) brightness(97%) contrast(88%);
    }
}

@mixin iconSize {
    width: 40px;
    height: 40px;

    @include media($mobile-menu-break-max) {
        width: 34px;
        height: 34px;
    }
}