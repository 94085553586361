$bg_register: #0d1a22;
body.register {
  #content-container {
    background-color: $bg_register;

    .flyout__field-input {
      #{$text-inputs-list},
      .select-holder {
        background-color: #263138;
        border-color: transparent;
      }
    }
  }
}

.register-block {
  max-width: 670px;
  min-height: 85vh;
  margin: auto;
  padding-top: 35px;
  color: #fff;
  h1 {
    margin-bottom: 25px;
    text-align: center;
    font-family: $reg-font;
    font-size: 23px;
    font-weight: 300;
    color: #fff;
  }

  label.checkbox::before,
  label.checkbox::after {
    filter: invert(1)
  }

  .flyout__field {
    &.field-invalid {
      label.checkbox {
        &:before {
          filter: invert(13%) sepia(89%) saturate(5552%) hue-rotate(354deg) brightness(101%) contrast(87%);
        }
      }
    }
  }
}

.register-block {
  .flyout__group-field,
  .flyout__group-field-note {
    width: auto;
    max-width: 100%;
    color: #fff;
    opacity: 1;
  }
  .flyout__group-field-note {
    margin-top: 16px;
    margin-bottom: 16px;
    @include media($mobile-menu-break) {
      margin-bottom: 26px;
    }
    opacity: 0.3;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.register-footer {
  padding-top: 10px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .btn {
    display: inline-block;
    min-width: 200px;
    font-size: 12px !important;
  }

  .terms-checkbox {
    padding-bottom: 15px;
    .checkbox {
      line-height: 1.25;
      padding-right:0;
      font-size: 14px;
    }
  }
}

.default-layout footer {
  position: relative;
}

@include media($mobile-menu-break) {
  .register-body {
    margin-left: -2px;
    margin-right: -2px;
  }
  .register-account {
    margin-top: 47px;
  }
}

@include media($mobile-menu-break-max) {
  .register-block {
    .account-photo {
      margin-bottom: 25px;
    }
  }
}

.register-block {
  .account-photo {
    margin-bottom: 10px;
    &__edit,
    &__active {
      max-width: 90px;
      max-height: 90px;
    }

    &__block {
      max-width: 90px;
      left: 40px;
      .svg-photo {
        width: 150px;
        height: 150px;
      }
    }

    .image-crop {
      width: 90px;
      height: 90px;
    }
  }
}

.register-content {
  &.grid {
    column-gap:20px;
    margin-left: 0;
    justify-content: center;
    width: 100%;

    > [class*='grid'] {
      display: flex;
      margin-left: 0;
      width: 100%;
      fieldset {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }
  }
}

.select-holder {
  [class*='__field'] & {
    display: block;
  }
}

.register-block a:not([class]) {
  color: #fff;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}


html.ie-browser {
  body.register {
    #content-container {
  
      .flyout__field-input {
        #{$text-inputs-list},
        .select-holder {
          background-color: #fff;
          border-color: transparent;
          color: #000;
        }
      }
    }
  }
}