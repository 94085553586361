.rating {
    @include list-reset();
    margin: .5rem 0 1rem;
    padding: 0;
    @include flexbox;
    flex-flow: row;
    justify-content: space-around;
    align-content: center;
    justify-content: center;
    li {
      &:before {
        content:'';
        float:left;
        padding-top:100%;
      }
      //line-height:1.95rem;
      flex:1;
      font-size:1.1rem;
    @include media($tablet){  
        font-size:.7rem;
    }
    @include media($lg-tablet){  
        font-size:.7rem;
    }
    @include media($lg-desktop){  
        font-size:1.1rem;
    }

      text-align: center;
      font-weight:bold;
      border-radius:50%;
      background-color: #fff;
      border:1px solid #333;
      cursor: pointer;
      color:#333;
      opacity:.3;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 5px;

      transition:all .15s linear;
      &:hover,&.filled{
          background-color: $main-color;
          color:#fff;
          opacity:1;
          border-color: $main-color;
          /*&+ li {
              opacity:.90;
              color:$main-color;
          }
          &+ li + li{
              opacity:.80;
              color:$main-color;
          }
          &+ li + li + li{
              opacity:.70;
              color:$main-color;
          }
          &+ li + li + li + li{
              opacity:.60;
              color:$main-color;
          }*/
      }
      &:hover{
          box-shadow:0 0 10px rgba(0,0,0,.2);
      }
    }
  
    &.readonly .filled {
      color: #666;
    }
  }

  .ratings-disabled{
    opacity: .6;
    li{pointer-events: none;}
 }