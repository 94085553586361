h1,h2,h3,h4,.h1,.h2,.h3,.h4 {
    font-family: $title-font;
    margin: 0 0 .2rem;
    color:$black;
    font-weight:600;
    line-height:1.1;
}
h4{
    font-family: 'Panton-SemiBold';
}

h5,h6,.h5,.h6 {
    font-family: $content-font;
    color:$sec-color;
    line-height:1.1;
    font-weight:normal;
}

h1,.h1 {padding: .5rem 0 0rem;color:$main-color;
    font-size: 1.65rem;
    display: inline-block;
}
h2,.h2 {
    padding: 1rem 0 .75rem;
    font-size: 1.5rem;
}

h3,.h3 {
    padding: .9rem 0 .6rem;
    font-size: 1.4rem;

}
h4,.h4 {font-size: 1rem;padding: .5rem 0 .1rem;margin: 0 0 .4rem;
    @include media($phablet){
        font-size: 1.1rem;
    }

}
h5,.h5 {font-size: 18px; margin: 25px 0 10px;}

h6,.h6 {font-size: .9rem;}

a {
    text-decoration: none;color: $third-color;transition: color 0.1s linear;
    &:active,
    &:focus {
        outline: none;
    }
}

p {
    font-family: $content-font;
    font-weight: $content-font-weight;
    color:$content-font-color;
    margin: .4rem 0 1rem;
    line-height: $content-line-height;
    a{text-decoration: underline;}
}

strong{font-weight:bold;}

.intro{
    font-weight:bold;
    margin:.4em 0 .8em;
    font-size: 1.1em;
}

ol,ul {
    margin: 0 0 0 $small-spacing;
    padding:0 0 $large-spacing 0;
    li {
        margin:0 0 0 $large-spacing;
        padding:0 0 $small-spacing 0;
    }
}

dl {
    margin-bottom: $small-spacing;
    dt {
        font-weight: bold;
        margin-top: $small-spacing;
    }
    dd {
        margin: 0;
    }
}

hr {
    border-bottom: 1px solid rgba(#777,.3);
    border-left: none;
    border-right: none;
    border-top: none;
    margin:1.2em 0;
}


sub, sup {font-size: 45%;line-height: 0;position: relative;vertical-align: middle;}
sup {top: -0.6rem;}
sub {bottom: -.4rem;}



small{font-size:70%;}
span.lg{font-size:110%;}
span.xl{font-size:130%;}
p small{font-size:90%;}

.block-title,.block-footer{
    background-color:$sec-color;
    color:#fff;
    text-align:center;
    font-family: $bold-font;
    margin:0 0 0;
    a{text-decoration:none;}
    a:hover{text-decoration:underline;}
    clear:both;
    padding:25px 5% 25px;


    span{display:table;margin:0 auto -24px auto; width:auto;padding:5px 5%;}
}
h4.block-title{ 
    margin:10px 0 0;
    span{padding:0 5%;margin:10px auto -11px auto; }
}

.block-title.block-title-inverse{
        color:$sec-color;
        background-color:#fff;
}

.block-title.block-title-purple{
        h1,h2,h3{color:$sec-color;}
        background-color:lighten($sec-color,57%);
}


.block-footer{
    margin:25px 0 40px;    border-bottom:3px solid #eee;
    span{margin:0 auto -16px auto;}
}

.page-title{
    margin:30px 0 25px;
    position:relative;
    clear:both;
    border-bottom:3px solid #ddd;
    width:100%;
    h1{margin:0;
        small{display:block;font-size:52.5%;}
    }
    .thickbox img{width:auto;}
    img {
        width: 100%;
    }
    img + .page-title-inner {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 2%;
        bottom: 0;
        right: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .slide-content-left {
            width: 45%;
            padding: 0 0 0 4%;
        }
        h1,
        .breadcrumb a {
            color: #fff;
            text-shadow: 0 0 20px rgba(#000, .9);
            @include media($tablet){
                font-size: 1em;
            }
        }
        h1{
            @include media($tablet){
                font-size: 3rem;
            }
        }
    }
}

.breadcrumb{
    margin:0rem 0 1rem;
    padding:0;
    font-family: $semi-font;
    h6{font-size:.8rem;}
    li{margin:0;display:inline-block;color:#bbb;text-transform:uppercase;line-height:.8em;padding:0;
        a{color:#888;
            &:hover{color:#000;}
        }
        &:after{@include icon($icon-arrow-right);font-size:.7em;color:#999;vertical-align:top;padding:1px 1px 0 2px;display:inline-block;}
    }
}

.page-title .breadcrumb{
    margin:0 0 -0.5rem;
}