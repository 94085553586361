.form-branding {
  .img-placeholder {
    background: $white;
    margin-bottom: 1rem;
    > img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .img-upload.croppie-container {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      background: rgba(0,0,0,0.125);

      .cr-slider-wrap {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        z-index: 1;
        width: 50%;
      }

      .cr-boundary {
        background: $white;
      }
    }
  }
  .color-picker-swatch { width: 40px; }
  .color-picker-input-wrapper { width: 100%; }

  .color-picker-wrapper .input-group .input-group-addon:first-child{
    border-right-width: 1px;
  }

  fieldset {
    margin-bottom: 2rem;
  }

  .image-controls {
    display: flex;
    justify-content: flex-end;
    // align-items: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: absolute;
    inset: 0;
    z-index: 1;
    padding: 3px;
    background: rgba(0,0,0,0.125);

    > li {
      margin: 0;
      padding: 0;
      
      > .image-controls__btn {
        display: block;
        background: $main-color;
        cursor: pointer;
        margin: 2px;
        border-radius: 50%;
        svg {
          @include iconSize();
          fill: $white;
        }
      }
    }

    &--action {
      z-index: 3;
      pointer-events: none;
      background: unset;
      > li {
        .image-controls__btn {
          pointer-events: auto;
          background: $success-color
        }
        &:first-child {
          .image-controls__btn { background: $danger-color }
        }
      }
    }
  }
}