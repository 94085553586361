@mixin alert-theme($color, $icon) {

  &:before {
    @include icon($icon);
  }

  border-color: $color;
  background-color: scale-color($color, $lightness: 91%);
  //background-color:#eee;
  color: $color;

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    color: $color;

    &:first-child {
      margin-top: 0;
      padding-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

}

@mixin alert-theme-inverted($color, $icon) {
  &:before {
    @include icon($icon);
  }

  border-color: $main-color;
  background-color: $color;
  //background-color:#eee;
  color: #fff;

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    color: #fff;

    &:first-child {
      margin-top: 0;
      padding-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  a {
    color: #fff;
  }

}


.alert {
  padding: .8rem 1rem .8rem 2.4rem;
  font-size: 85%;
  clear: both;

  li,
  p {
    text-transform: none;
    font-family: $content-font;
  }

  p:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  border-width:1px;
  font-family: $semi-font;
  font-weight:normal;

  margin:.5rem 0;
  border-style:solid;
  color:#333;

  vertical-align:middle;
  line-height:1.05;
  position:relative;

  &:before {
    font-size: 1rem;
    position: absolute;
    left: 1rem;
    top: .7rem
  }

  @include media($phablet) {
    font-size: 100%;
    padding: 1rem 1.5rem 1rem 3rem;

    &:before {
      font-size: 1.35rem;
      left: 1rem;
    }
  }


  &.radius {
    border-radius: 0.6rem;
  }

  strong {
    font-family: $bold-font;
  }

  &.alert-primary {
    @include alert-theme-inverted($main-color, $icon-info-circle);
  }

  &.alert-secondary {
    @include alert-theme-inverted($sec-color, $icon-info-circle);
  }

  &.alert-info {
    @include alert-theme($info-color, $icon-info-circle);
  }

  &.alert-error {
    @include alert-theme($error-color, $icon-error);
  }

  &.alert-success {
    @include alert-theme($success-color, $icon-tick-thick);
  }

  &.alert-warning {
    @include alert-theme($warning-color, $icon-danger);
  }

  strong {
    font-family: $bold-font;
  }

  ul,
  ol {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}



.alert-dismissible {
  padding-right: 2.5em;
  transition: all .3s ease;

  &:after {
    cursor: pointer;
    @include icon($icon-cross);
    float: right;
    opacity: .4;
    display: block;
    text-align: center;
    position: absolute;
    right: 1.2rem;
    border-radius: 50%;
    width: 30px;
    line-height: 30px;
    margin-top: -15px;
    top: 50%;
    background-color: #fff;
  }

  &:hover:after {
    opacity: 1;
  }
}

.page-alert {
  margin: 100px auto 5px auto;
  max-width: 700px;
}


#toast-container {
  > .toast {
    $green: #7ED321;
    color: $grey;
    border-radius: 0;
    box-shadow: none;
    background: #fff;
    position: relative;
    font-size: 14px;
    width:335px;
    @include media($phablet) {
      font-size:15px;
      width:375px;
    }
    opacity: .9;

    &:before {
      content: '';
      display: block;
      position: absolute;
      background: url('#{$svg-path}Icons/Info.svg') no-repeat center;
      background-size: contain;
      -webkit-background-size: contain;
      width: 38px;
      height: 39px;
      left: 10px;
      top: 2px;
    }

    &:hover {
      box-shadow: 0 0 10px 2px rgba($grey, 0.25);
    }

    .toast-close-button {
      color: #555;
      right: 0;
    }

    &-success {
      &:before {
        background-image: url('#{$svg-path}Icons/Tick.svg');
        filter: invert(66%) sepia(100%) saturate(378%) hue-rotate(39deg) brightness(93%) contrast(94%);
      }
      > div {
        color: $green;
      }
      &:hover {
        box-shadow: 0 0 10px 2px rgba($green, 0.125);
      }
    }

    &-error {
      &:before {
        background-image: url('#{$svg-path}Icons/Ban.svg');
        filter: invert(28%) sepia(78%) saturate(6434%) hue-rotate(348deg) brightness(93%) contrast(99%);
      }
      > div {
        color: $red;
      }
      &:hover {
        box-shadow: 0 0 10px 2px rgba($red, 0.125);
      }
    }

    &-info {
      &:before {
        filter: invert(62%) sepia(98%) saturate(3697%) hue-rotate(163deg) brightness(98%) contrast(101%);
      }
      > div {
        color: $main-color;
      }
      &:hover {
        box-shadow: 0 0 10px 2px rgba($main-color, 0.125);
      }
    }
  }
}

.login-container {
  .alert.alert-error {
    a { 
      color: inherit;
      font-weight: 600;
      text-decoration: underline;
    }
  }
}

.site-alert { position: sticky; top: 0; z-index: 10000; }
.site-alert-content { padding-right: 1rem; }
.site-alert-control {padding-top: 7px;}

.site-alert.site-alert-type-1 { background-color: #e4f7eb; }
.site-alert.site-alert-type-2,
.site-alert.site-alert-type-3,
.site-alert.site-alert-type-4 { background-color: #f2f2f7; }
.site-alert.site-alert-type-5 { background-color: #ffb067; }
	
.site-alert .container {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;

  h4 { padding-top: 0; }
  p { margin: 0; }
}