@charset "UTF-8";
/*theme*/
/*Paths*/
/*theme palete*/
/*theme color variables*/
/*alert colors*/
/*fonts*/
/*spacing*/
/*buttons*/
@media screen and (max-width: 350px) {
  .mobile-hide {
    display: none;
  }
}

@media screen and (min-width: 1250px) {
  .desktop-hide {
    display: none;
  }
}

/*form fields: textarea, checkbox, selects etc*/
.dropmenu-holder ul li a .svg-icon, .dropmenu-holder ul[class*='menu--xs'] > li > a .svg-icon, .has-submenu.dropmenu-open .dropmenu-holder--submenu:before, .sidebar-footer .dropmenu-holder__menu li a svg {
  width: 32px !important;
  height: 32px !important;
}

@font-face {
  font-family: "Panton-Light";
  src: url(../assets/fonts/ce71b25d-7dec-4db1-8487-806205a046d5.woff2) format("woff2"), url(../assets/fonts/86377c61-f730-4059-8764-41ea8a511df9.woff) format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Panton-SemiBold';
  src: url(../assets/fonts/350E6E_20_0.woff2) format("woff2"), url(../assets/fonts/350E6E_20_0.woff) format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Panton-Bold';
  src: url("../assets/fonts/panton-bold-webfont.woff2") format("woff2"), url("../assets/fonts/panton-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "icon";
  src: url(../assets/fonts/Flaticon.woff?v32) format("woff");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  display: inline-block;
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.fi:before {
  display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.icon-link-hover:hover {
  opacity: .8;
}

.icon-link-hover:focus {
  opacity: .5;
}

.svg-icon {
  height: 64px;
  fill: #ff0000;
}

.icon-rubbish-bin:before {
  content: "\f100";
}

.icon-unlock:before {
  content: "\f101";
}

.icon-lock:before {
  content: "\f102";
}

.icon-download:before {
  content: "\f103";
}

.icon-file-word:before {
  content: "\f104";
}

.icon-file-pdf:before {
  content: "\f105";
}

.icon-tick-circle:before {
  content: "\f106";
}

.icon-account:before {
  content: "\f107";
}

.icon-plus-circle:before {
  content: "\f108";
}

.icon-minus-circle:before {
  content: "\f109";
}

.icon-warning:before {
  content: "\f10a";
}

.icon-history:before {
  content: "\f10b";
}

.icon-settings:before {
  content: "\f10c";
}

.icon-info-circle:before {
  content: "\f10d";
}

.icon-info-circle-full:before {
  content: "\f10e";
}

.icon-notifications-on:before {
  content: "\f10f";
}

.icon-more:before {
  content: "\f110";
}

.icon-eye:before {
  content: "\f111";
}

.icon-suitcase:before {
  content: "\f112";
}

.icon-notifications:before {
  content: "\f113";
}

.icon-add-button:before {
  content: "\f114";
}

.icon-cancel-button:before {
  content: "\f115";
}

.icon-note:before {
  content: "\f116";
}

.icon-arrow-right:before {
  content: "\f117";
}

.icon-arrow-down:before {
  content: "\f118";
}

.icon-arrow-left:before {
  content: "\f119";
}

.icon-arrow-up:before {
  content: "\f11a";
}

.icon-loading:before {
  content: "\f11b";
}

.icon-newspaper:before {
  content: "\f11c";
}

.icon-question:before {
  content: "\f11d";
}

.icon-new-message:before {
  content: "\f11e";
}

.icon-link:before {
  content: "\f11f";
}

.icon-blog:before {
  content: "\f120";
}

.icon-creativity:before {
  content: "\f121";
}

.icon-password:before {
  content: "\f122";
}

.icon-target:before {
  content: "\f123";
}

.icon-link-full:before {
  content: "\f124";
}

.icon-rating:before {
  content: "\f125";
}

.icon-forward:before {
  content: "\f126";
}

.icon-menu-sml:before {
  content: "\f127";
}

.icon-back:before {
  content: "\f128";
}

.icon-menu:before {
  content: "\f129";
}

.icon-error:before {
  content: "\f12a";
}

.icon-tool:before {
  content: "\f12b";
}

.icon-avatar:before {
  content: "\f12c";
}

.icon-refresh-lock:before {
  content: "\f12d";
}

.icon-list:before {
  content: "\f12e";
}

.icon-graph:before {
  content: "\f12f";
}

.icon-plan:before {
  content: "\f130";
}

.icon-search:before {
  content: "\f131";
}

.icon-comment:before {
  content: "\f132";
}

.icon-tick-thick:before {
  content: "\f133";
}

.icon-danger:before {
  content: "\f134";
}

.icon-tick:before {
  content: "\f135";
}

.icon-cross:before {
  content: "\f136";
}

/*functions*/
@keyframes bounce {
  0%,
  30%,
  70%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

/* Fade in animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Fade out animation */
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes shake {
  0%,
  10%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  1%,
  9% {
    transform: translate3d(-1px, 0, 0);
  }
  2%,
  8% {
    transform: translate3d(2px, 0, 0);
  }
  3%,
  5%,
  7% {
    transform: translate3d(-4px, 0, 0);
  }
  4%,
  6% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes click {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 1099px) {
  .mobile-hide {
    display: none !important;
  }
}

@media (min-width: 1100px) {
  .desktop-hide {
    display: none !important;
  }
}

[class^="icon-"], [class*=" icon-"] {
  display: inline-block;
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

/*flexbox*/
/*CSS3 Columns*/
/*base*/
.container {
  width: 100%;
  padding-left: 8vw;
  padding-right: 8vw;
}

@media (max-width: 329px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1550px) {
  .container {
    width: 1366px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
  }
}

.grid {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 0;
}

.grid.grid-wrap {
  flex-wrap: wrap;
}

.grid:not([class*='--wrap']) .grid-cell {
  flex: 1;
}

/* Alignment per row */
.grid-top {
  align-items: flex-start;
}

.grid-bottom {
  align-items: flex-end;
}

.grid-center {
  align-items: center;
  height: 100%;
  justify-content: center;
}

.grid-justify-between {
  justify-content: space-between;
}

/* Alignment per cell */
.grid-cell-top {
  align-self: flex-start;
}

.grid-cell-bottom {
  align-self: flex-end;
}

.grid-cell-center {
  align-self: center;
}

/* Base classes for all media */
.grid-fit,
.grid-cell {
  -moz-box-flex: 1  ;
  -webkit-flex: 1  ;
  -ms-flex: 1  ;
  flex: 1  ;
}

.grid > [class*=grid-] {
  width: 100%;
}

@media (min-width: 550px) {
  .grid > [class*=grid-] {
    padding-left: 5%;
    padding-right: 5%;
  }
}

.grid.no-gutters > [class*=grid-] {
  padding-left: 0;
  padding-right: 0;
}

.grid.grid-no-padding {
  padding-top: 0;
  padding-bottom: 0;
}

@media (min-width: 550px) {
  /* With gutters */
  .grid {
    flex-direction: row;
    margin: 0 0 0 0;
    margin-left: -5%;
    width: 105%;
  }
  .grid.no-gutters {
    margin: 0;
  }
  .grid > [class*=grid-] {
    margin: 0 0 0 5%;
    padding: 0;
  }
  .grid.no-gutters {
    width: 100%;
  }
  .grid.no-gutters > [class*=grid-] {
    margin: 0;
  }
  .grid.grid-no-padding {
    padding-top: 0;
    padding-bottom: 0;
  }
  .grid.grid-no-padding > [class*=grid-] {
    padding-top: 0;
    padding-bottom: 0;
  }
  .grid-100 {
    -moz-box-flex: 1 1 100%  ;
    -webkit-flex: 1 1 100%  ;
    -ms-flex: 1 1 100%  ;
    flex: 1 1 100%  ;
    max-width: 100%;
    width: 100%;
  }
  .grid-80 {
    -moz-box-flex: 1 1 80%  ;
    -webkit-flex: 1 1 80%  ;
    -ms-flex: 1 1 80%  ;
    flex: 1 1 80%  ;
    max-width: 80%;
    width: 100%;
  }
  .grid-75 {
    -moz-box-flex: 1 1 75%  ;
    -webkit-flex: 1 1 75%  ;
    -ms-flex: 1 1 75%  ;
    flex: 1 1 75%  ;
    max-width: 75%;
    width: 100%;
  }
  .grid-70 {
    -moz-box-flex: 1 1 70%  ;
    -webkit-flex: 1 1 70%  ;
    -ms-flex: 1 1 70%  ;
    flex: 1 1 70%  ;
    max-width: 70%;
    width: 100%;
  }
  .grid-66 {
    -moz-box-flex: 1 1 66.66%  ;
    -webkit-flex: 1 1 66.66%  ;
    -ms-flex: 1 1 66.66%  ;
    flex: 1 1 66.66%  ;
    max-width: 66.66%;
    width: 100%;
  }
  .grid-60 {
    -moz-box-flex: 1 1 60%  ;
    -webkit-flex: 1 1 60%  ;
    -ms-flex: 1 1 60%  ;
    flex: 1 1 60%  ;
    max-width: 60%;
    width: 100%;
  }
  .grid-50 {
    -moz-box-flex: 1 1 50%  ;
    -webkit-flex: 1 1 50%  ;
    -ms-flex: 1 1 50%  ;
    flex: 1 1 50%  ;
    max-width: 50%;
    width: 100%;
  }
  .grid-45 {
    -moz-box-flex: 1 1 45%  ;
    -webkit-flex: 1 1 45%  ;
    -ms-flex: 1 1 45%  ;
    flex: 1 1 45%  ;
    max-width: 45%;
    width: 100%;
  }
  .grid-40 {
    -moz-box-flex: 1 1 40%  ;
    -webkit-flex: 1 1 40%  ;
    -ms-flex: 1 1 40%  ;
    flex: 1 1 40%  ;
    max-width: 40%;
    width: 100%;
  }
  .grid-33 {
    -moz-box-flex: 1 1 33.33%  ;
    -webkit-flex: 1 1 33.33%  ;
    -ms-flex: 1 1 33.33%  ;
    flex: 1 1 33.33%  ;
    max-width: 33.33%;
    width: 100%;
  }
  .grid-30 {
    -moz-box-flex: 1 1 30%  ;
    -webkit-flex: 1 1 30%  ;
    -ms-flex: 1 1 30%  ;
    flex: 1 1 30%  ;
    max-width: 30%;
    width: 100%;
  }
  .grid-25 {
    -moz-box-flex: 1 1 25%  ;
    -webkit-flex: 1 1 25%  ;
    -ms-flex: 1 1 25%  ;
    flex: 1 1 25%  ;
    max-width: 25%;
    width: 100%;
  }
  .grid-20 {
    -moz-box-flex: 1 1 20%  ;
    -webkit-flex: 1 1 20%  ;
    -ms-flex: 1 1 20%  ;
    flex: 1 1 20%  ;
    max-width: 20%;
    width: 100%;
  }
  .grid-10 {
    -moz-box-flex: 1 1 10%  ;
    -webkit-flex: 1 1 10%  ;
    -ms-flex: 1 1 10%  ;
    flex: 1 1 10%  ;
    max-width: 10%;
    width: 100%;
  }
  .grid-order-end {
    order: 10;
  }
}

.hidden {
  display: none !important;
}

.visible-phone {
  display: inherit !important;
}

.visible-tablet {
  display: none !important;
}

.visible-desktop {
  display: none !important;
}

.hidden-phone {
  display: none !important;
}

.hidden-tablet {
  display: inherit !important;
}

.hidden-desktop {
  display: inherit !important;
}

@media (min-width: 1000px) {
  .visible-phone {
    display: none !important;
  }
  .visible-tablet {
    display: inherit !important;
  }
  .visible-desktop {
    display: none !important;
  }
  .hidden-phone {
    display: inherit !important;
  }
  .hidden-tablet {
    display: none !important;
  }
  .hidden-desktop {
    display: inherit !important;
  }
}

@media (min-width: 1250px) {
  .visible-phone {
    display: none !important;
  }
  .visible-tablet {
    display: none !important;
  }
  .visible-desktop {
    display: inherit !important;
  }
  .hidden-phone {
    display: inherit !important;
  }
  .hidden-tablet {
    display: inherit !important;
  }
  .hidden-desktop {
    display: none !important;
  }
}

.visible-print {
  display: none !important;
}

@media print {
  .visible-print {
    display: inherit !important;
  }
  .hidden-print {
    display: none;
  }
}

.grid--wrap {
  flex-wrap: wrap;
  width: unset;
  margin-left: -1rem;
  margin-right: -1rem;
}

.grid--wrap [class*="grid-"] {
  margin-left: 0;
  padding-left: 1rem;
  padding-right: 1rem;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

*, *:before, *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main {
  display: block;
}

* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input, textarea, [contenteditable] {
  -webkit-touch-callout: default;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

html {
  font-size: 15px;
  height: 100%;
}

@media screen and (min-width: 350px) {
  html {
    font-size: calc(15px + 3 * ((100vw - 350px) / 816));
  }
}

@media screen and (min-width: 1166px) {
  html {
    font-size: 18px;
  }
}

html login,
html forget-password,
html set-password, html change-password {
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 9999;
}

body {
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  line-height: 1.3;
  font-weight: 300;
  background-color: #f4f5f7;
  color: #262626;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 1099px) {
  body {
    overflow: hidden;
    height: 100% !important;
  }
}

body > ui-view {
  display: block;
}

body.home > ui-view {
  height: 100%;
}

body .content-container {
  background-color: #f4f5f7;
}

@media (max-width: 1099px) {
  body .content-container {
    display: flex;
    flex-direction: column;
    flex: 1 0 0%;
    overflow: auto;
    height: 100%;
  }
  body .content-container > * {
    width: 100%;
  }
}

main {
  width: 100%;
  padding-left: 8vw;
  padding-right: 8vw;
  min-height: 300px;
  padding-top: 4.5rem;
}

@media (max-width: 329px) {
  main {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1550px) {
  main {
    width: 1366px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
  }
}

img,
picture {
  margin: 0;
  max-width: 100%;
  height: auto;
}

/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, select {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  vertical-align: baseline;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */
textarea {
  resize: vertical;
}

.contenteditable-error {
  color: #ED1E23;
  font-size: 12px;
}

ul {
  list-style-type: disc;
}

h1, h2, h3, h4, .h1, .h2, .h3, .h4 {
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  margin: 0 0 .2rem;
  color: #38444B;
  font-weight: 600;
  line-height: 1.1;
}

h4 {
  font-family: 'Panton-SemiBold';
}

h5, h6, .h5, .h6 {
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  color: #2D363C;
  line-height: 1.1;
  font-weight: normal;
}

h1, .h1 {
  padding: .5rem 0 0rem;
  color: #00ADEF;
  font-size: 1.65rem;
  display: inline-block;
}

h2, .h2 {
  padding: 1rem 0 .75rem;
  font-size: 1.5rem;
}

h3, .h3 {
  padding: .9rem 0 .6rem;
  font-size: 1.4rem;
}

h4, .h4 {
  font-size: 1rem;
  padding: .5rem 0 .1rem;
  margin: 0 0 .4rem;
}

@media (min-width: 450px) {
  h4, .h4 {
    font-size: 1.1rem;
  }
}

h5, .h5 {
  font-size: 18px;
  margin: 25px 0 10px;
}

h6, .h6 {
  font-size: .9rem;
}

a {
  text-decoration: none;
  color: #22292D;
  transition: color 0.1s linear;
}

a:active, a:focus {
  outline: none;
}

p {
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  font-weight: 300;
  color: #262626;
  margin: .4rem 0 1rem;
  line-height: 1.3;
}

p a {
  text-decoration: underline;
}

strong {
  font-weight: bold;
}

.intro {
  font-weight: bold;
  margin: .4em 0 .8em;
  font-size: 1.1em;
}

ol, ul {
  margin: 0 0 0 0.5rem;
  padding: 0 0 1rem 0;
}

ol li, ul li {
  margin: 0 0 0 1rem;
  padding: 0 0 0.5rem 0;
}

dl {
  margin-bottom: 0.5rem;
}

dl dt {
  font-weight: bold;
  margin-top: 0.5rem;
}

dl dd {
  margin: 0;
}

hr {
  border-bottom: 1px solid rgba(119, 119, 119, 0.3);
  border-left: none;
  border-right: none;
  border-top: none;
  margin: 1.2em 0;
}

sub, sup {
  font-size: 45%;
  line-height: 0;
  position: relative;
  vertical-align: middle;
}

sup {
  top: -0.6rem;
}

sub {
  bottom: -.4rem;
}

small {
  font-size: 70%;
}

span.lg {
  font-size: 110%;
}

span.xl {
  font-size: 130%;
}

p small {
  font-size: 90%;
}

.block-title, .block-footer {
  background-color: #2D363C;
  color: #fff;
  text-align: center;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  margin: 0 0 0;
  clear: both;
  padding: 25px 5% 25px;
}

.block-title a, .block-footer a {
  text-decoration: none;
}

.block-title a:hover, .block-footer a:hover {
  text-decoration: underline;
}

.block-title span, .block-footer span {
  display: table;
  margin: 0 auto -24px auto;
  width: auto;
  padding: 5px 5%;
}

h4.block-title {
  margin: 10px 0 0;
}

h4.block-title span {
  padding: 0 5%;
  margin: 10px auto -11px auto;
}

.block-title.block-title-inverse {
  color: #2D363C;
  background-color: #fff;
}

.block-title.block-title-purple {
  background-color: #bec7ce;
}

.block-title.block-title-purple h1, .block-title.block-title-purple h2, .block-title.block-title-purple h3 {
  color: #2D363C;
}

.block-footer {
  margin: 25px 0 40px;
  border-bottom: 3px solid #eee;
}

.block-footer span {
  margin: 0 auto -16px auto;
}

.page-title {
  margin: 30px 0 25px;
  position: relative;
  clear: both;
  border-bottom: 3px solid #ddd;
  width: 100%;
}

.page-title h1 {
  margin: 0;
}

.page-title h1 small {
  display: block;
  font-size: 52.5%;
}

.page-title .thickbox img {
  width: auto;
}

.page-title img {
  width: 100%;
}

.page-title img + .page-title-inner {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 2%;
  bottom: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.page-title img + .page-title-inner .slide-content-left {
  width: 45%;
  padding: 0 0 0 4%;
}

.page-title img + .page-title-inner h1,
.page-title img + .page-title-inner .breadcrumb a {
  color: #fff;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.9);
}

@media (min-width: 1000px) {
  .page-title img + .page-title-inner h1,
  .page-title img + .page-title-inner .breadcrumb a {
    font-size: 1em;
  }
}

@media (min-width: 1000px) {
  .page-title img + .page-title-inner h1 {
    font-size: 3rem;
  }
}

.breadcrumb {
  margin: 0rem 0 1rem;
  padding: 0;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
}

.breadcrumb h6 {
  font-size: .8rem;
}

.breadcrumb li {
  margin: 0;
  display: inline-block;
  color: #bbb;
  text-transform: uppercase;
  line-height: .8em;
  padding: 0;
}

.breadcrumb li a {
  color: #888;
}

.breadcrumb li a:hover {
  color: #000;
}

.breadcrumb li:after {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-size: .7em;
  color: #999;
  vertical-align: top;
  padding: 1px 1px 0 2px;
  display: inline-block;
}

.page-title .breadcrumb {
  margin: 0 0 -0.5rem;
}

/* Helper Classes */
.align-left, .text-left {
  text-align: left !important;
}

.align-center, .text-center {
  text-align: center !important;
}

.align-right, .text-right {
  text-align: right !important;
}

.margin {
  margin: 0.5rem !important;
}

.padding {
  padding: 0.5rem !important;
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.margin-top {
  margin-top: 0.5rem !important;
}

.margin-bottom {
  margin-bottom: 0.5rem !important;
}

.margin-top-lg {
  margin-top: 1rem !important;
}

.margin-bottom-lg {
  margin-bottom: 1rem !important;
}

.margin-left {
  margin-left: 0.5rem !important;
}

.margin-right {
  margin-right: 0.5rem !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.opacity-half {
  opacity: .5;
}

.full-width {
  width: 100% !important;
}

.block {
  display: block !important;
}

.inline {
  display: inline !important;
}

.inline-block {
  display: inline-block !important;
}

.float-left, .fl_l, .floatleft, .alignleft {
  float: left !important;
}

.float-right, .fl_r, .floatright, .alignright {
  float: right !important;
}

/* wp
.alignleft{
    float:left !important;
    margin:10px 15px 10px 0;
}
.alignright{
    float:right !important;
    margin:10px 0 10px 15px;
}
*/
.flex-end {
  align-self: flex-end;
}

.flex-fit {
  -moz-box-flex: 1  ;
  -webkit-flex: 1  ;
  -ms-flex: 1  ;
  flex: 1  ;
}

.clear-both, .clear, .clearfix {
  clear: both !important;
}

.box-center {
  margin-left: auto !important;
  margin-right: auto !important;
  float: none !important;
  width: 100%;
}

.overflow-hidden {
  overflow: hidden !important;
}

.hide {
  display: none !important;
}

.hide-height {
  max-height: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  overflow: hidden !important;
  border: 0 !important;
}

.hide-opacity {
  visibility: hidden;
  opacity: 0;
}

.show-opacity {
  visibility: visible;
  opacity: 1;
}

.lock-position {
  height: 100%;
  overflow: hidden;
  width: 100%;
  position: fixed;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.visually-hidden-focusable:active,
.visually-hidden-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.text-white {
  color: #fff !important;
}

.text-black {
  color: #000 !important;
}

.text-blue {
  color: #00ADEF !important;
}

.flexbox {
  display: flex;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*modules*/
.browser-alert {
  margin: 0;
  background: #454545;
  color: #fff;
  display: none;
  font-size: 16px;
  position: relative;
  z-index: 1111000;
  text-align: center;
  padding: 10px 25px;
  top: 0;
  width: 100%;
  z-index: 99999;
}

.browser-alert img {
  display: block;
  margin: 0 auto;
  text-align: center;
  width: 300px;
}

.browser-alert a {
  color: #fff;
  text-decoration: underline;
}

.pdfjs {
  background-color: #fff;
}

.pdfjs .textLayer {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  opacity: 0.2;
  line-height: 1;
}

.pdfjs .textLayer > div {
  color: transparent;
  position: absolute;
  white-space: pre;
  cursor: text;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
}

.pdfjs .textLayer .highlight {
  margin: -1px;
  padding: 1px;
  background-color: #b400aa;
  border-radius: 4px;
}

.pdfjs .textLayer .highlight.begin {
  border-radius: 4px 0 0 4px;
}

.pdfjs .textLayer .highlight.end {
  border-radius: 0 4px 4px 0;
}

.pdfjs .textLayer .highlight.middle {
  border-radius: 0;
}

.pdfjs .textLayer .highlight.selected {
  background-color: #006400;
}

.pdfjs .textLayer ::selection {
  background: #00f;
}

.pdfjs .textLayer ::-moz-selection {
  background: #00f;
}

.pdfjs .textLayer .endOfContent {
  display: block;
  position: absolute;
  left: 0;
  top: 100%;
  right: 0;
  bottom: 0;
  z-index: -1;
  cursor: default;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
}

.pdfjs .textLayer .endOfContent.active {
  top: 0;
}

.pdfjs .annotationLayer section {
  position: absolute;
}

.pdfjs .annotationLayer .linkAnnotation > a {
  position: absolute;
  font-size: 1em;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.pdfjs .annotationLayer .linkAnnotation > a {
  background: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7") 0 0 repeat;
}

.pdfjs .annotationLayer .linkAnnotation > a:hover {
  opacity: 0.2;
  background: #ff0;
  box-shadow: 0 2px 10px #ff0;
}

.pdfjs .annotationLayer .textAnnotation img {
  position: absolute;
  cursor: pointer;
}

.pdfjs .annotationLayer .textWidgetAnnotation input,
.pdfjs .annotationLayer .textWidgetAnnotation textarea,
.pdfjs .annotationLayer .choiceWidgetAnnotation select,
.pdfjs .annotationLayer .buttonWidgetAnnotation.checkBox input,
.pdfjs .annotationLayer .buttonWidgetAnnotation.radioButton input {
  background-color: rgba(0, 54, 255, 0.13);
  border: 1px solid transparent;
  box-sizing: border-box;
  font-size: 9px;
  height: 100%;
  padding: 0 3px;
  vertical-align: top;
  width: 100%;
}

.pdfjs .annotationLayer .textWidgetAnnotation textarea {
  font: message-box;
  font-size: 9px;
  resize: none;
}

.pdfjs .annotationLayer .textWidgetAnnotation input[disabled],
.pdfjs .annotationLayer .textWidgetAnnotation textarea[disabled],
.pdfjs .annotationLayer .choiceWidgetAnnotation select[disabled],
.pdfjs .annotationLayer .buttonWidgetAnnotation.checkBox input[disabled],
.pdfjs .annotationLayer .buttonWidgetAnnotation.radioButton input[disabled] {
  background: 0;
  border: 1px solid transparent;
  cursor: not-allowed;
}

.pdfjs .annotationLayer .textWidgetAnnotation input:hover,
.pdfjs .annotationLayer .textWidgetAnnotation textarea:hover,
.pdfjs .annotationLayer .choiceWidgetAnnotation select:hover,
.pdfjs .annotationLayer .buttonWidgetAnnotation.checkBox input:hover,
.pdfjs .annotationLayer .buttonWidgetAnnotation.radioButton input:hover {
  border: 1px solid #000;
}

.pdfjs .annotationLayer .textWidgetAnnotation input:focus,
.pdfjs .annotationLayer .textWidgetAnnotation textarea:focus,
.pdfjs .annotationLayer .choiceWidgetAnnotation select:focus {
  background: 0;
  border: 1px solid transparent;
}

.pdfjs .annotationLayer .textWidgetAnnotation input.comb {
  font-family: monospace;
  padding-left: 2px;
  padding-right: 0;
}

.pdfjs .annotationLayer .textWidgetAnnotation input.comb:focus {
  width: 115%;
}

.pdfjs .annotationLayer .buttonWidgetAnnotation.checkBox input,
.pdfjs .annotationLayer .buttonWidgetAnnotation.radioButton input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

.pdfjs .annotationLayer .popupWrapper {
  position: absolute;
  width: 20em;
}

.pdfjs .annotationLayer .popup {
  position: absolute;
  z-index: 200;
  max-width: 20em;
  background-color: #ff9;
  box-shadow: 0 2px 5px #333;
  border-radius: 2px;
  padding: 0.6em;
  margin-left: 5px;
  cursor: pointer;
  word-wrap: break-word;
}

.pdfjs .annotationLayer .popup h1 {
  font-size: 1em;
  border-bottom: 1px solid #000;
  padding-bottom: 0.2em;
}

.pdfjs .annotationLayer .popup p {
  padding-top: 0.2em;
}

.pdfjs .annotationLayer .highlightAnnotation,
.pdfjs .annotationLayer .underlineAnnotation,
.pdfjs .annotationLayer .squigglyAnnotation,
.pdfjs .annotationLayer .strikeoutAnnotation,
.pdfjs .annotationLayer .fileAttachmentAnnotation {
  cursor: pointer;
}

.pdfjs .pdfViewer .canvasWrapper {
  overflow: hidden;
}

.pdfjs .pdfViewer .page {
  direction: ltr;
  width: 816px;
  height: 1056px;
  margin: 1px auto -8px auto;
  position: relative;
  overflow: visible;
  border: 9px solid transparent;
  background-clip: content-box;
  border-image: url(../assets/images/shadow.png) 9 9 repeat;
  background-color: white;
}

.pdfjs .pdfViewer.removePageBorders .page {
  margin: 0 auto 10px auto;
  border: 0;
}

.pdfjs .pdfViewer.singlePageView {
  display: inline-block;
}

.pdfjs .pdfViewer.singlePageView .page {
  margin: 0;
  border: 0;
}

.pdfjs .pdfViewer .page canvas {
  margin: 0;
  display: block;
}

.pdfjs .pdfViewer .page .loadingIcon {
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: url("../assets/images/loading-icon.gif") center no-repeat;
}

.pdfjs .pdfPresentationMode:-webkit-full-screen .pdfViewer .page {
  margin-bottom: 100%;
  border: 0;
}

.pdfjs .pdfPresentationMode:-moz-full-screen .pdfViewer .page {
  margin-bottom: 100%;
  border: 0;
}

.pdfjs .pdfPresentationMode:-ms-fullscreen .pdfViewer .page {
  margin-bottom: 100% !important;
  border: 0;
}

.pdfjs .pdfPresentationMode:fullscreen .pdfViewer .page {
  margin-bottom: 100%;
  border: 0;
}

.pdfjs * {
  padding: 0;
  margin: 0;
}

.pdfjs input,
.pdfjs button,
.pdfjs select {
  font: message-box;
  outline: 0;
}

.pdfjs .hidden {
  display: none !important;
}

.pdfjs [hidden] {
  display: none !important;
}

.pdfjs #viewerContainer.pdfPresentationMode:-webkit-full-screen {
  top: 0;
  border-top: 2px solid transparent;
  background-color: #000;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: none;
  -webkit-user-select: none;
}

.pdfjs #viewerContainer.pdfPresentationMode:-moz-full-screen {
  top: 0;
  border-top: 2px solid transparent;
  background-color: #000;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: none;
  -moz-user-select: none;
}

.pdfjs #viewerContainer.pdfPresentationMode:-ms-fullscreen {
  top: 0 !important;
  border-top: 2px solid transparent;
  width: 100%;
  height: 100%;
  overflow: hidden !important;
  cursor: none;
  -ms-user-select: none;
}

.pdfjs #viewerContainer.pdfPresentationMode:-ms-fullscreen::-ms-backdrop {
  background-color: #000;
}

.pdfjs #viewerContainer.pdfPresentationMode:fullscreen {
  top: 0;
  border-top: 2px solid transparent;
  background-color: #000;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.pdfjs .pdfPresentationMode:-webkit-full-screen a:not(.internalLink) {
  display: none;
}

.pdfjs .pdfPresentationMode:-moz-full-screen a:not(.internalLink) {
  display: none;
}

.pdfjs .pdfPresentationMode:-ms-fullscreen a:not(.internalLink) {
  display: none !important;
}

.pdfjs .pdfPresentationMode:fullscreen a:not(.internalLink) {
  display: none;
}

.pdfjs .pdfPresentationMode:-webkit-full-screen .textLayer > div {
  cursor: none;
}

.pdfjs .pdfPresentationMode:-moz-full-screen .textLayer > div {
  cursor: none;
}

.pdfjs .pdfPresentationMode:-ms-fullscreen .textLayer > div {
  cursor: none;
}

.pdfjs .pdfPresentationMode:fullscreen .textLayer > div {
  cursor: none;
}

.pdfjs .pdfPresentationMode.pdfPresentationModeControls > *,
.pdfjs .pdfPresentationMode.pdfPresentationModeControls .textLayer > div {
  cursor: default;
}

.pdfjs #outerContainer {
  width: 100%;
  height: 100%;
  position: relative;
}

.pdfjs #sidebarContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 200px;
  visibility: hidden;
  -webkit-transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-duration: 200ms;
  transition-timing-function: ease;
}

html[dir='ltr'] .pdfjs #sidebarContainer {
  -webkit-transition-property: left;
  transition-property: left;
  left: -200px;
}

html[dir='rtl'] .pdfjs #sidebarContainer {
  -webkit-transition-property: right;
  transition-property: right;
  right: -200px;
}

.pdfjs #outerContainer.sidebarMoving > #sidebarContainer,
.pdfjs #outerContainer.sidebarOpen > #sidebarContainer {
  visibility: visible;
}

html[dir='ltr'] .pdfjs #outerContainer.sidebarOpen > #sidebarContainer {
  left: 0;
}

html[dir='rtl'] .pdfjs #outerContainer.sidebarOpen > #sidebarContainer {
  right: 0;
}

.pdfjs #mainContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-width: 320px;
  -webkit-transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-duration: 200ms;
  transition-timing-function: ease;
}

html[dir='ltr'] .pdfjs #outerContainer.sidebarOpen > #mainContainer {
  -webkit-transition-property: left;
  transition-property: left;
  left: 200px;
}

html[dir='rtl'] .pdfjs #outerContainer.sidebarOpen > #mainContainer {
  -webkit-transition-property: right;
  transition-property: right;
  right: 200px;
}

.pdfjs #sidebarContent {
  top: 32px;
  bottom: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  width: 200px;
  background-color: rgba(0, 0, 0, 0.1);
}

html[dir='ltr'] .pdfjs #sidebarContent {
  left: 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
}

html[dir='rtl'] .pdfjs #sidebarContent {
  right: 0;
  box-shadow: inset 1px 0 0 rgba(0, 0, 0, 0.25);
}

.pdfjs #viewerContainer {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 32px;
  right: 0;
  bottom: 0;
  left: 0;
  outline: 0;
}

html[dir='ltr'] .pdfjs #viewerContainer {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.05);
}

html[dir='rtl'] .pdfjs #viewerContainer {
  box-shadow: inset -1px 0 0 rgba(255, 255, 255, 0.05);
}

.pdfjs .toolbar {
  position: relative;
  left: 0;
  right: 0;
  z-index: 9999;
  cursor: default;
}

.pdfjs #toolbarContainer {
  width: 100%;
}

.pdfjs #toolbarSidebar {
  width: 200px;
  height: 32px;
  background-color: #424242;
  background-image: url(../assets/images/texture.png), linear-gradient(rgba(77, 77, 77, 0.99), rgba(64, 64, 64, 0.95));
}

html[dir='ltr'] .pdfjs #toolbarSidebar {
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25), inset 0 -1px 0 rgba(255, 255, 255, 0.05), 0 1px 0 rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.1);
}

html[dir='rtl'] .pdfjs #toolbarSidebar {
  box-shadow: inset 1px 0 0 rgba(0, 0, 0, 0.25), inset 0 1px 0 rgba(255, 255, 255, 0.05), 0 1px 0 rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.1);
}

.pdfjs #toolbarContainer,
.pdfjs .findbar,
.pdfjs .secondaryToolbar {
  position: relative;
  height: 32px;
  background-color: #474747;
  background-image: url(../assets/images/texture.png), linear-gradient(rgba(82, 82, 82, 0.99), rgba(69, 69, 69, 0.95));
}

html[dir='ltr'] .pdfjs #toolbarContainer,
.pdfjs .findbar,
.pdfjs .secondaryToolbar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.08), inset 0 1px 1px rgba(0, 0, 0, 0.15), inset 0 -1px 0 rgba(255, 255, 255, 0.05), 0 1px 0 rgba(0, 0, 0, 0.15), 0 1px 1px rgba(0, 0, 0, 0.1);
}

html[dir='rtl'] .pdfjs #toolbarContainer,
.pdfjs .findbar,
.pdfjs .secondaryToolbar {
  box-shadow: inset -1px 0 0 rgba(255, 255, 255, 0.08), inset 0 1px 1px rgba(0, 0, 0, 0.15), inset 0 -1px 0 rgba(255, 255, 255, 0.05), 0 1px 0 rgba(0, 0, 0, 0.15), 0 1px 1px rgba(0, 0, 0, 0.1);
}

.pdfjs #toolbarViewer {
  height: 32px;
}

.pdfjs #loadingBar {
  position: relative;
  width: 100%;
  height: 4px;
  background-color: #333;
  border-bottom: 1px solid #333;
}

.pdfjs #loadingBar .progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #ddd;
  overflow: hidden;
  -webkit-transition: width 200ms;
  transition: width 200ms;
}

@-webkit-keyframes progressIndeterminate {
  .pdfjs 0% {
    left: -142px;
  }
  .pdfjs 100% {
    left: 0;
  }
}

@keyframes progressIndeterminate {
  .pdfjs 0% {
    left: -142px;
  }
  .pdfjs 100% {
    left: 0;
  }
}

.pdfjs #loadingBar .progress.indeterminate {
  background-color: #999;
  -webkit-transition: none;
  transition: none;
}

.pdfjs #loadingBar .progress.indeterminate .glimmer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: calc(100% + 150px);
  background: repeating-linear-gradient(135deg, #bbb 0, #999 5px, #999 45px, #ddd 55px, #ddd 95px, #bbb 100px);
  -webkit-animation: progressIndeterminate 950ms linear infinite;
  animation: progressIndeterminate 950ms linear infinite;
}

.pdfjs .findbar,
.pdfjs .secondaryToolbar {
  top: 32px;
  position: absolute;
  z-index: 10000;
  height: 32px;
  min-width: 16px;
  padding: 0 6px 0 6px;
  margin: 4px 2px 4px 2px;
  color: #d9d9d9;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  cursor: default;
}

html[dir='ltr'] .pdfjs .findbar {
  left: 68px;
}

html[dir='rtl'] .pdfjs .findbar {
  right: 68px;
}

.pdfjs .findbar label {
  -webkit-user-select: none;
  -moz-user-select: none;
}

.pdfjs #findInput[data-status='pending'] {
  background-image: url(../assets/images/loading-small.png);
  background-repeat: no-repeat;
  background-position: right;
}

html[dir='rtl'] .pdfjs #findInput[data-status='pending'] {
  background-position: left;
}

.pdfjs .secondaryToolbar {
  padding: 6px;
  height: auto;
  z-index: 30000;
}

html[dir='ltr'] .pdfjs .secondaryToolbar {
  right: 4px;
}

html[dir='rtl'] .pdfjs .secondaryToolbar {
  left: 4px;
}

.pdfjs #secondaryToolbarButtonContainer {
  max-width: 200px;
  max-height: 400px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  margin-bottom: -4px;
}

.pdfjs .doorHanger,
.pdfjs .doorHangerRight {
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}

.pdfjs .doorHanger:after,
.pdfjs .doorHanger:before,
.pdfjs .doorHangerRight:after,
.pdfjs .doorHangerRight:before {
  bottom: 100%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.pdfjs .doorHanger:after,
.pdfjs .doorHangerRight:after {
  border-bottom-color: rgba(82, 82, 82, 0.99);
  border-width: 8px;
}

.pdfjs .doorHanger:before,
.pdfjs .doorHangerRight:before {
  border-bottom-color: rgba(0, 0, 0, 0.5);
  border-width: 9px;
}

html[dir='ltr'] .pdfjs .doorHanger:after,
html[dir='rtl'] .pdfjs .doorHangerRight:after {
  left: 13px;
  margin-left: -8px;
}

html[dir='ltr'] .pdfjs .doorHanger:before,
html[dir='rtl'] .pdfjs .doorHangerRight:before {
  left: 13px;
  margin-left: -9px;
}

html[dir='rtl'] .pdfjs .doorHanger:after,
html[dir='ltr'] .pdfjs .doorHangerRight:after {
  right: 13px;
  margin-right: -8px;
}

html[dir='rtl'] .pdfjs .doorHanger:before,
html[dir='ltr'] .pdfjs .doorHangerRight:before {
  right: 13px;
  margin-right: -9px;
}

.pdfjs #findResultsCount {
  background-color: #d9d9d9;
  color: #525252;
  text-align: center;
  padding: 3px 4px;
}

.pdfjs #findMsg {
  font-style: italic;
  color: #a6b7d0;
}

.pdfjs #findInput.notFound {
  background-color: #f66;
}

.pdfjs #toolbarViewerMiddle {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

html[dir='ltr'] .pdfjs #toolbarViewerLeft,
html[dir='rtl'] .pdfjs #toolbarViewerRight {
  float: left;
}

html[dir='ltr'] .pdfjs #toolbarViewerRight,
html[dir='rtl'] .pdfjs #toolbarViewerLeft {
  float: right;
}

html[dir='ltr'] .pdfjs #toolbarViewerLeft > *,
html[dir='ltr'] .pdfjs #toolbarViewerMiddle > *,
html[dir='ltr'] .pdfjs #toolbarViewerRight > *,
html[dir='ltr'] .pdfjs .findbar > * {
  position: relative;
  float: left;
}

html[dir='rtl'] .pdfjs #toolbarViewerLeft > *,
html[dir='rtl'] .pdfjs #toolbarViewerMiddle > *,
html[dir='rtl'] .pdfjs #toolbarViewerRight > *,
html[dir='rtl'] .pdfjs .findbar > * {
  position: relative;
  float: right;
}

html[dir='ltr'] .pdfjs .splitToolbarButton {
  margin: 3px 2px 4px 0;
  display: inline-block;
}

html[dir='rtl'] .pdfjs .splitToolbarButton {
  margin: 3px 0 4px 2px;
  display: inline-block;
}

html[dir='ltr'] .pdfjs .splitToolbarButton > .toolbarButton {
  border-radius: 0;
  float: left;
}

html[dir='rtl'] .pdfjs .splitToolbarButton > .toolbarButton {
  border-radius: 0;
  float: right;
}

.pdfjs .toolbarButton,
.pdfjs .secondaryToolbarButton,
.pdfjs .overlayButton {
  border: 0 none;
  background: 0;
  width: 32px;
  height: 25px;
}

.pdfjs .toolbarButton > span {
  display: inline-block;
  width: 0;
  height: 0;
  overflow: hidden;
}

.pdfjs .toolbarButton[disabled],
.pdfjs .secondaryToolbarButton[disabled],
.pdfjs .overlayButton[disabled] {
  opacity: 0.5;
}

.pdfjs .toolbarButton.group {
  margin-right: 0;
}

.pdfjs .splitToolbarButton.toggled .toolbarButton {
  margin: 0;
}

.pdfjs .splitToolbarButton:hover > .toolbarButton,
.pdfjs .splitToolbarButton:focus > .toolbarButton,
.pdfjs .splitToolbarButton.toggled > .toolbarButton,
.pdfjs .toolbarButton.textButton {
  background-color: rgba(0, 0, 0, 0.12);
  background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.35);
  border-color: rgba(0, 0, 0, 0.32) rgba(0, 0, 0, 0.38) rgba(0, 0, 0, 0.42);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05) inset, 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 1px 0 rgba(255, 255, 255, 0.05);
  -webkit-transition-property: background-color, border-color, box-shadow;
  -webkit-transition-duration: 150ms;
  -webkit-transition-timing-function: ease;
  transition-property: background-color, border-color, box-shadow;
  transition-duration: 150ms;
  transition-timing-function: ease;
}

.pdfjs .splitToolbarButton > .toolbarButton:hover,
.pdfjs .splitToolbarButton > .toolbarButton:focus,
.pdfjs .dropdownToolbarButton:hover,
.pdfjs .overlayButton:hover,
.pdfjs .overlayButton:focus,
.pdfjs .toolbarButton.textButton:hover,
.pdfjs .toolbarButton.textButton:focus {
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05) inset, 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 0 1px rgba(0, 0, 0, 0.05);
  z-index: 199;
}

.pdfjs .splitToolbarButton > .toolbarButton {
  position: relative;
}

html[dir='ltr'] .pdfjs .splitToolbarButton > .toolbarButton:first-child,
html[dir='rtl'] .pdfjs .splitToolbarButton > .toolbarButton:last-child {
  position: relative;
  margin: 0;
  margin-right: -1px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-right-color: transparent;
}

html[dir='ltr'] .pdfjs .splitToolbarButton > .toolbarButton:last-child,
html[dir='rtl'] .pdfjs .splitToolbarButton > .toolbarButton:first-child {
  position: relative;
  margin: 0;
  margin-left: -1px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-left-color: transparent;
}

.pdfjs .splitToolbarButtonSeparator {
  padding: 8px 0;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.08);
  display: inline-block;
  margin: 5px 0;
}

html[dir='ltr'] .pdfjs .splitToolbarButtonSeparator {
  float: left;
}

html[dir='rtl'] .pdfjs .splitToolbarButtonSeparator {
  float: right;
}

.pdfjs .splitToolbarButton:hover > .splitToolbarButtonSeparator,
.pdfjs .splitToolbarButton.toggled > .splitToolbarButtonSeparator {
  padding: 12px 0;
  margin: 1px 0;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.03);
  -webkit-transition-property: padding;
  -webkit-transition-duration: 10ms;
  -webkit-transition-timing-function: ease;
  transition-property: padding;
  transition-duration: 10ms;
  transition-timing-function: ease;
}

.pdfjs .toolbarButton,
.pdfjs .dropdownToolbarButton,
.pdfjs .secondaryToolbarButton,
.pdfjs .overlayButton {
  min-width: 16px;
  padding: 2px 6px 0;
  border: 1px solid transparent;
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  line-height: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: default;
  -webkit-transition-property: background-color, border-color, box-shadow;
  -webkit-transition-duration: 150ms;
  -webkit-transition-timing-function: ease;
  transition-property: background-color, border-color, box-shadow;
  transition-duration: 150ms;
  transition-timing-function: ease;
}

html[dir='ltr'] .pdfjs .toolbarButton,
html[dir='ltr'] .pdfjs .overlayButton,
html[dir='ltr'] .pdfjs .dropdownToolbarButton {
  margin: 3px 2px 4px 0;
}

html[dir='rtl'] .pdfjs .toolbarButton,
html[dir='rtl'] .pdfjs .overlayButton,
html[dir='rtl'] .pdfjs .dropdownToolbarButton {
  margin: 3px 0 4px 2px;
}

.pdfjs .toolbarButton:hover,
.pdfjs .toolbarButton:focus,
.pdfjs .dropdownToolbarButton,
.pdfjs .overlayButton,
.pdfjs .secondaryToolbarButton:hover,
.pdfjs .secondaryToolbarButton:focus {
  background-color: rgba(0, 0, 0, 0.12);
  background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.35);
  border-color: rgba(0, 0, 0, 0.32) rgba(0, 0, 0, 0.38) rgba(0, 0, 0, 0.42);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05) inset, 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 1px 0 rgba(255, 255, 255, 0.05);
}

.pdfjs .toolbarButton:hover:active,
.pdfjs .overlayButton:hover:active,
.pdfjs .dropdownToolbarButton:hover:active,
.pdfjs .secondaryToolbarButton:hover:active {
  background-color: rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
  border-color: rgba(0, 0, 0, 0.35) rgba(0, 0, 0, 0.4) rgba(0, 0, 0, 0.45);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset, 0 0 1px rgba(0, 0, 0, 0.2) inset, 0 1px 0 rgba(255, 255, 255, 0.05);
  -webkit-transition-property: background-color, border-color, box-shadow;
  -webkit-transition-duration: 10ms;
  -webkit-transition-timing-function: linear;
  transition-property: background-color, border-color, box-shadow;
  transition-duration: 10ms;
  transition-timing-function: linear;
}

.pdfjs .toolbarButton.toggled,
.pdfjs .splitToolbarButton.toggled > .toolbarButton.toggled,
.pdfjs .secondaryToolbarButton.toggled {
  background-color: rgba(0, 0, 0, 0.3);
  background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
  border-color: rgba(0, 0, 0, 0.4) rgba(0, 0, 0, 0.45) rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset, 0 0 1px rgba(0, 0, 0, 0.2) inset, 0 1px 0 rgba(255, 255, 255, 0.05);
  -webkit-transition-property: background-color, border-color, box-shadow;
  -webkit-transition-duration: 10ms;
  -webkit-transition-timing-function: linear;
  transition-property: background-color, border-color, box-shadow;
  transition-duration: 10ms;
  transition-timing-function: linear;
}

.pdfjs .toolbarButton.toggled:hover:active,
.pdfjs .splitToolbarButton.toggled > .toolbarButton.toggled:hover:active,
.pdfjs .secondaryToolbarButton.toggled:hover:active {
  background-color: rgba(0, 0, 0, 0.4);
  border-color: rgba(0, 0, 0, 0.4) rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.55);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2) inset, 0 0 1px rgba(0, 0, 0, 0.3) inset, 0 1px 0 rgba(255, 255, 255, 0.05);
}

.pdfjs .dropdownToolbarButton {
  width: 120px;
  max-width: 120px;
  padding: 0;
  overflow: hidden;
  background: url(../assets/images/toolbarButton-menuArrows.png) no-repeat;
}

html[dir='ltr'] .pdfjs .dropdownToolbarButton {
  background-position: 95%;
}

html[dir='rtl'] .pdfjs .dropdownToolbarButton {
  background-position: 5%;
}

.pdfjs .dropdownToolbarButton > select {
  min-width: 140px;
  font-size: 12px;
  color: #f2f2f2;
  margin: 0;
  padding: 3px 2px 2px;
  border: 0;
  background: rgba(0, 0, 0, 0);
}

.pdfjs .dropdownToolbarButton > select > option {
  background: #3d3d3d;
}

.pdfjs #customScaleOption {
  display: none;
}

.pdfjs #pageWidthOption {
  border-bottom: 1px rgba(255, 255, 255, 0.5) solid;
}

html[dir='ltr'] .pdfjs .splitToolbarButton:first-child,
html[dir='ltr'] .pdfjs .toolbarButton:first-child,
html[dir='rtl'] .pdfjs .splitToolbarButton:last-child,
html[dir='rtl'] .pdfjs .toolbarButton:last-child {
  margin-left: 4px;
}

html[dir='ltr'] .pdfjs .splitToolbarButton:last-child,
html[dir='ltr'] .pdfjs .toolbarButton:last-child,
html[dir='rtl'] .pdfjs .splitToolbarButton:first-child,
html[dir='rtl'] .pdfjs .toolbarButton:first-child {
  margin-right: 4px;
}

.pdfjs .toolbarButtonSpacer {
  width: 30px;
  display: inline-block;
  height: 1px;
}

html[dir='ltr'] .pdfjs #findPrevious {
  margin-left: 3px;
}

html[dir='ltr'] .pdfjs #findNext {
  margin-right: 3px;
}

html[dir='rtl'] .pdfjs #findPrevious {
  margin-right: 3px;
}

html[dir='rtl'] .pdfjs #findNext {
  margin-left: 3px;
}

.pdfjs .toolbarButton::before,
.pdfjs .secondaryToolbarButton::before {
  position: absolute;
  display: inline-block;
  top: 4px;
  left: 7px;
}

html[dir='ltr'] .pdfjs .secondaryToolbarButton::before {
  left: 4px;
}

html[dir='rtl'] .pdfjs .secondaryToolbarButton::before {
  right: 4px;
}

html[dir='ltr'] .pdfjs .toolbarButton#sidebarToggle::before {
  content: url(../assets/images/toolbarButton-sidebarToggle.png);
}

html[dir='rtl'] .pdfjs .toolbarButton#sidebarToggle::before {
  content: url(../assets/images/toolbarButton-sidebarToggle-rtl.png);
}

html[dir='ltr'] .pdfjs .toolbarButton#secondaryToolbarToggle::before {
  content: url(../assets/images/toolbarButton-secondaryToolbarToggle.png);
}

html[dir='rtl'] .pdfjs .toolbarButton#secondaryToolbarToggle::before {
  content: url(../assets/images/toolbarButton-secondaryToolbarToggle-rtl.png);
}

html[dir='ltr'] .pdfjs .toolbarButton.findPrevious::before {
  content: url(../assets/images/findbarButton-previous.png);
}

html[dir='rtl'] .pdfjs .toolbarButton.findPrevious::before {
  content: url(../assets/images/findbarButton-previous-rtl.png);
}

html[dir='ltr'] .pdfjs .toolbarButton.findNext::before {
  content: url(../assets/images/findbarButton-next.png);
}

html[dir='rtl'] .pdfjs .toolbarButton.findNext::before {
  content: url(../assets/images/findbarButton-next-rtl.png);
}

html[dir='ltr'] .pdfjs .toolbarButton.pageUp::before {
  content: url(../assets/images/toolbarButton-pageUp.png);
}

html[dir='rtl'] .pdfjs .toolbarButton.pageUp::before {
  content: url(../assets/images/toolbarButton-pageUp-rtl.png);
}

html[dir='ltr'] .pdfjs .toolbarButton.pageDown::before {
  content: url(../assets/images/toolbarButton-pageDown.png);
}

html[dir='rtl'] .pdfjs .toolbarButton.pageDown::before {
  content: url(../assets/images/toolbarButton-pageDown-rtl.png);
}

.pdfjs .toolbarButton.zoomOut::before {
  content: url(../assets/images/toolbarButton-zoomOut.png);
}

.pdfjs .toolbarButton.zoomIn::before {
  content: url(../assets/images/toolbarButton-zoomIn.png);
}

.pdfjs .toolbarButton.presentationMode::before,
.pdfjs .secondaryToolbarButton.presentationMode::before {
  content: url(../assets/images/toolbarButton-presentationMode.png);
}

.pdfjs .toolbarButton.print::before,
.pdfjs .secondaryToolbarButton.print::before {
  content: url(../assets/images/toolbarButton-print.png);
}

.pdfjs .toolbarButton.openFile::before,
.pdfjs .secondaryToolbarButton.openFile::before {
  content: url(../assets/images/toolbarButton-openFile.png);
}

.pdfjs .toolbarButton.download::before,
.pdfjs .secondaryToolbarButton.download::before {
  content: url(../assets/images/toolbarButton-download.png);
}

.pdfjs .toolbarButton.bookmark,
.pdfjs .secondaryToolbarButton.bookmark {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: 0;
  padding-top: 4px;
  text-decoration: none;
}

.pdfjs .secondaryToolbarButton.bookmark {
  padding-top: 5px;
}

.pdfjs .bookmark[href='#'] {
  opacity: 0.5;
  pointer-events: none;
}

.pdfjs .toolbarButton.bookmark::before,
.pdfjs .secondaryToolbarButton.bookmark::before {
  content: url(../assets/images/toolbarButton-bookmark.png);
}

.pdfjs #viewThumbnail.toolbarButton::before {
  content: url(../assets/images/toolbarButton-viewThumbnail.png);
}

html[dir='ltr'] .pdfjs #viewOutline.toolbarButton::before {
  content: url(../assets/images/toolbarButton-viewOutline.png);
}

html[dir='rtl'] .pdfjs #viewOutline.toolbarButton::before {
  content: url(../assets/images/toolbarButton-viewOutline-rtl.png);
}

.pdfjs #viewAttachments.toolbarButton::before {
  content: url(../assets/images/toolbarButton-viewAttachments.png);
}

.pdfjs #viewFind.toolbarButton::before {
  content: url(../assets/images/toolbarButton-search.png);
}

.pdfjs .toolbarButton.pdfSidebarNotification::after {
  position: absolute;
  display: inline-block;
  top: 1px;
  content: '';
  background-color: #70db55;
  height: 9px;
  width: 9px;
  border-radius: 50%;
}

html[dir='ltr'] .pdfjs .toolbarButton.pdfSidebarNotification::after {
  left: 17px;
}

html[dir='rtl'] .pdfjs .toolbarButton.pdfSidebarNotification::after {
  right: 17px;
}

.pdfjs .secondaryToolbarButton {
  position: relative;
  margin: 0 0 4px 0;
  padding: 3px 0 1px 0;
  height: auto;
  min-height: 25px;
  width: auto;
  min-width: 100%;
  white-space: normal;
}

html[dir='ltr'] .pdfjs .secondaryToolbarButton {
  padding-left: 24px;
  text-align: left;
}

html[dir='rtl'] .pdfjs .secondaryToolbarButton {
  padding-right: 24px;
  text-align: right;
}

html[dir='ltr'] .pdfjs .secondaryToolbarButton.bookmark {
  padding-left: 27px;
}

html[dir='rtl'] .pdfjs .secondaryToolbarButton.bookmark {
  padding-right: 27px;
}

html[dir='ltr'] .pdfjs .secondaryToolbarButton > span {
  padding-right: 4px;
}

html[dir='rtl'] .pdfjs .secondaryToolbarButton > span {
  padding-left: 4px;
}

.pdfjs .secondaryToolbarButton.firstPage::before {
  content: url(../assets/images/secondaryToolbarButton-firstPage.png);
}

.pdfjs .secondaryToolbarButton.lastPage::before {
  content: url(../assets/images/secondaryToolbarButton-lastPage.png);
}

.pdfjs .secondaryToolbarButton.rotateCcw::before {
  content: url(../assets/images/secondaryToolbarButton-rotateCcw.png);
}

.pdfjs .secondaryToolbarButton.rotateCw::before {
  content: url(../assets/images/secondaryToolbarButton-rotateCw.png);
}

.pdfjs .secondaryToolbarButton.handTool::before {
  content: url(../assets/images/secondaryToolbarButton-handTool.png);
}

.pdfjs .secondaryToolbarButton.documentProperties::before {
  content: url(../assets/images/secondaryToolbarButton-documentProperties.png);
}

.pdfjs .verticalToolbarSeparator {
  display: block;
  padding: 8px 0;
  margin: 8px 4px;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.08);
}

html[dir='ltr'] .pdfjs .verticalToolbarSeparator {
  margin-left: 2px;
}

html[dir='rtl'] .pdfjs .verticalToolbarSeparator {
  margin-right: 2px;
}

.pdfjs .horizontalToolbarSeparator {
  display: block;
  margin: 0 0 4px 0;
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.08);
}

.pdfjs .toolbarField {
  padding: 3px 6px;
  margin: 4px 0 4px 0;
  border: 1px solid transparent;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.09);
  background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.35);
  border-color: rgba(0, 0, 0, 0.32) rgba(0, 0, 0, 0.38) rgba(0, 0, 0, 0.42);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05) inset, 0 1px 0 rgba(255, 255, 255, 0.05);
  color: #f2f2f2;
  font-size: 12px;
  line-height: 14px;
  outline-style: none;
  transition-property: background-color, border-color, box-shadow;
  transition-duration: 150ms;
  transition-timing-function: ease;
}

.pdfjs .toolbarField[type='checkbox'] {
  display: inline-block;
  margin: 8px 0;
}

.pdfjs .toolbarField.pageNumber {
  -moz-appearance: textfield;
  min-width: 16px;
  text-align: right;
  width: 40px;
}

.pdfjs .toolbarField.pageNumber.visiblePageIsLoading {
  background-image: url(../assets/images/loading-small.png);
  background-repeat: no-repeat;
  background-position: 1px;
}

.pdfjs .toolbarField.pageNumber::-webkit-inner-spin-button,
.pdfjs .toolbarField.pageNumber::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.pdfjs .toolbarField:hover {
  background-color: rgba(255, 255, 255, 0.11);
  border-color: rgba(0, 0, 0, 0.4) rgba(0, 0, 0, 0.43) rgba(0, 0, 0, 0.45);
}

.pdfjs .toolbarField:focus {
  background-color: rgba(255, 255, 255, 0.15);
  border-color: rgba(77, 184, 255, 0.8) rgba(77, 184, 255, 0.85) rgba(77, 184, 255, 0.9);
}

.pdfjs .toolbarLabel {
  min-width: 16px;
  padding: 3px 6px 3px 2px;
  margin: 4px 2px 4px 0;
  border: 1px solid transparent;
  border-radius: 2px;
  color: #d9d9d9;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  -webkit-user-select: none;
  -moz-user-select: none;
  cursor: default;
}

.pdfjs #thumbnailView {
  position: absolute;
  width: 120px;
  top: 0;
  bottom: 0;
  padding: 10px 40px 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.pdfjs .thumbnail {
  float: left;
  margin-bottom: 5px;
}

.pdfjs #thumbnailView > a:last-of-type > .thumbnail {
  margin-bottom: 10px;
}

.pdfjs #thumbnailView > a:last-of-type > .thumbnail:not([data-loaded]) {
  margin-bottom: 9px;
}

.pdfjs .thumbnail:not([data-loaded]) {
  border: 1px dashed rgba(255, 255, 255, 0.5);
  margin: -1px -1px 4px -1px;
}

.pdfjs .thumbnailImage {
  border: 1px solid transparent;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5), 0 2px 8px rgba(0, 0, 0, 0.3);
  opacity: 0.8;
  z-index: 99;
  background-color: white;
  background-clip: content-box;
}

.pdfjs .thumbnailSelectionRing {
  border-radius: 2px;
  padding: 7px;
}

.pdfjs a:focus > .thumbnail > .thumbnailSelectionRing > .thumbnailImage,
.pdfjs .thumbnail:hover > .thumbnailSelectionRing > .thumbnailImage {
  opacity: 0.9;
}

.pdfjs a:focus > .thumbnail > .thumbnailSelectionRing,
.pdfjs .thumbnail:hover > .thumbnailSelectionRing {
  background-color: rgba(255, 255, 255, 0.15);
  background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
  background-clip: padding-box;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05) inset, 0 0 1px rgba(255, 255, 255, 0.2) inset, 0 0 1px rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.9);
}

.pdfjs .thumbnail.selected > .thumbnailSelectionRing > .thumbnailImage {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
  opacity: 1;
}

.pdfjs .thumbnail.selected > .thumbnailSelectionRing {
  background-color: rgba(255, 255, 255, 0.3);
  background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
  background-clip: padding-box;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05) inset, 0 0 1px rgba(255, 255, 255, 0.1) inset, 0 0 1px rgba(0, 0, 0, 0.2);
  color: white;
}

.pdfjs #outlineView,
.pdfjs #attachmentsView {
  position: absolute;
  width: 192px;
  top: 0;
  bottom: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-user-select: none;
  -moz-user-select: none;
}

.pdfjs #outlineView {
  padding: 4px 4px 0;
}

.pdfjs #attachmentsView {
  padding: 3px 4px 0;
}

html[dir='ltr'] .pdfjs .outlineWithDeepNesting > .outlineItem,
html[dir='ltr'] .pdfjs .outlineItem > .outlineItems {
  margin-left: 20px;
}

html[dir='rtl'] .pdfjs .outlineWithDeepNesting > .outlineItem,
html[dir='rtl'] .pdfjs .outlineItem > .outlineItems {
  margin-right: 20px;
}

.pdfjs .outlineItem > a,
.pdfjs .attachmentsItem > button {
  text-decoration: none;
  display: inline-block;
  min-width: 95%;
  min-width: calc(100% - 4px);
  height: auto;
  margin-bottom: 1px;
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 13px;
  line-height: 15px;
  -moz-user-select: none;
  white-space: normal;
}

.pdfjs .attachmentsItem > button {
  border: 0 none;
  background: 0;
  cursor: pointer;
  width: 100%;
}

html[dir='ltr'] .pdfjs .outlineItem > a {
  padding: 2px 0 5px 4px;
}

html[dir='ltr'] .pdfjs .attachmentsItem > button {
  padding: 2px 0 3px 7px;
  text-align: left;
}

html[dir='rtl'] .pdfjs .outlineItem > a {
  padding: 2px 4px 5px 0;
}

html[dir='rtl'] .pdfjs .attachmentsItem > button {
  padding: 2px 7px 3px 0;
  text-align: right;
}

.pdfjs .outlineItemToggler {
  position: relative;
  height: 0;
  width: 0;
  color: rgba(255, 255, 255, 0.5);
}

.pdfjs .outlineItemToggler::before {
  content: url(../assets/images/treeitem-expanded.png);
  display: inline-block;
  position: absolute;
}

html[dir='ltr'] .pdfjs .outlineItemToggler.outlineItemsHidden::before {
  content: url(../assets/images/treeitem-collapsed.png);
}

html[dir='rtl'] .pdfjs .outlineItemToggler.outlineItemsHidden::before {
  content: url(../assets/images/treeitem-collapsed-rtl.png);
}

.pdfjs .outlineItemToggler.outlineItemsHidden ~ .outlineItems {
  display: none;
}

html[dir='ltr'] .pdfjs .outlineItemToggler {
  float: left;
}

html[dir='rtl'] .pdfjs .outlineItemToggler {
  float: right;
}

html[dir='ltr'] .pdfjs .outlineItemToggler::before {
  right: 4px;
}

html[dir='rtl'] .pdfjs .outlineItemToggler::before {
  left: 4px;
}

.pdfjs .outlineItemToggler:hover,
.pdfjs .outlineItemToggler:hover + a,
.pdfjs .outlineItemToggler:hover ~ .outlineItems,
.pdfjs .outlineItem > a:hover,
.pdfjs .attachmentsItem > button:hover {
  background-color: rgba(255, 255, 255, 0.02);
  background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
  background-clip: padding-box;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05) inset, 0 0 1px rgba(255, 255, 255, 0.2) inset, 0 0 1px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.9);
}

.pdfjs .outlineItem.selected {
  background-color: rgba(255, 255, 255, 0.08);
  background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
  background-clip: padding-box;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05) inset, 0 0 1px rgba(255, 255, 255, 0.1) inset, 0 0 1px rgba(0, 0, 0, 0.2);
  color: white;
}

.pdfjs .noResults {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
  font-style: italic;
  cursor: default;
}

.pdfjs ::selection {
  background: rgba(0, 0, 255, 0.3);
}

.pdfjs ::-moz-selection {
  background: rgba(0, 0, 255, 0.3);
}

.pdfjs #errorWrapper {
  background: none repeat scroll 0 0 #f55;
  color: white;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 1000;
  padding: 3px;
  font-size: 0.8em;
}

.pdfjs .loadingInProgress #errorWrapper {
  top: 37px;
}

.pdfjs #errorMessageLeft {
  float: left;
}

.pdfjs #errorMessageRight {
  float: right;
}

.pdfjs #errorMoreInfo {
  background-color: #fff;
  color: black;
  padding: 3px;
  margin: 3px;
  width: 98%;
}

.pdfjs .overlayButton {
  width: auto;
  margin: 3px 4px 2px 4px !important;
  padding: 2px 6px 3px 6px;
}

.pdfjs #overlayContainer {
  display: table;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 40000;
}

.pdfjs #overlayContainer > * {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.pdfjs #overlayContainer > .container {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.pdfjs #overlayContainer > .container > .dialog {
  display: inline-block;
  padding: 15px;
  border-spacing: 4px;
  color: #d9d9d9;
  font-size: 12px;
  line-height: 14px;
  background-color: #474747;
  background-image: url(../assets/images/texture.png), linear-gradient(rgba(82, 82, 82, 0.99), rgba(69, 69, 69, 0.95));
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.08), inset 0 1px 1px rgba(0, 0, 0, 0.15), inset 0 -1px 0 rgba(255, 255, 255, 0.05), 0 1px 0 rgba(0, 0, 0, 0.15), 0 1px 1px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}

.pdfjs .dialog > .row {
  display: table-row;
}

.pdfjs .dialog > .row > * {
  display: table-cell;
}

.pdfjs .dialog .toolbarField {
  margin: 5px 0;
}

.pdfjs .dialog .separator {
  display: block;
  margin: 4px 0 4px 0;
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.08);
}

.pdfjs .dialog .buttonRow {
  text-align: center;
  vertical-align: middle;
}

.pdfjs .dialog :link {
  color: white;
}

.pdfjs #passwordOverlay > .dialog {
  text-align: center;
}

.pdfjs #passwordOverlay .toolbarField {
  width: 200px;
}

.pdfjs #documentPropertiesOverlay > .dialog {
  text-align: left;
}

.pdfjs #documentPropertiesOverlay .row > * {
  min-width: 100px;
}

html[dir='ltr'] .pdfjs #documentPropertiesOverlay .row > * {
  text-align: left;
}

html[dir='rtl'] .pdfjs #documentPropertiesOverlay .row > * {
  text-align: right;
}

.pdfjs #documentPropertiesOverlay .row > span {
  width: 125px;
  word-wrap: break-word;
}

.pdfjs #documentPropertiesOverlay .row > p {
  max-width: 225px;
  word-wrap: break-word;
}

.pdfjs #documentPropertiesOverlay .buttonRow {
  margin-top: 10px;
}

.pdfjs .clearBoth {
  clear: both;
}

.pdfjs .fileInput {
  background: white;
  color: black;
  margin-top: 5px;
  visibility: hidden;
  position: fixed;
  right: 0;
  top: 0;
}

.pdfjs #PDFBug {
  background: none repeat scroll 0 0 white;
  border: 1px solid #666;
  position: fixed;
  top: 32px;
  right: 0;
  bottom: 0;
  font-size: 10px;
  padding: 0;
  width: 300px;
}

.pdfjs #PDFBug .controls {
  background: #eee;
  border-bottom: 1px solid #666;
  padding: 3px;
}

.pdfjs #PDFBug .panels {
  bottom: 0;
  left: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  right: 0;
  top: 27px;
}

.pdfjs #PDFBug button.active {
  font-weight: bold;
}

.pdfjs .debuggerShowText {
  background: none repeat scroll 0 0 yellow;
  color: blue;
}

.pdfjs .debuggerHideText:hover {
  background: none repeat scroll 0 0 yellow;
}

.pdfjs #PDFBug .stats {
  font-family: courier;
  font-size: 10px;
  white-space: pre;
}

.pdfjs #PDFBug .stats .title {
  font-weight: bold;
}

.pdfjs #PDFBug table {
  font-size: 10px;
}

.pdfjs #viewer.textLayer-visible .textLayer {
  opacity: 1;
}

.pdfjs #viewer.textLayer-visible .canvasWrapper {
  background-color: #80ff80;
}

.pdfjs #viewer.textLayer-visible .canvasWrapper canvas {
  mix-blend-mode: screen;
}

.pdfjs #viewer.textLayer-visible .textLayer > div {
  background-color: rgba(255, 255, 0, 0.1);
  color: black;
  border: solid 1px rgba(255, 0, 0, 0.5);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.pdfjs #viewer.textLayer-hover .textLayer > div:hover {
  background-color: white;
  color: black;
}

.pdfjs #viewer.textLayer-shadow .textLayer > div {
  background-color: rgba(255, 255, 255, 0.6);
  color: black;
}

.pdfjs .grab-to-pan-grab {
  cursor: url("../assets/images/grab.cur"), move !important;
  cursor: -webkit-grab !important;
  cursor: -moz-grab !important;
  cursor: grab !important;
}

.pdfjs .grab-to-pan-grab *:not(input):not(textarea):not(button):not(select):not(:link) {
  cursor: inherit !important;
}

.pdfjs .grab-to-pan-grab:active,
.pdfjs .grab-to-pan-grabbing {
  cursor: url("../assets/images/grabbing.cur"), move !important;
  cursor: -webkit-grabbing !important;
  cursor: -moz-grabbing !important;
  cursor: grabbing !important;
  position: fixed;
  background: transparent;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 50000;
}

@page {
  margin: 0;
}

.pdfjs #printContainer {
  display: none;
}

@media screen and (min-resolution: 2dppx) {
  .pdfjs .toolbarButton::before {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    top: -5px;
  }
  .pdfjs .secondaryToolbarButton::before {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    top: -4px;
  }
  html[dir='ltr'] .pdfjs .toolbarButton::before,
  html[dir='rtl'] .pdfjs .toolbarButton::before {
    left: -1px;
  }
  html[dir='ltr'] .pdfjs .secondaryToolbarButton::before {
    left: -2px;
  }
  html[dir='rtl'] .pdfjs .secondaryToolbarButton::before {
    left: 186px;
  }
  .pdfjs .toolbarField.pageNumber.visiblePageIsLoading,
  .pdfjs #findInput[data-status='pending'] {
    background-image: url(../assets/images/loading-small@2x.png);
    background-size: 16px 17px;
  }
  .pdfjs .dropdownToolbarButton {
    background: url(../assets/images/toolbarButton-menuArrows@2x.png) no-repeat;
    background-size: 7px 16px;
  }
  html[dir='ltr'] .pdfjs .toolbarButton#sidebarToggle::before {
    content: url(../assets/images/toolbarButton-sidebarToggle@2x.png);
  }
  html[dir='rtl'] .pdfjs .toolbarButton#sidebarToggle::before {
    content: url(../assets/images/toolbarButton-sidebarToggle-rtl@2x.png);
  }
  html[dir='ltr'] .pdfjs .toolbarButton#secondaryToolbarToggle::before {
    content: url(../assets/images/toolbarButton-secondaryToolbarToggle@2x.png);
  }
  html[dir='rtl'] .pdfjs .toolbarButton#secondaryToolbarToggle::before {
    content: url(../assets/images/toolbarButton-secondaryToolbarToggle-rtl@2x.png);
  }
  html[dir='ltr'] .pdfjs .toolbarButton.findPrevious::before {
    content: url(../assets/images/findbarButton-previous@2x.png);
  }
  html[dir='rtl'] .pdfjs .toolbarButton.findPrevious::before {
    content: url(../assets/images/findbarButton-previous-rtl@2x.png);
  }
  html[dir='ltr'] .pdfjs .toolbarButton.findNext::before {
    content: url(../assets/images/findbarButton-next@2x.png);
  }
  html[dir='rtl'] .pdfjs .toolbarButton.findNext::before {
    content: url(../assets/images/findbarButton-next-rtl@2x.png);
  }
  html[dir='ltr'] .pdfjs .toolbarButton.pageUp::before {
    content: url(../assets/images/toolbarButton-pageUp@2x.png);
  }
  html[dir='rtl'] .pdfjs .toolbarButton.pageUp::before {
    content: url(../assets/images/toolbarButton-pageUp-rtl@2x.png);
  }
  html[dir='ltr'] .pdfjs .toolbarButton.pageDown::before {
    content: url(../assets/images/toolbarButton-pageDown@2x.png);
  }
  html[dir='rtl'] .pdfjs .toolbarButton.pageDown::before {
    content: url(../assets/images/toolbarButton-pageDown-rtl@2x.png);
  }
  .pdfjs .toolbarButton.zoomIn::before {
    content: url(../assets/images/toolbarButton-zoomIn@2x.png);
  }
  .pdfjs .toolbarButton.zoomOut::before {
    content: url(../assets/images/toolbarButton-zoomOut@2x.png);
  }
  .pdfjs .toolbarButton.presentationMode::before,
  .pdfjs .secondaryToolbarButton.presentationMode::before {
    content: url(../assets/images/toolbarButton-presentationMode@2x.png);
  }
  .pdfjs .toolbarButton.print::before,
  .pdfjs .secondaryToolbarButton.print::before {
    content: url(../assets/images/toolbarButton-print@2x.png);
  }
  .pdfjs .toolbarButton.openFile::before,
  .pdfjs .secondaryToolbarButton.openFile::before {
    content: url(../assets/images/toolbarButton-openFile@2x.png);
  }
  .pdfjs .toolbarButton.download::before,
  .pdfjs .secondaryToolbarButton.download::before {
    content: url(../assets/images/toolbarButton-download@2x.png);
  }
  .pdfjs .toolbarButton.bookmark::before,
  .pdfjs .secondaryToolbarButton.bookmark::before {
    content: url(../assets/images/toolbarButton-bookmark@2x.png);
  }
  .pdfjs #viewThumbnail.toolbarButton::before {
    content: url(../assets/images/toolbarButton-viewThumbnail@2x.png);
  }
  html[dir='ltr'] .pdfjs #viewOutline.toolbarButton::before {
    content: url(../assets/images/toolbarButton-viewOutline@2x.png);
  }
  html[dir='rtl'] .pdfjs #viewOutline.toolbarButton::before {
    content: url(../assets/images/toolbarButton-viewOutline-rtl@2x.png);
  }
  .pdfjs #viewAttachments.toolbarButton::before {
    content: url(../assets/images/toolbarButton-viewAttachments@2x.png);
  }
  .pdfjs #viewFind.toolbarButton::before {
    content: url(../assets/images/toolbarButton-search@2x.png);
  }
  .pdfjs .secondaryToolbarButton.firstPage::before {
    content: url(../assets/images/secondaryToolbarButton-firstPage@2x.png);
  }
  .pdfjs .secondaryToolbarButton.lastPage::before {
    content: url(../assets/images/secondaryToolbarButton-lastPage@2x.png);
  }
  .pdfjs .secondaryToolbarButton.rotateCcw::before {
    content: url(../assets/images/secondaryToolbarButton-rotateCcw@2x.png);
  }
  .pdfjs .secondaryToolbarButton.rotateCw::before {
    content: url(../assets/images/secondaryToolbarButton-rotateCw@2x.png);
  }
  .pdfjs .secondaryToolbarButton.handTool::before {
    content: url(../assets/images/secondaryToolbarButton-handTool@2x.png);
  }
  .pdfjs .secondaryToolbarButton.documentProperties::before {
    content: url(../assets/images/secondaryToolbarButton-documentProperties@2x.png);
  }
  .pdfjs .outlineItemToggler::before {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    top: -1px;
    content: url(../assets/images/treeitem-expanded@2x.png);
  }
  html[dir='ltr'] .pdfjs .outlineItemToggler.outlineItemsHidden::before {
    content: url(../assets/images/treeitem-collapsed@2x.png);
  }
  html[dir='rtl'] .pdfjs .outlineItemToggler.outlineItemsHidden::before {
    content: url(../assets/images/treeitem-collapsed-rtl@2x.png);
  }
  html[dir='ltr'] .pdfjs .outlineItemToggler::before {
    right: 0;
  }
  html[dir='rtl'] .pdfjs .outlineItemToggler::before {
    left: 0;
  }
}

@media print {
  body {
    background: transparent none;
  }
  .pdfjs #sidebarContainer,
  .pdfjs #secondaryToolbar,
  .pdfjs .toolbar,
  .pdfjs #loadingBox,
  .pdfjs #errorWrapper,
  .pdfjs .textLayer {
    display: none;
  }
  .pdfjs #viewerContainer {
    overflow: visible;
  }
  .pdfjs #mainContainer,
  .pdfjs #viewerContainer,
  .pdfjs .page,
  .pdfjs .page canvas {
    position: static;
    padding: 0;
    margin: 0;
  }
  .pdfjs .page {
    float: left;
    display: none;
    border: 0;
    box-shadow: none;
    background-clip: content-box;
    background-color: white;
  }
  .pdfjs .page[data-loaded] {
    display: block;
  }
  .pdfjs .fileInput {
    display: none;
  }
  body[data-pdfjsprinting] .pdfjs #outerContainer {
    display: none;
  }
  body[data-pdfjsprinting] .pdfjs #printContainer {
    display: block;
  }
  .pdfjs #printContainer {
    height: 100%;
  }
  .pdfjs #printContainer > div {
    position: relative;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    overflow: visible;
    page-break-after: always;
    page-break-inside: avoid;
  }
  .pdfjs #printContainer canvas,
  .pdfjs #printContainer img {
    display: block;
  }
}

.pdfjs .visibleLargeView,
.pdfjs .visibleMediumView,
.pdfjs .visibleSmallView {
  display: none;
}

@media all and (max-width: 1040px) {
  .pdfjs #outerContainer.sidebarMoving #toolbarViewerMiddle,
  .pdfjs #outerContainer.sidebarOpen #toolbarViewerMiddle {
    display: table;
    margin: auto;
    left: auto;
    position: inherit;
    transform: none;
  }
}

@media all and (max-width: 980px) {
  .pdfjs .sidebarMoving .hiddenLargeView,
  .pdfjs .sidebarOpen .hiddenLargeView {
    display: none;
  }
  .pdfjs .sidebarMoving .visibleLargeView,
  .pdfjs .sidebarOpen .visibleLargeView {
    display: inherit;
  }
}

@media all and (max-width: 900px) {
  .pdfjs #toolbarViewerMiddle {
    display: table;
    margin: auto;
    left: auto;
    position: inherit;
    transform: none;
  }
  .pdfjs .sidebarMoving .hiddenMediumView,
  .pdfjs .sidebarOpen .hiddenMediumView {
    display: none;
  }
  .pdfjs .sidebarMoving .visibleMediumView,
  .pdfjs .sidebarOpen .visibleMediumView {
    display: inherit;
  }
}

@media all and (max-width: 840px) {
  .pdfjs #sidebarContainer {
    top: 32px;
    z-index: 100;
  }
  .pdfjs .loadingInProgress #sidebarContainer {
    top: 37px;
  }
  .pdfjs #sidebarContent {
    top: 32px;
    background-color: rgba(0, 0, 0, 0.7);
  }
  html[dir='ltr'] .pdfjs #outerContainer.sidebarOpen > #mainContainer {
    left: 0;
  }
  html[dir='rtl'] .pdfjs #outerContainer.sidebarOpen > #mainContainer {
    right: 0;
  }
  .pdfjs #outerContainer .hiddenLargeView,
  .pdfjs #outerContainer .hiddenMediumView {
    display: inherit;
  }
  .pdfjs #outerContainer .visibleLargeView,
  .pdfjs #outerContainer .visibleMediumView {
    display: none;
  }
}

@media all and (max-width: 770px) {
  .pdfjs #outerContainer .hiddenLargeView {
    display: none;
  }
  .pdfjs #outerContainer .visibleLargeView {
    display: inherit;
  }
}

@media all and (max-width: 700px) {
  .pdfjs #outerContainer .hiddenMediumView {
    display: none;
  }
  .pdfjs #outerContainer .visibleMediumView {
    display: inherit;
  }
}

@media all and (max-width: 640px) {
  .pdfjs .hiddenSmallView {
    display: none;
  }
  .pdfjs .visibleSmallView {
    display: inherit;
  }
  .pdfjs .toolbarButtonSpacer {
    width: 0;
  }
}

@media all and (max-width: 535px) {
  .pdfjs #scaleSelectContainer {
    display: none;
  }
}

#fileInput.fileInput {
  display: none;
}

/*.form-group {
  margin: .2em 0;
  display: flex;
  flex-flow: row wrap;
  label {
    @include flex(0 1 4em);
  }
  input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], input:not([type]), textarea, .inputElement, [contenteditable=true] {
    @include flex(1);
    min-width: 50px;
  }
  .btn {
    margin: 0;
  }
  .form-group{
    @include flex(0 0 50%;)
  }
}*/
.form-bordered {
  transform: translateZ(0);
  background-color: #fff;
  border: 1px solid #dfdfdf;
  margin-bottom: 1rem;
  padding: 8% 4% 2%;
}

/*input[required]{
  background-image: radial-gradient(#F00 15%, transparent 16%);
  background-size: 2em 2em;
  background-position: right center;
  background-repeat: no-repeat;
}*/
.input-heading,
.label {
  line-height: 1;
  font-size: 17px;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: #262626;
  color: #38444B;
}

.input-heading strong,
.label strong {
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: #262626;
}

.input-heading.lbl-sm,
.label.lbl-sm {
  line-height: .6;
  font-size: 95% !important;
}

.pac-container {
  margin-top: -1px !important;
  margin-top: -1px !important;
}

.input-width-xs {
  max-width: 100px;
}

.input-width-sm {
  max-width: 200px;
}

.recaptcha {
  flex: 1 1 100%;
  margin-left: -8px;
  margin-top: 25px;
}

@media (min-width: 550px) {
  .recaptcha {
    margin-left: 170px;
    margin-right: 20%;
    margin-top: 0px;
    width: auto;
  }
}

input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], input:not([type]), textarea, .inputElement, [contenteditable=true] {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  line-height: normal;
  font-size: 1rem;
  min-width: 10px;
  transition: all 260ms cubic-bezier(0.77, 0, 0.175, 1);
  color: #22292D;
  border-radius: 0;
  border: 2px solid #2D363C;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  background-color: #f9f9f9;
  font-weight: normal;
  margin-bottom: .4rem;
  padding: .95rem .9rem;
  font-size: 16px;
}

input[type="color"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="datetime"]::-webkit-input-placeholder, input[type="datetime-local"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="month"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder, input[type="search"]::-webkit-input-placeholder, input[type="tel"]::-webkit-input-placeholder, input[type="text"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="url"]::-webkit-input-placeholder, input[type="week"]::-webkit-input-placeholder, input:not([type])::-webkit-input-placeholder, textarea::-webkit-input-placeholder, .inputElement::-webkit-input-placeholder, [contenteditable=true]::-webkit-input-placeholder {
  color: #2D363C;
  font-style: italic;
}

input[type="color"]:-moz-placeholder, input[type="date"]:-moz-placeholder, input[type="datetime"]:-moz-placeholder, input[type="datetime-local"]:-moz-placeholder, input[type="email"]:-moz-placeholder, input[type="month"]:-moz-placeholder, input[type="number"]:-moz-placeholder, input[type="password"]:-moz-placeholder, input[type="search"]:-moz-placeholder, input[type="tel"]:-moz-placeholder, input[type="text"]:-moz-placeholder, input[type="time"]:-moz-placeholder, input[type="url"]:-moz-placeholder, input[type="week"]:-moz-placeholder, input:not([type]):-moz-placeholder, textarea:-moz-placeholder, .inputElement:-moz-placeholder, [contenteditable=true]:-moz-placeholder {
  color: #2D363C;
  font-style: italic;
}

input[type="color"]::-moz-placeholder, input[type="date"]::-moz-placeholder, input[type="datetime"]::-moz-placeholder, input[type="datetime-local"]::-moz-placeholder, input[type="email"]::-moz-placeholder, input[type="month"]::-moz-placeholder, input[type="number"]::-moz-placeholder, input[type="password"]::-moz-placeholder, input[type="search"]::-moz-placeholder, input[type="tel"]::-moz-placeholder, input[type="text"]::-moz-placeholder, input[type="time"]::-moz-placeholder, input[type="url"]::-moz-placeholder, input[type="week"]::-moz-placeholder, input:not([type])::-moz-placeholder, textarea::-moz-placeholder, .inputElement::-moz-placeholder, [contenteditable=true]::-moz-placeholder {
  color: #2D363C;
  font-style: italic;
}

input[type="color"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="datetime"]:-ms-input-placeholder, input[type="datetime-local"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="month"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder, input[type="search"]:-ms-input-placeholder, input[type="tel"]:-ms-input-placeholder, input[type="text"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="url"]:-ms-input-placeholder, input[type="week"]:-ms-input-placeholder, input:not([type]):-ms-input-placeholder, textarea:-ms-input-placeholder, .inputElement:-ms-input-placeholder, [contenteditable=true]:-ms-input-placeholder {
  color: #2D363C;
  font-style: italic;
}

input[type="color"]:placeholder, input[type="date"]:placeholder, input[type="datetime"]:placeholder, input[type="datetime-local"]:placeholder, input[type="email"]:placeholder, input[type="month"]:placeholder, input[type="number"]:placeholder, input[type="password"]:placeholder, input[type="search"]:placeholder, input[type="tel"]:placeholder, input[type="text"]:placeholder, input[type="time"]:placeholder, input[type="url"]:placeholder, input[type="week"]:placeholder, input:not([type]):placeholder, textarea:placeholder, .inputElement:placeholder, [contenteditable=true]:placeholder {
  color: #2D363C;
  font-style: italic;
}

input[type="color"]:hover, input[type="date"]:hover, input[type="datetime"]:hover, input[type="datetime-local"]:hover, input[type="email"]:hover, input[type="month"]:hover, input[type="number"]:hover, input[type="password"]:hover, input[type="search"]:hover, input[type="tel"]:hover, input[type="text"]:hover, input[type="time"]:hover, input[type="url"]:hover, input[type="week"]:hover, input:not([type]):hover, textarea:hover, .inputElement:hover, [contenteditable=true]:hover {
  border-color: #aaa;
}

input[type="color"]:focus, input[type="date"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="email"]:focus, input[type="month"]:focus, input[type="number"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="text"]:focus, input[type="time"]:focus, input[type="url"]:focus, input[type="week"]:focus, input:not([type]):focus, textarea:focus, .inputElement:focus, [contenteditable=true]:focus {
  outline: 0px solid transparent;
  border-color: #222;
  background-color: #f9f9f9;
}

input[type="color"]:disabled, input[type="date"]:disabled, input[type="datetime"]:disabled, input[type="datetime-local"]:disabled, input[type="email"]:disabled, input[type="month"]:disabled, input[type="number"]:disabled, input[type="password"]:disabled, input[type="search"]:disabled, input[type="tel"]:disabled, input[type="text"]:disabled, input[type="time"]:disabled, input[type="url"]:disabled, input[type="week"]:disabled, input:not([type]):disabled, textarea:disabled, .inputElement:disabled, [contenteditable=true]:disabled {
  opacity: 0.8;
}

input[type="color"] select, input[type="date"] select, input[type="datetime"] select, input[type="datetime-local"] select, input[type="email"] select, input[type="month"] select, input[type="number"] select, input[type="password"] select, input[type="search"] select, input[type="tel"] select, input[type="text"] select, input[type="time"] select, input[type="url"] select, input[type="week"] select, input:not([type]) select, textarea select, .inputElement select, [contenteditable=true] select {
  padding: .95rem .9rem;
  font-size: 16px;
  border: none;
  font-style: normal;
}

input[type="color"] select.field--sm, input[type="date"] select.field--sm, input[type="datetime"] select.field--sm, input[type="datetime-local"] select.field--sm, input[type="email"] select.field--sm, input[type="month"] select.field--sm, input[type="number"] select.field--sm, input[type="password"] select.field--sm, input[type="search"] select.field--sm, input[type="tel"] select.field--sm, input[type="text"] select.field--sm, input[type="time"] select.field--sm, input[type="url"] select.field--sm, input[type="week"] select.field--sm, input:not([type]) select.field--sm, textarea select.field--sm, .inputElement select.field--sm, [contenteditable=true] select.field--sm {
  padding: 0.6rem !important;
}

input[type="color"].field--sm, input[type="date"].field--sm, input[type="datetime"].field--sm, input[type="datetime-local"].field--sm, input[type="email"].field--sm, input[type="month"].field--sm, input[type="number"].field--sm, input[type="password"].field--sm, input[type="search"].field--sm, input[type="tel"].field--sm, input[type="text"].field--sm, input[type="time"].field--sm, input[type="url"].field--sm, input[type="week"].field--sm, input:not([type]).field--sm, textarea.field--sm, .inputElement.field--sm, [contenteditable=true].field--sm {
  padding: 0.6rem !important;
}

input[type="color"].input--error, input[type="date"].input--error, input[type="datetime"].input--error, input[type="datetime-local"].input--error, input[type="email"].input--error, input[type="month"].input--error, input[type="number"].input--error, input[type="password"].input--error, input[type="search"].input--error, input[type="tel"].input--error, input[type="text"].input--error, input[type="time"].input--error, input[type="url"].input--error, input[type="week"].input--error, input:not([type]).input--error, textarea.input--error, .inputElement.input--error, [contenteditable=true].input--error {
  border-color: #ED1E23;
  color: #ED1E23;
  background: #fcdbdc;
}

.select-holder {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  line-height: normal;
  font-size: 1rem;
  min-width: 10px;
  transition: all 260ms cubic-bezier(0.77, 0, 0.175, 1);
  color: #22292D;
  border-radius: 0;
  border: 2px solid #2D363C;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  background-color: #f9f9f9;
  font-weight: normal;
  margin-bottom: .4rem;
  padding: .95rem .9rem;
  font-size: 16px;
  padding: 0;
  display: inline-block;
  min-width: 140px;
  height: auto;
  cursor: pointer;
  font-size: normal;
  position: relative;
}

.select-holder::-webkit-input-placeholder {
  color: #2D363C;
  font-style: italic;
}

.select-holder:-moz-placeholder {
  color: #2D363C;
  font-style: italic;
}

.select-holder::-moz-placeholder {
  color: #2D363C;
  font-style: italic;
}

.select-holder:-ms-input-placeholder {
  color: #2D363C;
  font-style: italic;
}

.select-holder:placeholder {
  color: #2D363C;
  font-style: italic;
}

.select-holder:hover {
  border-color: #aaa;
}

.select-holder:focus {
  outline: 0px solid transparent;
  border-color: #222;
  background-color: #f9f9f9;
}

.select-holder:disabled {
  opacity: 0.8;
}

.select-holder select {
  padding: .95rem .9rem;
  font-size: 16px;
  border: none;
  font-style: normal;
}

.select-holder select.field--sm {
  padding: 0.6rem !important;
}

.select-holder.field--sm {
  padding: 0.6rem !important;
}

.select-holder select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  background-image: none;
  border: none;
  box-shadow: none;
  margin: -2px;
  border-top: 4px solid transparent;
  color: #22292D;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  cursor: pointer;
  height: auto;
  position: relative;
  width: 100%;
  z-index: 10;
}

.select-holder select::-ms-expand {
  display: none;
}

.select-holder select:hover:before {
  color: #aaa;
}

.select-holder select:focus:before {
  color: #222;
}

.select-holder select option {
  color: #22292D;
}

.select-holder select option:disabled {
  color: #ccc !important;
}

.select-holder select.ng-invalid, .select-holder select option:first-child {
  color: #2D363C;
}

.select-holder:before {
  content: '';
  display: block;
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -1.15rem;
  fill: #B1B1B1;
  filter: invert(85%) sepia(0%) saturate(255%) hue-rotate(151deg) brightness(86%) contrast(85%);
  width: 40px;
  height: 40px;
  background: url("../assets/svg/Icons/Angle Down Sm.svg") no-repeat center/contain;
}

@media (max-width: 1099px) {
  .select-holder:before {
    width: 34px;
    height: 34px;
  }
}

.select-holder--xs select {
  padding: 0.25rem 1.5rem 0.425rem 0.5em;
  font-size: 14px;
}

.select-holder--xs:before {
  right: -5px;
}

.select-holder--readonly {
  opacity: 0.5;
  pointer-events: none;
}

.select-holder--readonly:before {
  visibility: hidden;
}

.select-holder--blue {
  border-color: #00ADEF !important;
}

.select-holder--blue:before {
  filter: invert(43%) sepia(97%) saturate(1115%) hue-rotate(164deg) brightness(100%) contrast(101%);
}

input[type="color"].input-xs, input[type="date"].input-xs, input[type="datetime"].input-xs, input[type="datetime-local"].input-xs, input[type="email"].input-xs, input[type="month"].input-xs, input[type="number"].input-xs, input[type="password"].input-xs, input[type="search"].input-xs, input[type="tel"].input-xs, input[type="text"].input-xs, input[type="time"].input-xs, input[type="url"].input-xs, input[type="week"].input-xs, input:not([type]).input-xs, textarea.input-xs, .inputElement.input-xs, [contenteditable=true].input-xs {
  padding: .375rem .5rem;
  font-size: 16px;
  line-height: normal;
}

input[type="color"].input-sm, input[type="date"].input-sm, input[type="datetime"].input-sm, input[type="datetime-local"].input-sm, input[type="email"].input-sm, input[type="month"].input-sm, input[type="number"].input-sm, input[type="password"].input-sm, input[type="search"].input-sm, input[type="tel"].input-sm, input[type="text"].input-sm, input[type="time"].input-sm, input[type="url"].input-sm, input[type="week"].input-sm, input:not([type]).input-sm, textarea.input-sm, .inputElement.input-sm, [contenteditable=true].input-sm {
  padding: 14px .75rem;
  font-size: 16px;
}

input[type="color"].input-lg, input[type="date"].input-lg, input[type="datetime"].input-lg, input[type="datetime-local"].input-lg, input[type="email"].input-lg, input[type="month"].input-lg, input[type="number"].input-lg, input[type="password"].input-lg, input[type="search"].input-lg, input[type="tel"].input-lg, input[type="text"].input-lg, input[type="time"].input-lg, input[type="url"].input-lg, input[type="week"].input-lg, input:not([type]).input-lg, textarea.input-lg, .inputElement.input-lg, [contenteditable=true].input-lg {
  padding: .75rem 1.85rem;
  font-size: 17px;
}

input[type="color"].input-xl, input[type="date"].input-xl, input[type="datetime"].input-xl, input[type="datetime-local"].input-xl, input[type="email"].input-xl, input[type="month"].input-xl, input[type="number"].input-xl, input[type="password"].input-xl, input[type="search"].input-xl, input[type="tel"].input-xl, input[type="text"].input-xl, input[type="time"].input-xl, input[type="url"].input-xl, input[type="week"].input-xl, input:not([type]).input-xl, textarea.input-xl, .inputElement.input-xl, [contenteditable=true].input-xl {
  padding: .85rem 1.2rem;
  font-size: 19px;
}

.select-holder.select-lg select {
  padding: .75rem 1.85rem;
  font-size: 17px;
}

/* I use form--complex for forms that have more complex layouts than simple left/right label and field alignment etc */
.form--complex {
  display: flex;
}

.form__object--fillspace {
  /* This element will receive priority of size.
    All other elements will be sized at the size dictated by their display type */
  flex-grow: 1;
}

.form__object--fillspace + .form__object--fillspace-gap {
  margin-left: .5em;
}

.form__object--fillspace .btn {
  margin: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.form-row {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  flex-direction: column;
  margin: 0;
  width: 100%;
  padding: 0;
}

.form-row label {
  margin-top: 1.2rem;
  margin-bottom: .3rem;
  display: block;
  width: 100%;
}

@media (min-width: 550px) {
  .form-row label {
    display: block;
    width: 100%;
  }
}

.form-row input[type="color"], .form-row input[type="date"], .form-row input[type="datetime"], .form-row input[type="datetime-local"], .form-row input[type="email"], .form-row input[type="month"], .form-row input[type="number"], .form-row input[type="password"], .form-row input[type="search"], .form-row input[type="tel"], .form-row input[type="text"], .form-row input[type="time"], .form-row input[type="url"], .form-row input[type="week"], .form-row input:not([type]), .form-row textarea, .form-row .inputElement, .form-row [contenteditable=true],
.form-row .select-holder {
  margin: 0;
}

.form-row label.hide + input,
.form-row label.hide + .select-holder {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

@media (min-width: 550px) {
  .form-row label.hide + input,
  .form-row label.hide + .select-holder {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.form-row label.checkbox, .form-row label.radio {
  margin-top: .2rem;
}

@media (min-width: 550px) {
  .form-row {
    flex-direction: row;
    margin: .3rem 0 1.2rem;
  }
  .form-row label {
    margin-top: .3rem;
    margin-bottom: .3rem;
  }
  .form-row label.checkbox, .form-row label.radio {
    margin-top: .2rem;
  }
}

.form-row p {
  flex-direction: column;
  width: 100%;
  margin-bottom: .2em;
  font-size: 14px;
}

.form-row a {
  text-decoration: underline;
  color: #777;
}

.form-row a:hover {
  text-decoration: none;
  color: #111;
}

.form-row p.location {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  line-height: normal;
  font-size: 1rem;
  min-width: 10px;
  transition: all 260ms cubic-bezier(0.77, 0, 0.175, 1);
  color: #22292D;
  border-radius: 0;
  border: 2px solid #2D363C;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  background-color: #f9f9f9;
  font-weight: normal;
  margin-bottom: .4rem;
  padding: .95rem .9rem;
  font-size: 16px;
  margin-bottom: 0;
  height: auto;
}

.form-row p.location::-webkit-input-placeholder {
  color: #2D363C;
  font-style: italic;
}

.form-row p.location:-moz-placeholder {
  color: #2D363C;
  font-style: italic;
}

.form-row p.location::-moz-placeholder {
  color: #2D363C;
  font-style: italic;
}

.form-row p.location:-ms-input-placeholder {
  color: #2D363C;
  font-style: italic;
}

.form-row p.location:placeholder {
  color: #2D363C;
  font-style: italic;
}

.form-row p.location:hover {
  border-color: #aaa;
}

.form-row p.location:focus {
  outline: 0px solid transparent;
  border-color: #222;
  background-color: #f9f9f9;
}

.form-row p.location:disabled {
  opacity: 0.8;
}

.form-row p.location select {
  padding: .95rem .9rem;
  font-size: 16px;
  border: none;
  font-style: normal;
}

.form-row p.location select.field--sm {
  padding: 0.6rem !important;
}

.form-row p.location.field--sm {
  padding: 0.6rem !important;
}

.form-row--has-helper {
  position: relative;
}

.form-row--has-helper .help-holder {
  margin-top: 10px;
}

.form-row--has-helper .help-holder > svg {
  left: auto !important;
  right: 0;
  top: 1rem !important;
}

.form-row--has-helper .help-holder .info {
  margin-bottom: 0;
}

.form-row .form-cell {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  width: 100%;
}

@media (min-width: 550px) {
  .form-row .form-cell + .form-cell {
    margin-top: 1.5em;
  }
}

@media (min-width: 1250px) {
  .form-row .form-cell {
    -moz-box-flex: 1  ;
    -webkit-flex: 1  ;
    -ms-flex: 1  ;
    flex: 1  ;
    padding: 0 3% 0 0;
  }
  .form-row .form-cell + .form-cell {
    margin-top: 0;
  }
}

.form-row .form-cell:last-child {
  padding: 0 0 0 0;
}

.signup-form {
  position: relative;
  padding: 40px 5%;
  background-color: #22292D;
}

.signup-form input[type="color"], .signup-form input[type="date"], .signup-form input[type="datetime"], .signup-form input[type="datetime-local"], .signup-form input[type="email"], .signup-form input[type="month"], .signup-form input[type="number"], .signup-form input[type="password"], .signup-form input[type="search"], .signup-form input[type="tel"], .signup-form input[type="text"], .signup-form input[type="time"], .signup-form input[type="url"], .signup-form input[type="week"], .signup-form input:not([type]), .signup-form textarea, .signup-form .inputElement, .signup-form [contenteditable=true],
.signup-form select {
  padding: .75rem 1.85rem;
  font-size: 17px;
}

@media (min-width: 1000px) {
  .signup-form {
    padding: 50px 14%;
  }
}

textarea {
  resize: vertical;
  height: 5em;
}

.form-loader:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: #fff;
  z-index: 100;
  transition: opacity 1s linear;
  visibility: hidden;
}

.form-loader.form-loading:before {
  visibility: visible;
  opacity: .5;
}

.form-row.form-group {
  align-items: initial;
}

.form-row.form-group .form-group--input input[type="color"], .form-row.form-group .form-group--input input[type="date"], .form-row.form-group .form-group--input input[type="datetime"], .form-row.form-group .form-group--input input[type="datetime-local"], .form-row.form-group .form-group--input input[type="email"], .form-row.form-group .form-group--input input[type="month"], .form-row.form-group .form-group--input input[type="number"], .form-row.form-group .form-group--input input[type="password"], .form-row.form-group .form-group--input input[type="search"], .form-row.form-group .form-group--input input[type="tel"], .form-row.form-group .form-group--input input[type="text"], .form-row.form-group .form-group--input input[type="time"], .form-row.form-group .form-group--input input[type="url"], .form-row.form-group .form-group--input input[type="week"], .form-row.form-group .form-group--input input:not([type]), .form-row.form-group .form-group--input textarea, .form-row.form-group .form-group--input .inputElement, .form-row.form-group .form-group--input [contenteditable=true] {
  min-width: 260px;
}

.form-row.form-group .form-group--button {
  flex-grow: 0;
  display: flex;
  align-items: initial;
}

.form-row.form-group .form-group--button .btn {
  white-space: nowrap;
}

[toggle-password] {
  position: relative;
}

[toggle-password]:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: url("../assets/svg/Icons/View Slash.svg") no-repeat center/contain;
  filter: invert(43%) sepia(5%) saturate(577%) hue-rotate(160deg) brightness(96%) contrast(90%);
}

[toggle-password] svg {
  opacity: 0;
}

[toggle-password].active:before {
  opacity: 0;
}

[toggle-password].active svg {
  opacity: 1;
  fill: #666e73;
}

[class*='invalid']:not([class*='form']) [toggle-password]:before {
  filter: invert(30%) sepia(96%) saturate(5220%) hue-rotate(347deg) brightness(92%) contrast(103%);
}

.field-illuminate {
  border: 1px solid transparent;
}

.field-illuminate--active {
  border: 1px solid #00ADEF;
  box-shadow: 0 0 5px 0 #23c2ff;
}

input.radio,
input.checkbox {
  position: absolute;
  left: -9999px;
  height: 0;
  width: 0;
}

label.checkbox,
label.radio {
  margin: 0.5rem 0 0.3rem 0;
  padding: 0;
  font-size: 16px;
  line-height: 16px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding-left: 23px;
  padding-right: 16px;
}

label.checkbox:hover:before,
label.radio:hover:before {
  border-color: #000;
}

label.radio:before,
label.checkbox:before {
  content: "";
  display: block;
  border: solid 1px #aaa;
  background-color: #f9f9f9;
  transition: border-color .1s ease;
  width: 16px;
  height: 16px;
  top: 0;
  position: absolute;
  left: 0;
}

label.radio:before {
  border-radius: 50%;
}

label.radio:after {
  content: "";
  display: block;
  border-radius: 50%;
  visibility: hidden;
  background: #38444B;
  width: 8px;
  height: 8px;
  transform: scale(0.5);
  position: absolute;
  left: 4px;
  top: 4px;
  margin: 0;
}

label.checkbox:after,
input[type=checkbox] + span:after {
  -webkit-filter: grayscale(100%) brightness(500%) brightness(40%);
  filter: grayscale(100%) brightness(500%) brightness(40%);
  content: '';
  display: block;
  background: url("../assets/svg/Icons/Tick Sq Bold.svg") no-repeat center/contain;
  width: 32px;
  height: 32px;
  transform: scale(0.01);
  text-align: left;
  position: absolute;
  top: -8px;
  left: -8px;
  filter: invert(69%) sepia(81%) saturate(4951%) hue-rotate(163deg) brightness(99%) contrast(101%);
}

label.checkbox:before {
  border: none;
  background: url("../assets/svg/Icons/Tick Box Empty Bold.svg") no-repeat center/contain;
  width: 32px;
  height: 32px;
  position: absolute;
  top: -8px;
  left: -8px;
  filter: invert(69%) sepia(81%) saturate(4951%) hue-rotate(163deg) brightness(99%) contrast(101%);
}

label.checkbox.checkbox-lg,
label.radio.radio-lg {
  padding-left: 28px;
  font-size: 17px;
  font-weight: bold;
  margin: .7rem 0.7rem .5rem 0;
  line-height: 20px;
}

label.checkbox.checkbox-lg:before,
label.radio.radio-lg:before {
  width: 20px;
  height: 20px;
}

label.radio.radio-lg:after {
  width: 10px;
  height: 10px;
  left: 5px;
  top: 5px;
}

label.checkbox.checkbox-lg:after {
  line-height: 20px;
  font-size: 20px;
}

input.radio:checked + .radio-btn,
input.checkbox:checked + .radio-btn {
  opacity: 1;
}

input.radio:checked + .radio-btn:before,
input.checkbox:checked + .radio-btn:before {
  transform: scale(1);
  opacity: 1;
  margin: -.2rem .5rem 0 -.3rem;
  display: inline-block;
}

input[type=radio]:checked + .radio-btn.radio-select span.text-unchecked,
input[type=checkbox]:checked + .radio-btn.radio-select span.text-unchecked {
  display: none;
}

input[type=radio]:checked + .radio-btn.radio-select span.text-checked,
input[type=checkbox]:checked + .radio-btn.radio-select span.text-checked {
  display: inline;
}

input[type=radio] + .radio-btn.radio-select span.text-checked,
input[type=checkbox] + .radio-btn.radio-select span.text-checked {
  display: none;
}

input[type=radio]:checked + .radio:after,
input[type=checkbox]:checked + .checkbox:after,
label.radio.checked:after,
label.checkbox.checked:after {
  visibility: visible;
  transform: scale(1);
}

input[type=radio]:checked + .radio:before,
input[type=checkbox]:checked + .radio:before {
  border: solid 1px #38444B;
}

input[type=radio]:checked + .checkbox:before,
input[type=checkbox]:checked + .checkbox:before,
input[type=checkbox]:checked + span:before {
  visibility: visible;
  color: #38444B;
  transform: scale(1);
}

input[type=radio]:disabled + .checkbox,
input[type=checkbox]:disabled + .checkbox {
  cursor: not-allowed;
  opacity: .4;
}

input[type=radio] + label.radio,
input[type=checkbox] + label.checkbox,
input[type=checkbox] + span {
  user-select: none;
  display: inline-block;
}

input[type=radio] + label .sub,
input[type=checkbox] + label .sub {
  color: #b7b7b7;
  display: block;
  margin-top: -3px;
}

label.radio-btn {
  opacity: .85;
  display: inline-block;
  width: auto;
}

label.radio-btn:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  transition: all .1s ease;
  margin: -.3rem -.3rem 0 -.3rem;
  transform: scale(0.01);
  opacity: 0;
  font-size: 1.2em;
}

.radio-btn.radio-btn--inline {
  width: auto;
  display: inline-block;
}

.radio-btn.radio-btn--inline:after {
  margin-left: 0.75rem;
}

.radio-btn:hover {
  cursor: pointer;
}

.radio-btn:after {
  display: none;
}

.radio-btn + .radio-btn {
  margin-right: .5rem;
}

label.radio.radio--inline {
  width: auto;
  display: inline-block;
}

.reveal-area {
  transition: all .3s ease-out;
  max-height: 600px;
  overflow: hidden;
}

input[type=radio].reveal-checkbox:checked + label + .reveal-area ~ .btn-group #activate-card-btn,
input[type=checkbox].reveal-checkbox:checked + label + .reveal-area ~ .btn-group #activate-card-btn {
  display: none;
}

input[type=radio].reveal-checkbox:checked + label + .reveal-area ~ .btn-group #confirm-email-btn,
input[type=checkbox].reveal-checkbox:checked + label + .reveal-area ~ .btn-group #confirm-email-btn {
  display: inline-block;
}

input[type=radio].reveal-checkbox + label + .reveal-area ~ .btn-group #confirm-email-btn,
input[type=checkbox].reveal-checkbox + label + .reveal-area ~ .btn-group #confirm-email-btn {
  display: none;
}

input[type=radio].reveal-checkbox + label + .reveal-area ~ .btn-group #activate-card-btn,
input[type=checkbox].reveal-checkbox + label + .reveal-area ~ .btn-group #activate-card-btn {
  display: inline-block;
}

.toggle-hidden.reveal-area {
  padding-top: 0;
  padding-bottom: 0;
  max-height: 0;
  border-bottom: 0;
}

#pay-cc:not(:checked) ~ .pay-cc {
  display: none;
}

#pay-cc:checked ~ .pay-cc {
  display: table;
}

#pay-bt:not(:checked) ~ .pay-bt {
  display: none;
}

#pay-bt:checked ~ .pay-bt {
  display: table;
}

.flyout__field label.checkbox {
  color: #fff;
}

.flyout__field label.checkbox:before, .flyout__field label.checkbox:after {
  filter: invert(1);
}

.flyout__field label.checkbox a {
  color: #fff;
  text-decoration: underline;
}

.flyout__field label.checkbox a:hover {
  text-decoration: none;
}

.flyout__field input[type="checkbox"]:checked + .checkbox::before {
  color: #fff;
}

.flyout__field.field-invalid label.checkbox:before {
  filter: invert(13%) sepia(89%) saturate(5552%) hue-rotate(354deg) brightness(101%) contrast(87%);
}

.checkbox-label {
  display: flex;
}

.checkbox-label .tooltip__button svg {
  width: 32px;
  height: 32px;
  margin: 0 -0.8em;
}

.checkbox-label .tooltip__content {
  padding: 0.5rem 1rem 0.25rem;
}

.alert {
  padding: .8rem 1rem .8rem 2.4rem;
  font-size: 85%;
  clear: both;
  border-width: 1px;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  font-weight: normal;
  margin: .5rem 0;
  border-style: solid;
  color: #333;
  vertical-align: middle;
  line-height: 1.05;
  position: relative;
}

.alert li,
.alert p {
  text-transform: none;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
}

.alert p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.alert:before {
  font-size: 1rem;
  position: absolute;
  left: 1rem;
  top: .7rem;
}

@media (min-width: 450px) {
  .alert {
    font-size: 100%;
    padding: 1rem 1.5rem 1rem 3rem;
  }
  .alert:before {
    font-size: 1.35rem;
    left: 1rem;
  }
}

.alert.radius {
  border-radius: 0.6rem;
}

.alert strong {
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
}

.alert.alert-primary {
  border-color: #00ADEF;
  background-color: #00ADEF;
  color: #fff;
}

.alert.alert-primary:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.alert.alert-primary h1,
.alert.alert-primary h2,
.alert.alert-primary h3,
.alert.alert-primary h4,
.alert.alert-primary h5,
.alert.alert-primary p {
  color: #fff;
}

.alert.alert-primary h1:first-child,
.alert.alert-primary h2:first-child,
.alert.alert-primary h3:first-child,
.alert.alert-primary h4:first-child,
.alert.alert-primary h5:first-child,
.alert.alert-primary p:first-child {
  margin-top: 0;
  padding-top: 0;
}

.alert.alert-primary h1:last-child,
.alert.alert-primary h2:last-child,
.alert.alert-primary h3:last-child,
.alert.alert-primary h4:last-child,
.alert.alert-primary h5:last-child,
.alert.alert-primary p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.alert.alert-primary a {
  color: #fff;
}

.alert.alert-secondary {
  border-color: #00ADEF;
  background-color: #2D363C;
  color: #fff;
}

.alert.alert-secondary:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.alert.alert-secondary h1,
.alert.alert-secondary h2,
.alert.alert-secondary h3,
.alert.alert-secondary h4,
.alert.alert-secondary h5,
.alert.alert-secondary p {
  color: #fff;
}

.alert.alert-secondary h1:first-child,
.alert.alert-secondary h2:first-child,
.alert.alert-secondary h3:first-child,
.alert.alert-secondary h4:first-child,
.alert.alert-secondary h5:first-child,
.alert.alert-secondary p:first-child {
  margin-top: 0;
  padding-top: 0;
}

.alert.alert-secondary h1:last-child,
.alert.alert-secondary h2:last-child,
.alert.alert-secondary h3:last-child,
.alert.alert-secondary h4:last-child,
.alert.alert-secondary h5:last-child,
.alert.alert-secondary p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.alert.alert-secondary a {
  color: #fff;
}

.alert.alert-info {
  border-color: #2D363C;
  background-color: #eaedef;
  color: #2D363C;
}

.alert.alert-info:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.alert.alert-info h1,
.alert.alert-info h2,
.alert.alert-info h3,
.alert.alert-info h4,
.alert.alert-info h5,
.alert.alert-info p {
  color: #2D363C;
}

.alert.alert-info h1:first-child,
.alert.alert-info h2:first-child,
.alert.alert-info h3:first-child,
.alert.alert-info h4:first-child,
.alert.alert-info h5:first-child,
.alert.alert-info p:first-child {
  margin-top: 0;
  padding-top: 0;
}

.alert.alert-info h1:last-child,
.alert.alert-info h2:last-child,
.alert.alert-info h3:last-child,
.alert.alert-info h4:last-child,
.alert.alert-info h5:last-child,
.alert.alert-info p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.alert.alert-error {
  border-color: #ED1E23;
  background-color: #fdebeb;
  color: #ED1E23;
}

.alert.alert-error:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.alert.alert-error h1,
.alert.alert-error h2,
.alert.alert-error h3,
.alert.alert-error h4,
.alert.alert-error h5,
.alert.alert-error p {
  color: #ED1E23;
}

.alert.alert-error h1:first-child,
.alert.alert-error h2:first-child,
.alert.alert-error h3:first-child,
.alert.alert-error h4:first-child,
.alert.alert-error h5:first-child,
.alert.alert-error p:first-child {
  margin-top: 0;
  padding-top: 0;
}

.alert.alert-error h1:last-child,
.alert.alert-error h2:last-child,
.alert.alert-error h3:last-child,
.alert.alert-error h4:last-child,
.alert.alert-error h5:last-child,
.alert.alert-error p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.alert.alert-success {
  border-color: #3eb44a;
  background-color: #edf9ee;
  color: #3eb44a;
}

.alert.alert-success:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.alert.alert-success h1,
.alert.alert-success h2,
.alert.alert-success h3,
.alert.alert-success h4,
.alert.alert-success h5,
.alert.alert-success p {
  color: #3eb44a;
}

.alert.alert-success h1:first-child,
.alert.alert-success h2:first-child,
.alert.alert-success h3:first-child,
.alert.alert-success h4:first-child,
.alert.alert-success h5:first-child,
.alert.alert-success p:first-child {
  margin-top: 0;
  padding-top: 0;
}

.alert.alert-success h1:last-child,
.alert.alert-success h2:last-child,
.alert.alert-success h3:last-child,
.alert.alert-success h4:last-child,
.alert.alert-success h5:last-child,
.alert.alert-success p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.alert.alert-warning {
  border-color: #ffc048;
  background-color: #fff9ef;
  color: #ffc048;
}

.alert.alert-warning:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.alert.alert-warning h1,
.alert.alert-warning h2,
.alert.alert-warning h3,
.alert.alert-warning h4,
.alert.alert-warning h5,
.alert.alert-warning p {
  color: #ffc048;
}

.alert.alert-warning h1:first-child,
.alert.alert-warning h2:first-child,
.alert.alert-warning h3:first-child,
.alert.alert-warning h4:first-child,
.alert.alert-warning h5:first-child,
.alert.alert-warning p:first-child {
  margin-top: 0;
  padding-top: 0;
}

.alert.alert-warning h1:last-child,
.alert.alert-warning h2:last-child,
.alert.alert-warning h3:last-child,
.alert.alert-warning h4:last-child,
.alert.alert-warning h5:last-child,
.alert.alert-warning p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.alert strong {
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
}

.alert ul,
.alert ol {
  margin-bottom: 0;
  padding-bottom: 0;
}

.alert-dismissible {
  padding-right: 2.5em;
  transition: all .3s ease;
}

.alert-dismissible:after {
  cursor: pointer;
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  float: right;
  opacity: .4;
  display: block;
  text-align: center;
  position: absolute;
  right: 1.2rem;
  border-radius: 50%;
  width: 30px;
  line-height: 30px;
  margin-top: -15px;
  top: 50%;
  background-color: #fff;
}

.alert-dismissible:hover:after {
  opacity: 1;
}

.page-alert {
  margin: 100px auto 5px auto;
  max-width: 700px;
}

#toast-container > .toast {
  color: #B1B1B1;
  border-radius: 0;
  box-shadow: none;
  background: #fff;
  position: relative;
  font-size: 14px;
  width: 335px;
  opacity: .9;
}

@media (min-width: 450px) {
  #toast-container > .toast {
    font-size: 15px;
    width: 375px;
  }
}

#toast-container > .toast:before {
  content: '';
  display: block;
  position: absolute;
  background: url("../assets/svg/Icons/Info.svg") no-repeat center;
  background-size: contain;
  -webkit-background-size: contain;
  width: 38px;
  height: 39px;
  left: 10px;
  top: 2px;
}

#toast-container > .toast:hover {
  box-shadow: 0 0 10px 2px rgba(177, 177, 177, 0.25);
}

#toast-container > .toast .toast-close-button {
  color: #555;
  right: 0;
}

#toast-container > .toast-success:before {
  background-image: url("../assets/svg/Icons/Tick.svg");
  filter: invert(66%) sepia(100%) saturate(378%) hue-rotate(39deg) brightness(93%) contrast(94%);
}

#toast-container > .toast-success > div {
  color: #7ED321;
}

#toast-container > .toast-success:hover {
  box-shadow: 0 0 10px 2px rgba(126, 211, 33, 0.125);
}

#toast-container > .toast-error:before {
  background-image: url("../assets/svg/Icons/Ban.svg");
  filter: invert(28%) sepia(78%) saturate(6434%) hue-rotate(348deg) brightness(93%) contrast(99%);
}

#toast-container > .toast-error > div {
  color: #ED1E23;
}

#toast-container > .toast-error:hover {
  box-shadow: 0 0 10px 2px rgba(237, 30, 35, 0.125);
}

#toast-container > .toast-info:before {
  filter: invert(62%) sepia(98%) saturate(3697%) hue-rotate(163deg) brightness(98%) contrast(101%);
}

#toast-container > .toast-info > div {
  color: #00ADEF;
}

#toast-container > .toast-info:hover {
  box-shadow: 0 0 10px 2px rgba(0, 173, 239, 0.125);
}

.login-container .alert.alert-error a {
  color: inherit;
  font-weight: 600;
  text-decoration: underline;
}

.site-alert {
  position: sticky;
  top: 0;
  z-index: 10000;
}

.site-alert-content {
  padding-right: 1rem;
}

.site-alert-control {
  padding-top: 7px;
}

.site-alert.site-alert-type-1 {
  background-color: #e4f7eb;
}

.site-alert.site-alert-type-2,
.site-alert.site-alert-type-3,
.site-alert.site-alert-type-4 {
  background-color: #f2f2f7;
}

.site-alert.site-alert-type-5 {
  background-color: #ffb067;
}

.site-alert .container {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.site-alert .container h4 {
  padding-top: 0;
}

.site-alert .container p {
  margin: 0;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"],
button,
a,
label,
div,
span {
  /*&.btn-loading{
        &:before {
            margin-top:-1px;
            font-size: 150%;
            content:$icon-loading !important;
            animation:spin3d .75s infinite;
        }
    }*/
}

button.btn,
input[type="button"].btn,
input[type="reset"].btn,
input[type="submit"].btn,
button.btn,
a.btn,
label.btn,
div.btn,
span.btn {
  border: 1px solid transparent;
  border-radius: 0;
  background: none;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  text-align: center;
  position: relative;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  fill: currentColor;
  text-decoration: none;
  outline: none;
  text-align: center;
  line-height: 1.1;
  color: #fff;
  transition: all .1s linear;
  margin: .2em 0;
  padding: 16px 2.1rem;
  font-size: 13px;
  margin: .3rem 0 .3rem;
  background-color: #00ADEF;
  color: #ffffff;
}

button.btn:focus,
input[type="button"].btn:focus,
input[type="reset"].btn:focus,
input[type="submit"].btn:focus,
button.btn:focus,
a.btn:focus,
label.btn:focus,
div.btn:focus,
span.btn:focus {
  transform: scale(0.98);
}

button.btn:disabled:not(.btn-success),
input[type="button"].btn:disabled:not(.btn-success),
input[type="reset"].btn:disabled:not(.btn-success),
input[type="submit"].btn:disabled:not(.btn-success),
button.btn:disabled:not(.btn-success),
a.btn:disabled:not(.btn-success),
label.btn:disabled:not(.btn-success),
div.btn:disabled:not(.btn-success),
span.btn:disabled:not(.btn-success) {
  cursor: not-allowed;
  opacity: 0.3;
}

button.btn:hover:not(:disabled),
input[type="button"].btn:hover:not(:disabled),
input[type="reset"].btn:hover:not(:disabled),
input[type="submit"].btn:hover:not(:disabled),
button.btn:hover:not(:disabled),
a.btn:hover:not(:disabled),
label.btn:hover:not(:disabled),
div.btn:hover:not(:disabled),
span.btn:hover:not(:disabled) {
  color: #ffffff;
  background-color: #006389;
}

button.btn i,
input[type="button"].btn i,
input[type="reset"].btn i,
input[type="submit"].btn i,
button.btn i,
a.btn i,
label.btn i,
div.btn i,
span.btn i {
  margin-left: .1em;
  margin-right: .1em;
  margin-top: -2px;
  font-size: 85%;
}

button:disabled,
input[type="button"]:disabled,
input[type="reset"]:disabled,
input[type="submit"]:disabled,
button:disabled,
a:disabled,
label:disabled,
div:disabled,
span:disabled {
  cursor: not-allowed;
  filter: brightness(80%);
  opacity: .8;
  position: relative;
}

button:disabled svg.icon-lock,
input[type="button"]:disabled svg.icon-lock,
input[type="reset"]:disabled svg.icon-lock,
input[type="submit"]:disabled svg.icon-lock,
button:disabled svg.icon-lock,
a:disabled svg.icon-lock,
label:disabled svg.icon-lock,
div:disabled svg.icon-lock,
span:disabled svg.icon-lock {
  height: 30px;
  margin-top: -3px;
  width: 30px;
  fill: white !important;
}

button .btn-submit,
input[type="button"] .btn-submit,
input[type="reset"] .btn-submit,
input[type="submit"] .btn-submit,
button .btn-submit,
a .btn-submit,
label .btn-submit,
div .btn-submit,
span .btn-submit {
  min-width: 13rem;
  margin-left: auto;
  margin-right: auto;
  background-color: #00ADEF;
}

button .form-loading .btn-submit:before,
input[type="button"] .form-loading .btn-submit:before,
input[type="reset"] .form-loading .btn-submit:before,
input[type="submit"] .form-loading .btn-submit:before,
button .form-loading .btn-submit:before,
a .form-loading .btn-submit:before,
label .form-loading .btn-submit:before,
div .form-loading .btn-submit:before,
span .form-loading .btn-submit:before {
  display: none;
}

button .success-message,
input[type="button"] .success-message,
input[type="reset"] .success-message,
input[type="submit"] .success-message,
button .success-message,
a .success-message,
label .success-message,
div .success-message,
span .success-message {
  display: none;
}

button.btn-success,
input[type="button"].btn-success,
input[type="reset"].btn-success,
input[type="submit"].btn-success,
button.btn-success,
a.btn-success,
label.btn-success,
div.btn-success,
span.btn-success {
  background-color: #3eb44a;
  color: #ffffff;
}

button.btn-success:hover:not(:disabled),
input[type="button"].btn-success:hover:not(:disabled),
input[type="reset"].btn-success:hover:not(:disabled),
input[type="submit"].btn-success:hover:not(:disabled),
button.btn-success:hover:not(:disabled),
a.btn-success:hover:not(:disabled),
label.btn-success:hover:not(:disabled),
div.btn-success:hover:not(:disabled),
span.btn-success:hover:not(:disabled) {
  color: #ffffff;
  background-color: #24682b;
}

button.btn-success span,
input[type="button"].btn-success span,
input[type="reset"].btn-success span,
input[type="submit"].btn-success span,
button.btn-success span,
a.btn-success span,
label.btn-success span,
div.btn-success span,
span.btn-success span {
  display: none;
}

button.btn-success span.success-message,
input[type="button"].btn-success span.success-message,
input[type="reset"].btn-success span.success-message,
input[type="submit"].btn-success span.success-message,
button.btn-success span.success-message,
a.btn-success span.success-message,
label.btn-success span.success-message,
div.btn-success span.success-message,
span.btn-success span.success-message {
  display: inline-block;
}

button.btn-success:before,
input[type="button"].btn-success:before,
input[type="reset"].btn-success:before,
input[type="submit"].btn-success:before,
button.btn-success:before,
a.btn-success:before,
label.btn-success:before,
div.btn-success:before,
span.btn-success:before {
  position: absolute;
  top: 30%;
  left: 1.2rem;
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-size: 110%;
}

button.btn-next,
input[type="button"].btn-next,
input[type="reset"].btn-next,
input[type="submit"].btn-next,
button.btn-next,
a.btn-next,
label.btn-next,
div.btn-next,
span.btn-next {
  margin: 2.5em 0 1em;
}

button.btn-next:after,
input[type="button"].btn-next:after,
input[type="reset"].btn-next:after,
input[type="submit"].btn-next:after,
button.btn-next:after,
a.btn-next:after,
label.btn-next:after,
div.btn-next:after,
span.btn-next:after {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  margin-left: .4em;
  line-height: .6;
  margin-top: -1px;
}

button.btn-next:hover,
input[type="button"].btn-next:hover,
input[type="reset"].btn-next:hover,
input[type="submit"].btn-next:hover,
button.btn-next:hover,
a.btn-next:hover,
label.btn-next:hover,
div.btn-next:hover,
span.btn-next:hover {
  background-color: #318e3a;
}

button.btn-prev,
input[type="button"].btn-prev,
input[type="reset"].btn-prev,
input[type="submit"].btn-prev,
button.btn-prev,
a.btn-prev,
label.btn-prev,
div.btn-prev,
span.btn-prev {
  margin: 2.5em 0 1em;
}

button.btn-prev:before,
input[type="button"].btn-prev:before,
input[type="reset"].btn-prev:before,
input[type="submit"].btn-prev:before,
button.btn-prev:before,
a.btn-prev:before,
label.btn-prev:before,
div.btn-prev:before,
span.btn-prev:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  margin-right: .4em;
  line-height: 0.6 !important;
  margin-top: -1px;
}

button .btn-group.btn-set,
input[type="button"] .btn-group.btn-set,
input[type="reset"] .btn-group.btn-set,
input[type="submit"] .btn-group.btn-set,
button .btn-group.btn-set,
a .btn-group.btn-set,
label .btn-group.btn-set,
div .btn-group.btn-set,
span .btn-group.btn-set {
  margin: 0;
}

button .btn-group.btn-set .btn,
input[type="button"] .btn-group.btn-set .btn,
input[type="reset"] .btn-group.btn-set .btn,
input[type="submit"] .btn-group.btn-set .btn,
button .btn-group.btn-set .btn,
a .btn-group.btn-set .btn,
label .btn-group.btn-set .btn,
div .btn-group.btn-set .btn,
span .btn-group.btn-set .btn {
  height: 100%;
  padding: 0.6rem 0.8rem;
  font-size: .725rem;
  margin: .1rem 0;
  line-height: normal;
  -moz-box-flex: 1  ;
  -webkit-flex: 1  ;
  -ms-flex: 1  ;
  flex: 1  ;
  align-self: center;
  background-color: #eee;
  color: #ffffff;
  height: 42px;
  margin: 0 1px 0 0;
}

button .btn-group.btn-set .btn:hover:not(:disabled),
input[type="button"] .btn-group.btn-set .btn:hover:not(:disabled),
input[type="reset"] .btn-group.btn-set .btn:hover:not(:disabled),
input[type="submit"] .btn-group.btn-set .btn:hover:not(:disabled),
button .btn-group.btn-set .btn:hover:not(:disabled),
a .btn-group.btn-set .btn:hover:not(:disabled),
label .btn-group.btn-set .btn:hover:not(:disabled),
div .btn-group.btn-set .btn:hover:not(:disabled),
span .btn-group.btn-set .btn:hover:not(:disabled) {
  color: #ffffff;
  background-color: #bbbbbb;
}

button .btn-group.btn-set .btn:before, button .btn-group.btn-set .btn:after, button .btn-group.btn-set .btn i,
input[type="button"] .btn-group.btn-set .btn:before,
input[type="button"] .btn-group.btn-set .btn:after,
input[type="button"] .btn-group.btn-set .btn i,
input[type="reset"] .btn-group.btn-set .btn:before,
input[type="reset"] .btn-group.btn-set .btn:after,
input[type="reset"] .btn-group.btn-set .btn i,
input[type="submit"] .btn-group.btn-set .btn:before,
input[type="submit"] .btn-group.btn-set .btn:after,
input[type="submit"] .btn-group.btn-set .btn i,
button .btn-group.btn-set .btn:before,
button .btn-group.btn-set .btn:after,
button .btn-group.btn-set .btn i,
a .btn-group.btn-set .btn:before,
a .btn-group.btn-set .btn:after,
a .btn-group.btn-set .btn i,
label .btn-group.btn-set .btn:before,
label .btn-group.btn-set .btn:after,
label .btn-group.btn-set .btn i,
div .btn-group.btn-set .btn:before,
div .btn-group.btn-set .btn:after,
div .btn-group.btn-set .btn i,
span .btn-group.btn-set .btn:before,
span .btn-group.btn-set .btn:after,
span .btn-group.btn-set .btn i {
  color: #999;
}

button .btn-group.btn-set .btn:last-child,
input[type="button"] .btn-group.btn-set .btn:last-child,
input[type="reset"] .btn-group.btn-set .btn:last-child,
input[type="submit"] .btn-group.btn-set .btn:last-child,
button .btn-group.btn-set .btn:last-child,
a .btn-group.btn-set .btn:last-child,
label .btn-group.btn-set .btn:last-child,
div .btn-group.btn-set .btn:last-child,
span .btn-group.btn-set .btn:last-child {
  margin: 0;
}

button .btn-cancel,
input[type="button"] .btn-cancel,
input[type="reset"] .btn-cancel,
input[type="submit"] .btn-cancel,
button .btn-cancel,
a .btn-cancel,
label .btn-cancel,
div .btn-cancel,
span .btn-cancel {
  background-color: transparent;
  color: #ffffff;
  color: #00ADEF;
  border-radius: 0;
  border: 1px solid #00ADEF;
  margin: 2.5em 0 1em;
  max-width: 140px;
}

button .btn-cancel:hover:not(:disabled),
input[type="button"] .btn-cancel:hover:not(:disabled),
input[type="reset"] .btn-cancel:hover:not(:disabled),
input[type="submit"] .btn-cancel:hover:not(:disabled),
button .btn-cancel:hover:not(:disabled),
a .btn-cancel:hover:not(:disabled),
label .btn-cancel:hover:not(:disabled),
div .btn-cancel:hover:not(:disabled),
span .btn-cancel:hover:not(:disabled) {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
}

button .btn-cancel:hover:not(:disabled), button .btn-cancel.active,
input[type="button"] .btn-cancel:hover:not(:disabled),
input[type="button"] .btn-cancel.active,
input[type="reset"] .btn-cancel:hover:not(:disabled),
input[type="reset"] .btn-cancel.active,
input[type="submit"] .btn-cancel:hover:not(:disabled),
input[type="submit"] .btn-cancel.active,
button .btn-cancel:hover:not(:disabled),
button .btn-cancel.active,
a .btn-cancel:hover:not(:disabled),
a .btn-cancel.active,
label .btn-cancel:hover:not(:disabled),
label .btn-cancel.active,
div .btn-cancel:hover:not(:disabled),
div .btn-cancel.active,
span .btn-cancel:hover:not(:disabled),
span .btn-cancel.active {
  background-color: #00ADEF !important;
  border-color: #00ADEF;
  color: #fff;
}

button .btn-delete,
input[type="button"] .btn-delete,
input[type="reset"] .btn-delete,
input[type="submit"] .btn-delete,
button .btn-delete,
a .btn-delete,
label .btn-delete,
div .btn-delete,
span .btn-delete {
  border: 1px solid transparent;
  border-radius: 0;
  background: none;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  text-align: center;
  position: relative;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  fill: currentColor;
  text-decoration: none;
  outline: none;
  text-align: center;
  line-height: 1.1;
  color: #fff;
  transition: all .1s linear;
  margin: .2em 0;
  padding: 16px 2.1rem;
  font-size: 13px;
  margin: .3rem 0 .3rem;
  background-color: #f8acae;
  color: #ffffff;
}

button .btn-delete:focus,
input[type="button"] .btn-delete:focus,
input[type="reset"] .btn-delete:focus,
input[type="submit"] .btn-delete:focus,
button .btn-delete:focus,
a .btn-delete:focus,
label .btn-delete:focus,
div .btn-delete:focus,
span .btn-delete:focus {
  transform: scale(0.98);
}

button .btn-delete:disabled:not(.btn-success),
input[type="button"] .btn-delete:disabled:not(.btn-success),
input[type="reset"] .btn-delete:disabled:not(.btn-success),
input[type="submit"] .btn-delete:disabled:not(.btn-success),
button .btn-delete:disabled:not(.btn-success),
a .btn-delete:disabled:not(.btn-success),
label .btn-delete:disabled:not(.btn-success),
div .btn-delete:disabled:not(.btn-success),
span .btn-delete:disabled:not(.btn-success) {
  cursor: not-allowed;
  opacity: 0.3;
}

button .btn-delete:hover:not(:disabled),
input[type="button"] .btn-delete:hover:not(:disabled),
input[type="reset"] .btn-delete:hover:not(:disabled),
input[type="submit"] .btn-delete:hover:not(:disabled),
button .btn-delete:hover:not(:disabled),
a .btn-delete:hover:not(:disabled),
label .btn-delete:hover:not(:disabled),
div .btn-delete:hover:not(:disabled),
span .btn-delete:hover:not(:disabled) {
  color: #ffffff;
  background-color: #f14d51;
}

button .btn-delete i,
input[type="button"] .btn-delete i,
input[type="reset"] .btn-delete i,
input[type="submit"] .btn-delete i,
button .btn-delete i,
a .btn-delete i,
label .btn-delete i,
div .btn-delete i,
span .btn-delete i {
  margin-left: .1em;
  margin-right: .1em;
  margin-top: -2px;
  font-size: 85%;
}

button.btn.btn-block,
input[type="button"].btn.btn-block,
input[type="reset"].btn.btn-block,
input[type="submit"].btn.btn-block,
button.btn.btn-block,
a.btn.btn-block,
label.btn.btn-block,
div.btn.btn-block,
span.btn.btn-block {
  display: block;
  width: 100%;
}

button.btn.btn-sec,
input[type="button"].btn.btn-sec,
input[type="reset"].btn.btn-sec,
input[type="submit"].btn.btn-sec,
button.btn.btn-sec,
a.btn.btn-sec,
label.btn.btn-sec,
div.btn.btn-sec,
span.btn.btn-sec {
  background-color: #2D363C;
  color: #ffffff;
}

button.btn.btn-sec:hover:not(:disabled),
input[type="button"].btn.btn-sec:hover:not(:disabled),
input[type="reset"].btn.btn-sec:hover:not(:disabled),
input[type="submit"].btn.btn-sec:hover:not(:disabled),
button.btn.btn-sec:hover:not(:disabled),
a.btn.btn-sec:hover:not(:disabled),
label.btn.btn-sec:hover:not(:disabled),
div.btn.btn-sec:hover:not(:disabled),
span.btn.btn-sec:hover:not(:disabled) {
  color: #ffffff;
  background-color: #010202;
}

button.btn.btn-highlight,
input[type="button"].btn.btn-highlight,
input[type="reset"].btn.btn-highlight,
input[type="submit"].btn.btn-highlight,
button.btn.btn-highlight,
a.btn.btn-highlight,
label.btn.btn-highlight,
div.btn.btn-highlight,
span.btn.btn-highlight {
  background-color: #ED1E23;
  color: #ffffff;
}

button.btn.btn-highlight:hover:not(:disabled),
input[type="button"].btn.btn-highlight:hover:not(:disabled),
input[type="reset"].btn.btn-highlight:hover:not(:disabled),
input[type="submit"].btn.btn-highlight:hover:not(:disabled),
button.btn.btn-highlight:hover:not(:disabled),
a.btn.btn-highlight:hover:not(:disabled),
label.btn.btn-highlight:hover:not(:disabled),
div.btn.btn-highlight:hover:not(:disabled),
span.btn.btn-highlight:hover:not(:disabled) {
  color: #ffffff;
  background-color: #990c10;
}

button.btn.btn-invert,
input[type="button"].btn.btn-invert,
input[type="reset"].btn.btn-invert,
input[type="submit"].btn.btn-invert,
button.btn.btn-invert,
a.btn.btn-invert,
label.btn.btn-invert,
div.btn.btn-invert,
span.btn.btn-invert {
  background-color: transparent;
  color: #ffffff;
  color: #00ADEF;
  border-radius: 0;
  border: 1px solid #00ADEF;
}

button.btn.btn-invert:hover:not(:disabled),
input[type="button"].btn.btn-invert:hover:not(:disabled),
input[type="reset"].btn.btn-invert:hover:not(:disabled),
input[type="submit"].btn.btn-invert:hover:not(:disabled),
button.btn.btn-invert:hover:not(:disabled),
a.btn.btn-invert:hover:not(:disabled),
label.btn.btn-invert:hover:not(:disabled),
div.btn.btn-invert:hover:not(:disabled),
span.btn.btn-invert:hover:not(:disabled) {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
}

button.btn.btn-invert:hover:not(:disabled), button.btn.btn-invert.active,
input[type="button"].btn.btn-invert:hover:not(:disabled),
input[type="button"].btn.btn-invert.active,
input[type="reset"].btn.btn-invert:hover:not(:disabled),
input[type="reset"].btn.btn-invert.active,
input[type="submit"].btn.btn-invert:hover:not(:disabled),
input[type="submit"].btn.btn-invert.active,
button.btn.btn-invert:hover:not(:disabled),
button.btn.btn-invert.active,
a.btn.btn-invert:hover:not(:disabled),
a.btn.btn-invert.active,
label.btn.btn-invert:hover:not(:disabled),
label.btn.btn-invert.active,
div.btn.btn-invert:hover:not(:disabled),
div.btn.btn-invert.active,
span.btn.btn-invert:hover:not(:disabled),
span.btn.btn-invert.active {
  background-color: #00ADEF !important;
  border-color: #00ADEF;
  color: #fff;
}

button.btn.btn-invert--muted,
input[type="button"].btn.btn-invert--muted,
input[type="reset"].btn.btn-invert--muted,
input[type="submit"].btn.btn-invert--muted,
button.btn.btn-invert--muted,
a.btn.btn-invert--muted,
label.btn.btn-invert--muted,
div.btn.btn-invert--muted,
span.btn.btn-invert--muted {
  border-color: #B1B1B1;
  color: #B1B1B1;
}

button.btn.btn-invert--muted:hover:not(:disabled),
input[type="button"].btn.btn-invert--muted:hover:not(:disabled),
input[type="reset"].btn.btn-invert--muted:hover:not(:disabled),
input[type="submit"].btn.btn-invert--muted:hover:not(:disabled),
button.btn.btn-invert--muted:hover:not(:disabled),
a.btn.btn-invert--muted:hover:not(:disabled),
label.btn.btn-invert--muted:hover:not(:disabled),
div.btn.btn-invert--muted:hover:not(:disabled),
span.btn.btn-invert--muted:hover:not(:disabled) {
  background: transparent !important;
  border-color: #00ADEF;
  color: #00ADEF;
}

button.btn.btn-xl,
input[type="button"].btn.btn-xl,
input[type="reset"].btn.btn-xl,
input[type="submit"].btn.btn-xl,
button.btn.btn-xl,
a.btn.btn-xl,
label.btn.btn-xl,
div.btn.btn-xl,
span.btn.btn-xl {
  padding: 20px 4.6rem;
  font-size: .8rem;
  margin: .8rem 0 1rem;
}

button.btn.btn-lg,
input[type="button"].btn.btn-lg,
input[type="reset"].btn.btn-lg,
input[type="submit"].btn.btn-lg,
button.btn.btn-lg,
a.btn.btn-lg,
label.btn.btn-lg,
div.btn.btn-lg,
span.btn.btn-lg {
  padding: 20px 3.8rem;
  font-size: .775rem;
  margin-top: .5rem;
  margin-bottom: 1rem;
}

button.btn.btn-sm,
input[type="button"].btn.btn-sm,
input[type="reset"].btn.btn-sm,
input[type="submit"].btn.btn-sm,
button.btn.btn-sm,
a.btn.btn-sm,
label.btn.btn-sm,
div.btn.btn-sm,
span.btn.btn-sm {
  padding: 0.6rem 0.8rem;
  font-size: .725rem;
  margin: .1rem 0;
  line-height: normal;
}

button.btn.btn-xs,
input[type="button"].btn.btn-xs,
input[type="reset"].btn.btn-xs,
input[type="submit"].btn.btn-xs,
button.btn.btn-xs,
a.btn.btn-xs,
label.btn.btn-xs,
div.btn.btn-xs,
span.btn.btn-xs {
  padding: 1em 1.125em;
  font-size: .6rem;
  margin: 0;
}

button.btn.btn-xs svg,
input[type="button"].btn.btn-xs svg,
input[type="reset"].btn.btn-xs svg,
input[type="submit"].btn.btn-xs svg,
button.btn.btn-xs svg,
a.btn.btn-xs svg,
label.btn.btn-xs svg,
div.btn.btn-xs svg,
span.btn.btn-xs svg {
  width: 20px;
  height: 20px;
  margin-right: -0.5em;
}

button.btn.btn-dropdown,
input[type="button"].btn.btn-dropdown,
input[type="reset"].btn.btn-dropdown,
input[type="submit"].btn.btn-dropdown,
button.btn.btn-dropdown,
a.btn.btn-dropdown,
label.btn.btn-dropdown,
div.btn.btn-dropdown,
span.btn.btn-dropdown {
  padding-left: .85em;
  padding-right: .7em;
  margin-left: 1px !important;
  display: inline-block;
  height: 100%;
  min-width: 40px;
  vertical-align: middle;
}

button.btn.btn-dropdown:before,
input[type="button"].btn.btn-dropdown:before,
input[type="reset"].btn.btn-dropdown:before,
input[type="submit"].btn.btn-dropdown:before,
button.btn.btn-dropdown:before,
a.btn.btn-dropdown:before,
label.btn.btn-dropdown:before,
div.btn.btn-dropdown:before,
span.btn.btn-dropdown:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  transition: transform .2s ease;
  display: inline-block;
  margin: 0px;
  margin-bottom: 2px;
  line-height: 1.1;
}

button.btn.btn-dropdown:hover:before,
input[type="button"].btn.btn-dropdown:hover:before,
input[type="reset"].btn.btn-dropdown:hover:before,
input[type="submit"].btn.btn-dropdown:hover:before,
button.btn.btn-dropdown:hover:before,
a.btn.btn-dropdown:hover:before,
label.btn.btn-dropdown:hover:before,
div.btn.btn-dropdown:hover:before,
span.btn.btn-dropdown:hover:before {
  transform: rotate(-90deg);
}

button.btn.btn-cart-remove,
input[type="button"].btn.btn-cart-remove,
input[type="reset"].btn.btn-cart-remove,
input[type="submit"].btn.btn-cart-remove,
button.btn.btn-cart-remove,
a.btn.btn-cart-remove,
label.btn.btn-cart-remove,
div.btn.btn-cart-remove,
span.btn.btn-cart-remove {
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
}

button.btn.btn-cart-remove:before,
input[type="button"].btn.btn-cart-remove:before,
input[type="reset"].btn.btn-cart-remove:before,
input[type="submit"].btn.btn-cart-remove:before,
button.btn.btn-cart-remove:before,
a.btn.btn-cart-remove:before,
label.btn.btn-cart-remove:before,
div.btn.btn-cart-remove:before,
span.btn.btn-cart-remove:before {
  padding-right: 10px;
  color: #ED1E23;
  float: left;
  font-size: 135%;
  line-height: .9;
}

button.btn.btn-cart-add:before,
input[type="button"].btn.btn-cart-add:before,
input[type="reset"].btn.btn-cart-add:before,
input[type="submit"].btn.btn-cart-add:before,
button.btn.btn-cart-add:before,
a.btn.btn-cart-add:before,
label.btn.btn-cart-add:before,
div.btn.btn-cart-add:before,
span.btn.btn-cart-add:before {
  padding-right: 10px;
  color: #aaa;
  float: left;
  font-size: 135%;
  line-height: .9;
}

button.btn.btn-facebook,
input[type="button"].btn.btn-facebook,
input[type="reset"].btn.btn-facebook,
input[type="submit"].btn.btn-facebook,
button.btn.btn-facebook,
a.btn.btn-facebook,
label.btn.btn-facebook,
div.btn.btn-facebook,
span.btn.btn-facebook {
  padding: 20px 4.6rem;
  font-size: .8rem;
  margin: .8rem 0 1rem;
  background-color: #49639f;
  color: #ffffff;
  color: #fff;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  margin: .8em 0 1em;
  padding-left: 2.5em;
  padding-right: 0;
  width: 100%;
  max-width: 400px;
  height: auto;
  display: table;
  position: relative;
  vertical-align: middle;
}

button.btn.btn-facebook:hover:not(:disabled),
input[type="button"].btn.btn-facebook:hover:not(:disabled),
input[type="reset"].btn.btn-facebook:hover:not(:disabled),
input[type="submit"].btn.btn-facebook:hover:not(:disabled),
button.btn.btn-facebook:hover:not(:disabled),
a.btn.btn-facebook:hover:not(:disabled),
label.btn.btn-facebook:hover:not(:disabled),
div.btn.btn-facebook:hover:not(:disabled),
span.btn.btn-facebook:hover:not(:disabled) {
  color: #ffffff;
  background-color: #293759;
}

button.btn.btn-facebook:before,
input[type="button"].btn.btn-facebook:before,
input[type="reset"].btn.btn-facebook:before,
input[type="submit"].btn.btn-facebook:before,
button.btn.btn-facebook:before,
a.btn.btn-facebook:before,
label.btn.btn-facebook:before,
div.btn.btn-facebook:before,
span.btn.btn-facebook:before {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  padding: .4em .5em;
  display: table-cell;
  padding-right: 10px;
  color: #fff;
  font-size: 31px;
  margin: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

button.btn-danger,
input[type="button"].btn-danger,
input[type="reset"].btn-danger,
input[type="submit"].btn-danger,
button.btn-danger,
a.btn-danger,
label.btn-danger,
div.btn-danger,
span.btn-danger {
  background-color: #ED1E23;
  color: #ffffff;
}

button.btn-danger:hover:not(:disabled),
input[type="button"].btn-danger:hover:not(:disabled),
input[type="reset"].btn-danger:hover:not(:disabled),
input[type="submit"].btn-danger:hover:not(:disabled),
button.btn-danger:hover:not(:disabled),
a.btn-danger:hover:not(:disabled),
label.btn-danger:hover:not(:disabled),
div.btn-danger:hover:not(:disabled),
span.btn-danger:hover:not(:disabled) {
  color: #ffffff;
  background-color: #990c10;
}

button [class~="btn__icon"],
input[type="button"] [class~="btn__icon"],
input[type="reset"] [class~="btn__icon"],
input[type="submit"] [class~="btn__icon"],
button [class~="btn__icon"],
a [class~="btn__icon"],
label [class~="btn__icon"],
div [class~="btn__icon"],
span [class~="btn__icon"] {
  width: 1.5em;
  height: 1.5em;
  color: inherit;
  margin-top: -0.5em;
  margin-bottom: -0.5em;
}

.btn-rounded {
  border-radius: 50px !important;
}

.btn-group {
  position: relative;
  clear: both;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: .9rem 0 2rem;
  padding: .45rem 0 1rem;
}

.btn-group::after {
  clear: both;
  content: "";
  display: table;
}

.btn-group .btn-next, .btn-group .btn-prev {
  height: 54px;
}

.btn-group .btn {
  width: 100%;
  margin: .2rem 0 .5rem;
}

.btn-group .btn-next, .btn-group .btn-cancel {
  align-self: flex-end;
  margin-left: auto !important;
}

.btn-group .btn {
  width: 100%;
}

.btn-group .add-checked-to-cart {
  -moz-box-flex: 1  ;
  -webkit-flex: 1  ;
  -ms-flex: 1  ;
  flex: 1  ;
}

@media (min-width: 450px) {
  .btn-group .btn {
    width: auto;
    margin: 1rem 0;
  }
  .btn-group .add-checked-to-cart {
    -moz-box-flex: 0 0 auto  ;
    -webkit-flex: 0 0 auto  ;
    -ms-flex: 0 0 auto  ;
    flex: 0 0 auto  ;
    max-width: auto;
    width: 100%;
    margin-left: auto;
  }
  .btn-group .btn + .btn {
    margin-left: 5px;
  }
  .btn-group .btn + .btn.btn-block {
    margin-left: 0;
  }
}

.btn-down-arrow {
  width: 100%;
  display: block;
}

.btn-down-arrow:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-size: 30px;
  padding: 30px;
  display: block;
  color: #00ADEF;
}

.btn-down-arrow:hover:before {
  color: #2D363C;
}

.aux-link {
  display: inline-block;
  vertical-align: middle;
  border-left: 1px solid #eee;
  margin: 0 0 0 5%;
  padding: 0 0 0 5%;
}

.button--disabled {
  pointer-events: none;
  opacity: .2;
}

table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  margin: 5px 0 10px;
}

@media (min-width: 1100px) {
  table {
    margin: 10px 0 15px;
  }
}

table tr,
table td,
table th {
  vertical-align: middle;
}

table tr.align-top,
table td.align-top,
table th.align-top {
  vertical-align: top !important;
}

table tr.align-middle,
table td.align-middle,
table th.align-middle {
  vertical-align: middle !important;
}

table tr {
  background-color: #fff;
  border-top: 1px solid #e5e5e5;
}

table td {
  font-weight: normal;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  padding: 8px;
}

@media (min-width: 1100px) {
  table td {
    padding: 10px;
  }
}

table td i[class*=card]:before {
  font-size: 2.9em;
}

table thead tr {
  border-top: none;
  border-bottom: 1px solid #d9d9d9;
  font-weight: normal;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #888;
}

table tr th {
  padding: 8px;
  margin: 0;
  border-bottom: 2px solid #999;
  font-weight: normal;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  text-align: left;
}

@media (min-width: 1100px) {
  table tr th {
    padding: 10px;
  }
}

.table-selectable tbody tr:hover {
  background-color: #eee;
}

.table-selectable a {
  display: block;
}

.table-selectable a.btn {
  display: inline-block;
}

.table-selectable .icon-pdf-file-symbol {
  font-size: 19px;
  margin: -1px 0;
}

table.table-xl td {
  padding: 12px 12px;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
}

table.table-xl .btn {
  margin: -2px 0;
}

table.table-lg {
  margin: 10px 0;
  font-size: 15px;
}

table.table-lg td {
  padding: 12px 12px;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
}

table.table-lg .btn {
  margin: -2px 0;
}

table.table-md .btn {
  margin: -2px 0;
}

table.table-sm {
  font-size: 14px;
}

table.table-sm tr th {
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  font-size: 13px;
  padding: 4px 8px;
}

table.table-sm td {
  padding: 4px 8px;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
}

table.table-sm .btn {
  margin: -2px 0;
}

table.table-xs {
  margin: 5px 0;
  font-size: 14px;
}

table.table-xs td {
  padding: 2px 5px;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
}

table.table-xs .btn {
  margin: 0;
}

.moment-picker-specific-views table tr th {
  border: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  border-radius: 50%;
  visibility: hidden;
  opacity: 0;
  transition: opacity .2s linear;
  display: block;
  z-index: 99999;
  width: 24px;
  height: 24px;
  border: 0.2rem solid rgba(255, 255, 255, 0.2);
  border-top-color: white;
  animation: spin 1s infinite linear;
}

.loading--double {
  border-style: double;
  border-width: .5rem;
}

.btn-loading {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.btn-loading .loading {
  position: absolute;
  left: 2rem;
  top: 50%;
  margin-top: -12px;
}

.btn-loading-relative {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.btn-loading-relative .loading {
  position: relative;
  display: none;
  left: -1rem;
  top: 50%;
  margin-top: -12px;
}

.page-loading {
  position: fixed;
  left: 50%;
  z-index: 99999;
  top: 40%;
  color: #333;
}

.page-loading.loading {
  width: 38px;
  height: 38px;
  margin-top: -38px;
  margin-left: -19px;
  border: 0.25rem solid rgba(50, 50, 50, 0.1);
  border-top-color: #333;
}

.page-loading.loading-text {
  width: 228px;
  font-style: italic;
  font-size: .8rem;
  margin-top: .3rem;
  color: #333;
  text-align: center;
  margin-left: -114px;
  visibility: hidden;
  opacity: 0;
  transition: opacity .2s linear;
}

.home .page-loading {
  color: #fff;
}

.home .page-loading.loading-text {
  color: #fff;
}

.home .page-loading.loading {
  border: 0.25rem solid rgba(250, 250, 250, 0.15);
  border-top-color: #eee;
}

.form-loading .loading,
.is-loading {
  visibility: visible !important;
  opacity: 1 !important;
  display: block;
}

input[type="color"].parsley-success, input[type="date"].parsley-success, input[type="datetime"].parsley-success, input[type="datetime-local"].parsley-success, input[type="email"].parsley-success, input[type="month"].parsley-success, input[type="number"].parsley-success, input[type="password"].parsley-success, input[type="search"].parsley-success, input[type="tel"].parsley-success, input[type="text"].parsley-success, input[type="time"].parsley-success, input[type="url"].parsley-success, input[type="week"].parsley-success, input:not([type]).parsley-success, textarea.parsley-success, .inputElement.parsley-success, [contenteditable=true].parsley-success {
  border: 1px solid #3eb44a;
}

input[type="color"].parsley-error, input[type="date"].parsley-error, input[type="datetime"].parsley-error, input[type="datetime-local"].parsley-error, input[type="email"].parsley-error, input[type="month"].parsley-error, input[type="number"].parsley-error, input[type="password"].parsley-error, input[type="search"].parsley-error, input[type="tel"].parsley-error, input[type="text"].parsley-error, input[type="time"].parsley-error, input[type="url"].parsley-error, input[type="week"].parsley-error, input:not([type]).parsley-error, textarea.parsley-error, .inputElement.parsley-error, [contenteditable=true].parsley-error {
  border: 1px solid #fef3f3;
}

.parsley-error .checkbox:after {
  border: 1px solid #fef3f3;
}

.parsley-error .select-holder {
  border: 1px solid #fef3f3;
}

.parsley-errors-list {
  font-size: 0.9em;
  line-height: 0.9em;
  list-style-type: none;
  margin: 2px 0 3px;
  opacity: 0;
  padding: 0;
  transition: all 0.3s ease 0s;
}

.parsley-errors-list.filled {
  opacity: 1;
  margin: 0 0 -4px 0;
  position: relative;
  display: block;
  width: 100%;
  background: white;
  color: #ED1E23;
  padding: 6px 2px 0px;
  font-size: 13px;
  text-align: left;
  display: block;
  border-radius: 0 0 4px 4px;
}

.parsley-errors-list.filled li {
  display: block;
}

.form-row.parsley-error {
  margin-bottom: 0;
}

.parsley-error input[type=radio].radio-btn:required + label, .parsley-error input[type=checkbox].radio-btn:required + label {
  border: 3px solid #ED1E23;
}

p.validate {
  margin: -.6rem auto .1rem 0;
  display: none;
  color: #fff;
  background: #ED1E23;
  padding: 12px;
  font-size: 13px;
  width: 100%;
  text-align: left;
  position: absolute;
  line-height: 1;
  left: 0;
  top: calc(100% + 22px);
  line-height: auto;
  z-index: 1;
}

p.validate:before {
  width: 0;
  height: 0;
  content: '';
  z-index: 2;
  border-bottom: 10px solid #ED1E23;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  top: -8px;
  left: 10px;
}

p.validate else {
  display: block;
  width: 100%;
}

.field-invalid textarea,
.field-invalid input, .field-invalid .select-holder, .field-invalid [contenteditable=true] {
  border: 1px solid #ED1E23 !important;
  box-shadow: 0 0 5px 0 #ED1E23;
}

.field-invalid::-webkit-input-placeholder {
  color: #ff676b;
}

.field-invalid:-moz-placeholder {
  /* Firefox 18- */
  color: #ff676b;
}

.field-invalid::-moz-placeholder {
  /* Firefox 19+ */
  color: #ff676b;
}

.field-invalid:-ms-input-placeholder {
  color: #ff676b;
}

.field-invalid p.validate {
  display: block;
}

@media (max-width: 1099px) {
  html,
  body,
  ui-view,
  .sidebar-pusher,
  .content-container {
    height: 100%;
  }
}

.content-container,
.content-container-inner {
  position: relative;
}

@media (min-width: 1100px) {
  .sidebar-pusher--minimal {
    transform: translate3d(75px, 0, 0) !important;
    left: 0 !important;
    width: calc(100% - 75px) !important;
    overflow: hidden;
  }
}

.sidebar-pusher--minimal ~ .sidebar {
  width: 75px;
}

.sidebar-pusher--minimal ~ .sidebar .header {
  padding: 0;
}

.sidebar-pusher--minimal ~ .sidebar .sidebar-content {
  width: 75px;
  overflow: auto;
}

.sidebar-pusher--minimal ~ .sidebar .sidebar-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(45, 54, 60, 0.3);
  border-radius: 1px;
  background-color: rgba(45, 54, 60, 0.5);
}

.sidebar-pusher--minimal ~ .sidebar .sidebar-content::-webkit-scrollbar {
  width: 2px;
  height: 0;
  background-color: #F5F5F5;
}

.sidebar-pusher--minimal ~ .sidebar .sidebar-content::-webkit-scrollbar-thumb {
  border-radius: 1px;
  -webkit-box-shadow: inset 0 0 6px rgba(45, 54, 60, 0.3);
  background-color: #00ADEF;
}

.sidebar-pusher--minimal ~ .sidebar .sidebar-footer > ul {
  padding: 0;
  width: 75px;
}

.sidebar-pusher--minimal ~ .sidebar .sidebar-footer > ul > li > a {
  padding-left: 15px;
  padding-right: 15px;
}

.sidebar-pusher--minimal ~ .sidebar .sidebar-footer > ul > li > a > span {
  display: none;
}

.sidebar-pusher--minimal ~ .sidebar .sidebar-footer > ul > li > a > svg {
  margin: 0;
}

@media (min-width: 767px) {
  .sidebar-pusher--minimal ~ .sidebar .sidebar-footer .myaccount > a > img.svg-icon {
    left: 12px;
  }
}

@media (min-width: 1100px) {
  .sidebar-pusher--minimal ~ .profile-flyout .flyout--open,
  .sidebar-pusher--minimal ~ .search-component .flyout--open,
  .sidebar-pusher--minimal ~ .flyout .flyout--open {
    left: 75px;
  }
}

.sidebar-pusher--minimal .articles-main {
  max-width: 1360px;
}

.sidebar-pusher {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1000;
  transition: all 0.4s linear;
  overflow: hidden;
}

@media (max-width: 1099px) {
  .sidebar-pusher {
    display: flex;
    flex-flow: column nowrap;
  }
  .sidebar-pusher > * {
    width: 100%;
  }
}

.default-layout .sidebar-pusher .content-container {
  background: #2D363C;
  min-height: 100vh;
}

@media (max-width: 1099px) {
  .sidebar-pusher::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background: rgba(0, 0, 0, 0.5);
    content: '';
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s linear;
  }
  [class*='sidebar-open-'] .sidebar-pusher::after {
    opacity: 1;
    visibility: visible;
  }
}

.sidebar {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  width: 300px;
  height: 100%;
  z-index: 1;
  transition: all 0.4s linear;
  display: none;
}

.articles-page .sidebar {
  display: block;
}

.sidebar.sidebar-right {
  left: auto;
  right: 0;
}

.sidebar.sidebar-right::after {
  right: auto;
  left: 0;
}

[class*='sidebar-open-'] .sidebar {
  visibility: visible;
  transition: all 0.4s linear;
  transform: scale(1, 1);
}

/* Effect: Push-Left*/
@media (min-width: 1100px) {
  .sidebar {
    z-index: 9999;
  }
  .sidebar-open-left .sidebar.sidebar-left {
    transform: translate3d(0, 0, 0);
  }
  .sidebar-open-left .sidebar.sidebar-right {
    transform: translate3d(100%, 0, 0);
  }
  .sidebar-open-right .sidebar.sidebar-left {
    transform: translate3d(-100%, 0, 0);
  }
  .sidebar-open-right .sidebar.sidebar-right {
    transform: translate3d(0, 0, 0);
  }
  .sidebar-left {
    transform: translate3d(-100%, 0, 0);
  }
  .sidebar-right {
    transform: translate3d(100%, 0, 0);
  }
}

@media (max-width: 1099px) {
  [class*='sidebar-open-'] .sidebar {
    z-index: 0;
  }
  /* Effect: Push-Right*/
  .sidebar-open-left .sidebar-pusher {
    transform: translate3d(340px, 0, 0);
  }
  .sidebar-open-right .sidebar-pusher {
    transform: translate3d(-340px, 0, 0);
  }
  .sidebar {
    transform: translate3d(0, 0, 0) scale(0.9, 0.9);
  }
  .sidebar.cart {
    transform: translate3d(0, 0, 0) scale(0.9, 0.9);
  }
}

[class*='sidebar-open-'] .sidebar {
  visibility: visible;
  transition: all 0.4s linear;
}

.sidebar::after {
  display: none;
}

/* Fallback example for browsers that don't support 3D transforms (and no JS fallback) */
.no-csstransforms3d .sidebar-pusher,
.no-js .sidebar-pusher {
  padding-left: 300px;
}

@media (min-width: 1100px) {
  .articles-page .sidebar {
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
  .articles-page .sidebar-pusher {
    left: 340px;
    width: calc(100% - 340px);
  }
  .articles-page .sidebar-pusher.modal-open {
    z-index: 10000;
  }
}

@media (max-width: 1099px) {
  #freshworks-container {
    opacity: 1;
    transition: all .2s linear;
    visibility: visible;
  }
  .hide-fresworkssupport #freshworks-container {
    visibility: hidden;
    opacity: 0;
  }
}

.modal-open-body {
  overflow: hidden;
}

.search-bar {
  width: 100%;
  margin: 5px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.search-bar input {
  -moz-box-flex: 1  ;
  -webkit-flex: 1  ;
  -ms-flex: 1  ;
  flex: 1  ;
  color: #2D363C;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  font-weight: normal;
  margin: 0;
  height: 45px;
  border-right: none;
  background-color: #f4f4f4;
}

.search-bar button.btn {
  width: 50px;
  height: 45px;
  border: 1px solid transparent;
  border-radius: 0;
  background: none;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  text-align: center;
  position: relative;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  fill: currentColor;
  text-decoration: none;
  outline: none;
  text-align: center;
  line-height: 1.1;
  color: #fff;
  transition: all .1s linear;
  margin: .2em 0;
  padding: 16px 2.1rem;
  font-size: 13px;
  margin: .3rem 0 .3rem;
  background-color: #00ADEF;
  color: #ffffff;
  border: none;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}

.search-bar button.btn:focus {
  transform: scale(0.98);
}

.search-bar button.btn:disabled:not(.btn-success) {
  cursor: not-allowed;
  opacity: 0.3;
}

.search-bar button.btn:hover:not(:disabled) {
  color: #ffffff;
  background-color: #006389;
}

.search-bar button.btn i {
  margin-left: .1em;
  margin-right: .1em;
  margin-top: -2px;
  font-size: 85%;
}

.search-bar button.btn:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  position: relative;
  font-size: 21px;
}

.search-bar button.btn:hover {
  background-color: #2D363C;
}

.popupOpen {
  overflow-y: auto;
}

.popupOpen app-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.popupHolder {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  text-align: center;
  box-shadow: 0 0 10px #000;
  transition: all .2s linear;
  transform: scale(0.1, 0.1);
  flex-wrap: nowrap;
}

@media (min-width: 1000px) {
  .popupHolder:not(.fullscreen) {
    top: 80px;
    left: 50%;
    max-width: 1400px;
    transform: translateX(-50%);
    width: calc(100% - 160px);
    height: calc(100% - 160px);
  }
}

.popupHolder .canvas-container {
  margin: auto;
  overflow: hidden;
}

.popupHolder.fullscreen.android .canvas-footer__copyright, .popupHolder.pdf-viewer .canvas-footer__copyright {
  display: none;
}

.popupHolder.fullscreen.android .canvas-footer__controls > li:not(:last-child), .popupHolder.pdf-viewer .canvas-footer__controls > li:not(:last-child) {
  display: none;
}

.popupHolder.fullscreen.android .canvas-footer__controls > li:last-child button, .popupHolder.pdf-viewer .canvas-footer__controls > li:last-child button {
  padding: 0;
  height: 32px;
}

.popupHolder.fullscreen.android .canvas-footer, .popupHolder.pdf-viewer .canvas-footer {
  position: absolute;
  z-index: 1;
  width: 100%;
  top: 0;
}

.popupHolder:before {
  cursor: pointer;
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  display: inline-block;
  padding: .95rem;
  text-shadow: 0 0 1px #fff;
  z-index: 1;
  position: fixed;
  top: 0;
  right: 0;
  display: none;
}

@media (min-width: 350px) {
  .popupHolder:before {
    padding: 1.25rem;
  }
}

.popupHolder:hover:before {
  color: #333;
}

.popupHolder.html-content .canvas-body {
  overflow: auto;
}

.popupOpen .popupHolder {
  visibility: visible;
  opacity: 1;
  z-index: 99999;
  transform: scale(1, 1);
}

@media (min-width: 1000px) {
  .popupOpen .popupHolder:not(.fullscreen) {
    transform: scale(1, 1) translateX(-50%);
  }
}

.zoom-image:hover {
  opacity: .9;
}

.canvas-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f1f1f1;
}

@media print {
  .canvas-footer {
    display: none;
  }
}

.html-content .canvas-footer .canvas-footer__controls li:not(:last-child) {
  display: none !important;
}

@media only screen and (max-width: 766px) {
  .canvas-footer {
    flex-wrap: wrap;
  }
  .canvas-footer > * {
    width: 100%;
  }
}

@media only screen and (min-width: 767px) {
  .canvas-footer__copyright {
    padding-left: 1.5rem;
  }
}

@media only screen and (max-width: 766px) {
  .canvas-footer__copyright {
    text-align: center;
    padding: 5px;
    border-top: 1px solid #ddd;
    font-size: 0.75rem;
  }
}

.canvas-footer__controls {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
  margin-left: auto;
}

@media only screen and (max-width: 766px) {
  .canvas-footer__controls {
    justify-content: center;
    order: -1;
  }
  .canvas-footer__controls .fullscreen-btn {
    display: none;
  }
}

.canvas-footer__controls li {
  margin: 0;
  padding: inherit;
}

.canvas-footer__controls li button {
  padding: 0.75rem;
  border: 0;
  background: none;
  outline: none;
  cursor: pointer;
}

@media (min-width: 1000px) {
  .canvas-footer__controls li button {
    padding: 1.1rem;
  }
}

.canvas-footer__controls li button:hover {
  background: #00ADEF;
}

.canvas-footer__controls li button:hover .svg-icon {
  fill: #fff !important;
}

.canvas-footer__controls li button .svg-icon {
  width: 2rem;
  height: 2rem;
  fill: #262626 !important;
}

@media only screen and (max-width: 766px) {
  .canvas-footer__controls li button .svg-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.canvas-footer__controls li.zoom:not(.active) {
  display: none;
}

.canvas-footer__controls li:last-child {
  position: absolute;
  top: 0;
  right: 0;
  background: #f1f1f1;
  z-index: 1;
}

.iframe-popup-button {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46px;
  width: 42px;
  background: #181818;
  cursor: pointer;
}

.iframe-popup-button:hover:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  border-bottom: 2px solid #eeeeee;
  z-index: 0;
}

.iframe-popup-button svg {
  position: relative;
  width: 36px;
  height: 36px;
  z-index: 1;
  fill: #fff;
  pointer-events: none;
}

.canvas-body {
  position: relative;
  display: flex;
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  z-index: 0;
}

.canvas-body .image-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
}

@media print {
  .canvas-body .image-placeholder {
    display: none;
  }
}

.canvas-body .image-placeholder--show {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  visibility: visible;
}

.canvas-body .image-placeholder--show ~ .canvas-container,
.canvas-body .image-placeholder--show ~ canvas,
.canvas-body .image-placeholder--show ~ iframe,
.canvas-body .image-placeholder--show ~ embed {
  display: none;
}

.canvas-body .image-placeholder--show > img {
  opacity: 0;
  pointer-events: none;
}

.canvas-body .image-placeholder .image-boundary,
.canvas-body .image-placeholder img {
  max-width: 100%;
  max-height: 100%;
}

.canvas-body .image-placeholder .image-boundary[src*=".svg"],
.canvas-body .image-placeholder img[src*=".svg"] {
  width: 100%;
  height: 100%;
}

.canvas-body .image-placeholder .image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.canvas-body .image-placeholder .image-container img {
  touch-action: none;
}

.canvas-body .image-placeholder .image-container img.reset {
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
}

.canvas-body .popup-iframe-block {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.canvas-body .popup-iframe-block iframe,
.canvas-body .popup-iframe-block embed {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.canvas-body .popup-iframe-block:not(.popup--loaded):before {
  content: 'Loading...';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  font-size: 1.75em;
}

@media print {
  .canvas-body .popup-iframe-block:not(.popup--loaded):before {
    display: none;
  }
}

.canvas-body .popup-iframe-block[data-src*="/404"]:before {
  content: 'Not Found';
}

.bg-absolute-image--svg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -99999;
  opacity: 0;
  pointer-events: none;
  max-width: 500px;
}

.fullscreen-note {
  padding: 1rem;
  position: absolute;
  display: block;
  background: #f1f1f1;
}

.fullscreen-note .btn-style {
  display: inline-block;
  padding: 5px 8px;
  border: 1px solid #00ADEF;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
}

.external-btnClose {
  display: none;
  background: #00ADEF;
  border: 0;
  outline: none;
  cursor: pointer;
}

.external-btnClose svg {
  width: 2rem;
  height: 2rem;
  fill: #fff !important;
}

.pdfjs .pdfViewer .page {
  margin-bottom: 10px;
  border-image: unset;
}

.pdfjs .pdfViewer .canvasWrapper {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.mobileapp-only {
  display: none !important;
}

.mobileapp .mobileapp-only {
  display: block !important;
}

.popup-holder {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  z-index: 999999;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s linear;
}

.popup-close {
  position: absolute;
  right: -1rem;
  top: -1rem;
  width: 2rem;
  height: 2rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: #00ADEF;
  text-align: center;
  cursor: pointer;
  line-height: 1.1;
  border-radius: 50%;
  border: 0.1rem solid #00ADEF;
  background-color: #fff;
}

.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 300px;
  width: 95%;
  max-width: 800px;
  background-color: #fff;
  padding: 3% 4%;
}

/*.open-popup{*/
.popup-holder {
  visibility: visible;
  opacity: 1;
}

/*}*/
.last-comma-holder:last-child .last-comma {
  display: none;
}

.ngdialog.ngdialog-theme-default {
  padding: 0;
}

.ngdialog.ngdialog-theme-default .ngdialog-content {
  min-width: 310px;
  max-width: 600px;
  width: 95%;
  padding: 1.5rem 0.8rem 0 0.8rem;
}

@media (min-width: 450px) {
  .ngdialog.ngdialog-theme-default .ngdialog-content {
    padding: 1.5rem 1.8rem 0 1.8rem;
  }
}

.ngdialog.ngdialog-theme-default .ngdialog-content .btn-submit {
  width: 100%;
}

.ngdialog.ngdialog-theme-default .ngdialog-close {
  padding: 0.2rem 0.6rem;
}

.ngdialog.ngdialog-theme-default .ngdialog-close:before {
  font-size: 2rem;
}

.ngdialog.ngdialog-theme-default .ngdialog-close:focus {
  outline: none;
}

.ngdialog.ngdialog-theme-default .ngdialog-content {
  overflow: auto;
  top: 20px;
  border-radius: 0;
}

.ngdialog.ngdialog-theme-default .ngdialog-content .ngdialog-close:before {
  content: "";
  background: url("../assets/svg/Icons/Close Circle.svg") no-repeat center;
  background-size: contain;
  -webkit-background-size: contain;
  width: 38px;
  height: 38px;
  filter: invert(75%) sepia(29%) saturate(0%) hue-rotate(150deg) brightness(88%) contrast(98%);
}

.ngdialog.ngdialog-theme-default .ngdialog-content .ngdialog-close:hover:before {
  filter: invert(63%) sepia(0%) saturate(0%) hue-rotate(111deg) brightness(95%) contrast(93%);
}

.ngdialog.ngdialog-theme-default .ngdialog-content .ngdialog-buttons {
  padding-bottom: 20px;
}

.ngdialog.ngdialog-theme-default .ngdialog-content .ngdialog-buttons > div {
  display: flex;
  justify-content: center;
}

.ngdialog.ngdialog-theme-default .ngdialog-content .ngdialog-buttons > div button {
  margin: 0 5px;
  min-width: 120px;
  padding: 15px 10px;
}

.form-prompt-message {
  display: flex;
}

.form-prompt-message svg {
  width: 42px;
  height: 42px;
  margin-top: -10px;
}

.rating {
  margin: 0;
  padding: 0;
  list-style: none;
  margin: .5rem 0 1rem;
  padding: 0;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  align-content: center;
  justify-content: center;
}

.rating > li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.rating li {
  flex: 1;
  font-size: 1.1rem;
  text-align: center;
  font-weight: bold;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #333;
  cursor: pointer;
  color: #333;
  opacity: .3;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  transition: all .15s linear;
}

.rating li:before {
  content: '';
  float: left;
  padding-top: 100%;
}

@media (min-width: 1000px) {
  .rating li {
    font-size: .7rem;
  }
}

@media (min-width: 1100px) {
  .rating li {
    font-size: .7rem;
  }
}

@media (min-width: 1550px) {
  .rating li {
    font-size: 1.1rem;
  }
}

.rating li:hover, .rating li.filled {
  background-color: #00ADEF;
  color: #fff;
  opacity: 1;
  border-color: #00ADEF;
  /*&+ li {
              opacity:.90;
              color:$main-color;
          }
          &+ li + li{
              opacity:.80;
              color:$main-color;
          }
          &+ li + li + li{
              opacity:.70;
              color:$main-color;
          }
          &+ li + li + li + li{
              opacity:.60;
              color:$main-color;
          }*/
}

.rating li:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.rating.readonly .filled {
  color: #666;
}

.ratings-disabled {
  opacity: .6;
}

.ratings-disabled li {
  pointer-events: none;
}

.dropmenu-trigger {
  position: relative;
  /* webkit flicker fix */
  -webkit-font-smoothing: antialiased;
  /* webkit text rendering fix */
}

.dropmenu-trigger > a {
  color: #fff;
}

.dropmenu-trigger__btn {
  cursor: pointer;
}

.dropmenu-trigger--xs.dropmenu-open .dropmenu-holder {
  top: calc(100% + 15px);
}

.dropmenu-trigger--xs .dropmenu-holder ul {
  min-width: 160px;
}

.dropmenu-trigger--xs .dropmenu-holder ul li a {
  min-height: 36px;
  padding: 0.5em 1em;
  font-size: 0.9em;
}

.dropmenu-trigger--light .dropmenu-holder {
  background: #ebebeb;
  border: 1px solid #ddd;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.125);
}

.dropmenu-trigger--light .dropmenu-holder:before {
  content: '';
  border-color: transparent;
  display: block;
  width: 20px;
  height: 20px;
  background: #ebebeb;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  transform: rotate(45deg);
  box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.0325);
  top: -0.5rem;
}

.dropmenu-trigger--light .dropmenu-holder--top:before {
  border: none;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.0325);
}

.dropmenu-trigger--light .dropmenu-holder ul li:not(:first-child) {
  border-top: 1px solid #ddd;
}

.dropmenu-trigger--light .dropmenu-holder ul li a {
  color: #748191;
}

.dropmenu-open > .dropmenu-holder {
  z-index: 9999;
  visibility: visible;
  opacity: 1;
}

@media (max-width: 1099px) {
  header .dropmenu-open > .dropmenu-holder {
    right: 0;
  }
}

@media (min-width: 1100px) {
  .dropmenu-open > .dropmenu-holder {
    top: 4rem;
  }
  .dropmenu-open > .dropmenu-holder--top {
    top: unset !important;
    bottom: calc(100% + 15px) !important;
  }
}

.dropmenu-holder {
  transition: all 0.5s ease-in-out 0.5s;
  z-index: 99;
  position: fixed;
  top: 0;
  background: #2D363C;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.5);
  transition: all 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 3.5rem;
  right: -19px;
}

@media (min-width: 767px) and (min-width: 1100px) {
  .dropmenu-holder {
    right: 0;
  }
}

@media (min-width: 767px) {
  .dropmenu-holder:before {
    width: 0;
    height: 0;
    content: '';
    z-index: 2;
    border-bottom: 0.6rem solid #2D363C;
    border-left: 0.6rem solid transparent;
    border-right: 0.6rem solid transparent;
    position: absolute;
    right: 12%;
    top: -0.5rem;
    z-index: -1;
  }
}

@media (min-width: 767px) and (min-width: 1100px) {
  .dropmenu-holder:before {
    right: 17px;
  }
}

@media (min-width: 767px) {
  .dropmenu-holder:after {
    content: '';
    display: block;
    height: 1px;
    height: 30px;
    background: red;
    position: absolute;
    top: 0;
    right: 0;
  }
  .dropmenu-holder--top {
    top: unset;
    bottom: 100%;
  }
  .dropmenu-holder--top:before {
    top: unset !important;
    bottom: -0.5rem;
    transform-origin: center;
    transform: rotate(180deg);
  }
  .template-docs__heading .dropmenu-holder--top:before {
    transform: rotate(220deg) !important;
  }
  .dropmenu-holder--left {
    right: unset !important;
    left: 0;
  }
  .dropmenu-holder--left:before {
    right: unset;
    left: 15px;
  }
}

@media (max-width: 766px) {
  .main-header .dropmenu-holder {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100vh;
    min-width: 100%;
    opacity: 1;
    visibility: visible;
  }
  .dropmenu-holder .dropmenu-holder__menu li a [class^='icon-'],
  .dropmenu-holder .dropmenu-holder__menu li a [class*=' icon-'] {
    margin-right: 0.75rem;
  }
}

.dropmenu-holder__menu {
  min-width: 250px;
}

.sidebar-footer .dropmenu-holder__menu {
  background: #2D363C;
}

.dropmenu-holder__menu a.default {
  position: relative;
}

.dropmenu-holder__menu a.default:after {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #B1B1B1;
  position: absolute;
  top: 50%;
  right: 22px;
  margin-top: -5px;
}

.dropmenu-holder__menu a.selected {
  position: relative;
  color: white;
}

.dropmenu-holder__menu a.selected svg.svg-icon {
  fill: #00ADEF !important;
}

.dropmenu-holder__menu a.selected:not(.default):before {
  content: '';
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  background: url("../assets/svg/Icons/Tick%20Circle%20Sm.svg") no-repeat center/contain;
  filter: invert(71%) sepia(97%) saturate(4538%) hue-rotate(163deg) brightness(99%) contrast(101%);
}

.dropmenu-holder__menu a.selected:hover {
  color: #fff;
}

.dropmenu-holder ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;
  padding: 0;
}

.dropmenu-holder ul > li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.dropmenu-holder ul li {
  display: block;
  width: 100%;
  font-size: 0.85rem;
  line-height: 1;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.dropmenu-holder ul li a {
  display: flex !important;
  align-items: center;
  line-height: 1;
  width: 100%;
  padding: 5px 10px 5px 20px;
  color: #eee;
  background-color: rgba(0, 0, 0, 0.01);
  font-size: 16px;
  transition: unset;
  min-height: 46px;
}

.dropmenu-holder ul li a .svg-icon {
  margin: 0 15px 0 0;
  fill: #eee !important;
}

.dropmenu-holder ul li a.link--disabled {
  pointer-events: none;
  color: #5e6a74;
}

.dropmenu-holder ul li a.link--disabled .svg-icon {
  fill: #5e6a74 !important;
}

.dropmenu-holder ul li:first-child {
  border: none;
}

.dropmenu-holder ul li[disabled] {
  pointer-events: none;
  border-top-color: #8f949c !important;
}

.dropmenu-holder ul li[disabled] a {
  background: #92abbd !important;
  color: #777777 !important;
}

.dropmenu-holder ul.dropmenu-holder__head li {
  border: 0;
}

.dropmenu-holder ul[class*='menu--xs'] {
  min-width: 160px;
}

.dropmenu-holder ul[class*='menu--xs'] > li > a {
  padding: 10px .65rem;
  font-size: 12px;
  min-height: unset;
}

.dropmenu-holder .dropmenu-holder__menu li a.link--active, .dropmenu-holder .dropmenu-holder__menu li a:hover {
  color: #fff !important;
  background: #00ADEF !important;
}

.dropmenu-holder .dropmenu-holder__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  margin-bottom: 1.75rem;
}

@media (min-width: 767px) {
  .dropmenu-holder .dropmenu-holder__head {
    margin-bottom: 0;
    border-bottom: 1px solid #333;
    display: none;
  }
}

.dropmenu-holder .dropmenu-holder__head li {
  flex: 0 0 3.575rem;
  margin: 0 0.75em;
}

.dropmenu-holder .dropmenu-holder__head li a {
  padding: 0 10px;
  text-align: center;
}

.dropmenu-holder .dropmenu-holder__head li a img {
  border-radius: 0;
  max-width: 1.25rem;
}

.dropmenu-holder .dropmenu-holder__head li:last-child a img {
  width: 24px;
  height: auto;
  max-width: 100%;
}

.dropmenu-holder--submenu li a {
  padding-left: 3.75rem !important;
}

.dropmenu-holder--submenu li a .option-selected {
  margin-left: -62px !important;
}

.sidebar-footer .dropmenu-holder--submenu li a {
  padding-left: 20px !important;
  padding-right: 50px !important;
}

.sidebar-footer .dropmenu-holder--submenu li a .option-selected {
  margin-left: -54px !important;
}

.dropmenu-trigger__close svg {
  fill: #98A9BC !important;
}

.st-dropdown-content {
  position: absolute;
  z-index: 1000;
  background: #F6F7F8;
  border: 1px solid #ddd;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.125);
}

.st-dropdown-content .note-suggestions {
  all: unset;
}

.st-dropdown-trigger {
  cursor: pointer;
}

body > .st-dropdown-content {
  z-index: 9999;
}

.questionnaire-group .rating .st-dropdown-trigger {
  display: block;
}

.badge {
  padding: .5rem;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  font-weight: normal;
  margin: .5rem 0;
  border-style: solid;
  color: #333;
  background-color: #2D363C;
  color: #fff;
  vertical-align: middle;
  line-height: 1.1;
  position: relative;
  width: auto;
  border-radius: 0.6rem;
  font-size: 72.5%;
}

.badge:before {
  font-size: 1.1rem;
  position: absolute;
  left: 1.2rem;
  top: 1.05rem;
}

.badge:before {
  font-size: .725rem;
  left: .4rem;
  top: .35rem;
}

.badge.no-radius {
  border-radius: 0;
}

.badge.badge-primary {
  padding: .3rem .5rem .3rem 1.3rem;
  border-color: #00ADEF;
  background-color: #00ADEF;
  color: #fff;
}

.badge.badge-primary:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.badge.badge-primary h1,
.badge.badge-primary h2,
.badge.badge-primary h3,
.badge.badge-primary h4,
.badge.badge-primary h5,
.badge.badge-primary p {
  color: #fff;
}

.badge.badge-primary h1:first-child,
.badge.badge-primary h2:first-child,
.badge.badge-primary h3:first-child,
.badge.badge-primary h4:first-child,
.badge.badge-primary h5:first-child,
.badge.badge-primary p:first-child {
  margin-top: 0;
  padding-top: 0;
}

.badge.badge-primary h1:last-child,
.badge.badge-primary h2:last-child,
.badge.badge-primary h3:last-child,
.badge.badge-primary h4:last-child,
.badge.badge-primary h5:last-child,
.badge.badge-primary p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.badge.badge-primary a {
  color: #fff;
}

.badge.badge-secondary {
  padding: .3rem .5rem .3rem 1.3rem;
  border-color: #00ADEF;
  background-color: #2D363C;
  color: #fff;
}

.badge.badge-secondary:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.badge.badge-secondary h1,
.badge.badge-secondary h2,
.badge.badge-secondary h3,
.badge.badge-secondary h4,
.badge.badge-secondary h5,
.badge.badge-secondary p {
  color: #fff;
}

.badge.badge-secondary h1:first-child,
.badge.badge-secondary h2:first-child,
.badge.badge-secondary h3:first-child,
.badge.badge-secondary h4:first-child,
.badge.badge-secondary h5:first-child,
.badge.badge-secondary p:first-child {
  margin-top: 0;
  padding-top: 0;
}

.badge.badge-secondary h1:last-child,
.badge.badge-secondary h2:last-child,
.badge.badge-secondary h3:last-child,
.badge.badge-secondary h4:last-child,
.badge.badge-secondary h5:last-child,
.badge.badge-secondary p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.badge.badge-secondary a {
  color: #fff;
}

.badge.badge-info {
  padding: .3rem .5rem .3rem 1.3rem;
  border-color: #2D363C;
  background-color: #eaedef;
  color: #2D363C;
}

.badge.badge-info:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.badge.badge-info h1,
.badge.badge-info h2,
.badge.badge-info h3,
.badge.badge-info h4,
.badge.badge-info h5,
.badge.badge-info p {
  color: #2D363C;
}

.badge.badge-info h1:first-child,
.badge.badge-info h2:first-child,
.badge.badge-info h3:first-child,
.badge.badge-info h4:first-child,
.badge.badge-info h5:first-child,
.badge.badge-info p:first-child {
  margin-top: 0;
  padding-top: 0;
}

.badge.badge-info h1:last-child,
.badge.badge-info h2:last-child,
.badge.badge-info h3:last-child,
.badge.badge-info h4:last-child,
.badge.badge-info h5:last-child,
.badge.badge-info p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.badge.badge-error {
  padding: .3rem .5rem .3rem 1.3rem;
  border-color: #ED1E23;
  background-color: #fdebeb;
  color: #ED1E23;
}

.badge.badge-error:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.badge.badge-error h1,
.badge.badge-error h2,
.badge.badge-error h3,
.badge.badge-error h4,
.badge.badge-error h5,
.badge.badge-error p {
  color: #ED1E23;
}

.badge.badge-error h1:first-child,
.badge.badge-error h2:first-child,
.badge.badge-error h3:first-child,
.badge.badge-error h4:first-child,
.badge.badge-error h5:first-child,
.badge.badge-error p:first-child {
  margin-top: 0;
  padding-top: 0;
}

.badge.badge-error h1:last-child,
.badge.badge-error h2:last-child,
.badge.badge-error h3:last-child,
.badge.badge-error h4:last-child,
.badge.badge-error h5:last-child,
.badge.badge-error p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.badge.badge-success {
  padding: .3rem .5rem .3rem 1.3rem;
  border-color: #3eb44a;
  background-color: #edf9ee;
  color: #3eb44a;
}

.badge.badge-success:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.badge.badge-success h1,
.badge.badge-success h2,
.badge.badge-success h3,
.badge.badge-success h4,
.badge.badge-success h5,
.badge.badge-success p {
  color: #3eb44a;
}

.badge.badge-success h1:first-child,
.badge.badge-success h2:first-child,
.badge.badge-success h3:first-child,
.badge.badge-success h4:first-child,
.badge.badge-success h5:first-child,
.badge.badge-success p:first-child {
  margin-top: 0;
  padding-top: 0;
}

.badge.badge-success h1:last-child,
.badge.badge-success h2:last-child,
.badge.badge-success h3:last-child,
.badge.badge-success h4:last-child,
.badge.badge-success h5:last-child,
.badge.badge-success p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.badge.badge-warning {
  padding: .3rem .5rem .3rem 1.3rem;
  border-color: #ffc048;
  background-color: #fff9ef;
  color: #ffc048;
}

.badge.badge-warning:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.badge.badge-warning h1,
.badge.badge-warning h2,
.badge.badge-warning h3,
.badge.badge-warning h4,
.badge.badge-warning h5,
.badge.badge-warning p {
  color: #ffc048;
}

.badge.badge-warning h1:first-child,
.badge.badge-warning h2:first-child,
.badge.badge-warning h3:first-child,
.badge.badge-warning h4:first-child,
.badge.badge-warning h5:first-child,
.badge.badge-warning p:first-child {
  margin-top: 0;
  padding-top: 0;
}

.badge.badge-warning h1:last-child,
.badge.badge-warning h2:last-child,
.badge.badge-warning h3:last-child,
.badge.badge-warning h4:last-child,
.badge.badge-warning h5:last-child,
.badge.badge-warning p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.st-tab-content .tab-pane:not(.active) {
  display: none;
}

.st-tabs {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.st-tabs li {
  margin: 0;
  padding: 0;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 19.5px;
  text-align: center;
}

.st-tabs li > a {
  color: #AAB3BE;
}

.st-tabs li.active a {
  position: relative;
  display: block;
  color: #38444B;
}

.st-tabs li.active a:after {
  content: '';
  display: block;
  width: 100%;
  height: 1.25px;
  position: absolute;
  bottom: -5px;
  left: 0;
  background: #38444B;
}

.st-tabs li + li {
  margin-left: 20px;
}

/* ==========================================================================
   EXAMPLE Media Queries for Responsive Design.
   These examples override the primary ('mobile first') styles.
   Modify as content requires.
   ========================================================================== */
@media print, (min-resolution: 1.25dppx), (min-resolution: 120dpi) {
  /* Style adjustments for high resolution devices */
}

/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request:
   http://www.phpied.com/delay-loading-your-print-css/
   ========================================================================== */
@media print {
  body {
    padding-top: 5mm;
  }
  *,
  *:before,
  *:after,
  *:first-letter,
  *:first-line {
    background: transparent !important;
    color: #000 !important;
    /* Black prints faster:
                                   http://www.sanbeiji.com/archives/953 */
    box-shadow: none !important;
    text-shadow: none !important;
  }
  svg {
    color: #000 !important;
    fill: #000 !important;
  }
  /*
    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }
    */
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  /*
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */
  /*
    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }
    */
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  /*
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}

.main-header-client {
  display: none !important;
}

@media print {
  .main-header span {
    display: inline !important;
  }
}

.intro-help-text {
  margin-top: -4px;
  font-size: 14px;
  opacity: .4;
}

.responsive__template-form section + section {
  margin-top: 30px;
}

.responsive__template-form .heading {
  display: flex;
  padding-bottom: 3px;
  justify-content: space-between;
  color: #38444B;
  border-bottom: 1px solid #AAB3BE;
}

.responsive__template-form .heading h3 {
  padding: 0;
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 30px;
}

.responsive__template-form .heading > span {
  cursor: pointer;
}

.responsive__template-form .heading > span svg {
  width: 32px;
  height: 32px;
  fill: #38444B;
}

.responsive__template-form .heading > span.active svg {
  fill: #00ADEF;
}

@media (min-width: 1000px) {
  .responsive__template-form .heading {
    flex: 0 0 26px;
    max-width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #bfbfbf;
    font-weight: bold;
  }
  .responsive__template-form .heading h3 {
    transform: rotate(-90deg);
    font-size: 14px;
    padding: 1px 0 0 0;
    text-transform: uppercase;
    line-height: 1;
  }
  .responsive__template-form section {
    padding: 1px 1px 0 0 !important;
    margin: 0 !important;
    display: flex;
    flex-direction: row;
  }
  .responsive__template-form .questionnaire-ratings {
    flex: 0 0 150px;
    max-width: 150px;
    background-color: #eee;
  }
}

.responsive__template-form .diagram {
  padding: 20px 25%;
  display: block;
  margin: 0 auto;
  border-bottom: 1px solid #DDE0E5;
}

.responsive__template-form .question p:last-child {
  margin-bottom: 0;
}

.responsive__template-form .question .question-title {
  padding-top: 0;
}

.responsive__template-form .questionnaire {
  padding-top: 20px;
}

.responsive__template-form .questionnaire + .questionnaire {
  margin-top: 20px;
  border-top: 1px solid #DDE0E5;
}

.responsive__template-form .question-title {
  margin: 0;
  padding: 0;
  color: #38444B;
  font-size: 15px;
  font-weight: 600;
  line-height: 22.5px;
}

@media (min-width: 1000px) {
  .responsive__template-form .question-title {
    min-height: 70px;
  }
}

.responsive__template-form .questionnaire-group {
  display: flex;
}

.responsive__template-form .questionnaire-group .question {
  padding-right: 20px;
  flex: 1;
}

.responsive__template-form .questionnaire-group .question p {
  margin: 5px 0 0;
  padding: 0;
  color: #38444B;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 1.1;
}

.responsive__template-form .rating {
  position: relative;
  margin: 0;
  display: flex;
  flex-direction: column;
  max-width: 220px;
  width: 100%;
  text-align: center;
}

@media (min-width: 1000px) {
  .responsive__template-form .rating .st-dropdown-trigger:hover .tooltip .tooltip__content {
    opacity: 1;
    visibility: visible;
  }
}

.responsive__template-form .rating .tooltip {
  position: absolute;
  top: 4px;
  right: 0;
}

.responsive__template-form .rating .tooltip__title {
  display: block;
  text-align: left;
}

.responsive__template-form .rating .tooltip__title h3 {
  font-size: 16px;
  padding: 0;
  margin: -8px 0 5px;
}

.responsive__template-form .rating .tooltip__title h6 {
  font-size: 14px;
}

@media (min-width: 1000px) {
  .responsive__template-form .rating .tooltip__button {
    visibility: hidden;
    opacity: .5;
  }
  .responsive__template-form .rating .tooltip__button:hover {
    opacity: 1;
  }
}

@media (min-width: 1000px) {
  fieldRules .responsive__template-form .rating .tooltip__close {
    display: none;
  }
}

.responsive__template-form .rating .tooltip__content {
  padding: 1.2rem 1.2rem 0.6rem;
  margin-top: -5px;
  margin-left: -5px;
  transform: translateX(-89%) scale(1);
}

.responsive__template-form .rating .tooltip__content:before {
  left: auto;
  right: 6.5%;
}

@media (min-width: 1000px) {
  .responsive__template-form .rating .tooltip {
    top: 0;
  }
}

.responsive__template-form .rating textarea::placeholder {
  color: #999;
  font-style: italic;
}

.responsive__template-form .rating .form__label {
  margin: 3px 0 3px;
}

.responsive__template-form .rating textarea {
  margin: 0;
  padding: 8px 5px;
  text-align: center;
  background: #FFFFFF;
  border: unset;
  resize: none;
  color: #262626;
  font-size: 14px;
  line-height: 1;
  height: 40px;
}

.responsive__template-form .rating textarea:disabled {
  background: unset;
}

@media (min-width: 1000px) {
  .responsive__template-form .rating textarea:disabled {
    background: #ddd;
  }
}

.responsive__template-form .rating template-field.ng-invalid {
  border: 1px solid #ED1E23;
  box-shadow: #ED1E23 0px 0px 0.75rem;
}

.responsive__template-form .rating .validate {
  display: block;
  width: 130px;
  font-size: 11px;
  left: unset;
  right: 0;
  padding: 10px;
}

.responsive__template-form .rating .validate:before {
  left: unset;
  right: 12px;
}

@media (min-width: 1000px) {
  .responsive__template-form .question-title {
    padding: 4px .8em 4px 1em;
    line-height: 1.2;
    display: flex;
    align-items: center;
    height: 64px;
    font-weight: 400;
    font-size: 14px;
    background: #dedede;
    width: 100%;
  }
  .responsive__template-form .question-title b {
    font-weight: 600;
  }
  .responsive__template-form .questionnaire {
    margin: 0 !important;
    border: none !important;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .responsive__template-form .questionnaire .questionnaire-table dt.question-help {
    background: #EfF0F2;
    font-weight: 400;
    padding: 8px 15px;
    min-height: 110px;
  }
  .responsive__template-form .questionnaire .questionnaire-table {
    border-left: 1px solid #e8e9ea;
    border-right: 1px solid #e8e9ea;
    border-bottom: 1px solid #e8e9ea;
  }
  .responsive__template-form .questionnaire .questionnaire-group {
    border-left: 1px solid #e8e9ea;
    border-right: 1px solid #e8e9ea;
    border-bottom: 1px solid #e8e9ea;
  }
  .responsive__template-form .questionnaire .questionnaire-group .question {
    padding: 0;
    display: flex;
    align-items: center;
  }
  .responsive__template-form .questionnaire .questionnaire-group .question p {
    margin: 0;
    font-size: 13px;
  }
  .responsive__template-form .questionnaire .questionnaire-group textarea {
    height: 34px;
    padding: 7px 5px;
  }
  .responsive__template-form .questionnaire .questionnaire-group p {
    padding: 5px 1em;
  }
  .responsive__template-form .questionnaire-table {
    margin-top: 0 !important;
  }
  .responsive__template-form .questionnaire-table dt {
    margin-top: 0 !important;
  }
}

.responsive__template-form .rating {
  max-width: 220px;
  width: 60%;
}

.responsive__template-form .rating .form__label {
  display: none;
}

.responsive__template-form .rating textarea {
  border: 1px solid transparent;
}

.responsive__template-form .rating:hover .st-dropdown-trigger:not(.disabled) textarea {
  outline: 0px solid transparent;
  border-color: #e8e9ea;
  background-color: #f9f9f9;
}

.responsive__template-form .questionnaire-ratings .rating {
  width: auto;
  max-width: initial;
}

.responsive__template-form .questionnaire-table, .responsive__template-form .questionnaire-ratings {
  margin: 10px 0 0;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.responsive__template-form .questionnaire-table dt, .responsive__template-form .questionnaire-table .question, .responsive__template-form .questionnaire-ratings dt, .responsive__template-form .questionnaire-ratings .question {
  padding: 8px 15px;
  background: #EEF0F2;
  color: #38444B;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 1.1;
}

.responsive__template-form .questionnaire-table dt.question-help, .responsive__template-form .questionnaire-table .question.question-help, .responsive__template-form .questionnaire-ratings dt.question-help, .responsive__template-form .questionnaire-ratings .question.question-help {
  background: none;
  padding: 0 0 8px;
  font-weight: 400;
}

.responsive__template-form .questionnaire-table dt.question-help ul, .responsive__template-form .questionnaire-table dt.question-help li, .responsive__template-form .questionnaire-table .question.question-help ul, .responsive__template-form .questionnaire-table .question.question-help li, .responsive__template-form .questionnaire-ratings dt.question-help ul, .responsive__template-form .questionnaire-ratings dt.question-help li, .responsive__template-form .questionnaire-ratings .question.question-help ul, .responsive__template-form .questionnaire-ratings .question.question-help li {
  padding-bottom: .1rem;
}

.responsive__template-form .questionnaire-table dd, .responsive__template-form .questionnaire-table .rating-input, .responsive__template-form .questionnaire-ratings dd, .responsive__template-form .questionnaire-ratings .rating-input {
  position: relative;
  height: 100%;
}

.responsive__template-form .questionnaire-table dd .mce-content-body, .responsive__template-form .questionnaire-table .rating-input .mce-content-body, .responsive__template-form .questionnaire-ratings dd .mce-content-body, .responsive__template-form .questionnaire-ratings .rating-input .mce-content-body {
  height: 100%;
  margin: 0;
  padding: 10px 15px;
  background: #FFFFFF;
  border: unset;
  resize: none;
  color: #262626;
  font-size: 15px;
  letter-spacing: 0.25px;
  line-height: 1.1;
  min-height: 110px;
}

.responsive__template-form .questionnaire-table dd .mce-content-body:not([contenteditable]), .responsive__template-form .questionnaire-table .rating-input .mce-content-body:not([contenteditable]), .responsive__template-form .questionnaire-ratings dd .mce-content-body:not([contenteditable]), .responsive__template-form .questionnaire-ratings .rating-input .mce-content-body:not([contenteditable]) {
  background: unset;
}

.responsive__template-form .questionnaire-table dd .validate, .responsive__template-form .questionnaire-table .rating-input .validate, .responsive__template-form .questionnaire-ratings dd .validate, .responsive__template-form .questionnaire-ratings .rating-input .validate {
  display: block;
  font-size: 11px;
  padding: 10px;
}

.responsive__template-form .questionnaire-table dd template-field, .responsive__template-form .questionnaire-table .rating-input template-field, .responsive__template-form .questionnaire-ratings dd template-field, .responsive__template-form .questionnaire-ratings .rating-input template-field {
  position: relative;
  display: block;
  height: 100%;
}

.responsive__template-form .questionnaire-table dd .mce-edit-focus ~ .input-counter, .responsive__template-form .questionnaire-table .rating-input .mce-edit-focus ~ .input-counter, .responsive__template-form .questionnaire-ratings dd .mce-edit-focus ~ .input-counter, .responsive__template-form .questionnaire-ratings .rating-input .mce-edit-focus ~ .input-counter {
  display: block;
}

.responsive__template-form .questionnaire-table dd template-field.ng-invalid .mce-content-body, .responsive__template-form .questionnaire-table .rating-input template-field.ng-invalid .mce-content-body, .responsive__template-form .questionnaire-ratings dd template-field.ng-invalid .mce-content-body, .responsive__template-form .questionnaire-ratings .rating-input template-field.ng-invalid .mce-content-body {
  border: 1px solid #ED1E23;
  box-shadow: #ED1E23 0px 0px 0.75rem;
}

.responsive__template-form .questionnaire-table dd div.mce-tinymce-inline, .responsive__template-form .questionnaire-table .rating-input div.mce-tinymce-inline, .responsive__template-form .questionnaire-ratings dd div.mce-tinymce-inline, .responsive__template-form .questionnaire-ratings .rating-input div.mce-tinymce-inline {
  position: absolute;
  bottom: 100%;
  z-index: 1;
  top: unset !important;
  left: unset !important;
  right: 0px;
}

.responsive__template-form .questionnaire-table dd template-field.ng-invalid-max-lines .mce-content-body,
.responsive__template-form .questionnaire-table dd template-field.ng-invalid-max-lines textarea, .responsive__template-form .questionnaire-table .rating-input template-field.ng-invalid-max-lines .mce-content-body,
.responsive__template-form .questionnaire-table .rating-input template-field.ng-invalid-max-lines textarea, .responsive__template-form .questionnaire-ratings dd template-field.ng-invalid-max-lines .mce-content-body,
.responsive__template-form .questionnaire-ratings dd template-field.ng-invalid-max-lines textarea, .responsive__template-form .questionnaire-ratings .rating-input template-field.ng-invalid-max-lines .mce-content-body,
.responsive__template-form .questionnaire-ratings .rating-input template-field.ng-invalid-max-lines textarea {
  border: 1px solid #ffc048;
  box-shadow: #ffc048 0px 0px 0.75rem;
}

.responsive__template-form .questionnaire-table dd template-field.ng-invalid-max-lines + p.validate, .responsive__template-form .questionnaire-table .rating-input template-field.ng-invalid-max-lines + p.validate, .responsive__template-form .questionnaire-ratings dd template-field.ng-invalid-max-lines + p.validate, .responsive__template-form .questionnaire-ratings .rating-input template-field.ng-invalid-max-lines + p.validate {
  background-color: #ffc048;
  color: #2D363C;
}

.responsive__template-form .questionnaire-table dd template-field.ng-invalid-max-lines + p.validate:before, .responsive__template-form .questionnaire-table .rating-input template-field.ng-invalid-max-lines + p.validate:before, .responsive__template-form .questionnaire-ratings dd template-field.ng-invalid-max-lines + p.validate:before, .responsive__template-form .questionnaire-ratings .rating-input template-field.ng-invalid-max-lines + p.validate:before {
  border-bottom-color: #ffc048;
}

.responsive__template-form .questionnaire-ratings {
  margin-top: 0;
}

/*layout*/
.welcome-username {
  text-align: center;
  color: #fff;
  font-size: 23px;
  font-weight: 300;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  padding: 0 0 7vh;
  margin-bottom: 0;
  margin-top: .5rem;
}

.card-locked {
  opacity: 0.5;
}

.card-locked .btn {
  visibility: hidden;
}

main.homepage:not(.homepage--collapsed) .card-holder {
  display: flex;
  justify-content: center;
}

.card-holder .card {
  width: 100%;
  height: 4.5rem;
  text-align: left;
  position: relative;
  color: #fff;
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@media (max-width: 1099px) {
  .card-holder .card {
    max-width: 276px;
    height: 55px;
    margin: auto;
  }
}

.card-holder .card .card-box {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  background-color: #2D363C;
  overflow: hidden;
}

.card-holder .card .card-box .channel-background {
  position: absolute;
  top: -15px;
  left: -15px;
  width: calc(100% + 30px);
  height: calc(100% + 30px);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transform: translateZ(0);
  transition: all 0.3s ease;
}

@media (max-width: 1099px) {
  .card-holder .card .card-box .channel-background {
    display: none;
  }
}

.homepage--collapsed .card-holder .card .card-box .channel-background {
  display: none;
}

.card-holder .card .card-box .channel-background:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #2D363C;
  z-index: 0;
  opacity: 0.25;
}

.card-holder .card p {
  color: #fff;
  font-size: 12px;
  line-height: 1.5;
}

.card-holder .card .channel-number {
  font-size: 2.2rem;
  display: inline-block;
}

.card-holder .card.card-main .channel-number {
  font-size: 2.8rem;
  line-height: 0.8;
}

.card-holder .card .channel-label {
  display: inline-block;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  font-size: 9px;
  font-weight: normal;
}

@media (min-width: 1100px) {
  .card-holder .card .channel-label {
    font-size: 10px;
  }
}

.card-holder .card .channel-icon,
.card-holder .card .icon-lock {
  position: absolute;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  opacity: 0.9;
}

main.homepage:not(.homepage--collapsed) .card-holder .card {
  min-height: 17rem;
  text-align: center;
  max-width: 200px;
}

main.homepage:not(.homepage--collapsed) .card-holder .card .icon-lock {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: auto;
  right: auto;
  bottom: 2rem;
  opacity: 0.9;
}

main.homepage:not(.homepage--collapsed) .card-holder .card:first-child {
  margin-left: 0;
}

main.homepage:not(.homepage--collapsed) .card-holder .card:last-child {
  margin-right: 0;
}

main.homepage:not(.homepage--collapsed) .card-holder .card .channel-number,
main.homepage:not(.homepage--collapsed) .card-holder .card .channel-label {
  display: block;
  transform: translateZ(0);
}

main.homepage:not(.homepage--collapsed) .card-holder .card .footer-area p {
  margin: 0.875rem 0 1rem;
}

main.homepage:not(.homepage--collapsed) .card-holder .card .footer-area .btn {
  margin: 0;
  background-color: transparent;
  color: #ffffff;
  color: #fff;
  border-radius: 0;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 9px;
  padding: 12px 0 12px 15px;
  width: auto;
  flex-grow: 0;
  min-width: 130px;
}

main.homepage:not(.homepage--collapsed) .card-holder .card .footer-area .btn:hover:not(:disabled) {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
}

main.homepage:not(.homepage--collapsed) .card-holder .card .footer-area .btn:hover:not(:disabled), main.homepage:not(.homepage--collapsed) .card-holder .card .footer-area .btn.active {
  background-color: #fff !important;
  border-color: #fff;
  color: #22292D;
}

main.homepage:not(.homepage--collapsed) .card-holder .card .footer-area .btn:last-child {
  margin-top: 10px;
}

main.homepage:not(.homepage--collapsed) .card-holder .card .footer-area .btn svg {
  width: 20px;
  height: 11px;
  fill: #fff;
}

main.homepage:not(.homepage--collapsed) .card-holder .card .footer-area .btn:hover svg {
  fill: #000;
}

.card-holder .card:hover .card-box .channel-background {
  -ms-filter: blur(0.325rem);
  filter: blur(0.325rem);
}

.card-holder .card.card-main:hover a:before,
.card-holder .card.card-main:hover .card-locked:before {
  background-color: #22292d;
}

.home {
  width: 100%;
  background-color: #22292D;
}

.home:not(.profile):not(.changepassword) {
  height: 100%;
}

.home .image-header {
  position: relative;
  width: 100%;
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home .content-container {
  width: 100%;
  height: 100%;
  background-color: #22292D;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.home .content-container [ui-view='content'] {
  width: 100%;
}

@media (max-width: 1099px) {
  .home .content-container [ui-view='content'] {
    height: 100%;
  }
}

.opt-dashboard .content-container {
  justify-content: unset;
}

.opt-dashboard .content-container [ui-view='content'] {
  padding-top: 3.5vh;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.opt-dashboard .content-container [ui-view='content'] template-landing {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.opt-dashboard .content-container [ui-view='content'] .templates-landing {
  width: 100%;
  flex: 1;
}

.image-header.is-loading {
  height: 100%;
}

/**** vvvv HERE IS THE NEW STUFF vvvv  ****/
.slider-container {
  position: relative;
  background: url(/assets/images/driver.jpg) no-repeat 100% 100%;
  background-size: cover;
  /* you have to add a height here if your container isn't otherwise set
          becuse the absolutely positioned image divs won't calculate the height
          for you */
  height: 300px;
  width: 100%;
}

.slider-container .header {
  height: 60px;
}

.slider-container h4 {
  padding: 10px 10px;
  bottom: 0;
  margin: 0;
  opacity: 0;
  width: 100%;
  visibility: hidden;
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  background-color: rgba(0, 118, 163, 0.88);
}

.slider-container h4 small {
  font-size: 65%;
  font-weight: 300;
}

.slider-container a {
  color: #fff;
  transition: opacity 0.2s linear;
}

.slider-container a:hover {
  opacity: 0.6;
}

.blog-footer {
  position: fixed;
  bottom: 0;
  z-index: 0;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background-color: rgba(34, 34, 34, 0.92);
  height: 80px;
}

.blog-footer .blog-item {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: opacity 1s ease;
}

.blog-footer .container {
  max-width: 475px;
  margin: 0 auto;
  position: relative;
  height: 80px;
}

.blog-footer .container a {
  opacity: 0.85;
  transition: opacity 0.2s ease;
  display: block;
}

.blog-footer .container a:after {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  color: #eee;
  float: right;
  position: absolute;
  z-index: 1;
  margin-top: -19px;
  top: 50%;
  height: 100%;
  right: 20px;
  font-size: 38px;
  opacity: 0.8;
}

.blog-footer .container a:hover {
  opacity: 1;
}

.blog-footer .container a:hover:after {
  opacity: 1;
}

.blog-footer .container h5 {
  color: #aaa;
  padding: 16px 0 4px 0;
  margin: 0;
  text-shadow: 0 0 10px black;
}

.blog-footer .container p {
  color: #fff;
  padding: 0;
  margin: 0;
  text-shadow: 0 0 10px black;
  font-weight: 600;
  max-width: 85%;
  line-height: 0.825em;
}

/* Animations */
.ng-fade {
  opacity: 1 !important;
  visibility: visible !important;
}

footer {
  position: fixed;
  width: 100%;
  bottom: 10px;
  left: 0;
  height: 55px;
  z-index: 0;
  opacity: 0.75;
  text-align: center;
  opacity: 0.25;
  font-size: 8.25px;
}

footer img {
  max-height: 2.2rem;
}

@media (min-width: 1100px) {
  footer {
    height: 70px;
    font-size: 12px;
  }
}

.home footer {
  opacity: 1;
  bottom: 1%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

@media (max-width: 1099px) {
  .home footer {
    padding-top: 40px;
    position: relative;
  }
}

.home footer > a {
  display: inline-block;
  margin-bottom: 0.9rem;
  color: #fff;
}

.home footer > a img {
  display: block;
}

.home footer .footer-logo {
  margin: 0 5rem;
}

.poweredby {
  color: #fff;
  margin: 0.4rem auto 0.9rem auto;
}

.articles-page .poweredby {
  color: #2D363C;
}

.card-holder .card svg.channel-icon {
  width: 24px;
  height: 24px;
  fill: #fff;
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.card-holder .card a.--icon-hide svg {
  display: none;
}

@media (max-width: 1099px) {
  main.homepage {
    padding-top: 35px;
    padding-left: 50px;
    padding-right: 50px;
  }
  .home:not(.opt-dashboard) footer {
    margin-top: 35px;
  }
  .welcome-username {
    font-size: 17px;
    padding-bottom: 20px;
  }
}

@media (min-width: 1100px) {
  main.homepage {
    padding: 100px 0 25%;
  }
}

main.homepage:not(.homepage--collapsed) {
  padding: 0 15px 12.5%;
}

main.homepage:not(.homepage--collapsed) .card-holder .card svg.channel-icon:not(.icon-lock) {
  display: none;
}

.homepage .channel-display {
  transition: all 0.25s ease;
  position: relative;
  padding: 0;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
}

.homepage .channel-display > span {
  font-size: 1.75rem;
}

.homepage .card-holder {
  margin: 0;
}

@media (max-width: 766px) {
  .homepage .card-holder {
    padding: 0 !important;
  }
}

.homepage .card-holder .card {
  display: flex;
  padding: 0 8px;
}

.homepage .card-holder .card > * {
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.5);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.homepage .card-holder .card:hover {
  z-index: 1;
}

.homepage .card-holder .card:hover .card-box {
  box-shadow: 0 0 20px #000;
}

.homepage .card-holder .card .footer-area {
  display: flex;
  flex-direction: column;
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  position: relative;
  padding-top: 20px;
  padding-bottom: 15px;
  visibility: hidden;
  opacity: 0;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: -100%;
}

.homepage .card-holder .card .footer-area > p {
  width: 100%;
  flex-grow: 1;
  margin-bottom: -25%;
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.homepage .card-holder .card .footer-area + .btn-mysps {
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 3.5rem;
}

.homepage .card-holder .card .footer-area + .btn-mysps svg {
  width: 40px;
  height: 40px;
  fill: #fff;
}

@media (max-width: 1099px) {
  .homepage .card-holder .card .footer-area + .btn-mysps svg {
    width: 34px;
    height: 34px;
  }
}

.homepage .card-holder .card .btn-mysps {
  margin-top: auto;
}

.homepage:not(.homepage--collapsed) .channel-display {
  transform: translateZ(0) translateY(0.8725rem);
  margin: 0 auto;
}

.MySPS .homepage:not(.homepage--collapsed) .channel-display {
  transform: translateZ(0) translateY(0.75rem);
}

.homepage:not(.homepage--collapsed) .channel-display .channel-name {
  max-width: 140px;
  max-height: 77px;
}

.homepage:not(.homepage--collapsed) .channel-display .channel-name.desktop {
  display: block;
}

.homepage:not(.homepage--collapsed) .channel-display .channel-name.mobile {
  display: none;
}

.homepage:not(.homepage--collapsed) .card > * {
  padding-top: 50%;
  align-items: center;
}

.homepage:not(.homepage--collapsed) .card:hover {
  transform: scale(1.2);
}

.homepage:not(.homepage--collapsed) .card:hover .footer-area {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
  margin-bottom: 0;
}

.homepage:not(.homepage--collapsed) .card:hover .footer-area > p {
  margin-bottom: 25px;
}

.homepage.homepage--collapsed .card-holder {
  padding: 0 25%;
}

.homepage.homepage--collapsed .channel-display .channel-number {
  font-size: 2.4rem !important;
}

.homepage.homepage--collapsed .channel-display .channel-name.desktop {
  display: none;
}

.homepage.homepage--collapsed .channel-display .channel-name.mobile {
  display: block;
}

.homepage.homepage--collapsed .card {
  padding: 3px 0 !important;
}

.homepage.homepage--collapsed .card .footer-area {
  display: none;
}

.homepage.homepage--collapsed .card .footer-area + .btn-mysps {
  display: block;
}

.homepage.homepage--collapsed .card:hover {
  transform: scale(0.9);
}

body.home:not(.profile):not(.changepassword) .main-header .menu {
  display: none;
}

.channel-display {
  color: #fff;
  display: inline-block;
  width: auto;
  padding-right: 2%;
}

.MySPS .homepage.homepage--collapsed .channel-display {
  display: flex;
  align-items: center;
  padding-left: 12px;
}

@media (max-width: 1099px) {
  .channel-display[data-channel^="f"] .channel-name {
    max-width: 100px;
  }
}

@media (min-width: 550px) {
  .article-header .channel-display#channel-intro .channel-name {
    max-width: none;
    width: 128px;
    height: 57.2px;
  }
}

@media (min-width: 550px) {
  .article-header .channel-display#channel-capability .channel-name {
    max-width: none;
    width: 139px;
    height: 57.2px;
  }
}

@media (min-width: 550px) {
  .article-header .channel-display#channel-ptt .channel-name {
    max-width: none;
    width: 187.8px;
    height: 57.2px;
  }
}

@media (min-width: 550px) {
  .article-header .channel-display#channel-culture .channel-name {
    max-width: none;
    width: 139px;
    height: 57.2px;
  }
}

@media (min-width: 550px) {
  .article-header .channel-display#channel-strategy .channel-name {
    max-width: none;
    width: 139px;
    height: 57.2px;
  }
}

@media (min-width: 550px) {
  .article-header .channel-display#channel-leadership .channel-name {
    max-width: none;
    width: 139px;
    height: 57.2px;
  }
}

.channel-display .channel-name {
  fill: #fff;
  max-width: 120px;
  max-height: 26px;
}

.MySPS .homepage .channel-display .channel-name {
  width: 32px;
  height: 32px;
}

@media (min-width: 1100px) {
  .MySPS .homepage .channel-display .channel-name {
    width: 70px;
    height: 70px;
  }
}

.article-header .channel-display .channel-name {
  max-width: 62px;
  max-height: 57px;
}

@media (min-width: 550px) {
  .article-header .channel-display .channel-name {
    max-width: 82px;
    max-height: 77px;
  }
  .article-header .channel-display .channel-name--step {
    max-width: 106px;
    max-height: 60px;
  }
}

.channel-display .channel-number {
  padding-top: 20px;
  font-weight: bold;
  font-size: 46px;
  line-height: 0.75;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
}

.MySPS .homepage .channel-display .channel-number {
  padding-top: 0;
  text-transform: none;
  font-size: 17px !important;
  line-height: 1.25;
  font-family: 'Panton-SemiBold';
  letter-spacing: 1px;
  margin-left: 8px;
}

@media (min-width: 1100px) {
  .MySPS .homepage .channel-display .channel-number {
    font-size: 20px !important;
    margin-left: 0;
  }
}

@media (min-width: 1100px) {
  .channel-display .channel-number {
    font-size: 50px;
  }
}

@media (max-width: 449px) {
  .channel-display .channel-number {
    font-size: 36px;
  }
}

.channel-display .channel-label {
  width: 7rem;
  line-height: 1.15;
  letter-spacing: 0.05rem;
  font-family: 'Panton-SemiBold';
  font-size: 8px;
  text-transform: uppercase;
}

@media (min-width: 1100px) {
  .channel-display .channel-label {
    width: auto;
    font-size: 10px;
  }
}

@media (max-width: 449px) {
  .channel-display .channel-label {
    font-size: 6px;
  }
}

.article-header .channel-display .channel-label {
  width: 8em;
}

.cards-extra-button {
  margin-bottom: -1vh;
  margin-top: 3.5vh;
  text-align: center;
  position: relative;
  z-index: 1;
  pointer-events: none;
}

.cards-extra-button a.btn {
  margin: 0;
}

@media (max-width: 1099px) {
  .cards-extra-button a.btn {
    padding: 12px 15px;
    font-size: 11px;
  }
}

.cards-extra-button > * {
  pointer-events: auto;
}

.cards-extra-button.disabled > .btn {
  pointer-events: none;
  color: #666;
  border-color: #666;
  opacity: .75;
}

/* Light Theme */
.light-theme .template-diagram .diagram-btn {
  filter: url(#diagram_shadow_lightest);
}

.light-theme .template-diagram .diagram-btn:hover {
  filter: url(#diagram_shadow_light);
}

.light-theme .main-header, .light-theme .homepage .card-holder .card > * {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.01);
}

.light-theme .homepage .card-holder .card:hover .card-box {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
}

#header .main-header, .article-header__container {
  background: #00ADEF;
}

@media (min-width: 1100px) {
  #header {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 9999;
  }
}

.sidebar {
  position: fixed;
}

.main-header {
  position: relative;
  width: 100%;
  transition: all .2s ease;
  z-index: 100;
  /*&:before{
    content:'';
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background: linear-gradient(to top, rgba(68,48,46,.55), rgba(46,30,29,.85));
  }*/
  /*&:after{
    content:'';
    position: absolute;
    top:0;
    opacity:1;
    left:0;
    width:100%;
    height:100%;
    z-index: -1;
    background-image: url(/assets/images/driver-new.jpg);
    background-size: 100%;
    background-position: top center;
    @include media($mobile-menu-break) {
      background-size: 100%;
    }
    background-attachment: fixed;
    background-repeat: no-repeat;
  }*/
  background: transparent;
  /*height:100px;
  @include media($tablet) {
    height:150px;
  }*/
  /*fix for CMS Area*/
  width: 100%;
}

@media (min-width: 1100px) {
  .main-header {
    box-shadow: 0 0 6rem rgba(46, 30, 29, 0.9);
  }
}

.main-header .container {
  height: 60px;
  position: relative;
  z-index: 100;
}

@media (min-width: 1100px) {
  .main-header .container {
    height: 60px;
  }
}

@media (max-width: 1099px) {
  .main-header .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.main-header .container .title {
  position: absolute;
  width: 100%;
  padding: 0;
  left: 0;
  display: none;
  text-align: center;
  z-index: 1;
  line-height: 60px;
  color: #fff;
  font-size: 1.25rem;
}

@media (max-width: 349px) {
  .main-header .container .title {
    display: inline-block;
    max-width: 12rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    left: 50%;
    font-size: 1.05rem;
    margin-left: -6rem;
  }
}

@media (min-width: 1100px) {
  .main-header .container .title {
    line-height: 60px;
  }
}

.main-header .container .offline-mode {
  position: absolute;
  display: block;
  top: 40px;
  margin-left: -10px;
  text-align: center;
  width: 100%;
}

@media (min-width: 1100px) {
  .main-header .container .offline-mode {
    top: 60px;
    margin-left: 0px;
  }
}

.main-header .container .offline-mode span {
  border-color: #00ADEF;
  background-color: #ED1E23;
  color: #fff;
  font-size: .75em;
  padding: .3em .4em;
  width: auto;
  height: auto;
  margin: 3.1rem auto 0;
}

.main-header .container .offline-mode span:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.main-header .container .offline-mode span h1,
.main-header .container .offline-mode span h2,
.main-header .container .offline-mode span h3,
.main-header .container .offline-mode span h4,
.main-header .container .offline-mode span h5,
.main-header .container .offline-mode span p {
  color: #fff;
}

.main-header .container .offline-mode span h1:first-child,
.main-header .container .offline-mode span h2:first-child,
.main-header .container .offline-mode span h3:first-child,
.main-header .container .offline-mode span h4:first-child,
.main-header .container .offline-mode span h5:first-child,
.main-header .container .offline-mode span p:first-child {
  margin-top: 0;
  padding-top: 0;
}

.main-header .container .offline-mode span h1:last-child,
.main-header .container .offline-mode span h2:last-child,
.main-header .container .offline-mode span h3:last-child,
.main-header .container .offline-mode span h4:last-child,
.main-header .container .offline-mode span h5:last-child,
.main-header .container .offline-mode span p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.main-header .container .offline-mode span a {
  color: #fff;
}

.main-header .container .offline-mode span:before {
  margin: -.1rem .2rem 0 .1rem;
  display: inline-block;
}

.main-header .logo {
  pointer-events: none;
}

.main-header .logo,
.main-header [on-scroll-logo] {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 2;
  overflow: hidden;
  align-self: baseline;
  margin: 0;
}

.main-header .logo .logo-icon,
.main-header [on-scroll-logo] .logo-icon {
  transition: all .2s ease;
  height: 29px;
}

.main-header .logo .logo-text,
.main-header [on-scroll-logo] .logo-text {
  width: 220px;
  height: 150px;
}

.main-header .searcher {
  display: inline-block;
  padding: 6.5em 3% 0 3%;
}

.main-header .searcher:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-size: 150%;
  color: #fff;
  display: block;
}

@media (min-width: 1000px) {
  .main-header .searcher:before {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.main-header .menu {
  float: left;
  text-align: center;
  z-index: 2;
  position: relative;
  /*&:after{
      content:"Menu";
      color:#666;
      font-size:.65rem;
      display: block;
      margin-top:.1rem;
    }*/
}

.main-header .menu svg {
  fill: #fff;
  width: 42px;
  height: 42px;
}

.main-header .mobile-menu {
  display: block;
  margin: -10px auto 0 auto;
  width: 100%;
  text-align: center;
  z-index: 2;
  display: inline-block;
}

.main-header .mobile-menu:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-size: 150%;
  color: #2D363C;
  padding-top: 15px;
  padding-bottom: 10px;
  display: block;
}

@media (min-width: 1100px) {
  .main-header .mobile-menu {
    display: none;
  }
}

.main-header .mysps {
  /*&&:after{
      content:"My SPS";
    }*/
}

.main-header .mysps:before {
  content: "";
  font-size: 175%;
}

.main-header .blog:before {
  content: "";
  font-size: 165%;
}

.main-header .blog:after {
  content: "Blog";
}

.main-header .myaccount {
  display: inline-block;
}

@media (min-width: 767px) {
  .main-header .myaccount--search, .main-header .myaccount--blog {
    padding-right: 0;
  }
}

.main-header .myaccount a img {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.main-header .myaccount span {
  display: none;
  font-size: .95rem;
  /*&:after {
        @include icon($icon-arrow-down);
        font-size: .6rem;
        display: inline-block;
        margin: 0 .6rem 0 .5rem;
      }*/
}

.main-header .myaccount > a > svg {
  width: 34px;
  height: 34px;
  fill: #fff;
}

.articles-page .main-header .myaccount > a > svg {
  fill: #fff !important;
}

@media (min-width: 1100px) {
  .main-header .myaccount > a > svg {
    width: 42px;
    height: 42px;
  }
}

.main-header .myaccount > a.account-profile {
  position: relative;
}

.main-header .myaccount > a.account-profile > img {
  position: absolute;
  margin: 0 !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main-header .myaccount > a.account-profile > img + .svg-icon {
  opacity: 0;
}

.main-header.mobile-menu-open nav {
  max-height: 400px;
}

.main-header.mobile-menu-open .mobile-menu:before {
  content: "";
}

.main-header nav {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: right;
  height: 100%;
  position: relative;
  display: block;
  color: #fff;
}

.main-header nav > ul {
  margin: 0;
  padding: 0;
  list-style: none;
  height: 100%;
}

.main-header nav > ul > li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.main-header nav > ul > li {
  height: 100%;
  cursor: pointer;
}

@media (min-width: 1100px) {
  .main-header nav > ul > li {
    padding: 0 5px;
  }
}

.main-header nav > ul > li a {
  height: 100%;
  display: flex;
  align-items: center;
}

.main-header nav a {
  opacity: .9;
  transition: all .2s ease;
}

.main-header nav a:hover {
  opacity: 1;
  text-decoration: none;
}

.home .title {
  display: none !important;
}

.home header .logo {
  display: block !important;
}

.home .back-btn {
  display: none;
}

@media (min-width: 1100px) {
  .home .menu {
    display: none;
  }
  .home.changepassword .menu, .home.profile .menu {
    display: block;
  }
}

.back-btn {
  display: none;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  line-height: 60px;
  padding: 0 .8rem;
  font-size: 1.2rem;
}

@media (min-width: 1100px) {
  .back-btn {
    line-height: 60px;
  }
}

.back-btn i {
  font-size: 1.9rem;
  display: inline-block;
  margin: -.2rem .1rem 0 0;
  color: #fff;
}

.back-btn:hover {
  color: #555;
}

@media (min-width: 1100px) {
  header .back-btn {
    display: none;
  }
}

.dropmenu-holder--submenu {
  display: none;
  border-top: 1px solid #333;
  background: #22292D;
  white-space: nowrap;
}

.has-submenu.dropmenu-open .dropmenu-holder--submenu {
  display: block;
}

@media (min-width: 1100px) {
  .myaccount--menu .dropmenu-holder--submenu .svg-icon {
    display: none;
  }
}

@media (max-width: 1099px) {
  .myaccount--menu .dropmenu-holder--submenu {
    border-top: 0;
    display: block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 80px;
    left: 0;
    transform: translateX(100%);
    background: #2D363C;
    transition: all 0.5s ease-in-out;
  }
  .myaccount--menu .dropmenu-holder--submenu li a {
    padding-left: 20px !important;
  }
  .myaccount--menu .has-submenu.dropmenu-open .dropmenu-holder--submenu {
    transform: translateX(0%);
  }
  .myaccount--menu .has-submenu.dropmenu-open .dropmenu-holder--submenu:after {
    content: '';
    z-index: 0;
    background: #2D363C;
    display: block;
    position: fixed;
    top: -80px;
    width: 100%;
    height: 80px;
  }
  .myaccount--menu .has-submenu.dropmenu-open .dropmenu-holder--submenu:before {
    z-index: 1;
    content: '';
    display: block;
    position: fixed;
    background: url("../assets/svg/Icons/Arrow%20Back.svg") no-repeat center/contain;
    filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(45deg) brightness(111%) contrast(101%);
    top: -64px;
    left: 15px;
  }
}

.dropmenu-holder__menu .has-submenu.dropmenu-open > a:after,
.sidebar-myaccount .has-submenu.dropmenu-open > a:after {
  background-image: url("../assets/svg/Icons/Minus.svg");
}

.dropmenu-holder__menu .has-submenu > a,
.sidebar-myaccount .has-submenu > a {
  position: relative;
}

.dropmenu-holder__menu .has-submenu > a:after,
.sidebar-myaccount .has-submenu > a:after {
  content: '';
  position: absolute;
  background: url("../assets/svg/Icons/Angle%20Forward.svg") no-repeat center/contain;
  width: 20px;
  height: 20px;
  filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(45deg) brightness(111%) contrast(101%);
  top: 50%;
  margin-top: -12px;
  right: 15px;
}

@media (min-width: 1100px) {
  .dropmenu-holder__menu .has-submenu > a:after,
  .sidebar-myaccount .has-submenu > a:after {
    background-image: url("../assets/svg/Icons/Add.svg");
    width: 24px;
    height: 24px;
  }
}

[on-scroll-logo] {
  color: #fff;
  font-size: 2rem;
  text-align: center;
  line-height: 0.875;
  font-weight: 600;
}

[on-scroll-logo] .channel-name {
  fill: #fff;
  width: 62px;
  height: 32px;
  margin-bottom: 5px;
}

body.home [on-scroll-logo] {
  display: none !important;
}

[on-scroll-logo] span {
  font-size: 1.25rem;
  display: block;
}

.home.MySPS .menu {
  display: block !important;
}

.header-clientname {
  color: #fff;
  font-size: 1.25rem;
  letter-spacing: 0.2px;
  font-weight: 600;
  line-height: 1;
}

.disable-header--home .home-button {
  display: none;
}

.disable-header--search [class*='--search'] {
  display: none;
}

.disable-header--menu:not(.register) [class*='--menu'] {
  display: none;
}

.main-header .myaccount .dropmenu-holder__menu .dropmenu-holder--submenu a > span {
  display: block;
}

.menu.menu-button.home-button.f-tag {
  display: none !important;
}

body.home.opt-dashboard .menu.menu-button.home-button.f-tag {
  display: block !important;
}

@media (max-width: 1099px) {
  .myaccount--menu .dropmenu-holder {
    display: flex;
    flex-direction: column;
    position: fixed;
  }
  .myaccount--menu .dropmenu-holder account-menu {
    width: 100%;
    display: block;
    flex-grow: 1;
    overflow-y: auto;
  }
  .myaccount--menu .dropmenu-holder__head {
    width: 100%;
    background-color: #2D363C;
  }
}

.articles-page .main-header .logo {
  display: none;
}

.resource, .row-block--box,
.row-block--overview, .steps-block > li article,
.article-thumbnails > li article {
  border: 1px solid #F6F7F8;
  box-shadow: 0 2px 6px 0 rgba(56, 68, 75, 0.05);
}

.articles-page [ui-view="content"] {
  background: #F6F7F8;
}

.articles-page [ui-view="content"] h3 {
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
}

@media (max-width: 766px) {
  .articles-page [ui-view="content"] h3 {
    font-size: 20px;
  }
}

.articles-page [ui-view="content"] p {
  line-height: 1.5;
}

.logo-icon-sps {
  display: none;
}

.articles-page:not(.myTemplates):not(.admin-page):not(.MySPS) .logo-icon-client {
  display: none;
}

.articles-page .logo-icon-sps {
  display: block;
}

.articles-page footer {
  display: none;
}

.articles-page .articles-main footer {
  display: block;
  position: relative;
}

@media (min-width: 1100px) {
  .articles-page .main-header {
    display: none;
  }
  .articles-page .sidebar .header .close-menu-button,
  .articles-page .sidebar .header .account-button {
    display: none;
  }
  .articles-page .sidebar .header {
    height: 6.8rem;
  }
  .articles-page .sidebar-content {
    top: 6.8rem;
    overflow: visible;
  }
}

.articles-main {
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1rem;
  overflow-x: hidden;
}

@media (min-width: 370px) {
  .articles-main {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1100px) {
  .articles-main {
    padding-top: 0;
    max-width: 1166px;
    padding-left: 50px;
    padding-right: 50px;
    overflow: visible;
  }
}

@media (max-width: 1099px) {
  body.articles-page .articles-main {
    overflow: unset;
  }
}

.articles-main.main-full {
  max-width: 100%;
}

.breadcrumb {
  display: none;
}

.breadcrumb h6 {
  text-transform: uppercase;
}

.article-header {
  left: 0;
  top: 0;
  background: #00ADEF;
}

.article-header__container {
  background-color: #00ADEF;
  position: relative;
  height: 6.8rem;
}

.blog-page .article-header__container {
  background-color: #00ADEF !important;
}

.article-header__container--has-bg {
  background-color: #2D363C !important;
}

.article-header__container--has-bg:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #2D363C;
  z-index: 1;
  opacity: 0.25;
}

@media (max-width: 1099px) {
  .article-header__container {
    display: none;
  }
}

@media (max-width: 449px) {
  .article-header__container {
    height: 80px;
  }
}

.article-header__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@media (max-width: 1099px) {
  .article-header {
    position: sticky;
    top: 0;
    z-index: 2;
  }
}

.article-header .articles-main {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
}

.article-header .articles-main > h1:first-child {
  padding-left: 0 !important;
}

.article-header h1,
.article-header h2,
.article-header h3 {
  color: #fff;
  font-size: 26px;
  flex: 1;
  padding-left: 2%;
}

@media (min-width: 1250px) {
  .article-header h1,
  .article-header h2,
  .article-header h3 {
    font-size: 2rem;
  }
}

@media (max-width: 999px) {
  .article-header h1,
  .article-header h2,
  .article-header h3 {
    max-width: 55%;
    margin-right: auto;
  }
}

.MySPS .article-header h1, .MySPS
.article-header h2, .MySPS
.article-header h3 {
  margin: 0;
  padding-top: 0;
  font-weight: lighter;
  font-family: "Panton-Light", Arial, Helvetica, sans-serif;
}

.article-header h1 {
  padding-top: 0;
  margin-bottom: 0;
}

.article-header .background-image {
  width: 100%;
  top: 0;
  left: 0;
  transform: scale(1.25);
  height: 100%;
  position: absolute;
  background-image: url("/assets/images/rowing.jpg");
  background-size: cover;
  filter: blur(5px);
  background-position: center;
  z-index: 0;
}

.article-header .channel-display {
  border-right: 0.1rem solid #fff;
  padding-left: 0;
  display: flex;
}

.article-header .channel-display svg {
  width: 100%;
}

@media (min-width: 330px) {
  .article-header .channel-display {
    padding-left: 1%;
  }
}

@media (min-width: 767px) {
  .article-header .channel-display {
    padding-left: 8%;
  }
}

@media (min-width: 1100px) {
  .article-header .channel-display {
    padding-left: 0;
  }
}

article figure {
  width: 100%;
  max-height: 400px;
  overflow: hidden;
}

article figure img {
  width: 100%;
}

article .article-body {
  padding-top: 30px;
  padding-bottom: 4rem;
}

@media (min-width: 330px) {
  article .article-body {
    padding-left: 3%;
    padding-right: 3%;
  }
}

@media (min-width: 767px) {
  article .article-body {
    padding-left: 8%;
    padding-right: 8%;
  }
}

@media (min-width: 1100px) {
  article .article-body {
    padding: 4rem 0;
  }
}

.articles-list article {
  width: 100%;
  margin: 3px 0;
}

.articles-list article:hover {
  z-index: 2;
}

article.main-sidebar {
  flex: 1;
}

aside {
  flex: 0 0 15em;
  padding: 20px;
}

.gutter-sizer {
  width: 1%;
}

.resources {
  padding: 0 0 4rem;
}

@media (min-width: 1100px) {
  .resources {
    margin-left: -10px;
    margin-right: -10px;
    display: flex;
    flex-wrap: wrap;
  }
}

@media (max-width: 1099px) {
  .resources {
    margin-top: 2rem;
  }
}

.resource {
  padding: 25px 50px 30px;
  position: relative;
  margin: 0 0 20px;
  background-color: #fff;
}

.resource h3 {
  padding-top: 0.325em;
  font-size: 17px !important;
  margin-bottom: 0.5em !important;
}

@media (min-width: 1000px) {
  .resource h3 {
    font-size: 22px !important;
  }
}

.resource--no-box {
  padding: 0;
  box-shadow: none;
  background: none;
  margin: 0 !important;
  border: none;
}

.resource--no-download ul li a:after {
  display: none;
}

@media (min-width: 1100px) {
  .resource {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
    margin-right: 10px;
    margin-left: 10px;
  }
}

@media (max-width: 549px) {
  .resource {
    padding: 20px 25px 15px;
  }
}

.resource > * {
  position: relative;
  z-index: 1;
}

.resource h2 {
  font-size: 24px;
}

.articles-page .resource h2 {
  border-bottom: 0;
  margin-bottom: 0;
}

.resource ul {
  margin: 0;
  padding: 0;
  list-style: none;
  padding: 0.5rem 0;
}

.resource ul > li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.resource ul li {
  position: relative;
}

.resource ul li:not(:empty):before {
  content: '';
  width: 50px;
  height: 50px;
  background-color: #F6F7F8;
  position: absolute;
  top: 0;
  left: 0;
}

.resource ul li p {
  margin: 0;
}

.resource ul li p a {
  text-decoration: none;
}

.resource ul li a {
  position: relative;
  padding: 0 40px 0.25rem 0;
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
}

.resource ul li a i, .resource ul li a em {
  margin-left: auto;
  color: #B1B1B1;
  font-size: 13px;
  font-style: normal;
  opacity: 0.75;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) opacity 0.3s;
}

.resource ul li a:hover {
  color: #00ADEF;
}

.resource ul li a:hover span:not([class]):before {
  background: #00ADEF;
  z-index: 1;
}

.resource ul li a:hover i, .resource ul li a:hover em {
  color: #00ADEF;
}

.resource ul li a:hover i:after, .resource ul li a:hover em:after {
  opacity: 1;
}

.resource ul li a:hover:before {
  opacity: 1 !important;
  background-color: #f15900 !important;
  filter: invert(100%) !important;
}

.resource ul li a:hover:after {
  filter: invert(45%) sepia(100%) saturate(1740%) hue-rotate(164deg) brightness(100%) contrast(103%);
}

.resource ul li a span:not([class]) {
  position: relative;
  margin-right: 15px;
  flex-shrink: 0;
  width: 78px;
  height: 50px;
  position: relative;
  overflow: hidden;
}

.resource ul li a span:not([class]):before, .resource ul li a span:not([class]):after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
}

.resource ul li a span:not([class]):after {
  -webkit-filter: grayscale(100%) brightness(500%) brightness(40%);
  filter: grayscale(100%) brightness(500%) brightness(40%);
  background: url("../assets/svg/Icons/Play.svg") no-repeat center;
  background-size: 70px;
  filter: invert(100%) sepia(5%) saturate(705%) hue-rotate(197deg) brightness(118%) contrast(100%);
  z-index: 1;
}

.resource ul li a span:not([class]) img {
  position: absolute;
  top: -10%;
  left: -10%;
  width: 100px !important;
  max-width: 100px;
}

.resource ul li a:after {
  -webkit-filter: grayscale(100%) brightness(500%) brightness(40%);
  filter: grayscale(100%) brightness(500%) brightness(40%);
  content: '';
  width: 30px;
  height: 30px;
  margin-left: 10px;
  background: url("../assets/svg/Icons/Download.svg") no-repeat center;
  background-size: 30px;
  flex-shrink: 0;
  opacity: 0.75;
  position: absolute;
  top: calc(50% - 3px);
  right: 0;
  transform: translateY(-50%);
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) opacity 0.3s;
  color: '';
  -webkit-filter: invert(77%) sepia(10%) saturate(304%) hue-rotate(173deg) brightness(92%) contrast(88%);
  filter: invert(77%) sepia(10%) saturate(304%) hue-rotate(173deg) brightness(92%) contrast(88%);
}

.resource ul li a[href*='.doc']:before, .resource ul li a[href*='.docx']:before, .resource ul li a[href*='.pdf']:before, .resource ul li a[href*='.pptx']:before, .resource ul li a[href*='.ppt']:before, .resource ul li a[href*='.jpg']:before, .resource ul li a[href*='.jpeg']:before, .resource ul li a[href*='.svg']:before, .resource ul li a[href*='.png']:before, .resource ul li a[href*='.xls']:before, .resource ul li a[href*='.xlsx']:before, .resource ul li a[href*='fliphtml5']:before, .resource ul li a[href*='.html']:before {
  content: '';
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  margin-right: 15px;
  background-size: 42px;
  background-repeat: no-repeat;
  background-position: center;
  color: '';
  -webkit-filter: invert(77%) sepia(10%) saturate(304%) hue-rotate(173deg) brightness(92%) contrast(88%);
  filter: invert(77%) sepia(10%) saturate(304%) hue-rotate(173deg) brightness(92%) contrast(88%);
}

.resource ul li a[href*='.doc']:before, .resource ul li a[href*='.docx']:before {
  background-image: url("../assets/svg/Icons/File Word.svg");
}

.resource ul li a[href*='.pdf']:before {
  background-image: url("../assets/svg/Icons/File PDF.svg");
}

.resource ul li a[href*='.ppt']:before, .resource ul li a[href*='.pptx']:before, .resource ul li a[href*='fliphtml5']:before, .resource ul li a[href*='.html']:before {
  background-image: url("../assets/svg/Icons/File Powerpoint.svg");
}

.resource ul li a[href*='.jpg']:before, .resource ul li a[href*='.jpeg']:before, .resource ul li a[href*='.png']:before, .resource ul li a[href*='.svg']:before {
  background-image: url("../assets/svg/Icons/File Image.svg");
}

.resource ul li a[href*='.xls']:before, .resource ul li a[href*='.xlsx']:before {
  background-image: url("../assets/svg/Icons/File Excel.svg");
}

.resource ul + ul {
  padding-top: 0;
  margin-top: -0.5rem;
}

.articles-page pre {
  background: #cecece;
  padding: 1rem;
  margin-bottom: 2.5rem;
  margin-top: 0.5rem;
  font-size: 12px;
  white-space: pre-wrap;
  user-select: text;
}

.row-block {
  display: flex;
}

@media (max-width: 549px) {
  .row-block {
    flex-wrap: wrap;
  }
}

.row-block:not(.row-block--box) .col-block {
  width: 100%;
}

.row-block h4 {
  line-height: 1.425;
  position: relative;
}

.row-block h4 em {
  display: block;
  color: #AAB3BE;
  font-size: 11px !important;
  line-height: 14px;
  font-weight: 600;
  letter-spacing: 1.82px;
  font-style: normal;
  text-transform: uppercase;
}

@media (min-width: 550px) {
  .row-block {
    margin: 0 -1rem;
  }
  .col-block {
    flex: 1 1 0;
    padding: 0 1rem;
  }
  .col-block--360 {
    flex: 1 0 360px;
    max-width: 360px;
  }
  .col-block.is-two-thirds {
    flex: 1 1 66.6666%;
    max-width: 66.6666%;
  }
  .col-block.is-one-third {
    flex: 1 1 33.3333%;
    max-width: 33.3333%;
  }
  .col-block.is-three-fifths {
    flex: 1 1 60%;
    max-width: 60%;
  }
  .col-block.is-two-fifths {
    flex: 1 1 40%;
    max-width: 40%;
  }
}

[class^='resource-item--'] {
  display: block;
  position: relative;
  padding-left: 3rem;
  padding-right: 5.5rem;
  margin-bottom: 1.5rem;
}

@media (min-width: 550px) {
  [class^='resource-item--'] {
    padding-left: 3.5rem;
    padding-right: 6rem;
  }
}

[class^='resource-item--']:before {
  -webkit-filter: grayscale(100%) brightness(500%) brightness(40%);
  filter: grayscale(100%) brightness(500%) brightness(40%);
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 42px;
  height: 42px;
  color: '';
  -webkit-filter: invert(77%) sepia(10%) saturate(304%) hue-rotate(173deg) brightness(92%) contrast(88%);
  filter: invert(77%) sepia(10%) saturate(304%) hue-rotate(173deg) brightness(92%) contrast(88%);
  background-position: center;
  background-size: contain;
  -webkit-background-size: contain;
  background-repeat: no-repeat;
}

@media (min-width: 550px) {
  [class^='resource-item--']:before {
    width: 48px;
    height: 48px;
  }
}

[class^='resource-item--']:after {
  content: attr(data-size);
  display: block;
  position: absolute;
  top: 0;
  right: 1em;
  background-image: url("../assets/svg/Icons/Download.svg");
  background-position: center right;
  background-size: contain;
  -webkit-background-size: contain;
  background-repeat: no-repeat;
  color: '';
  -webkit-filter: invert(77%) sepia(10%) saturate(304%) hue-rotate(173deg) brightness(92%) contrast(88%);
  filter: invert(77%) sepia(10%) saturate(304%) hue-rotate(173deg) brightness(92%) contrast(88%);
  font-size: 11px;
  text-transform: uppercase;
  padding: 0.5em 3.25em 0.5em 0;
  margin-top: 0.8em;
  letter-spacing: 0.5px;
}

@media (min-width: 550px) {
  [class^='resource-item--']:after {
    margin-top: 0.5em;
    font-size: 13px;
  }
}

[class^='resource-item--'][class$='powerpoint']:before {
  background-image: url("../assets/svg/Icons/File Powerpoint.svg");
}

[class^='resource-item--'][class$='excel']:before {
  background-image: url("../assets/svg/Icons/File Excel.svg");
}

[class^='resource-item--'][class$='word']:before {
  background-image: url("../assets/svg/Icons/File Word.svg");
}

[class^='resource-item--'][class$='pdf']:before {
  background-image: url("../assets/svg/Icons/File PDF.svg");
}

[class^='resource-item--'][class$='image']:before {
  background-image: url("../assets/svg/Icons/File Image.svg");
}

[class^='resource-item--'][class$='video']:before {
  background-image: url("../assets/svg/Icons/File Video.svg");
}

[class^='resource-item--'][class$='audio']:before {
  background-image: url("../assets/svg/Icons/File Audio.svg");
}

.inline-resource h4 {
  padding: 0.6em 0 0 !important;
  margin-bottom: 0.325em !important;
  font-weight: 600;
  color: #333;
  line-height: 1.5;
  font-size: 15px !important;
}

@media (min-width: 550px) {
  .inline-resource h4 {
    font-size: 17px !important;
    padding: 0.75em 0 0 !important;
  }
}

.inline-resource p {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 13px;
}

@media (min-width: 550px) {
  .inline-resource p {
    font-size: 15px;
  }
}

blockquote {
  position: relative;
  text-align: center;
  font-size: 1.125rem;
  color: #333;
  font-weight: 600;
  line-height: 1.6;
  margin-right: auto;
  margin-left: auto;
  max-width: 280px;
  padding: 1.25rem;
  box-shadow: 0 8px 24px 0 rgba(56, 68, 75, 0.05);
  background-color: white;
  margin-bottom: 5rem;
}

blockquote strong {
  font-weight: 600;
}

@media (min-width: 1100px) {
  blockquote {
    max-width: 414px;
    padding: 1.5rem;
  }
}

blockquote .from {
  position: absolute;
  display: block;
  font-size: 15px;
  color: #00ADEF;
  font-weight: normal;
  line-height: 1.5;
  top: 100%;
  left: 0;
  width: 100%;
}

blockquote .from p {
  margin: 0;
}

blockquote .from:before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-top: 1.75rem solid white;
  border-left: 1.75rem solid transparent;
  margin: 0 auto 0.75rem;
  transform: translateX(-0.7rem);
}

.row-block--box,
.row-block--overview {
  padding: 2em 20px;
  margin: 0;
  background: #EEF0F2;
  border-color: #e6e6e6;
  flex-wrap: wrap;
}

.row-block--box:not(.row-block--image) > div:last-of-type:not(.col-block--image),
.row-block--overview:not(.row-block--image) > div:last-of-type:not(.col-block--image) {
  flex: 1 1 0;
  max-width: none;
}

.row-block--box h3,
.row-block--overview h3 {
  display: flex;
  align-items: center;
  padding-top: 0.25rem;
}

.row-block--box h3 svg,
.row-block--overview h3 svg {
  width: 80px;
  height: 80px;
  fill: #00ADEF;
  margin-right: 20px;
  margin-left: -20px;
  margin-top: -10px;
  flex-shrink: 0;
}

@media (max-width: 766px) {
  .row-block--box h3 svg,
  .row-block--overview h3 svg {
    height: 60px;
    width: 60px;
    margin-left: -5px;
    margin-right: 20px;
  }
}

.row-block--box h4,
.row-block--overview h4 {
  display: flex;
  margin-bottom: 15px;
  padding-top: 0 !important;
  font-size: 17px !important;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
}

@media (min-width: 767px) {
  .row-block--box h4,
  .row-block--overview h4 {
    font-size: 20px !important;
  }
}

.row-block--box h4 svg,
.row-block--overview h4 svg {
  color: '';
  -webkit-filter: invert(77%) sepia(10%) saturate(304%) hue-rotate(173deg) brightness(92%) contrast(88%);
  filter: invert(77%) sepia(10%) saturate(304%) hue-rotate(173deg) brightness(92%) contrast(88%);
  width: 48px;
  height: 48px;
  position: absolute;
  right: calc(100% + 0.25rem);
  top: -0.0125rem;
  flex-shrink: 0;
}

@media (min-width: 1000px) {
  .row-block--box h4 svg,
  .row-block--overview h4 svg {
    width: 52px;
    height: 52px;
  }
}

@media (max-width: 549px) {
  .row-block--box,
  .row-block--overview {
    padding: 25px 40px;
  }
}

@media (min-width: 550px) {
  .row-block--box.row-block--gutter, .row-block--box.row-block--overview,
  .row-block--overview.row-block--gutter,
  .row-block--overview.row-block--overview {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  .row-block--box.row-block--gutter .col-block, .row-block--box.row-block--overview .col-block,
  .row-block--overview.row-block--gutter .col-block,
  .row-block--overview.row-block--overview .col-block {
    padding-left: 1.75rem;
    padding-right: 1.5rem;
  }
}

.row-block--box table,
.row-block--overview table {
  margin-top: 20px;
  table-layout: unset;
  background: none;
}

.row-block--box table tr,
.row-block--overview table tr {
  background: inherit;
  border: none;
}

.row-block--box table tr th,
.row-block--overview table tr th {
  font-weight: bold;
  padding-left: 0;
}

.row-block--box table tr td, .row-block--box table tr th,
.row-block--overview table tr td,
.row-block--overview table tr th {
  padding-top: 6px;
  padding-bottom: 6px;
  border: inherit;
  background: inherit;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
}

@media (min-width: 550px) {
  .row-block--box table tr td, .row-block--box table tr th,
  .row-block--overview table tr td,
  .row-block--overview table tr th {
    font-size: 17px;
  }
}

.col-block--icon {
  text-align: center;
}

@media (min-width: 550px) {
  .col-block--icon {
    margin-top: 5px;
    flex: 1 0 4rem;
    max-width: 4rem;
  }
}

.col-block--icon figure {
  display: inline-block;
  width: auto;
  text-align: center;
  margin: auto;
  overflow: visible;
}

@media (max-width: 549px) {
  .col-block--icon figure svg {
    width: 42px !important;
    height: 42px !important;
    margin-top: -7px !important;
    margin-left: -30px;
    margin-right: 10px;
  }
}

.row-block--image-cover {
  margin: 0;
  padding: 0;
  background: #fff;
  align-items: center;
}

.row-block--image-cover .col-block {
  position: relative;
  padding: 20px 30px;
  overflow: hidden;
}

.row-block--image-cover .col-block + [class*="--image"] {
  padding-top: 0;
}

@media (max-width: 549px) {
  .row-block--image-cover .col-block + [class*="--image"]:last-child {
    padding-bottom: 40px;
  }
}

@media (min-width: 767px) {
  .row-block--image-cover .col-block {
    flex: 1 1 50%;
    max-width: 50%;
  }
}

@media (min-width: 1000px) {
  .row-block--image-cover .col-block:not([class*="--image"]) {
    padding: 30px 50px;
  }
}

@media (min-width: 1100px) {
  .row-block--image-cover .col-block:not([class*="--image"]) {
    padding: 50px 80px;
  }
}

@media (min-width: 767px) {
  .row-block--image-cover .col-block--image {
    padding: 0;
  }
}

@media (max-width: 766px) {
  .row-block--image-cover .col-block--image {
    padding: 0 !important;
    order: -1;
  }
}

.articles-page figure {
  max-height: unset;
}

.articles-page .link {
  display: inline-flex;
  text-decoration: underline;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) all 0.3s;
}

.articles-page .link:hover {
  color: #00ADEF;
}

.articles-page .link:hover:after {
  color: blue;
  -webkit-filter: invert(54%) sepia(93%) saturate(2542%) hue-rotate(162deg) brightness(97%) contrast(101%);
  filter: invert(54%) sepia(93%) saturate(2542%) hue-rotate(162deg) brightness(97%) contrast(101%);
}

.articles-page .link:after {
  content: '';
  display: block;
  margin-left: 0.25em;
  width: 24px;
  background: url("../assets/svg/Icons/Arrow Right3.svg") no-repeat center;
  background-size: contain;
  -webkit-background-size: contain;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) all 0.3s;
  color: black;
  -webkit-filter: invert(10%) sepia(0%) saturate(2831%) hue-rotate(288deg) brightness(97%) contrast(88%);
  filter: invert(10%) sepia(0%) saturate(2831%) hue-rotate(288deg) brightness(97%) contrast(88%);
}

@media (max-width: 999px) {
  .row-block--equal-title {
    flex-wrap: wrap;
  }
}

.row-block--equal-title .col-block {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media (max-width: 999px) {
  .row-block--equal-title .col-block {
    flex: 1 0 100%;
    max-width: 100%;
  }
}

.row-block--equal-title .col-block h3 {
  line-height: 1.4;
  font-size: 17px !important;
}

@media (min-width: 1000px) {
  .row-block--equal-title .col-block h3 {
    font-size: 20px !important;
  }
}

@media (max-width: 999px) {
  .row-block--equal-title .col-block h3 {
    margin-bottom: 0;
    padding-top: 10px;
  }
}

.row-block--equal-title .col-block p {
  flex-grow: 1;
}

.row-block--icon-thumb {
  margin-left: 0;
  margin-right: 0;
  display: flex;
}

@media (min-width: 1000px) {
  .row-block--icon-thumb {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

@media (max-width: 999px) {
  .row-block--icon-thumb {
    flex-wrap: wrap;
    margin-left: -1em;
  }
}

.row-block--icon-thumb > .col-block {
  position: relative;
}

.row-block--icon-thumb > .col-block svg {
  color: '';
  -webkit-filter: invert(77%) sepia(10%) saturate(304%) hue-rotate(173deg) brightness(92%) contrast(88%);
  filter: invert(77%) sepia(10%) saturate(304%) hue-rotate(173deg) brightness(92%) contrast(88%);
  width: 32px;
  height: 32px;
}

.row-block--icon-thumb > .col-block h4 {
  margin-top: 4px;
  margin-bottom: 0;
  padding-top: 5px;
  font-weight: 600;
  color: #333;
}

.row-block--icon-thumb > .col-block p {
  margin-top: 2px;
  margin-bottom: 0;
}

@media (max-width: 999px) {
  .row-block--icon-thumb > .col-block {
    flex: 1 1 50%;
    max-width: 50%;
    display: flex;
    align-items: center;
    padding-top: 1em;
    padding-left: 1em;
    padding-right: 1em;
    margin-top: 0.75em;
    margin-bottom: 0.75em;
  }
  .row-block--icon-thumb > .col-block:nth-child(even) {
    border-left: 2px solid #DDE0E5;
  }
  .row-block--icon-thumb > .col-block h4 {
    position: absolute;
    top: 0;
    left: calc(1.5em + 5px);
    margin: 0;
    display: block;
    color: #AAB3BE;
    font-size: 11px !important;
    line-height: 14px;
    font-weight: 600;
    letter-spacing: 1.82px;
    font-style: normal;
    text-transform: uppercase;
  }
  .row-block--icon-thumb > .col-block p {
    font-weight: 600;
    font-size: 13px !important;
    margin-left: 5px;
  }
}

@media (min-width: 1000px) {
  .row-block--icon-thumb > .col-block + .col-block {
    border-left: 2px solid #DDE0E5;
  }
  .row-block--icon-thumb > .col-block svg {
    position: absolute;
    top: -8px;
    left: 20px;
    width: 48px;
    height: 48px;
  }
  .row-block--icon-thumb > .col-block h4, .row-block--icon-thumb > .col-block p {
    margin-left: 45px;
    font-size: 17px !important;
  }
}

.articles-page .next-link {
  display: block;
  margin: 2.5rem auto 0;
  text-align: center;
  flex: 1 1 100%;
  max-width: 100%;
}

.articles-page .next-link a {
  display: inline-block;
  border: 1px solid #00ADEF;
  background: #00ADEF;
  padding: 15px 40px;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  letter-spacing: 2px;
  text-decoration: none;
  word-spacing: nowrap;
  white-space: nowrap;
  box-shadow: 0 8px 20px 0 rgba(30, 50, 62, 0.2);
}

@media (max-width: 549px) {
  .articles-page .next-link a {
    font-size: 9px;
    padding: 11px 30px;
  }
}

.articles-page .next-link a svg {
  fill: currentColor;
  width: 1.5em;
  height: 1.5em;
  margin: -2px 0 0 5px;
}

.articles-page .next-link a:hover {
  background: #00ADEF;
  color: #fff;
}

.articles-page .next-link a:hover svg {
  fill: #fff;
}

.row-block .next-link {
  margin-bottom: 10px;
  margin-top: 20px;
}

.row-block--image {
  padding-left: 2em;
  padding-right: 2em;
  line-height: 1.5;
  align-items: center;
  background: #fff;
  flex-wrap: nowrap;
}

@media (max-width: 999px) {
  .row-block--image {
    display: block;
  }
}

.row-block--image figure a {
  display: block;
}

.row-block--image figure a img {
  width: 100%;
}

@media (min-width: 1000px) {
  .row-block--image [class*='--image'] {
    flex: 1 1 50%;
    max-width: 50%;
  }
  .row-block--image [class*='--image']:last-child {
    padding-right: 2.5rem;
  }
  .row-block--image [class*='--image']:first-child {
    padding-left: 2.5rem;
  }
}

.list-check {
  list-style-type: none;
  margin: 0;
}

@media (min-width: 767px) {
  .list-check {
    display: flex;
    flex-wrap: wrap;
  }
}

.list-check > li {
  position: relative;
  margin: 10px 0 0 0;
  padding: 2.5px 0 5px 40px;
}

@media (min-width: 767px) {
  .list-check > li {
    padding: 4px 45px 5px;
  }
}

.list-check > li:before {
  -webkit-filter: grayscale(100%) brightness(500%) brightness(40%);
  filter: grayscale(100%) brightness(500%) brightness(40%);
  content: '';
  display: block;
  position: absolute;
  top: -5px;
  left: 0;
  width: 40px;
  height: 40px;
  background: url("../assets/svg/Icons/Tick Circle.svg") no-repeat top left;
  background-size: contain;
  -webkit-background-size: contain;
  background-repeat: no-repeat;
  color: '';
  -webkit-filter: invert(77%) sepia(10%) saturate(304%) hue-rotate(173deg) brightness(92%) contrast(88%);
  filter: invert(77%) sepia(10%) saturate(304%) hue-rotate(173deg) brightness(92%) contrast(88%);
}

@media (max-width: 1099px) {
  .list-check > li:before {
    width: 34px;
    height: 34px;
  }
}

@media (min-width: 767px) {
  .list-check > li {
    flex: 0 1 50%;
  }
}

.row-block--2column .video-content {
  font-size: 0.45em;
}

.article-header-logo {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  max-width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.article-header-logo__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.article-header-logo__background:before {
  content: '';
  position: absolute;
  width: calc(100% + 40px);
  min-height: 200%;
  background-color: #fff;
  transform: skew(25deg);
  right: -70px;
  top: -50%;
}

@media (min-width: 1100px) {
  .article-header-logo {
    max-width: 260px;
  }
}

@media (max-width: 766px) {
  .article-header-logo picture {
    text-align: center;
    padding-left: 25px;
  }
}

.article-header-client-logo {
  position: relative;
  max-width: 42px;
  z-index: 1;
  transform: translateX(60%);
}

@media (min-width: 1100px) {
  .article-header-client-logo {
    max-width: 140px;
    max-height: 90px;
    width: auto;
    transform: translateX(20%);
  }
}

.steps-block,
.article-thumbnails {
  margin: 0 -8px;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  counter-reset: thumbnailCount;
}

.steps-block > li,
.article-thumbnails > li {
  display: flex;
  margin: 0;
  padding: 8px;
  flex: 1 1 50%;
  max-width: 50%;
  counter-increment: thumbnailCount;
}

@media (min-width: 1000px) {
  .steps-block > li,
  .article-thumbnails > li {
    flex: 1 1 33.3333%;
    max-width: 33.3333%;
  }
}

@media (min-width: 1250px) {
  .steps-block > li,
  .article-thumbnails > li {
    flex: 1 1 25%;
    max-width: 25%;
  }
}

.steps-block > li.disabled,
.article-thumbnails > li.disabled {
  opacity: 0.4;
}

.steps-block > li.disabled a,
.steps-block > li.disabled .thumbnail-footer .icon,
.article-thumbnails > li.disabled a,
.article-thumbnails > li.disabled .thumbnail-footer .icon {
  display: none;
}

.steps-block > li article,
.article-thumbnails > li article {
  position: relative;
  width: 100%;
  flex-grow: 1;
  padding: 20px 30px 50px;
  background: #fff;
  color: #262626;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
}

.steps-block > li article h4,
.article-thumbnails > li article h4 {
  position: relative;
  margin: 10px 0;
  padding: 0 0 0 25px;
  font-family: inherit;
  font-size: 17px;
}

@media (min-width: 550px) {
  .steps-block > li article h4,
  .article-thumbnails > li article h4 {
    font-size: 20px;
  }
}

.steps-block > li article h4:before,
.article-thumbnails > li article h4:before {
  content: counter(thumbnailCount) ". ";
  position: absolute;
  top: 0;
  left: 0;
}

.steps-block > li article > a,
.article-thumbnails > li article > a {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
}

.steps-block > li article > .icon,
.article-thumbnails > li article > .icon {
  font-weight: bold;
  font-size: 28px;
  color: #00ADEF;
  text-transform: uppercase;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  min-height: 42px;
  display: flex;
  align-items: center;
}

.steps-block > li article > .icon svg,
.article-thumbnails > li article > .icon svg {
  width: 42px;
  height: 42px;
  fill: #00ADEF;
}

@media (min-width: 550px) {
  .steps-block > li article > .icon,
  .article-thumbnails > li article > .icon {
    font-size: 40px;
    min-height: 64px;
  }
  .steps-block > li article > .icon svg,
  .article-thumbnails > li article > .icon svg {
    width: 64px;
    height: 64px;
  }
}

.steps-block > li article table,
.article-thumbnails > li article table {
  margin: 0 0 0 17px;
  table-layout: unset;
  font-family: inherit;
}

@media (max-width: 766px) {
  .steps-block > li article table,
  .article-thumbnails > li article table {
    margin: 0;
  }
}

.steps-block > li article table tr,
.article-thumbnails > li article table tr {
  border: none;
}

.steps-block > li article table tr th,
.article-thumbnails > li article table tr th {
  font-weight: bold;
}

.steps-block > li article table tr th, .steps-block > li article table tr td,
.article-thumbnails > li article table tr th,
.article-thumbnails > li article table tr td {
  padding: 3px 10px;
  font-size: 11px;
  font-family: inherit;
  border: none;
  vertical-align: top;
}

@media (min-width: 550px) {
  .steps-block > li article table tr th, .steps-block > li article table tr td,
  .article-thumbnails > li article table tr th,
  .article-thumbnails > li article table tr td {
    font-size: 13px;
  }
}

.steps-block > li article .thumbnail-footer,
.article-thumbnails > li article .thumbnail-footer {
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
}

.steps-block > li article .thumbnail-footer .status,
.article-thumbnails > li article .thumbnail-footer .status {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  background: #B1B1B1;
  font-weight: bold;
  color: #fff;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.steps-block > li article .thumbnail-footer .icon,
.article-thumbnails > li article .thumbnail-footer .icon {
  margin-left: auto;
}

.steps-block > li article .thumbnail-footer .icon svg,
.article-thumbnails > li article .thumbnail-footer .icon svg {
  fill: #DDE0E5;
  width: 40px;
  height: 40px;
}

@media (max-width: 1099px) {
  .steps-block > li article .thumbnail-footer .icon svg,
  .article-thumbnails > li article .thumbnail-footer .icon svg {
    width: 34px;
    height: 34px;
  }
}

.row-accordion {
  position: relative;
  margin: 0;
  border-top: 1px solid #DDE0E5;
  border-bottom: 1px solid #DDE0E5;
  background-color: white;
}

.row-accordion .accordion-head {
  position: relative;
  display: flex;
  min-height: 66px;
  align-items: center;
  -webkit-font-smoothing: subpixel-antialiased;
  cursor: pointer;
  z-index: 1;
  font-weight: 600;
}

@media (max-width: 549px) {
  .row-accordion .accordion-head {
    font-size: 18px;
  }
}

.row-accordion .accordion-head .icon-label {
  margin-right: 12px;
  width: 64px;
  height: 64px;
  color: '';
  -webkit-filter: invert(77%) sepia(10%) saturate(304%) hue-rotate(173deg) brightness(92%) contrast(88%);
  filter: invert(77%) sepia(10%) saturate(304%) hue-rotate(173deg) brightness(92%) contrast(88%);
}

@media (max-width: 549px) {
  .row-accordion .accordion-head .icon-label {
    display: none;
  }
}

.row-accordion .accordion-head .icon-arrow {
  margin-left: auto;
  width: 40px;
  height: 40px;
  color: '';
  -webkit-filter: invert(77%) sepia(10%) saturate(304%) hue-rotate(173deg) brightness(92%) contrast(88%);
  filter: invert(77%) sepia(10%) saturate(304%) hue-rotate(173deg) brightness(92%) contrast(88%);
}

@media (max-width: 1099px) {
  .row-accordion .accordion-head .icon-arrow {
    width: 34px;
    height: 34px;
  }
}

@media (min-width: 550px) {
  .row-accordion .accordion-head .icon-arrow {
    margin-top: -2px;
  }
}

.row-accordion .accordion-head.active .icon-arrow {
  transform: rotate(180deg);
}

.row-accordion .accordion-body {
  position: relative;
  z-index: 1;
  padding-bottom: 30px;
}

.row-accordion .accordion-body h3 {
  font-size: 23px;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  padding-bottom: 0;
}

.row-accordion .accordion-body h3 + h3 {
  padding-top: 0;
}

@media (max-width: 766px) {
  .responsive-table {
    display: block;
  }
  .responsive-table tbody, .responsive-table tr, .responsive-table th, .responsive-table td {
    display: inherit;
  }
}

@media (max-width: 766px) {
  .row-block--2column {
    flex-wrap: wrap;
  }
  .row-block--2column > .col-block {
    width: 100%;
  }
}

@media (min-width: 1000px) {
  .col-block--title-icon {
    padding-right: 3rem !important;
    padding-left: 6rem !important;
  }
}

.col-block--title-icon h4 {
  display: block;
  padding-top: 0 !important;
  font-size: 17px !important;
}

@media (min-width: 767px) {
  .col-block--title-icon h4 {
    font-size: 20px !important;
    margin-top: 1em;
  }
}

@media (max-width: 766px) {
  .col-block--title-icon h4 {
    margin-top: 1em;
  }
}

.col-block--title-icon h4 svg {
  color: '';
  -webkit-filter: invert(77%) sepia(10%) saturate(304%) hue-rotate(173deg) brightness(92%) contrast(88%);
  filter: invert(77%) sepia(10%) saturate(304%) hue-rotate(173deg) brightness(92%) contrast(88%);
  width: 48px;
  height: 48px;
  position: absolute;
  right: calc(100% + 0.25rem);
  top: -0.0125rem;
  flex-shrink: 0;
}

@media (min-width: 1000px) {
  .col-block--title-icon h4 svg {
    width: 52px;
    height: 52px;
  }
}

.col-block--title-icon h4 em {
  display: block;
  position: relative;
  top: -0.75em;
}

@media (max-width: 766px) {
  .col-block--title-icon h4 {
    left: 2.5em;
    width: calc(100% - 2.5em);
  }
}

@media (min-width: 767px) {
  .row-block--image .col-block--title-icon {
    padding-left: 4rem !important;
  }
}

.row-block--image .col-block--title-icon h4 svg {
  color: '';
  -webkit-filter: invert(77%) sepia(10%) saturate(304%) hue-rotate(173deg) brightness(92%) contrast(88%);
  filter: invert(77%) sepia(10%) saturate(304%) hue-rotate(173deg) brightness(92%) contrast(88%);
  width: 48px;
  height: 48px;
  position: absolute;
  right: calc(100% + 0.25rem);
  top: -0.0125rem;
  flex-shrink: 0;
}

@media (min-width: 1000px) {
  .row-block--image .col-block--title-icon h4 svg {
    width: 52px;
    height: 52px;
  }
}

.process-block, .process-block--faqs {
  margin-top: 0;
  margin-left: 0;
  list-style-type: none;
  padding-bottom: 0;
}

.process-block:not([class*="--faqs"]), .process-block--faqs:not([class*="--faqs"]) {
  background-color: white;
}

.process-block:not([class*="--faqs"]) .process-item__head, .process-block--faqs:not([class*="--faqs"]) .process-item__head {
  min-height: 66px;
}

.process-block:not([class*="--faqs"]) .process-item__title:first-child, .process-block--faqs:not([class*="--faqs"]) .process-item__title:first-child {
  margin-left: 20px;
}

@media (min-width: 1100px) {
  .process-block:not([class*="--faqs"]) .process-item__title:first-child, .process-block--faqs:not([class*="--faqs"]) .process-item__title:first-child {
    margin-left: 25px;
  }
}

.process-item {
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #DDE0E5;
}

.process-block--faqs .process-item {
  border: none !important;
}

.process-item h3 {
  margin: 0 !important;
}

.process-item:first-child {
  border-top: 1px solid #DDE0E5;
}

.process-item__head {
  cursor: pointer;
}

.process-item__icon {
  margin: 0 5px;
  width: 48px;
  height: 48px;
  color: '';
  -webkit-filter: invert(77%) sepia(10%) saturate(304%) hue-rotate(173deg) brightness(92%) contrast(88%);
  filter: invert(77%) sepia(10%) saturate(304%) hue-rotate(173deg) brightness(92%) contrast(88%);
}

@media (min-width: 1100px) {
  .process-item__icon {
    margin: 0 10px;
    width: 60px;
    height: 60px;
  }
}

.process-item__title {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
}

.process-item__title-icon {
  margin-left: 10px;
  color: '';
  -webkit-filter: invert(77%) sepia(10%) saturate(304%) hue-rotate(173deg) brightness(92%) contrast(88%);
  filter: invert(77%) sepia(10%) saturate(304%) hue-rotate(173deg) brightness(92%) contrast(88%);
  width: 32px;
  height: 32px;
}

@media (min-width: 1100px) {
  .process-item__title {
    font-size: 17px;
  }
  .process-item__title-icon {
    margin-left: 15px;
    width: 42px;
    height: 42px;
  }
}

.process-item__btn {
  display: block;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex: 0 0 50px;
}

.process-block--faqs .process-item__btn {
  background: none;
  border: none;
  width: 44px;
  height: 44px;
  flex: 0 0 44px;
}

@media (min-width: 1000px) {
  .process-block--faqs .process-item__btn {
    width: 50px;
    height: 50px;
    flex: 0 0 50px;
  }
}

@media (min-width: 1000px) {
  .process-item__btn {
    width: 64px;
    height: 64px;
    flex: 0 0 64px;
  }
}

.process-item__btn:hover {
  border-color: #e1e1e1;
}

.process-item__btn-icon {
  transform-origin: center;
  width: 40px;
  height: 40px;
  color: '';
  -webkit-filter: invert(77%) sepia(10%) saturate(304%) hue-rotate(173deg) brightness(92%) contrast(88%);
  filter: invert(77%) sepia(10%) saturate(304%) hue-rotate(173deg) brightness(92%) contrast(88%);
}

@media (max-width: 1099px) {
  .process-item__btn-icon {
    width: 34px;
    height: 34px;
  }
}

.active > .process-item__head .process-item__btn-icon {
  transform: rotate(180deg);
}

.process-item__head {
  display: flex;
  align-items: center;
}

@media (min-width: 1000px) {
  .process-item__head {
    align-items: center;
  }
}

.process-item__head > strong, .process-item__head b {
  font-weight: 600;
}

@media (max-width: 999px) {
  .process-item__head > strong, .process-item__head b {
    align-self: center;
    font-size: 15px;
  }
}

.process-item__body {
  display: none;
  padding-right: 20px;
  padding-left: 20px;
}

.process-block--faqs .process-item__body {
  margin-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

@media (min-width: 1100px) {
  .process-item__body {
    padding-left: 3.5em;
    padding-right: 3.5em;
  }
}

.process-item__body__footer {
  width: 100%;
  margin: .5rem 0 .5rem;
  align-items: center;
  justify-content: center;
  display: flex;
  border-bottom: 1px solid #B1B1B1;
}

.process-item__body__footer-btn {
  align-items: center;
  justify-content: center;
  display: flex;
  color: #B1B1B1;
  font-size: 14px;
}

.process-item__body__footer-btn svg {
  fill: #B1B1B1;
}

.active > .process-item__body {
  display: block;
}

.process-item__body .process-block {
  margin: 0;
}

.steps-block > li article {
  padding-top: 25px;
  padding-bottom: 25px;
}

@media (max-width: 999px) {
  .steps-block > li {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

.steps-block > li:not(:last-child) article:before {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  background: #fff;
  position: absolute;
  top: 50%;
  right: -10px;
  margin-top: -10px;
  transform: rotate(45deg);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}

@media (max-width: 999px) {
  .steps-block > li:not(:last-child) article:before {
    right: 50%;
    top: auto;
    bottom: -8px;
    width: 16px;
    height: 16px;
  }
}

.steps-block > li:not(:last-child) article:after {
  content: '';
  display: block;
  width: 25px;
  height: 100%;
  background: #fff;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

@media (max-width: 999px) {
  .steps-block > li:not(:last-child) article:after {
    height: 25px;
    width: 100%;
    top: auto;
    bottom: 0;
  }
}

.steps-block .step-info {
  padding-left: 25px;
  font-size: 11px;
}

@media (min-width: 1000px) {
  .steps-block .step-info {
    font-size: 13px;
  }
}

@media (max-width: 999px) {
  .steps-block .step-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content max-content max-content;
    column-gap: 1em;
    grid-template-areas: "description description" "label1 label2" "content1 content2";
  }
}

.steps-block .step-info p {
  margin-bottom: 10px;
}

.steps-block .step-info p:last-of-type {
  grid-area: content2;
}

.steps-block .step-info .step-intro {
  grid-area: description;
  margin: 0 0 20px;
}

@media (min-width: 1000px) {
  .steps-block .step-info .step-intro {
    margin-bottom: 0;
    padding: 0 0 10px;
    min-height: 70px;
  }
}

.steps-block .step-info h6 {
  font-size: 1em;
  font-weight: 600;
}

@media (max-width: 999px) {
  .steps-block .step-info h6 {
    display: block;
    color: #AAB3BE;
    font-size: 11px !important;
    line-height: 14px;
    font-weight: 600;
    letter-spacing: 1.82px;
    font-style: normal;
    text-transform: uppercase;
  }
}

.steps-block .step-info h6:last-of-type {
  grid-area: label2;
}

h4 a {
  color: inherit;
}

.note {
  font-size: 13px;
  color: #38444B;
}

.note p {
  margin: 0 0 15px;
  color: inherit;
}

.note--box {
  padding: 30px 30px 25px;
  border: 1px solid #E1E1E1;
  background-color: #EEF0F2;
  box-shadow: 0 2px 6px 0 rgba(56, 68, 75, 0.1);
  color: #38444B;
}

.note__title {
  display: flex;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
}

.note__title svg {
  margin-top: -10px;
  margin-left: -10px;
  margin-right: 5px;
  width: 38px;
  height: 38px;
  fill: #B1B1B1;
}

.note__content ul {
  margin: 0 !important;
  padding: 0;
}

.articles-page ol {
  margin-left: 0;
}

.articles-page ol li {
  padding-left: 5px;
  padding-bottom: 15px;
}

@media (max-width: 999px) {
  .articles-main .row-block--overview .row-title {
    margin-bottom: 0;
    margin-left: -1.325em;
  }
  .articles-main .row-block--overview .row-title + [class*="--icon-thumb"] {
    padding-left: 1em;
  }
}

.row-title {
  display: flex;
  margin: 2px 0 1rem;
}

.row-title svg {
  fill: #00ADEF;
  width: 48px;
  height: 48px;
  margin-left: -8px;
}

@media (min-width: 1000px) {
  .row-title svg {
    width: 64px;
    height: 64px;
  }
}

.row-title h3 {
  margin-bottom: 0 !important;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0.25px;
}

.row-readtime {
  display: flex;
  font-size: 1rem;
  align-items: center;
}

.row-readtime strong {
  font-weight: 600;
  margin: 0 0.75rem 0 0.325rem;
}

.row-readtime svg {
  fill: #AAB3BE;
  width: 40px;
  height: 40px;
  margin-left: -4px;
}

@media (max-width: 1099px) {
  .row-readtime svg {
    width: 34px;
    height: 34px;
  }
}

.article-body ul[class="ng-scope"],
ul.list-content {
  margin-left: 0 !important;
}

.article-body ul[class="ng-scope"] > li,
ul.list-content > li {
  position: relative;
  display: block;
  margin-left: 0;
  padding-left: 1.875rem;
}

.article-body ul[class="ng-scope"] > li:before,
ul.list-content > li:before {
  content: '';
  display: block;
  width: 4px;
  height: 4px;
  position: absolute;
  top: 0.4875rem;
  left: 0.5rem;
  background: #38444B;
  border-radius: 50%;
}

.row-block--gutter {
  background-color: white;
}

@media (max-width: 1099px) {
  .row-block--gutter table {
    margin-bottom: 0;
  }
  .row-block--gutter table tr {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.75rem;
  }
  .row-block--gutter table tr td, .row-block--gutter table tr th {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.login-container {
  background: linear-gradient(#7448FF, #2D363C);
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
}

.login-container .login-msg {
  text-align: center;
  color: #efefef;
  font-size: 1rem;
  line-height: 1.4;
}

.login-container main {
  height: 100%;
}

.login-container .logo {
  display: block;
  text-align: center;
}

@media (max-width: 766px) {
  .login-container .logo {
    margin-bottom: 2rem;
  }
}

.login-container .logo > span {
  font-size: 2.5rem;
  display: block;
  margin-bottom: 1em;
}

.login-container .logo img.logo-icon {
  width: 10rem;
  margin: 0.1rem 0;
}

@media (min-width: 767px) {
  .login-container .logo img.logo-icon {
    margin: 0 0 2.75rem 0;
    max-height: 125px;
    height: 100%;
    width: auto;
  }
}

.login-container .logo img.logo-icon[src^="/assets/"] {
  max-width: 140px;
  width: 100%;
}

.login-container h2 {
  margin-top: 40px;
  color: white;
}

.login-container h3 {
  padding: 1.25rem 0 0.8rem;
  color: white;
}

.login-container .btn-group {
  margin-bottom: 1rem;
}

.login-container .btn-submit {
  letter-spacing: 2px;
  line-height: 1.25;
  padding-top: 1.25rem;
  padding-bottom: 1.125rem;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  position: relative;
  z-index: 99;
}

.login-container .disclaimer {
  margin-top: 1rem;
  text-align: center;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.75);
}

.login-background-brand {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 100%;
  background: no-repeat 0 0 #000;
  background-size: 100%;
  opacity: 0.2;
  background-size: cover;
}

.login-form {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  z-index: 2;
  padding: 0 !important;
  max-width: 460px;
  width: 100%;
  height: 100%;
}

@media (min-width: 350px) {
  .login-form {
    height: auto;
  }
}

.login-form .logo-icon {
  padding: 0 1rem;
}

.login-form input {
  border: none;
  background-color: rgba(255, 255, 255, 0.15);
}

.login-form .form-row {
  position: relative;
}

@media (min-width: 767px) {
  .login-form .form-row {
    margin: 0.5rem 0 1.125rem;
  }
}

.login-form .form-row--space-between {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 767px) {
  .login-form .form-row--space-between {
    margin-top: -0.5rem;
  }
}

.login-form .form-row input[type='text'],
.login-form .form-row input[type='password'],
.login-form .form-row input[type='email'] {
  padding-left: 60px;
  color: white;
}

.login-form .form-row input[type='text']::-webkit-input-placeholder,
.login-form .form-row input[type='password']::-webkit-input-placeholder,
.login-form .form-row input[type='email']::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.75);
}

.login-form .form-row input[type='text']:-moz-placeholder,
.login-form .form-row input[type='password']:-moz-placeholder,
.login-form .form-row input[type='email']:-moz-placeholder {
  color: rgba(255, 255, 255, 0.75);
}

.login-form .form-row input[type='text']::-moz-placeholder,
.login-form .form-row input[type='password']::-moz-placeholder,
.login-form .form-row input[type='email']::-moz-placeholder {
  color: rgba(255, 255, 255, 0.75);
}

.login-form .form-row input[type='text']:-ms-input-placeholder,
.login-form .form-row input[type='password']:-ms-input-placeholder,
.login-form .form-row input[type='email']:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.75);
}

.login-form .form-row input[type='text']:placeholder,
.login-form .form-row input[type='password']:placeholder,
.login-form .form-row input[type='email']:placeholder {
  color: rgba(255, 255, 255, 0.75);
}

.login-form .form-row input[type='text']:focus,
.login-form .form-row input[type='password']:focus,
.login-form .form-row input[type='email']:focus {
  color: #333;
}

.login-form .form-row input[type='text']:focus + svg,
.login-form .form-row input[type='password']:focus + svg,
.login-form .form-row input[type='email']:focus + svg {
  fill: #000;
}

.login-form .form-row svg {
  position: absolute;
  fill: white;
  opacity: 0.5;
  transition: fill 0.25s ease;
  top: 50%;
  left: 12px;
  width: 38px;
  height: 38px;
  margin-top: -19px;
}

.login-form label.checkbox {
  margin: 0;
  color: rgba(255, 255, 255, 0.6);
  font-weight: bold;
  font-size: 13px;
}

.login-form label.checkbox:before, .login-form label.checkbox:after {
  -webkit-filter: invert(0.9);
  -ms-filter: invert(0.9);
  filter: invert(0.9);
}

.login-form input.checkbox + label:before {
  border-radius: 2px;
  border: 1px solid rgba(255, 255, 255, 0);
}

.login-form label,
.login-form a {
  color: white;
}

.login-standard {
  padding: 0 7%;
  width: 100%;
}

.login-standard p.ip-warning {
  font-size: 0.75rem;
  margin: 0rem 0 0.75rem;
  color: rgba(255, 255, 255, 0.9);
}

@media (min-width: 767px) {
  .login-standard {
    padding: 0 14.675%;
  }
  .login-standard p.ip-warning {
    font-size: 14px;
  }
}

a.forgot-link {
  display: inline-block;
  padding: 0.2rem 0;
  vertical-align: middle;
  font-size: 13px;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  font-weight: 600;
  text-decoration: none;
  color: white;
  opacity: 0.7;
}

a.forgot-link:hover {
  color: white;
  opacity: 1;
}

.form-loader:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: white;
  z-index: 100;
  transition: opacity 0.3s linear;
  visibility: hidden;
}

.form-loader.form-loading:before {
  visibility: visible;
  opacity: 0.4;
}

.login-form-footer footer {
  text-align: center;
  font-size: 12px;
  color: white;
  position: fixed;
  bottom: 0;
  max-width: 420px;
  width: 100%;
  padding-bottom: 0.75rem;
  display: flex;
  align-items: flex-end;
  left: 50%;
  transform: translateX(-50%);
  justify-content: space-between;
  opacity: 1;
  height: auto;
}

@media (max-width: 766px) {
  .login-form-footer footer {
    padding-right: 7%;
    padding-left: 7%;
  }
}

.login-form-footer footer.position--static {
  position: relative;
  max-width: unset;
  padding-bottom: 0;
}

.login-form-footer footer a {
  display: inline-block;
  margin-bottom: 0.9rem;
  cursor: pointer;
  font-size: 8.25px;
}

@media (min-width: 767px) {
  .login-form-footer footer a {
    font-size: 10px;
  }
}

.login-form-footer footer .footer-logo a img {
  max-width: 2.5rem;
  width: 100%;
  max-height: initial;
}

.login-form-footer footer .footer-logo .poweredby {
  margin: 0;
}

.footer-link {
  display: none;
}

.footer-logo > a img {
  display: none;
}

@media (min-width: 767px) {
  .visible-sm {
    display: none;
  }
}

@media (max-width: 766px) {
  .visible-lg {
    display: none;
  }
  .form-loader .form-row:last-child {
    margin-top: 1rem;
    margin-bottom: 0.75rem;
  }
}

.supported-browsers {
  list-style: none;
  margin: 10px 0 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.supported-browsers > li {
  margin: 0;
  padding: 0 5px;
}

.supported-browsers > li > img {
  max-width: 60px;
}

sidebar-component {
  height: 100%;
}

.sidebar-content > ul > li a > .svg-icon, .sidebar-content > ul > li li.cat-back > .svg-icon, .sidebar-footer > ul > li a > .svg-icon, .sidebar-footer > ul > li li.cat-back > .svg-icon, .sidebar-content > ul.main-links > li > a svg,
.sidebar-footer > ul.main-links > li > a svg {
  width: 36px;
  height: 36px;
  fill: currentColor;
}

.sidebar-content > ul > li a,
.sidebar-content > ul > li li.cat-back,
.sidebar-footer > ul > li a,
.sidebar-footer > ul > li li.cat-back {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  color: #B1B1B1;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.15;
  cursor: pointer;
}

.sidebar-content > ul > li a > .svg-icon, .sidebar-content > ul > li li.cat-back > .svg-icon, .sidebar-footer > ul > li a > .svg-icon, .sidebar-footer > ul > li li.cat-back > .svg-icon {
  margin: 0 6px 0 0;
}

.sidebar-content > ul.main-links .selected-item > a,
.sidebar-footer > ul.main-links .selected-item > a, .sidebar-content > ul > li.active > a,
.sidebar-footer > ul > li.active > a {
  background-color: #22292D;
  border-left: 3px solid #00ADEF;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
}

.sidebar-content > ul.main-links .selected-item > a > .svg-icon, .sidebar-footer > ul.main-links .selected-item > a > .svg-icon, .sidebar-content > ul > li.active > a > .svg-icon, .sidebar-footer > ul > li.active > a > .svg-icon {
  fill: #00ADEF;
}

.sidebar-content > ul.main-links .selected-item > a + ul > li.active a, .sidebar-footer > ul.main-links .selected-item > a + ul > li.active a, .sidebar-content > ul > li.active > a + ul > li.active a, .sidebar-footer > ul > li.active > a + ul > li.active a {
  color: #00ADEF;
}

.sidebar {
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  background-color: #2D363C;
  width: 340px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.sidebar:before {
  content: '';
  width: 72px;
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  z-index: 1;
  height: 100%;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.4);
}

.sidebar p {
  margin: 0;
}

.sidebar .header {
  height: 60px;
  width: 100%;
  top: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 9;
  position: absolute;
  padding: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1100px) {
  .sidebar .header {
    padding-left: 72px;
  }
}

.sidebar .header .sidebar-logo {
  height: 95%;
  width: 7rem;
  max-height: 7rem;
  padding: 0.25rem;
}

.sidebar .header .sidebar-logo img {
  height: 100%;
  width: 100%;
}

.sidebar .header .close-menu-button,
.sidebar .header .cart-button,
.sidebar .header .logout-button,
.sidebar .header .account-button {
  color: #B1B1B1;
  width: 55px;
  position: absolute;
  font-size: 18px;
  font-weight: normal;
  right: 0px;
  opacity: 0.6;
  top: 0;
  line-height: 60px;
  display: block;
  border-left: 1px solid rgba(0, 0, 0, 0.4);
  height: 100%;
  vertical-align: middle;
  text-align: center;
}

.sidebar .header .close-menu-button span,
.sidebar .header .cart-button span,
.sidebar .header .logout-button span,
.sidebar .header .account-button span {
  display: inline-block;
  max-width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

.sidebar .header .close-menu-button {
  cursor: pointer;
  z-index: 1;
}

.sidebar .header .close-menu-button:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  display: inline-block;
  margin-top: -3px;
}

.sidebar .header .close-menu-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  opacity: 1;
}

.sidebar .header .logout-button {
  right: 50px;
  padding-left: 2px;
  z-index: 1;
}

.sidebar .header .logout-button:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-size: 1.2em;
}

.sidebar .header .account-button {
  display: inline-block;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  text-align: left;
  padding-left: 20px;
  width: 100%;
  color: #B1B1B1;
}

.sidebar .header .account-button:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-size: 27px;
  margin: 15px 8px 0 0;
  float: left;
}

.sidebar .header .account-button:hover {
  background-color: transparent;
  cursor: default;
}

.sidebar ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sidebar li {
  margin: 0;
  padding: 0;
  display: block;
}

.sidebar li.disabled-item {
  pointer-events: none;
  opacity: 0.5;
}

.sidebar span {
  color: #B1B1B1;
  position: relative;
}

@media (min-width: 1100px) {
  .sidebar li a:hover {
    color: #00ADEF !important;
  }
  .sidebar li a:hover .svg-icon {
    fill: #00ADEF !important;
  }
}

@media (max-width: 1099px) {
  .sidebar {
    display: none !important;
  }
}

.sidebar-content,
.sidebar-footer {
  position: absolute;
  top: 60px;
  left: 0;
  height: calc(100% - 60px);
  overflow: hidden;
  overflow-y: auto;
  padding: 0;
  transition: transform 0.3s linear;
  width: 340px;
  transform: translateX(0);
}

.sidebar-content h5,
.sidebar-footer h5 {
  display: none;
  width: 100%;
  padding: 0;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  font-weight: 700;
  color: #B1B1B1;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 19px;
  margin: 0;
}

.sidebar-content h5 a,
.sidebar-content h5 span,
.sidebar-footer h5 a,
.sidebar-footer h5 span {
  color: #B1B1B1;
  display: block;
  padding: 0.75em 0.4em 0.7em 1.1em;
}

.sidebar-content h5 a:hover,
.sidebar-footer h5 a:hover {
  background-color: #000;
}

.sidebar-content h5 i,
.sidebar-footer h5 i {
  font-size: 1em;
  margin-right: 5px;
  line-height: 1.1;
}

.sidebar-content > ul.main-links,
.sidebar-footer > ul.main-links {
  width: 72px;
  padding: 0;
  position: fixed;
  left: 0;
  z-index: 2;
  height: 100%;
}

.sidebar-content > ul.main-links .submenu--templates:not(.selected-item) > a,
.sidebar-footer > ul.main-links .submenu--templates:not(.selected-item) > a {
  background: #00ADEF;
  color: #fff;
}

.sidebar-content > ul.main-links .submenu--templates:not(.selected-item) > a:hover,
.sidebar-footer > ul.main-links .submenu--templates:not(.selected-item) > a:hover {
  color: #fff !important;
}

.sidebar-content > ul.main-links .submenu--templates:not(.selected-item) > a svg,
.sidebar-footer > ul.main-links .submenu--templates:not(.selected-item) > a svg {
  fill: currentColor !important;
}

.sidebar-content > ul.main-links > li.channel-leadership > a > span .nav-name,
.sidebar-footer > ul.main-links > li.channel-leadership > a > span .nav-name {
  font-size: .65em;
}

.sidebar-content > ul.main-links > li.channel-capability > a > span .nav-name,
.sidebar-footer > ul.main-links > li.channel-capability > a > span .nav-name {
  font-size: .65em;
}

.sidebar-content > ul.main-links > li,
.sidebar-footer > ul.main-links > li {
  padding: 0;
}

.sidebar-content > ul.main-links > li.selected-item > a span,
.sidebar-footer > ul.main-links > li.selected-item > a span {
  color: #00ADEF;
}

.sidebar-content > ul.main-links > li.selected-item > ul > li > a,
.sidebar-footer > ul.main-links > li.selected-item > ul > li > a {
  color: white;
}

.sidebar-content > ul.main-links > li > a,
.sidebar-footer > ul.main-links > li > a {
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  height: 54px;
  padding: 0;
  z-index: 1;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  justify-content: center;
}

.sidebar-content > ul.main-links > li > a.locked,
.sidebar-footer > ul.main-links > li > a.locked {
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0.375;
}

.sidebar-content > ul.main-links > li > a.locked .svg-icon,
.sidebar-footer > ul.main-links > li > a.locked .svg-icon {
  width: 28px;
  height: 28px;
  margin: -5px 0 0;
}

.sidebar-content > ul.main-links > li > a.locked span,
.sidebar-footer > ul.main-links > li > a.locked span {
  position: relative;
  top: unset;
  left: unset;
  transform: unset;
  margin-top: 5px;
}

.sidebar-content > ul.main-links > li > a > span,
.sidebar-footer > ul.main-links > li > a > span {
  font-weight: 600;
}

.sidebar-content > ul.main-links > li > a > span .nav-name,
.sidebar-footer > ul.main-links > li > a > span .nav-name {
  display: block;
  font-size: .8em;
}

.sidebar-content > ul.main-links > li > a > span .channel-navnumber,
.sidebar-footer > ul.main-links > li > a > span .channel-navnumber {
  font-size: 1.25em;
}

.sidebar-content > ul.main-links .selected-item ul,
.sidebar-footer > ul.main-links .selected-item ul {
  display: block;
}

.sidebar-content > ul.main-links .selected-item[class*="submenu--"] > a,
.sidebar-footer > ul.main-links .selected-item[class*="submenu--"] > a {
  font-weight: 600;
  color: #fff !important;
}

.sidebar-content > ul.main-links .selected-item[class*="submenu--"] > a .svg-icon,
.sidebar-footer > ul.main-links .selected-item[class*="submenu--"] > a .svg-icon {
  fill: #00ADEF !important;
}

.sidebar-content > ul.main-links .disabled-item,
.sidebar-footer > ul.main-links .disabled-item {
  pointer-events: none;
  color: #555;
}

.sidebar-content > ul.main-links .disabled-item .svg-icon,
.sidebar-footer > ul.main-links .disabled-item .svg-icon {
  fill: #4c4c4c !important;
}

.sidebar-content > ul.main-links .home-link--active,
.sidebar-footer > ul.main-links .home-link--active {
  background: #00ADEF;
}

.sidebar-content > ul.main-links .home-link--active a,
.sidebar-footer > ul.main-links .home-link--active a {
  color: #fff;
}

.sidebar-content > ul.main-links .home-link--active a:hover,
.sidebar-footer > ul.main-links .home-link--active a:hover {
  color: #fff !important;
}

.sidebar-content > ul.main-links .home-link--active a svg,
.sidebar-footer > ul.main-links .home-link--active a svg {
  fill: currentColor !important;
}

.sidebar-content > ul,
.sidebar-footer > ul {
  width: 340px;
  padding: 0 1px 0 72px;
}

.sidebar-content > ul > li,
.sidebar-footer > ul > li {
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebar-content > ul > li span,
.sidebar-footer > ul > li span {
  display: block;
  padding: 0;
  width: 100%;
  margin: 0;
  color: inherit;
}

.sidebar-content > ul > li span .dropdown,
.sidebar-footer > ul > li span .dropdown {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 18px;
  line-height: 3.1;
  cursor: pointer;
  padding: 0;
  text-align: center;
  border-left: 1px solid #eee;
  vertical-align: middle;
  width: 58px;
  height: 100%;
  display: block;
  text-align: center;
}

.sidebar-content > ul > li span .dropdown:before,
.sidebar-footer > ul > li span .dropdown:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  color: #aaa;
  vertical-align: middle;
  line-height: 1.7;
  margin-right: 3px;
}

.sidebar-content > ul > li span .dropdown:hover,
.sidebar-footer > ul > li span .dropdown:hover {
  background-color: #f0f0f0;
}

.sidebar-content > ul > li li.cat-back,
.sidebar-footer > ul > li li.cat-back {
  background-color: #eee;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
}

.sidebar-content > ul > li.active > ul,
.sidebar-footer > ul > li.active > ul {
  height: 100%;
  z-index: 9999;
}

.sidebar-content > ul > li > ul,
.sidebar-footer > ul > li > ul {
  width: calc(340px - 72px);
  display: none;
  left: 72px;
  position: absolute;
  background-color: #2D363C;
  top: 0;
  padding: 0;
  height: 100%;
}

.MySPS .sidebar-content > ul > li > ul, .MySPS
.sidebar-footer > ul > li > ul {
  display: block;
}

.sidebar-content > ul > li > ul > li > a,
.sidebar-footer > ul > li > ul > li > a {
  height: 54px;
  display: flex;
  align-items: center;
}

.sidebar-content > ul > li > ul li,
.sidebar-footer > ul > li > ul li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebar-content > ul > li > ul li > ul,
.sidebar-footer > ul > li > ul li > ul {
  position: relative;
  left: 0;
}

.sidebar-content > ul > li > ul li > ul > li,
.sidebar-footer > ul > li > ul li > ul > li {
  border: none;
}

.sidebar-content > ul > li > ul li > ul li:first-child,
.sidebar-footer > ul > li > ul li > ul li:first-child {
  padding-top: 8px;
}

.sidebar-content > ul > li > ul li > ul li:last-child,
.sidebar-footer > ul > li > ul li > ul li:last-child {
  padding-bottom: 8px;
}

.sidebar-content > ul > li > ul li > ul a,
.sidebar-footer > ul > li > ul li > ul a {
  height: 36px;
  font-size: 16px;
  letter-spacing: 0.25px;
  padding: 0 10px 0 50px;
  display: flex;
  align-items: center;
  border-left: 3px solid transparent;
}

.sidebar-content > ul > li > ul li.selected-item > ul,
.sidebar-footer > ul > li > ul li.selected-item > ul {
  background: #22292D;
}

.sidebar-footer {
  overflow: visible;
  width: calc(100% - 72px + 1px);
  z-index: 9;
  position: absolute;
  bottom: 0;
  top: auto;
  height: auto;
  /*&>ul>li:last-child {
        box-shadow:.2rem .2rem .2rem rgba(0, 0, 0, .8);
      }*/
  margin-left: 72px;
}

.sidebar-footer > ul > li:first-child {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebar-footer > ul > li > a {
  min-height: 54px;
}

.sidebar-footer > ul {
  width: auto;
  padding-left: 0;
}

.sidebar-pusher--minimal + .sidebar .sidebar-footer {
  margin-left: 0;
}

.sub-cat > .sidebar-content {
  transform: translateX(-340px);
}

.sidebar-footer .dropmenu-holder__menu {
  display: block;
  top: auto;
  position: absolute;
  left: 100%;
  bottom: 0.325rem;
  height: auto;
  z-index: -10;
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
}

.sidebar-footer .dropmenu-holder__menu:before {
  content: '';
  display: block;
  position: absolute;
  width: 1rem;
  height: 1rem;
  background: inherit;
  bottom: 1rem;
  left: -0.5rem;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.5);
  transform-origin: center;
  transform: rotate(45deg);
}

.sidebar-footer .dropmenu-holder__menu li {
  position: relative;
  z-index: 1;
  background: inherit;
}

.sidebar-footer .dropmenu-holder__menu li + li {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebar-footer .dropmenu-holder__menu li a {
  font-size: 16px;
  display: flex;
  align-items: center;
  height: 46px;
}

.sidebar-footer .dropmenu-holder__menu li a.link--disabled {
  pointer-events: none;
  color: #555;
}

.sidebar-footer .dropmenu-holder__menu li a.link--disabled .svg-icon {
  fill: #4c4c4c;
}

.sidebar-footer .dropmenu-holder__menu li a.invert {
  background-color: #22292D;
  color: #fff;
}

.sidebar-footer .dropmenu-holder__menu li a.invert .svg-icon {
  fill: currentColor !important;
}

.dropmenu-open > .dropmenu-holder > .dropmenu-holder__menu,
.myaccount.dropmenu-open .dropmenu-holder__menu {
  left: calc(100% + 1rem);
  opacity: 1;
  visibility: visible;
  z-index: 1;
}

[sidebar-max-height] {
  overflow: auto;
}

[sidebar-max-height]::-webkit-scrollbar {
  width: 5px;
}

[sidebar-max-height]::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: white;
  border-radius: 10px;
}

[sidebar-max-height]::-webkit-scrollbar-thumb {
  background: rgba(0, 173, 239, 0.75);
  border-radius: 10px;
}

.myaccount > a > img.svg-icon {
  border-radius: 50%;
  width: 30px !important;
  height: 30px !important;
}

@media (min-width: 767px) {
  .myaccount > a > img.svg-icon {
    margin: 0 20px 0 10px !important;
  }
}

.sidebar .myaccount > a > img.svg-icon {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 10px;
  margin-top: -15px !important;
}

.sidebar .myaccount > a > img.svg-icon + .svg-icon {
  opacity: 0;
}

.post-delivery-article-navitem {
  font-size: 0.925em;
}

article.article-detail {
  border: 0;
}

article.article-detail .article-body {
  max-width: 950px;
  margin: -2rem auto;
  padding: 5%;
  border: 1px solid #ddd;
}

.videoWrapper,
.responsive-object {
  position: relative;
  padding-bottom: 53.75%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
  overflow: hidden;
}

.videoWrapper > .responsive-object,
.responsive-object > .responsive-object {
  position: unset;
  padding-bottom: 0;
  padding: 0;
  overflow: unset;
  height: unset;
}

.videoWrapper iframe,
.responsive-object iframe,
.responsive-object .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.videoWrapper iframe ~ .video-content,
.responsive-object iframe ~ .video-content,
.responsive-object .content ~ .video-content {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  color: #00ADEF;
  font-weight: bold;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  pointer-events: none;
  background-size: cover;
  padding: 10px;
}

@media only screen and (max-width: 766px) {
  .videoWrapper iframe ~ .video-content,
  .responsive-object iframe ~ .video-content,
  .responsive-object .content ~ .video-content {
    display: none;
  }
}

.videoWrapper iframe ~ .video-content:before,
.responsive-object iframe ~ .video-content:before,
.responsive-object .content ~ .video-content:before {
  -webkit-filter: grayscale(100%) brightness(500%) brightness(40%);
  filter: grayscale(100%) brightness(500%) brightness(40%);
  content: '';
  display: block;
  width: 5rem;
  height: 5rem;
  background: #e65c0b url("../assets/svg/Icons/Do.svg") no-repeat center/2.75rem;
  -webkit-filter: invert(100%);
  filter: invert(100%);
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -2.5rem;
  margin-top: -2.5rem;
}

.videoWrapper iframe ~ .video-content h3,
.responsive-object iframe ~ .video-content h3,
.responsive-object .content ~ .video-content h3 {
  background-color: #333;
  font-size: 0.8em;
  padding: 0.6em 1em;
  line-height: 1.25;
  color: inherit;
  margin-bottom: 0.5em;
  -webkit-font-smoothing: subpixel-antialiased;
}

.videoWrapper iframe ~ .video-content h4,
.responsive-object iframe ~ .video-content h4,
.responsive-object .content ~ .video-content h4 {
  background: #333;
  font-size: 0.9em;
  padding: 1em 1.5em 0.75em;
  line-height: 1.25;
  color: inherit;
  margin-left: 2em;
  display: inline-block;
  max-width: 35%;
  -webkit-font-smoothing: subpixel-antialiased;
}

.videoWrapper.playing .video-content {
  display: none;
}

.articles-page h2 {
  margin-bottom: 1rem;
  border-bottom: 1px solid #AAB3BE;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 26px;
  -webkit-font-smoothing: subpixel-antialiased;
}

@media (max-width: 1099px) {
  .articles-page h2 {
    font-weight: 700;
    font-size: 20px;
  }
}

.articles-page .Rtable-head h2 {
  font-size: 20px;
  font-weight: 600;
  color: #262626;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  border: 0;
}

.articles-page h3 {
  font-size: 24px;
  padding-bottom: 0;
  margin-bottom: 1em;
}

@media (max-width: 1099px) {
  .articles-page h3 {
    font-size: 20px;
  }
}

.articles-page h4 {
  font-size: 18px;
  padding-top: 15px;
  padding-bottom: 0;
  margin-bottom: 0.8em;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
}

@media (max-width: 1099px) {
  .articles-page h4 {
    font-size: 16px;
  }
}

.step-block {
  padding: 1.5rem 2rem;
  background: #fff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.125);
}

@media only screen and (min-width: 767px) {
  .step-block {
    padding: 3rem 4rem;
  }
}

.step-block__title {
  margin-bottom: 20px;
  font-size: 22px;
}

.step-block__detail {
  margin: 0;
  list-style-type: none;
}

.step-block__detail a {
  color: #00ADEF;
  text-decoration: none;
}

.step-block__detail-item {
  display: flex;
  margin: 0;
  padding-bottom: 1rem;
}

.col-block--icon figure svg, .step-block__detail-icon {
  fill: #00ADEF;
  flex: 0 0 48px;
  width: 60px;
  height: 60px;
  margin-top: -16px;
}

.step-block__detail-content {
  padding-left: 20px;
}

.step-block__detail-content h4 {
  font-weight: bold;
  font-size: 1rem;
  color: #333;
  padding: 0;
  margin: 0 0 0.125rem;
}

.step-block__detail-content p {
  line-height: 1.25;
  margin: 0;
}

.step-block__detail-content ul {
  margin: 0;
  list-style-type: disc;
}

.step-block__detail-content ul li {
  padding-bottom: 0;
}

.articles-page .step-block__detail-content h3 {
  padding-top: 0;
  margin-bottom: .3em;
}

.article-body .highlight-text {
  background: yellow;
}

.timeline-block {
  table-layout: auto;
}

.timeline-block thead {
  display: none;
}

.timeline-block thead tr th {
  background: #EEF0F2;
  font-size: 16px;
}

@media (min-width: 550px) {
  .timeline-block thead tr th {
    font-size: 17px;
  }
}

.timeline-block tbody tr th, .timeline-block tbody tr td {
  font-size: 14px;
  padding: 12px 15px 10px;
}

.timeline-block tbody tr th[data-title="Action"], .timeline-block tbody tr td[data-title="Action"] {
  background-color: #eef0f2;
}

.timeline-block tbody tr th:before, .timeline-block tbody tr td:before {
  content: attr(data-title);
  display: block;
  margin-bottom: 0.75em;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #AAB3BE;
}

@media (min-width: 550px) {
  .timeline-block tbody tr th, .timeline-block tbody tr td {
    padding: 17px 20px 15px;
    font-size: 15px;
  }
}

.timeline-block tbody tr th {
  white-space: nowrap;
}

@media (min-width: 767px) {
  .timeline-block tbody tr th {
    min-width: 240px;
    font-size: 17px;
  }
  .timeline-block tbody tr th:first-child {
    min-width: 240px;
  }
}

@media (min-width: 550px) {
  .timeline-block tbody tr th.timeline-date {
    background: #ebebeb;
  }
}

.timeline-block tbody tr th.timeline-date {
  display: none;
  /* Hide as we don't sure the timeline column anymore */
}

.timeline-block tbody tr td {
  width: 100%;
}

.timeline-block tbody tr td p {
  margin-top: 0;
  margin-bottom: 10px;
}

.timeline-block tbody tr td ul {
  padding-bottom: 5px;
}

.timeline-block tr td, .timeline-block tr th {
  padding: 10px 15px;
  vertical-align: top;
  border: 1px solid #F6F7F8;
  background: #ffffff;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  color: #262626;
}

@media (min-width: 550px) {
  .timeline-block tr td, .timeline-block tr th {
    padding: 10px 20px;
  }
}

.timeline-block tr th {
  font-weight: 600;
}

.timeline-block tr svg {
  margin: -10px 5px -8px -5px;
  width: 40px;
  height: 40px;
  fill: #00ADEF;
}

@media (max-width: 1099px) {
  .timeline-block tr svg {
    width: 34px;
    height: 34px;
  }
}

@media (min-width: 550px) {
  .timeline-block tr svg {
    margin: -15px 8px -8px -8px;
  }
}

@media (max-width: 549px) {
  .timeline-block {
    display: block;
  }
  .timeline-block thead {
    display: none;
  }
  .timeline-block tbody, .timeline-block tr, .timeline-block th, .timeline-block td {
    display: block;
  }
  .timeline-block tr {
    position: relative;
    margin-bottom: 10px;
    border: none;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.025);
  }
  .timeline-block tbody tr th[data-title="From"], .timeline-block tbody tr[data-multiple-row='start'] ~ tr:before {
    position: absolute;
    left: 0;
    bottom: 0;
    padding-left: 15px;
    max-width: 75px;
    width: 100%;
    height: 100%;
    background: #ebebeb;
    z-index: 0;
  }
  .timeline-block tbody tr[data-multiple-row='start'] {
    overflow: hidden;
    margin: 10px 0 0 !important;
  }
  .timeline-block tbody tr[data-multiple-row='start'] ~ tr {
    margin: 0;
  }
  .timeline-block tbody tr[data-multiple-row='start'] ~ tr:before {
    display: block;
    height: calc(100% + 2px);
  }
  .timeline-block tbody tr[data-multiple-row='start'] ~ [data-multiple-row='end'] ~ tr:not([data-multiple-row]) {
    margin-top: 10px;
  }
  .timeline-block tbody tr[data-multiple-row='start'] ~ [data-multiple-row='end'] ~ tr:not([data-multiple-row]):before {
    display: none;
  }
}

.current-user-viewlevel {
  position: absolute;
  top: 100%;
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #B1B1B1;
  padding: 3px 10px 3px 4.5rem;
  right: 0;
}

.current-user-viewlevel svg {
  width: 32px;
  height: 32px;
  fill: #B1B1B1;
}

.current-user-viewlevel:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #2D363C;
  z-index: -1;
  transform: skew(25deg);
  right: -68px;
}

@media (min-width: 1100px) {
  .current-user-viewlevel {
    width: 100%;
  }
}

.custom-content-selector {
  position: relative;
  text-align: right;
  margin-bottom: -10px;
}

.custom-content-selector .select-holder {
  width: auto;
  display: inline-block;
  border-color: #B1B1B1;
  margin-bottom: 0;
}

.custom-content-selector .select-holder:before {
  right: 0;
}

.custom-content-selector .select-holder select {
  padding: 5px;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #B1B1B1;
  line-height: 1;
  padding-right: 40px;
}

.custom-content-selector .select-holder:hover {
  border-color: #748191;
}

.custom-content-selector .select-holder:hover select {
  color: #748191;
}

/* Variables
================================== */
@media (min-width: 1100px) {
  .mysps-container {
    margin-top: 4rem;
  }
}

.mysps-container {
  padding-bottom: 5em;
}

.mysps-landing {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sub-nav {
  width: 100%;
  background-color: #333;
  opacity: .9;
  position: fixed;
  z-index: 99;
  top: 60px;
}

@media (min-width: 1100px) {
  .sub-nav {
    top: 60px;
  }
}

.sub-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  padding-left: 8vw;
  padding-right: 8vw;
}

.sub-nav ul > li {
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (max-width: 329px) {
  .sub-nav ul {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1550px) {
  .sub-nav ul {
    width: 1366px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
  }
}

.sub-nav ul li {
  float: left;
  margin: 0;
  text-align: center;
  width: 25%;
}

.sub-nav ul li a {
  display: block;
  height: 52px;
  line-height: 52px;
  width: 100%;
}

.sub-nav ul li h4 {
  display: none;
  padding: 0;
  margin: 0 .5rem;
  color: #ccc;
  font-size: .925rem;
  line-height: 52px;
  padding: 0;
  font-weight: normal;
}

@media (min-width: 767px) {
  .sub-nav ul li h4 {
    display: inline-block;
  }
}

@media (min-width: 1100px) {
  .sub-nav ul li h4 {
    line-height: 68px;
  }
  .sub-nav ul li a {
    height: 68px;
    line-height: 68px;
  }
}

.sub-nav ul li i {
  display: inline-block;
  margin: -4px 0 0;
  font-size: 1.15rem;
  border-radius: 50%;
  border: 2px solid #777;
  padding: 0.5rem;
}

.sub-nav ul li i.icon-note {
  font-size: 1rem;
  padding: .55rem;
}

.sub-nav ul li.active, .sub-nav ul li:hover {
  background-color: #000;
}

.sub-nav.sub-nav-alt {
  background-color: #ddd;
  top: 112px;
}

.sub-nav.sub-nav-alt ul li {
  width: 33.33%;
  text-align: center;
  padding: 0;
}

.sub-nav.sub-nav-alt ul li a {
  display: table;
  height: 50px;
  text-align: center;
  margin: 0 auto;
}

.sub-nav.sub-nav-alt ul li h4 {
  padding: 0;
  margin: 0 .5rem;
  color: #555;
  display: table-cell;
  vertical-align: middle;
  line-height: 1;
}

.sub-nav.sub-nav-alt ul li.active, .sub-nav.sub-nav-alt ul li:hover {
  background-color: #aaa;
}

@media (min-width: 1100px) {
  .sub-nav.sub-nav-alt {
    top: 128px;
  }
  .sub-nav.sub-nav-alt ul li a {
    height: 60px;
  }
}

.sub-nav ~ main {
  margin-top: 1rem;
}

/* Tables
================================== */
.Rtable {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 0;
  padding: 0;
}

.Rtable h4 {
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  color: #38444B;
  font-weight: 600;
  line-height: 1.1;
  font-size: 1rem;
}

.Rtable h5 {
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  color: #38444B;
  font-weight: 600;
  line-height: 1.05;
  font-size: 16px;
}

.Rtable-cell {
  box-sizing: border-box;
  font-size: .9rem;
  position: relative;
  width: 100%;
  list-style: none;
  color: #555;
}

.Rtable-cell [contenteditable], .Rtable-cell.Rtable-head {
  padding: 0.5rem;
  min-height: 3.5rem;
  line-height: 1.15;
  background: #fff;
}

@media (min-width: 350px) {
  .Rtable-cell [contenteditable], .Rtable-cell.Rtable-head {
    padding: 12px 6px;
  }
}

@media (min-width: 767px) {
  .Rtable-cell [contenteditable], .Rtable-cell.Rtable-head {
    padding: 12px 15px;
  }
}

.Rtable-cell > h1,
.Rtable-cell > h2,
.Rtable-cell > h3,
.Rtable-cell > h4,
.Rtable-cell > h5,
.Rtable-cell > h6 {
  margin: 0;
  padding: 0;
}

.Rtable-cell.Rtableactive {
  position: relative;
  background-color: #bfbfbf !important;
}

.Rtable-cell.Rtableactive:after {
  width: 0;
  height: 0;
  content: '';
  z-index: 2;
  border-top: 1rem solid #bfbfbf;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  position: absolute;
  bottom: -1rem;
  margin-left: -1rem;
  left: 50%;
}

.Rtable-cell strong {
  color: #000;
}

.Rtable-container {
  padding: .3rem 0rem;
}

.Rtable-title {
  padding: 2rem 1rem 1rem;
}

.Rtable-title h3 {
  margin: 0;
  padding: 1rem 0;
}

.Rtable.RNav {
  background-color: #eee !important;
}

.Rtable.RNav h4 {
  font-size: .85rem;
  font-weight: bold;
  color: #fff;
}

.Rtable.RNav .Rtable-head.Rtable-cell {
  opacity: .75;
  cursor: pointer;
  border: none;
  border-right: solid 1px #ccc;
  background-color: #ddd;
  padding: 1.5rem .5rem;
}

.Rtable.RNav .Rtable-head.Rtable-cell > i {
  font-size: 2.5rem;
  color: #777;
  margin: .5rem 0 .5rem;
}

.Rtable.RNav .Rtable-head.Rtable-cell > a {
  display: block;
  text-align: center;
}

.Rtable.RNav .Rtable-head.Rtable-cell > a i {
  margin: -.4rem .2rem -.3rem 0;
}

.Rtable.RNav .Rtable-head.Rtable-cell h4 {
  color: #555;
}

@media (max-width: 449px) {
  .Rtable.RNav .Rtable-head.Rtable-cell h4 {
    display: none;
  }
}

.Rtable.RNav .RTable-link {
  opacity: .75;
}

.Rtable.RNav .RTable-link:hover {
  opacity: 1;
}

.Rtable.RNav .RTable-link > a {
  width: 100%;
  height: 100%;
}

@media (max-width: 766px) {
  .Rtable.RNav .RTable-multi {
    display: none;
  }
}

.Rtable-aux {
  text-align: center;
}

.Rtable-aux .Rtable-cell {
  min-height: auto;
  border: solid 0 white;
  background: #eee;
}

.Rtable-aux a {
  display: inline-block;
  margin: 0 .4rem 0 0;
  color: #aaa;
}

.Rtable-aux a i {
  margin: -.2rem 0 0 0;
}

@media (min-width: 767px) {
  /* Table column sizing
================================== */
  .Rtable--2cols > .Rtable-cell {
    width: 50%;
  }
  .Rtable--3cols > .Rtable-cell {
    width: 33.33%;
  }
  .Rtable--4cols > .Rtable-cell {
    width: 25%;
  }
  .Rtable--5cols > .Rtable-cell {
    width: 20%;
  }
  .Rtable--6cols > .Rtable-cell {
    width: 16.6%;
  }
}

.Rtable-head {
  position: relative;
  padding: .4rem 0 .4rem;
}

.Rtable-head h4 i,
.Rtable-head h5 i {
  margin: -.2rem .3rem 0 .1rem;
}

.Rtable-head h5 {
  padding: 0;
  margin: 0;
}

.Rtable-head .icon-tick-circle {
  color: #3eb44a;
}

.Rtable-head .edit-button {
  position: absolute;
  right: 0;
  top: 0.875rem;
}

.mysps-block-01 .Rtable-head h5 {
  font-size: 20px;
  font-weight: 600;
  color: #262626;
  display: flex;
  align-items: center;
}

.mysps-block-01 .Rtable-head .Rtable-head__icon {
  margin-right: 5px;
  height: 32px;
  width: 32px;
  fill: #00ADEF;
}

@media (min-width: 767px) {
  .mysps-block-01 .Rtable-head .Rtable-head__icon {
    height: 44px;
    width: 44px;
  }
}

.RGoals.Rtable {
  margin: 2rem 0;
  /*.Rtable-cell:nth-child(4n+2){
        background:lighten(#ddd,3%);
    }
    .Rtable-cell:nth-child(4n+3){
        background:lighten(#ddd,6%);
    }
    .Rtable-cell:nth-child(4n+4){
        background:lighten(#ddd,9%);
    }*/
}

.RGoals.Rtable .Rtable-cell {
  border: 1px solid #F5F6F5;
}

.RGoals.Rtable .Rtable-cell.Rtable-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #E1E1E1;
  min-height: 38px;
}

.RGoals.Rtable .Rtable-cell.Rtable-head .tooltip__button {
  margin-top: -8px;
  margin-bottom: -10px;
  margin-right: -10px;
}

@media (max-width: 766px) {
  .RGoals.Rtable .Rtable-cell.Rtable-head.Rtable-col-head {
    display: none;
  }
}

.RGoals.Rtable .Rtable-cell.Rtable-head.Rtable-row-head {
  background: #EBEBEB;
  justify-content: flex-start;
  align-items: center;
}

.RGoals.Rtable .Rtable-cell.Rtable-head.Rtable-row-head svg {
  flex-shrink: 0;
  height: 32px;
  width: 32px;
  margin-left: -6px;
  margin-right: 6px;
  fill: #00ADEF;
}

@media (min-width: 767px) {
  .RGoals.Rtable .Rtable-cell.Rtable-head.Rtable-row-head svg {
    height: 44px;
    width: 44px;
  }
}

@media (max-width: 766px) {
  .RGoals.Rtable .Rtable-cell.Rtable-head.Rtable-row-head {
    border-top: 2px solid #bfbfbf;
    border-bottom: 2px solid #bfbfbf;
    width: 100%;
    text-align: center;
    min-height: auto;
  }
}

.RGoals.Rtable [contenteditable] {
  border: 1px solid #F5F6F5;
  position: relative;
  height: 100%;
}

.btn.btn-invert.reminder {
  float: right;
  transition: opacity .2s linear;
  opacity: .8;
  margin: -.4rem 0 -.4rem 0;
  padding: 0 0.3rem 0 0;
  line-height: 1.9;
}

.btn.btn-invert.reminder:hover {
  opacity: 1;
}

.btn.btn-invert.reminder.reminder-set:before {
  color: #00ADEF;
}

.btn.btn-invert.reminder .badge {
  display: inline-block;
  width: 1.1rem;
  float: left;
  height: 1.1rem;
  margin: .15rem;
  font-size: .7rem;
  line-height: 1.7;
  padding: 0;
}

.RStatements {
  position: relative;
  margin: 1rem 0;
}

.RStatements .Rtable-head {
  position: relative;
}

.RStatements h5 {
  padding: .6rem 0;
}

.RStatements .btn.btn-invert.reminder {
  margin: -.4rem 0 -.4rem 0;
}

.RStatements .Rtable-group {
  border: none;
  padding: .4rem 2.5rem .3rem 0;
  position: relative;
}

.RStatements .Rtable-cell[contenteditable] {
  padding: .7rem;
  border: 1px solid #eee;
  background-color: #fff;
}

.Rtable-holder {
  background-color: #fff;
  border: 1px solid #eee;
  padding: 1rem 1.2rem 1rem;
  margin: .5rem 0;
  overflow: hidden;
}

.Rtable-new {
  margin: 1.5rem 0 1.2rem;
  width: auto;
  cursor: pointer;
  font-size: .85rem;
}

.Rtable-new i {
  font-size: 1.2rem;
  display: inline-block;
  margin: -.3rem .2rem -.1rem 0rem;
}

.Rtable-new:hover a {
  color: #444;
}

.Rcell-title[contenteditable] {
  margin: .0rem 0 .2rem;
  font-weight: bold;
  min-height: auto;
}

.Rcell-message {
  margin: -1px 0 .5rem;
  min-height: 3.5rem;
}

.Rcell-delete {
  position: absolute;
  right: -1.5rem;
  transform: rotate(45deg);
  margin: 0;
  transition: opacity .2s linear;
  opacity: .6;
  cursor: pointer;
  font-size: .8rem;
  display: inline-block;
}

.Rcell-delete:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-size: 1.1rem;
}

.Rcell-delete:hover {
  opacity: 1;
}

[contenteditable=true] {
  transition: all .1s linear;
  font-size: 16px;
  word-wrap: break-word;
  white-space: pre-wrap;
}

[contenteditable=true]:not([disabled]):hover {
  background-color: #fff !important;
}

[contenteditable=true]:not([disabled]):focus {
  background-color: #fff !important;
  opacity: 1 !important;
  border: 1px solid #00afeb !important;
  box-shadow: 0 0 1.125rem rgba(0, 175, 235, 0.5);
  position: relative;
  z-index: 9;
}

[contenteditable=true]:not(.ng-not-empty):before {
  pointer-events: none;
  content: attr(placeholder);
  user-select: none;
  white-space: pre-wrap;
  display: block;
  font-style: italic;
  /* For Firefox */
  font-size: 15px;
  color: #bfbfbf;
}

[contenteditable=true]:focus:before {
  display: none !important;
}

.psuedo-placeholder {
  position: absolute;
  pointer-events: none;
  font-style: italic;
  opacity: 0;
  z-index: 999;
  color: #555;
  padding: 10px 15px;
  font-size: 15px;
}

.help-holder {
  display: inline;
}

.Rtable-head h4 i.helpicon,
.Rtable-head h5 i.helpicon {
  margin: -.3rem .1rem 0 .1rem;
}

.helpicon {
  transition: all .3s linear;
  background-color: transparent;
  border-radius: 50% 50% 0 0;
  padding: .1rem .4rem 0 .4rem;
  text-align: center;
  opacity: .5;
  float: right;
  margin-top: -.4rem;
  cursor: pointer;
}

.helpicon:hover {
  opacity: 1;
}

.helpicon:before {
  content: "";
  font-size: 1.3rem;
  transform: rotate(0);
  display: block;
}

.RTable-head .helpicon {
  float: right;
}

h1 + .help-holder .helpicon,
h1 + form .help-holder .helpicon {
  margin-top: .6rem;
}

.help-holder svg {
  width: 38px;
  height: 38px;
  float: right;
  cursor: pointer;
  fill: #7f7f7f;
}

.info {
  background-color: #fff;
  cursor: pointer;
  padding: 0 1.5rem 0 1.5rem;
  max-height: 0;
  font-size: .85rem;
  overflow: hidden;
  font-size: .75rem;
  width: 100%;
  font-weight: normal;
  transition: max-height .3s linear;
  margin-bottom: 15px;
}

.info strong:first-child {
  padding: .8rem 0 .2rem;
  display: block;
  font-size: .85rem;
}

.info p {
  margin: 0;
  padding: 0 0 .2rem;
  line-height: 1.15;
}

.info ol,
.info ul {
  margin: 0;
  padding: 0;
}

.info ol li,
.info ul li {
  margin: 0 0 0 1rem;
  line-height: 1.15;
  padding: 0 0 0.2rem 0;
}

.info a {
  color: unset !important;
}

.info.infohide {
  max-height: 350px;
  padding-bottom: .5rem;
}

.Rtable-group .Rtable-head .info.infohide {
  max-height: 370px;
}

.chart-container {
  position: relative;
  max-width: 820px;
  margin: auto -1%  !important;
  width: 102% !important;
}

@media (min-width: 450px) {
  .chart-container {
    margin: auto !important;
    width: 100% !important;
  }
}

.chart {
  margin: auto -2%  !important;
  width: 104% !important;
}

@media (min-width: 450px) {
  .chart {
    margin: auto !important;
    width: 100% !important;
  }
}

.chart-buttons {
  overflow: hidden;
  z-index: 1;
  position: relative;
  text-align: center;
}

.chart-buttons .btn.btn-xs {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-right: 10px;
  border: 1px solid #bfbfbf;
  border-radius: 7.5rem;
  color: #bfbfbf;
  max-width: 80px;
  height: 36px;
  padding: 12px 15px 10px;
}

.chart-buttons .btn.btn-xs.active {
  background-color: #bfbfbf !important;
  border-color: #bfbfbf;
}

.chart-buttons .btn.btn-xs:hover {
  background: #bfbfbf !important;
}

.input-counter {
  position: absolute;
  bottom: .2rem;
  right: .3rem;
  display: none;
  opacity: .6;
  font-size: .75rem;
  z-index: 9999;
  color: #555;
}

@media (min-width: 767px) {
  .input-counter {
    bottom: 0rem;
  }
}

article.article-detail {
  border: 0;
}

article.article-detail .about-mysps-body {
  max-width: 950px;
  margin: -2rem auto;
  padding: 5%;
}

form[name="myPlanForm"] {
  position: relative;
  max-width: 100%;
}

form[name="myPlanForm"].form-loading:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(255, 255, 255, 0.125);
}

.tooltip {
  position: relative;
}

.tooltip__button {
  display: block;
  cursor: pointer;
}

.tooltip__button svg {
  width: 32px;
  height: 32px;
}

.tooltip__content {
  position: absolute;
  top: calc(100% + 20px);
  left: 50%;
  padding: 1rem 1.5rem 0.5rem;
  background: #ebebeb;
  border: 1px solid #ddd;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.125);
  z-index: 1;
  color: #262626;
  font-size: 12px;
  min-width: 250px;
  transform: translateX(-50%) scale(0.2);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  opacity: 0;
  visibility: hidden;
}

.tooltip--open .tooltip__content {
  transform: translateX(-50%) scale(1);
  opacity: 1;
  visibility: visible;
  cursor: auto;
}

.tooltip__content:before {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  background: #ebebeb;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  position: absolute;
  top: -10px;
  left: 50%;
  margin-left: -5px;
  transform: rotate(45deg);
  box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.0325);
}

.tooltip__title {
  display: flex;
  font-size: 14px;
  align-items: center;
}

.tooltip__title svg {
  margin-left: -10px;
  margin-right: 5px;
  width: 38px;
  height: 38px;
}

.tooltip__close {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  fill: #aaa;
  cursor: pointer;
  transition: fill 0.2s cubic-bezier(0.47, 0, 0.745, 0.715);
}

.tooltip__close:hover {
  fill: #333;
}

.tooltip__close svg {
  width: 32px;
  height: 32px;
}

.mysps-loading {
  position: relative;
  min-height: 100vh;
}

.home.MySPS .content-container {
  background-image: url("../assets/images/bg_mysps.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.home.MySPS .card-holder .card a {
  padding-top: 0;
  position: relative;
  overflow: hidden;
  box-shadow: none;
}

.home.MySPS .card-holder .card a:before {
  display: block;
  position: absolute;
  content: '';
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  top: -10px;
  left: -10px;
  background-image: url("../assets/images/bg_mysps.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-color: #fff;
  filter: blur(10px);
}

@media (min-width: 767px) {
  .task-row {
    flex-direction: row-reverse;
    display: flex;
  }
}

@media (min-width: 767px) {
  .task-col--list {
    padding-right: 25px;
  }
}

@media (min-width: 1000px) {
  .task-col--list {
    padding-right: 10%;
  }
}

.task-col--date {
  padding-bottom: 25px;
}

@media (min-width: 767px) {
  .task-col--date {
    padding-bottom: 0;
    flex: 1 0 224px;
    max-width: 224px;
  }
}

.mysps-notes__btn, .mysps-selfchecks__btn, .reminder__btn {
  color: #bfbfbf;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transition: color 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mysps-notes__btn svg, .mysps-selfchecks__btn svg, .reminder__btn svg {
  height: 32px;
  width: 32px;
  fill: #bfbfbf;
  transition: fill 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

@media (min-width: 767px) {
  .mysps-notes__btn svg, .mysps-selfchecks__btn svg, .reminder__btn svg {
    height: 44px;
    width: 44px;
  }
}

.mysps-notes__btn:hover, .mysps-selfchecks__btn:hover, .reminder__btn:hover {
  color: #a6a6a6;
}

.mysps-notes__btn:hover svg, .mysps-selfchecks__btn:hover svg, .reminder__btn:hover svg {
  fill: #a6a6a6;
}

.mysps-notes__box__body .Rtable-cell__btn svg, .mysps-selfchecks__delete svg {
  fill: #bfbfbf;
  height: 32px;
  width: 32px;
  opacity: 0.5;
  transition: opacity 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

@media (min-width: 767px) {
  .mysps-notes__box__body .Rtable-cell__btn svg, .mysps-selfchecks__delete svg {
    height: 44px;
    width: 44px;
  }
}

.mysps-notes__box__body .Rtable-cell__btn:hover svg, .mysps-selfchecks__delete:hover svg {
  opacity: 1;
}

.task-list {
  list-style-type: none;
  margin: 0;
}

.task-list__item {
  margin: 0;
  padding: 0 0 20px;
  display: flex;
}

.task-list__item--head {
  padding-bottom: 0;
  margin-bottom: 15px;
}

.task-list__link {
  color: #00ADEF;
  font-size: 13px;
  display: flex;
  align-items: center;
  text-decoration: underline;
}

.task-list__link:hover {
  text-decoration: none;
}

.task-list__link svg {
  fill: #00ADEF;
  height: 26px;
  width: 24px;
}

.task-list__info {
  flex-grow: 1;
  padding-top: 3px;
  padding-right: 20px;
}

.task-list__status {
  text-align: center;
  flex: 1 0 100px;
  max-width: 100px;
}

.task-list__status-icon {
  display: block;
  margin: auto;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #b3b3b3;
  cursor: pointer;
}

[class*='--completed'] .task-list__status-icon {
  background: #00ADEF;
  box-shadow: inset 0 0 0 4px #fff;
  border: 4px solid #00ADEF;
}

.task-list__status-label {
  font-size: 13px;
  display: block;
  text-align: center;
}

.task-list__counter {
  padding-left: 15px;
  padding-right: 30px;
}

.task-list__counter-label {
  display: flex;
  border: 2px solid #00ADEF;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: #00ADEF;
  width: 30px;
  height: 30px;
  font-weight: bold;
}

.task-list p {
  margin: 0 0 5px;
}

.task-date__calendar {
  position: relative;
  padding-bottom: 30px;
  background: #fff;
  border: 1px solid #EBEBEB;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  color: #262626;
  text-align: center;
  font-size: 13px;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  font-weight: bold;
}

.task-date__calendar figcaption {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 63px;
  transform: translateX(-50%);
}

.task-date__day {
  font-size: 11px;
  line-height: 16px;
}

.task-date__date {
  margin-top: 4px;
  margin-bottom: 15px;
  font-size: 58px;
  font-family: 'Panton-Light';
}

.task-date .svg-icon {
  display: block;
  width: 207px;
  height: 207px;
  margin: auto;
  fill: #2D2D2D !important;
}

.mysps-container button.btn-success:before, .mysps-container input[type="button"].btn-success:before, .mysps-container input[type="reset"].btn-success:before, .mysps-container input[type="submit"].btn-success:before, .mysps-container button.btn-success:before, .mysps-container a.btn-success:before, .mysps-container label.btn-success:before, .mysps-container div.btn-success:before, .mysps-container span.btn-success:before {
  top: 38%;
}

.mysps-container .btn-group {
  margin: 0;
}

.mysps-container .btn-submit {
  margin: 0;
  background: #fff;
  border: 1px solid #00ADEF;
  color: #00ADEF;
  font-size: 11px;
  letter-spacing: 2px;
  line-height: 1.25;
  height: 44px;
  min-width: auto;
}

.mysps-container .btn-submit > span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mysps-block-01 {
  display: flex;
  flex-direction: column;
  padding: 15px 10px;
  background: #fff;
  border: 1px solid #EBEBEB;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  flex-grow: 1;
}

.mysps-block-01 .Rtable-head {
  margin-bottom: 15px;
}

@media (min-width: 767px) {
  .mysps-block-01 .Rtable-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.mysps-block-01__flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mysps-block-01 .Rtable {
  flex-grow: 1;
}

.mysps-block-01 .rating {
  margin: 5px auto 0;
  max-width: 500px;
}

.grid--equal-height > [class*='grid-'] {
  display: flex;
  flex-direction: column;
}

.grid--performance {
  margin-bottom: 50px;
}

.grid--performance [class*='--calendar'] {
  margin-top: 50px;
  margin-bottom: 50px;
}

@media (min-width: 1000px) {
  .grid--performance [class*='--calendar'] {
    margin-top: 0;
    margin-bottom: 0;
    flex: 1 0 224px;
    max-width: 224px;
  }
}

.mysps-notes__item {
  width: 100%;
  padding: 2px 0;
}

@media (min-width: 767px) {
  .mysps-notes__item {
    padding: 10px;
  }
}

@media (min-width: 767px) {
  .mysps-notes {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
  }
  .mysps-notes__item {
    flex: 1 1 50%;
    width: 50%;
  }
}

.mysps-notes__count {
  display: inline;
}

.mysps-notes__box {
  padding: 8px 15px 10px 5px;
  border: 1px solid #EBEBEB;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}

@media (min-width: 767px) {
  .mysps-notes__box {
    padding: 20px 25px 20px 15px;
  }
}

.mysps-notes__box__head svg {
  margin-top: -2px;
  margin-right: 0px;
  height: 32px;
  width: 32px;
  fill: #00ADEF;
}

@media (min-width: 767px) {
  .mysps-notes__box__head svg {
    margin-right: 4px;
    margin-top: -9px;
    height: 44px;
    width: 44px;
  }
}

.mysps-notes__box__head h5 {
  position: relative;
  margin: 0;
  padding: 7px 0 0;
  color: #262626;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  cursor: pointer;
}

@media (min-width: 767px) {
  .mysps-notes__box__head h5 {
    font-size: 20px;
    line-height: 28px;
  }
}

.mysps-notes__box__head h5:after {
  content: '';
  position: absolute;
  top: 4px;
  right: -5px;
  background: url("../assets/svg/Icons/Angle Down.svg") no-repeat center/contain;
  height: 34px;
  width: 34px;
  opacity: 0.325;
}

[class$='--open'] .mysps-notes__box__head h5:after {
  right: 0;
  top: 8px;
  height: 20px;
  width: 20px;
  background-image: url("../assets/svg/Icons/Angle Up.svg");
}

[class$='--empty'] .mysps-notes__box__head h5:after {
  background-image: url("../assets/svg/Icons/Add Circle.svg");
  width: 38px;
  height: 38px;
  top: 2px;
  right: -7px;
}

.mysps-notes__box__body {
  padding-top: 15px;
  padding-left: 30px;
  counter-reset: notesCounter;
}

@media (min-width: 767px) {
  .mysps-notes__box__body {
    padding-left: 40px;
  }
}

.mysps-notes__box__body .Rcell-message {
  margin-bottom: 0;
}

.mysps-notes__box__body .Rtable-group {
  position: relative;
  counter-increment: notesCounter;
}

.mysps-notes__box__body .Rtable-cell {
  display: flex;
  align-items: center;
}

.mysps-notes__box__body .Rtable-cell [contenteditable] {
  line-height: 1.4275;
  border: 1px solid transparent;
  flex-grow: 1;
  color: #000;
}

@media (min-width: 767px) {
  .mysps-notes__box__body .Rtable-cell [contenteditable] {
    margin-right: 20px;
  }
}

.mysps-notes__box__body .Rtable-cell [contenteditable].ng-empty:not(:focus) {
  border-color: #d9d9d9;
}

.mysps-notes__box__body .Rtable-cell [contenteditable].ng-shake:not(:focus) {
  border-color: #ff0000;
}

.mysps-notes__box__body .Rtable-cell [contenteditable]:before {
  content: counter(notesCounter) ".";
  display: block;
  position: absolute;
  z-index: 1;
  font-size: 16px;
  top: 12px;
  left: -20px;
  color: #262626;
  transition: opacity 0.125s cubic-bezier(0.645, 0.045, 0.355, 1);
  font-weight: bold;
}

.mysps-notes__box__body .Rtable-cell__btn {
  margin-top: -10px;
  margin-right: -10px;
}

.mysps-notes__box__body .Rcell-delete {
  right: auto;
  position: relative;
  opacity: 0.25;
}

.mysps-notes__box__body .Rcell-delete:hover {
  opacity: 1;
}

.mysps-notes__box__body .Rcell-delete:before {
  font-size: 26px;
}

.mysps-notes__buttons {
  padding-top: 5px;
  padding-left: 0;
  margin-left: -10px;
}

@media (min-width: 767px) {
  .mysps-notes__buttons {
    margin-left: 0;
    padding-left: 5px;
  }
}

.mysps-tools {
  padding: 40px 58px 60px;
  border: 1px solid #EBEBEB;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}

.mysps-tools__title {
  padding: 0;
  border: 0 !important;
  margin-bottom: 30px !important;
}

@media (min-width: 550px) {
  .mysps-tools__feature {
    display: flex;
  }
}

.mysps-tools__feature--active {
  margin-top: 20px;
}

@media (min-width: 767px) {
  .mysps-tools__feature--active {
    display: flex;
  }
}

.mysps-tools__feature-img {
  flex: 1 0 100px;
}

@media (min-width: 1250px) {
  .mysps-tools__feature-img {
    flex: 1 0 150px;
  }
}

[class*='--active'] .mysps-tools__feature-img {
  flex: 1 0 250px;
}

@media (min-width: 550px) {
  [class*='--active'] .mysps-tools__feature-img {
    flex: 1 0 450px;
  }
}

.mysps-tools__feature-info {
  flex: 1 1 100%;
  max-width: 100%;
  color: #262626;
  font-size: 13px;
  line-height: 18;
  padding-top: 15px;
}

@media (min-width: 550px) {
  .mysps-tools__feature-info {
    padding-top: 0;
    padding-left: 35px;
  }
}

@media (min-width: 767px) {
  [class*='--active'] .mysps-tools__feature-info {
    padding-left: 35px;
  }
}

.mysps-tools__feature-info h3 {
  padding: 0;
  font-size: 14px;
  line-height: 20px;
}

@media (min-width: 550px) {
  .mysps-tools__feature-info h3 {
    padding-top: 5px;
    font-size: 17px;
    line-height: 23px;
  }
}

[class*='--active'] .mysps-tools__feature-info h3 {
  font-size: 17px;
  line-height: 23px;
}

.mysps-tools__img-controls {
  display: none;
  padding-top: 5px;
  list-style-type: none;
  text-align: right;
}

[class*='--active'] .mysps-tools__img-controls {
  display: block;
}

.mysps-tools__img-controls > li {
  margin-left: 5px;
  display: inline-block;
}

.mysps-tools__img-controls svg {
  width: 30px;
  height: 30px;
  fill: #262626;
}

.mysps-tools__model {
  padding-top: 20px;
}

.btn-link {
  color: #262626;
  font-size: 13px;
  text-decoration: none;
}

.btn-link--icon {
  display: flex;
  align-items: center;
}

.btn-link--icon svg {
  margin-right: 5px;
  fill: #262626;
  width: 30px;
  height: 30px;
}

.btn-link--primary {
  color: #00ADEF;
}

.btn-link--primary svg {
  fill: #00ADEF;
}

.mysps-selfchecks__form {
  margin-bottom: 20px;
  padding: 25px 15px;
  border: 1px solid #EBEBEB;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}

.mysps-selfchecks__form__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mysps-selfchecks__form__head .svg-toggle {
  height: 55px;
  width: 55px;
  opacity: 0.25;
  cursor: pointer;
}

.mysps-selfchecks__form__head--open .svg-toggle {
  opacity: 0;
}

.mysps-selfchecks__form__body {
  padding-top: 20px;
}

.mysps-selfchecks__form__body--has-info {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.mysps-selfchecks__form-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #262626;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  flex-grow: 1;
}

@media (min-width: 767px) {
  .mysps-selfchecks__form-title {
    flex-basis: 50%;
    max-width: 50%;
  }
}

.mysps-selfchecks__form-title__label {
  display: flex;
  align-items: center;
}

.mysps-selfchecks__form-title__label svg {
  margin-right: 5px;
  height: 32px;
  width: 32px;
  fill: #00ADEF;
}

@media (min-width: 767px) {
  .mysps-selfchecks__form-title__label svg {
    height: 44px;
    width: 44px;
  }
}

.mysps-selfchecks__form-title__button {
  position: relative;
}

@media (min-width: 767px) {
  .mysps-selfchecks__form-title__button {
    margin-right: 60px;
  }
}

.mysps-selfchecks__form-title__button-icon {
  width: 38px;
  height: 38px;
  fill: #262626;
}

.mysps-selfchecks__form-title__button-count {
  position: absolute;
  display: flex;
  background: #00ADEF;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  color: #fff;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  line-height: 1;
  top: 0;
  right: 4px;
}

.mysps-selfchecks__items {
  width: 100%;
  counter-reset: selfCheckCounter;
  list-style-type: none;
  margin: 0;
}

[class*='--has-info'] .mysps-selfchecks__items {
  flex: 1 1 0;
  max-width: none;
}

[class*='--has-info'] .mysps-selfchecks__items > li {
  flex-basis: 100%;
  max-width: 100%;
}

@media (min-width: 767px) {
  .mysps-selfchecks__items {
    display: flex;
    flex-wrap: wrap;
  }
}

.mysps-selfchecks__items > li {
  margin-left: 0;
  margin-bottom: 20px;
  counter-increment: selfCheckCounter;
  position: relative;
  padding: 0 45px;
}

@media (min-width: 767px) {
  .mysps-selfchecks__items > li {
    padding: 0 60px;
    flex: 1 1 50%;
    max-width: 50%;
  }
}

.mysps-selfchecks__items > li:before {
  content: counter(selfCheckCounter) ".";
  display: block;
  position: absolute;
  top: 0;
  left: 8px;
  color: #262626;
  font-size: 16px;
  line-height: 23px;
  font-weight: 600;
}

@media (min-width: 767px) {
  .mysps-selfchecks__items > li:before {
    left: 30px;
  }
}

[class*='--has-info'] .mysps-selfchecks__items > li:last-child:before {
  display: none;
}

.mysps-selfchecks__item {
  position: relative;
  color: #262626;
  font-size: 16px;
  line-height: 23px;
}

.mysps-selfchecks__item__title {
  font-weight: 600;
}

.mysps-selfchecks__field {
  margin-top: -12px;
  padding: 12px 15px;
  border: 1px solid transparent;
}

.mysps-selfchecks__delete {
  position: absolute;
  top: 0;
  right: -40px;
  cursor: pointer;
}

@media (min-width: 767px) {
  .mysps-selfchecks__delete {
    right: -50px;
  }
}

.mysps-selfchecks__buttons {
  width: 100%;
  padding-left: 50px;
}

@media (min-width: 767px) {
  .mysps-selfchecks__buttons {
    padding-left: 75px;
  }
  .mysps-selfchecks__buttons--pullright {
    padding-left: calc(50% + 75px);
  }
  [class*='--has-info'] .mysps-selfchecks__buttons {
    padding-left: 10px;
  }
}

.mysps-selfchecks__tooltip .tooltip__content {
  margin-left: -6px;
  padding-top: 25px;
}

.mysps-selfchecks__tooltip .tooltip__title {
  font-size: 17px;
}

.mysps-selfchecks__tooltip .tooltip__close {
  top: 5px;
  right: 5px;
}

@media (max-width: 766px) {
  .mysps-selfchecks__tooltip.tooltip--open .tooltip__content {
    transform: translateX(-89%) scale(1);
  }
  .mysps-selfchecks__tooltip.tooltip--open .tooltip__content:before {
    left: auto;
    right: 6.5%;
  }
}

.mysps-selfchecks__info {
  display: block;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 767px) {
  .mysps-selfchecks__info {
    padding-right: 5%;
    flex: 1 1 50%;
    max-width: 50%;
  }
}

.mysps-selfchecks__info > svg {
  display: none;
}

.mysps-selfchecks__info .info {
  padding: 10px 30px 20px;
  max-height: none;
  border: 1px solid #D8D8D8;
  background-color: #EBEBEB;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  color: #262626;
  font-size: 13px;
  cursor: auto;
}

.mysps-selfchecks__info .info > strong {
  font-size: 17px;
  display: flex;
  align-items: center;
}

.mysps-selfchecks__info .info > strong:before {
  content: '';
  display: block;
  width: 38px;
  height: 38px;
  background: url("../assets/svg/Icons/Info.svg") no-repeat center/contain;
  opacity: 0.875;
  margin-right: 10px;
  margin-left: -2px;
}

.mysps-selfchecks__info .info > div {
  margin-top: 20px;
}

.mysps-selfchecks__info .info > div p {
  display: flex;
  margin-bottom: 10px;
  line-height: 18px;
}

.mysps-selfchecks__info .info > div p > span {
  flex: 1 0 60px;
  max-width: 60px;
  margin-right: 10px;
  font-weight: bold;
}

.tooltip-form {
  font-size: 12px;
}

@media (min-width: 767px) {
  .tooltip-form {
    font-size: 17px;
  }
}

.tooltip-form__row {
  margin-left: -10px;
  margin-right: -10px;
  display: flex;
}

.tooltip-form__col {
  padding-left: 10px;
  padding-right: 10px;
  flex: 1 1 100%;
  max-width: 100%;
}

.tooltip-form__col--days, .tooltip-form__col--ampm {
  flex: 1 1 40%;
  max-width: 40%;
}

.tooltip-form__col--time {
  flex: 1 1 60%;
  max-width: 60%;
}

.tooltip-form__col--auto {
  flex: 0 1 auto;
  max-width: auto;
}

@media (max-width: 766px) {
  .tooltip-form__col--ampm {
    flex: 1 0 80px;
    max-width: 80px;
  }
  .tooltip-form__col--ampm [class*='__radioboxes'] > li {
    padding-left: 0;
  }
}

.tooltip-form__button {
  height: 24px;
  overflow: hidden;
}

.tooltip-form__label {
  display: block;
  margin-bottom: 15px;
  color: #bfbfbf;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 18px;
  text-transform: uppercase;
}

@media (max-width: 766px) {
  .tooltip-form__label {
    margin-bottom: 10px;
  }
}

.tooltip-form__checkboxes, .tooltip-form__radioboxes, .tooltip-form__buttons {
  list-style: none;
  margin: 0;
  padding: 0;
}

.tooltip-form__checkboxes > li, .tooltip-form__radioboxes > li, .tooltip-form__buttons > li {
  padding: 0 0 5px;
  margin: 0;
}

@media (max-width: 766px) {
  .tooltip-form__checkboxes > li, .tooltip-form__radioboxes > li, .tooltip-form__buttons > li {
    padding-bottom: 0;
  }
}

.tooltip-form__checkbox {
  display: flex;
  align-items: center;
}

.tooltip-form__checkbox svg {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  flex-shrink: 0;
}

.tooltip-form__select__field {
  margin-left: -5px;
  margin-right: -5px;
  position: relative;
}

.tooltip-form__select__field:after {
  content: '';
  height: 100%;
  width: 14px;
  position: absolute;
  top: 0;
  right: 0;
  background: url("../assets/svg/Icons/Angle Up.svg") no-repeat 0 3px/14px, url("../assets/svg/Icons/Angle Down.svg") no-repeat 0 12px/14px;
  filter: invert(63%) sepia(0%) saturate(0%) hue-rotate(147deg) brightness(92%) contrast(93%);
  z-index: -1;
}

@media (max-width: 766px) {
  .tooltip-form__select__field:after {
    top: -2px;
    background-size: 10px;
  }
}

.tooltip-form__select__field select {
  display: block;
  width: 100%;
  padding: 5px 20px 5px 5px;
  margin: 0;
  background: none;
  appearance: none;
  border: none;
  border-radius: 0;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.tooltip-form__select__field select::-ms-expand {
  display: none;
}

.tooltip-form__select--time {
  display: flex;
  margin-right: -10px;
  margin-left: -10px;
}

.tooltip-form__select--time > [class*='__field'] {
  padding-left: 10px;
  padding-right: 10px;
  flex: 1 1 100%;
}

.tooltip-form__select--time > [class*='__field']:not(:last-child):before {
  content: ':';
  position: absolute;
  right: 0;
  top: 0;
}

@media (max-width: 766px) {
  .tooltip-form__select--time > [class*='__field']:not(:last-child):before {
    top: -4px;
    right: 2px;
    font-size: 16px;
  }
}

.tooltip-form__select--time > [class*='__field']:not(:last-child):after {
  right: 10px;
}

.tooltip-form__radioboxes--vertical {
  display: flex;
}

@media (min-width: 767px) {
  .tooltip-form__radioboxes--vertical {
    margin-left: 10px;
  }
}

.tooltip-form__radioboxes--vertical [class*='__radiobox'] {
  padding-left: 10px;
}

.tooltip-form__radioboxes--vertical [class*='__radiobox'] label {
  flex-direction: column-reverse;
  align-items: center;
}

.tooltip-form__radioboxes--vertical [class*='__radiobox'] svg {
  margin: 3px 0 0;
}

.tooltip-form__radiobox {
  cursor: pointer;
}

.tooltip-form__radiobox svg {
  margin-top: -4px;
  margin-right: 10px;
  width: 24px;
  height: 24px;
}

.tooltip-form__radiobox label {
  display: flex;
  align-items: flex-start;
}

.tooltip-form__buttons {
  margin-top: 20px;
  display: flex;
  margin-left: -5px;
  margin-right: -5px;
}

@media (max-width: 766px) {
  .tooltip-form__buttons {
    margin-top: 30px;
  }
}

.tooltip-form__buttons > li {
  padding-left: 5px;
  padding-right: 5px;
  flex: 1 1 100%;
  max-width: 100%;
}

.tooltip-form__buttons .btn {
  margin: 0;
  width: 100%;
  height: 55px;
  font-size: 11px;
  letter-spacing: 2px;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltip-form__buttons .btn-alt {
  border: 1px solid #bfbfbf;
  color: #bfbfbf;
  background: none;
}

.tooltip-form__buttons .btn-alt:hover:not(:disabled) {
  border-color: #999999;
  color: #999999;
  background: none;
}

.tooltip-form__buttons .btn-submit {
  width: 100%;
  background: none;
}

.tooltip-form__text__field {
  position: relative;
}

.tooltip-form__text__field input[type="color"], .tooltip-form__text__field input[type="date"], .tooltip-form__text__field input[type="datetime"], .tooltip-form__text__field input[type="datetime-local"], .tooltip-form__text__field input[type="email"], .tooltip-form__text__field input[type="month"], .tooltip-form__text__field input[type="number"], .tooltip-form__text__field input[type="password"], .tooltip-form__text__field input[type="search"], .tooltip-form__text__field input[type="tel"], .tooltip-form__text__field input[type="text"], .tooltip-form__text__field input[type="time"], .tooltip-form__text__field input[type="url"], .tooltip-form__text__field input[type="week"], .tooltip-form__text__field input:not([type]), .tooltip-form__text__field textarea, .tooltip-form__text__field .inputElement, .tooltip-form__text__field [contenteditable=true] {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  font: inherit;
}

.ng-shake {
  animation: shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both infinite;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.MySPS .article-header h1 {
  padding-left: 25px;
  font-size: 36px;
}

@media (max-width: 766px) {
  .MySPS .article-header h1 {
    font-size: 26px;
    padding-left: 10px;
  }
}

.MySPS .article-header .articles-main {
  justify-content: flex-start;
}

@media (max-width: 766px) {
  .MySPS .article-header .articles-main .channel-display {
    padding-left: 0;
    padding-right: 10px;
  }
  .MySPS .article-header .articles-main .channel-display .channel-number {
    font-size: 26px;
  }
}

.MySPS .article-header .channel-number {
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 1.1;
}

.MySPS.articles-page h2.title--back {
  display: flex;
  align-items: center;
}

.MySPS.articles-page h2.title--back > a {
  margin-left: auto;
  font-weight: normal;
  font-size: 14px;
}

@media (min-width: 767px) {
  .MySPS.articles-page h2.title--back > a {
    font-size: 17px;
  }
}

.Rtable-cell--title {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  background-color: #eee;
}

.Rtable-cell--title .tooltip__button svg {
  width: 32px;
  height: 32px;
}

@media (min-width: 767px) {
  .Rtable-cell--title {
    display: none;
  }
}

ul.list {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}

ul.list > li {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.list li {
  font-size: 1.1rem;
  position: relative;
  padding: 1.5% 10% 1.5% 3%;
  border-top: 1px solid #ddd;
  width: 100%;
  display: block;
  cursor: pointer;
  transition: all .2s linear;
}

ul.list li p {
  margin: 0;
  padding: 0;
  font-size: 1.1rem;
  margin: 0 0 -.3rem;
  font-weight: bold;
}

ul.list li > small {
  display: block;
  margin: .3rem 0;
  line-height: 1;
}

ul.list li p + small {
  margin: .3rem 0 0;
}

ul.list li .badge {
  margin: .4rem .2rem 0 0;
  float: right;
}

ul.list li:after {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  position: absolute;
  font-size: 1.1rem;
  right: 3%;
  color: #555;
  margin-top: -.6rem;
  top: 50%;
}

ul.list li:hover {
  background-color: #fff;
}

ul.list.list-select li:after {
  content: "";
}

ul.list.list-select li.selected:after {
  content: "";
}

.ngdialog-content h4 {
  padding: 1em 0 .6rem 0;
}

.ngdialog-content h4 i {
  float: right;
  margin-top: -.6rem;
}

.ngdialog-content .back-btn {
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  position: absolute;
  left: 0;
  top: 0;
  line-height: 1.2;
  color: #999;
  padding: 1.6em .7rem;
  margin-top: -.5rem;
  font-size: .8rem;
}

.ngdialog-content .back-btn i {
  color: #999;
  font-size: 1.35rem;
  display: inline-block;
  margin: -.2rem .1rem 0 0;
}

.ngdialog-content .back-btn:hover {
  color: #555;
}

.ngdialog-content .select-holder, .ngdialog-content input[type=time] {
  height: 2.7rem;
}

.ngdialog-content .btn-group {
  margin: 0 0 .3rem;
}

.time-holder {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  width: 100%;
}

.time-holder .select-holder {
  margin-right: -.2rem;
  max-width: 135px;
  min-width: 100px;
}

.time-holder .select-holder select {
  -moz-box-flex: 1  ;
  -webkit-flex: 1  ;
  -ms-flex: 1  ;
  flex: 1  ;
}

.reminder {
  width: 300px;
}

@media (min-width: 767px) {
  .reminder {
    width: 420px;
  }
}

.reminder__item {
  display: flex;
  align-items: flex-start;
}

.reminder__list {
  margin: 0;
  padding: 15px 10px 10px;
  list-style-type: none;
  max-height: 275px;
  overflow: auto;
}

.reminder__list > li {
  padding-bottom: 15px;
}

@media (max-width: 766px) {
  .reminder__list > li {
    margin: 0;
  }
}

.reminder__day-time {
  display: flex;
}

[class*='--disabled'] > .reminder__day-time {
  opacity: 0.25;
}

.reminder__status {
  margin-right: 10px;
}

.reminder__status svg[class*='__icon'] {
  width: 24px;
  height: 24px;
  margin-top: -3px;
  cursor: pointer;
}

.reminder__label {
  font-size: 17px;
  line-height: 1.5;
}

.reminder__notes {
  margin-top: 5px;
  font-size: 11px;
  font-weight: normal;
}

.reminder__actions {
  list-style-type: none;
  padding: 0;
  margin: -2px 0 0 auto;
  display: flex;
  align-items: center;
}

.reminder__action__btn {
  cursor: pointer;
}

.reminder__action__btn svg {
  width: 24px;
  height: 24px;
  fill: #bfbfbf;
  transition: fill 0.25s ease;
}

.reminder__action__btn:hover svg {
  fill: #8c8c8c;
}

.reminder__controls {
  padding-bottom: 10px;
  text-align: center;
}

.reminder-detail-form {
  padding: 20px 20px 25px 35px;
}

@media (max-width: 766px) {
  .reminder-detail-form {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.reminder .preload {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.reminder .loading {
  position: relative;
  margin: 0;
  left: 0;
  top: 0;
}

#glyphs {
  border-bottom: 1px solid #ccc;
  padding: 2em 0;
  text-align: center;
}

.glyph {
  display: inline-block;
  width: 9em;
  margin: 1em;
  text-align: center;
  vertical-align: top;
  background: #FFF;
  user-select: unset;
}

.glyph .glyph-icon {
  padding: 10px;
  display: block;
  font-size: 64px;
  line-height: 1;
}

.glyph .glyph-icon .svg-icon {
  max-width: 100%;
  fill: red !important;
}

.glyph .glyph-icon:before {
  font-size: 64px;
  color: #222;
  margin-left: 0;
}

.glyph-icon-id {
  font-size: 0.65em;
  background-color: #222;
  color: #fff;
  border-radius: 4px;
  padding: 0.5em;
  color: #FFFF99;
  user-select: text;
  font-family: Consolas,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New, monospace;
}

@media (min-width: 1100px) {
  div[ui-view='mobile-footer'] {
    display: none;
  }
}

.footer-mobile--menu {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.articles-page .footer-mobile--menu {
  background-color: #2D363C !important;
}

.home.MySPS .footer-mobile--menu {
  display: none;
}

.footer-mobile--menuitem {
  display: block;
  margin: 0;
  padding: 0;
  flex: 1 1 100%;
  text-overflow: ellipsis;
  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
}

.footer-mobile--menuitem a {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  font-size: .625rem;
  padding: 0.5rem 0.5rem;
  text-align: center;
  min-height: 50px;
  justify-content: center;
}

.footer-mobile--menuitem a.active {
  color: #00ADEF !important;
}

.footer-mobile--menuitem a.active svg {
  fill: #00ADEF !important;
}

.footer-mobile--menuitem a svg {
  margin-top: -5px;
  margin-bottom: -5px;
  width: 32px;
  height: 32px;
  fill: #fff;
}

@media (max-width: 766px) {
  .footer-mobile--menu-sps {
    overflow: auto;
  }
  .footer-mobile--menu-sps::-webkit-scrollbar {
    height: 3px;
  }
  .footer-mobile--menu-sps::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: white;
    border-radius: 10px;
  }
  .footer-mobile--menu-sps::-webkit-scrollbar-thumb {
    background: rgba(0, 173, 239, 0.75);
    border-radius: 10px;
  }
  .footer-mobile--menu-sps .footer-mobile--menuitem a {
    padding-left: 10px;
    padding-right: 10px;
    line-height: 1;
    white-space: nowrap;
  }
}

[ui-view='submenus'] {
  position: relative;
}

[ui-view='submenus']:before, [ui-view='submenus']:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1rem;
  height: 100%;
  background: inherit;
  box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.5);
}

[ui-view='submenus']:before {
  left: -1rem;
}

[ui-view='submenus']:after {
  right: -1rem;
}

.article-submenu {
  display: flex;
  list-style-type: none;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0 3%;
  overflow: auto;
  border-bottom: 1px solid #DDE0E5;
  background: #fff;
}

@media (min-width: 767px) {
  .article-submenu {
    padding: 0 8%;
  }
}

@media (max-width: 329px) {
  .article-submenu {
    padding: 0 2px;
  }
}

@media (min-width: 1100px) {
  .article-submenu {
    display: none;
  }
}

.article-submenu--menuitem {
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

.article-submenu--menuitem.active a {
  color: #00ADEF;
}

.article-submenu--menuitem a {
  display: block;
  padding: 0.8rem;
  font-size: 13px;
  font-weight: 600;
  color: #38444B;
}

body.register #content-container {
  background-color: #0d1a22;
}

body.register #content-container .flyout__field-input input[type="color"], body.register #content-container .flyout__field-input input[type="date"], body.register #content-container .flyout__field-input input[type="datetime"], body.register #content-container .flyout__field-input input[type="datetime-local"], body.register #content-container .flyout__field-input input[type="email"], body.register #content-container .flyout__field-input input[type="month"], body.register #content-container .flyout__field-input input[type="number"], body.register #content-container .flyout__field-input input[type="password"], body.register #content-container .flyout__field-input input[type="search"], body.register #content-container .flyout__field-input input[type="tel"], body.register #content-container .flyout__field-input input[type="text"], body.register #content-container .flyout__field-input input[type="time"], body.register #content-container .flyout__field-input input[type="url"], body.register #content-container .flyout__field-input input[type="week"], body.register #content-container .flyout__field-input input:not([type]), body.register #content-container .flyout__field-input textarea, body.register #content-container .flyout__field-input .inputElement, body.register #content-container .flyout__field-input [contenteditable=true],
body.register #content-container .flyout__field-input .select-holder {
  background-color: #263138;
  border-color: transparent;
}

.register-block {
  max-width: 670px;
  min-height: 85vh;
  margin: auto;
  padding-top: 35px;
  color: #fff;
}

.register-block h1 {
  margin-bottom: 25px;
  text-align: center;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  font-size: 23px;
  font-weight: 300;
  color: #fff;
}

.register-block label.checkbox::before,
.register-block label.checkbox::after {
  filter: invert(1);
}

.register-block .flyout__field.field-invalid label.checkbox:before {
  filter: invert(13%) sepia(89%) saturate(5552%) hue-rotate(354deg) brightness(101%) contrast(87%);
}

.register-block .flyout__group-field,
.register-block .flyout__group-field-note {
  width: auto;
  max-width: 100%;
  color: #fff;
  opacity: 1;
}

.register-block .flyout__group-field-note {
  margin-top: 16px;
  margin-bottom: 16px;
  opacity: 0.3;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 1100px) {
  .register-block .flyout__group-field-note {
    margin-bottom: 26px;
  }
}

.register-footer {
  padding-top: 10px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.register-footer .btn {
  display: inline-block;
  min-width: 200px;
  font-size: 12px !important;
}

.register-footer .terms-checkbox {
  padding-bottom: 15px;
}

.register-footer .terms-checkbox .checkbox {
  line-height: 1.25;
  padding-right: 0;
  font-size: 14px;
}

.default-layout footer {
  position: relative;
}

@media (min-width: 1100px) {
  .register-body {
    margin-left: -2px;
    margin-right: -2px;
  }
  .register-account {
    margin-top: 47px;
  }
}

@media (max-width: 1099px) {
  .register-block .account-photo {
    margin-bottom: 25px;
  }
}

.register-block .account-photo {
  margin-bottom: 10px;
}

.register-block .account-photo__edit, .register-block .account-photo__active {
  max-width: 90px;
  max-height: 90px;
}

.register-block .account-photo__block {
  max-width: 90px;
  left: 40px;
}

.register-block .account-photo__block .svg-photo {
  width: 150px;
  height: 150px;
}

.register-block .account-photo .image-crop {
  width: 90px;
  height: 90px;
}

.register-content.grid {
  column-gap: 20px;
  margin-left: 0;
  justify-content: center;
  width: 100%;
}

.register-content.grid > [class*='grid'] {
  display: flex;
  margin-left: 0;
  width: 100%;
}

.register-content.grid > [class*='grid'] fieldset {
  display: flex;
  flex-direction: column;
  width: 100%;
}

[class*='__field'] .select-holder {
  display: block;
}

.register-block a:not([class]) {
  color: #fff;
  text-decoration: underline;
}

.register-block a:not([class]):hover {
  text-decoration: none;
}

html.ie-browser body.register #content-container .flyout__field-input input[type="color"], html.ie-browser body.register #content-container .flyout__field-input input[type="date"], html.ie-browser body.register #content-container .flyout__field-input input[type="datetime"], html.ie-browser body.register #content-container .flyout__field-input input[type="datetime-local"], html.ie-browser body.register #content-container .flyout__field-input input[type="email"], html.ie-browser body.register #content-container .flyout__field-input input[type="month"], html.ie-browser body.register #content-container .flyout__field-input input[type="number"], html.ie-browser body.register #content-container .flyout__field-input input[type="password"], html.ie-browser body.register #content-container .flyout__field-input input[type="search"], html.ie-browser body.register #content-container .flyout__field-input input[type="tel"], html.ie-browser body.register #content-container .flyout__field-input input[type="text"], html.ie-browser body.register #content-container .flyout__field-input input[type="time"], html.ie-browser body.register #content-container .flyout__field-input input[type="url"], html.ie-browser body.register #content-container .flyout__field-input input[type="week"], html.ie-browser body.register #content-container .flyout__field-input input:not([type]), html.ie-browser body.register #content-container .flyout__field-input textarea, html.ie-browser body.register #content-container .flyout__field-input .inputElement, html.ie-browser body.register #content-container .flyout__field-input [contenteditable=true],
html.ie-browser body.register #content-container .flyout__field-input .select-holder {
  background-color: #fff;
  border-color: transparent;
  color: #000;
}

.flyout {
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 1000;
  transition: all 0.5s ease-in-out 0.5s;
}

.flyout__overlay {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.25);
  transition: all 0.5s ease-in-out;
  opacity: 0;
  z-index: -1;
  visibility: hidden;
}

.flyout--open .flyout__overlay {
  opacity: 1;
  visibility: visible;
}

.flyout__block {
  position: relative;
  height: 100%;
  z-index: 1;
  background: #2D363C;
}

@media (min-width: 1100px) {
  .flyout__block {
    border-left: 1px solid rgba(255, 255, 255, 0.1);
  }
}

.flyout__content {
  overflow: auto;
  height: 100%;
}

@media (min-width: 1100px) {
  .flyout {
    left: -680px;
  }
  .flyout__content {
    padding: 2.5rem 4rem 5rem;
  }
  .flyout .site-logo {
    display: none;
  }
  body.home .flyout, body.support .flyout {
    left: auto;
    right: -680px;
  }
  .flyout--open {
    left: 340px;
  }
  body.home .flyout--open, body.support .flyout--open {
    left: unset;
    right: 0;
  }
}

@media (max-width: 1099px) {
  .flyout {
    width: 100%;
    right: -100%;
  }
  .flyout__content {
    padding: 7.5rem 2.5rem 5rem;
  }
}

@media (max-width: 1099px) and (min-width: 450px) {
  .flyout {
    max-width: 480px;
  }
}

@media (max-width: 1099px) {
  .flyout--open {
    right: 0;
  }
  .flyout .site-logo {
    display: block;
    position: absolute;
    top: 1.125rem;
    right: 2.25rem;
    width: 1.5rem;
  }
}

.flyout__group-field {
  display: flex;
  width: 100%;
}

@media (min-width: 1100px) {
  .flyout__group-field {
    width: 325px;
  }
}

.flyout__group-field svg {
  width: 38px;
  height: 38px;
  fill: #bfbfbf;
}

.field-invalid .flyout__group-field svg {
  fill: #ED1E23;
}

.modal-content .flyout__group-field svg,
.form-field.form-field--light .flyout__group-field svg {
  fill: #bfbfbf;
}

.flyout__group-field-note {
  max-width: 270px;
  color: #FFFFFF;
  opacity: 0.3;
  font-size: 13px;
  letter-spacing: 0.25px;
  line-height: 18px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.flyout__group-field-note--fluid {
  max-width: unset;
  text-align: left;
}

.find-user-form .flyout__group-field {
  max-width: 350px;
  width: 100%;
}

.flyout__field-input {
  position: relative;
  flex-grow: 1;
}

.flyout__field-input--has-icon input[type="color"], .flyout__field-input--has-icon input[type="date"], .flyout__field-input--has-icon input[type="datetime"], .flyout__field-input--has-icon input[type="datetime-local"], .flyout__field-input--has-icon input[type="email"], .flyout__field-input--has-icon input[type="month"], .flyout__field-input--has-icon input[type="number"], .flyout__field-input--has-icon input[type="password"], .flyout__field-input--has-icon input[type="search"], .flyout__field-input--has-icon input[type="tel"], .flyout__field-input--has-icon input[type="text"], .flyout__field-input--has-icon input[type="time"], .flyout__field-input--has-icon input[type="url"], .flyout__field-input--has-icon input[type="week"], .flyout__field-input--has-icon input:not([type]), .flyout__field-input--has-icon textarea, .flyout__field-input--has-icon .inputElement, .flyout__field-input--has-icon [contenteditable=true] {
  padding-left: 28px !important;
}

.flyout__field-input-icon {
  position: absolute;
  top: 50%;
  left: 15px;
  margin-top: -19px;
}

.form-field.form-field--xs .flyout__field-input-icon {
  left: 5px;
  margin-top: -12px;
}

.flyout__field-input input[type="color"], .flyout__field-input input[type="date"], .flyout__field-input input[type="datetime"], .flyout__field-input input[type="datetime-local"], .flyout__field-input input[type="email"], .flyout__field-input input[type="month"], .flyout__field-input input[type="number"], .flyout__field-input input[type="password"], .flyout__field-input input[type="search"], .flyout__field-input input[type="tel"], .flyout__field-input input[type="text"], .flyout__field-input input[type="time"], .flyout__field-input input[type="url"], .flyout__field-input input[type="week"], .flyout__field-input input:not([type]), .flyout__field-input textarea, .flyout__field-input .inputElement, .flyout__field-input [contenteditable=true] {
  margin: 0;
  padding-left: 60px;
  padding-right: 50px;
  background: #303f4c;
  color: #fff;
  border-color: transparent;
}

.flyout__field-input input[type="color"]:focus, .flyout__field-input input[type="date"]:focus, .flyout__field-input input[type="datetime"]:focus, .flyout__field-input input[type="datetime-local"]:focus, .flyout__field-input input[type="email"]:focus, .flyout__field-input input[type="month"]:focus, .flyout__field-input input[type="number"]:focus, .flyout__field-input input[type="password"]:focus, .flyout__field-input input[type="search"]:focus, .flyout__field-input input[type="tel"]:focus, .flyout__field-input input[type="text"]:focus, .flyout__field-input input[type="time"]:focus, .flyout__field-input input[type="url"]:focus, .flyout__field-input input[type="week"]:focus, .flyout__field-input input:not([type]):focus, .flyout__field-input textarea:focus, .flyout__field-input .inputElement:focus, .flyout__field-input [contenteditable=true]:focus {
  border-color: transparent;
}

.flyout__field-input input[type="color"]:hover, .flyout__field-input input[type="date"]:hover, .flyout__field-input input[type="datetime"]:hover, .flyout__field-input input[type="datetime-local"]:hover, .flyout__field-input input[type="email"]:hover, .flyout__field-input input[type="month"]:hover, .flyout__field-input input[type="number"]:hover, .flyout__field-input input[type="password"]:hover, .flyout__field-input input[type="search"]:hover, .flyout__field-input input[type="tel"]:hover, .flyout__field-input input[type="text"]:hover, .flyout__field-input input[type="time"]:hover, .flyout__field-input input[type="url"]:hover, .flyout__field-input input[type="week"]:hover, .flyout__field-input input:not([type]):hover, .flyout__field-input textarea:hover, .flyout__field-input .inputElement:hover, .flyout__field-input [contenteditable=true]:hover {
  border-color: #999;
}

.flyout__field-input input[type="color"]::placeholder, .flyout__field-input input[type="date"]::placeholder, .flyout__field-input input[type="datetime"]::placeholder, .flyout__field-input input[type="datetime-local"]::placeholder, .flyout__field-input input[type="email"]::placeholder, .flyout__field-input input[type="month"]::placeholder, .flyout__field-input input[type="number"]::placeholder, .flyout__field-input input[type="password"]::placeholder, .flyout__field-input input[type="search"]::placeholder, .flyout__field-input input[type="tel"]::placeholder, .flyout__field-input input[type="text"]::placeholder, .flyout__field-input input[type="time"]::placeholder, .flyout__field-input input[type="url"]::placeholder, .flyout__field-input input[type="week"]::placeholder, .flyout__field-input input:not([type])::placeholder, .flyout__field-input textarea::placeholder, .flyout__field-input .inputElement::placeholder, .flyout__field-input [contenteditable=true]::placeholder {
  color: #aaa;
}

.modal-content .flyout__field-input input[type="color"],
.form-field.form-field--light .flyout__field-input input[type="color"], .modal-content .flyout__field-input input[type="date"],
.form-field.form-field--light .flyout__field-input input[type="date"], .modal-content .flyout__field-input input[type="datetime"],
.form-field.form-field--light .flyout__field-input input[type="datetime"], .modal-content .flyout__field-input input[type="datetime-local"],
.form-field.form-field--light .flyout__field-input input[type="datetime-local"], .modal-content .flyout__field-input input[type="email"],
.form-field.form-field--light .flyout__field-input input[type="email"], .modal-content .flyout__field-input input[type="month"],
.form-field.form-field--light .flyout__field-input input[type="month"], .modal-content .flyout__field-input input[type="number"],
.form-field.form-field--light .flyout__field-input input[type="number"], .modal-content .flyout__field-input input[type="password"],
.form-field.form-field--light .flyout__field-input input[type="password"], .modal-content .flyout__field-input input[type="search"],
.form-field.form-field--light .flyout__field-input input[type="search"], .modal-content .flyout__field-input input[type="tel"],
.form-field.form-field--light .flyout__field-input input[type="tel"], .modal-content .flyout__field-input input[type="text"],
.form-field.form-field--light .flyout__field-input input[type="text"], .modal-content .flyout__field-input input[type="time"],
.form-field.form-field--light .flyout__field-input input[type="time"], .modal-content .flyout__field-input input[type="url"],
.form-field.form-field--light .flyout__field-input input[type="url"], .modal-content .flyout__field-input input[type="week"],
.form-field.form-field--light .flyout__field-input input[type="week"], .modal-content .flyout__field-input input:not([type]),
.form-field.form-field--light .flyout__field-input input:not([type]), .modal-content .flyout__field-input textarea,
.form-field.form-field--light .flyout__field-input textarea, .modal-content .flyout__field-input .inputElement,
.form-field.form-field--light .flyout__field-input .inputElement, .modal-content .flyout__field-input [contenteditable=true],
.form-field.form-field--light .flyout__field-input [contenteditable=true] {
  padding-top: 16px;
  padding-bottom: 16px;
  background: #fff;
  color: #414141;
  border: 1px solid #fff;
}

.modal-content .flyout__field-input input[type="color"]:hover,
.form-field.form-field--light .flyout__field-input input[type="color"]:hover, .modal-content .flyout__field-input input[type="date"]:hover,
.form-field.form-field--light .flyout__field-input input[type="date"]:hover, .modal-content .flyout__field-input input[type="datetime"]:hover,
.form-field.form-field--light .flyout__field-input input[type="datetime"]:hover, .modal-content .flyout__field-input input[type="datetime-local"]:hover,
.form-field.form-field--light .flyout__field-input input[type="datetime-local"]:hover, .modal-content .flyout__field-input input[type="email"]:hover,
.form-field.form-field--light .flyout__field-input input[type="email"]:hover, .modal-content .flyout__field-input input[type="month"]:hover,
.form-field.form-field--light .flyout__field-input input[type="month"]:hover, .modal-content .flyout__field-input input[type="number"]:hover,
.form-field.form-field--light .flyout__field-input input[type="number"]:hover, .modal-content .flyout__field-input input[type="password"]:hover,
.form-field.form-field--light .flyout__field-input input[type="password"]:hover, .modal-content .flyout__field-input input[type="search"]:hover,
.form-field.form-field--light .flyout__field-input input[type="search"]:hover, .modal-content .flyout__field-input input[type="tel"]:hover,
.form-field.form-field--light .flyout__field-input input[type="tel"]:hover, .modal-content .flyout__field-input input[type="text"]:hover,
.form-field.form-field--light .flyout__field-input input[type="text"]:hover, .modal-content .flyout__field-input input[type="time"]:hover,
.form-field.form-field--light .flyout__field-input input[type="time"]:hover, .modal-content .flyout__field-input input[type="url"]:hover,
.form-field.form-field--light .flyout__field-input input[type="url"]:hover, .modal-content .flyout__field-input input[type="week"]:hover,
.form-field.form-field--light .flyout__field-input input[type="week"]:hover, .modal-content .flyout__field-input input:not([type]):hover,
.form-field.form-field--light .flyout__field-input input:not([type]):hover, .modal-content .flyout__field-input textarea:hover,
.form-field.form-field--light .flyout__field-input textarea:hover, .modal-content .flyout__field-input .inputElement:hover,
.form-field.form-field--light .flyout__field-input .inputElement:hover, .modal-content .flyout__field-input [contenteditable=true]:hover,
.form-field.form-field--light .flyout__field-input [contenteditable=true]:hover {
  border-color: transparent;
}

.form-field.form-field--xs .flyout__field-input input[type="color"], .form-field.form-field--xs .flyout__field-input input[type="date"], .form-field.form-field--xs .flyout__field-input input[type="datetime"], .form-field.form-field--xs .flyout__field-input input[type="datetime-local"], .form-field.form-field--xs .flyout__field-input input[type="email"], .form-field.form-field--xs .flyout__field-input input[type="month"], .form-field.form-field--xs .flyout__field-input input[type="number"], .form-field.form-field--xs .flyout__field-input input[type="password"], .form-field.form-field--xs .flyout__field-input input[type="search"], .form-field.form-field--xs .flyout__field-input input[type="tel"], .form-field.form-field--xs .flyout__field-input input[type="text"], .form-field.form-field--xs .flyout__field-input input[type="time"], .form-field.form-field--xs .flyout__field-input input[type="url"], .form-field.form-field--xs .flyout__field-input input[type="week"], .form-field.form-field--xs .flyout__field-input input:not([type]), .form-field.form-field--xs .flyout__field-input textarea, .form-field.form-field--xs .flyout__field-input .inputElement, .form-field.form-field--xs .flyout__field-input [contenteditable=true] {
  padding: 8.25px 3em 8.25px 28px;
  font-size: 14px;
}

.flyout__field-input-model--no-icon {
  padding-left: 20px !important;
}

.flyout__field-input-model--busy {
  pointer-events: none;
}

.flyout__field-input-clear {
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: -19px;
  cursor: pointer;
}

.flyout__group-field--search .flyout__field-input-clear:before {
  display: none;
}

.field-invalid .flyout__field-input-clear-icon {
  fill: #ED1E23;
}

.form-field.form-field--xs .flyout__field-input-clear {
  right: 7px;
  margin-top: -12px;
}

.flyout__field-input .select-holder {
  margin: 0;
  background: #303f4c;
  color: #fff;
}

.form-field.form-field--light .flyout__field-input .select-holder {
  background: #fff;
  color: #414141;
  border: 1px solid #fff;
}

.flyout__field-input .select-holder select {
  color: inherit;
}

.modal-content .flyout__field-input .select-holder {
  background: #fff;
  color: #414141;
  border: 1px solid #fff;
}

.flyout__field-input > .loading {
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -12px;
  border-color: #00ADEF rgba(255, 255, 255, 0.2) rgba(255, 255, 255, 0.2);
}

.flyout__field-dropdown {
  background: #243542;
  border: 2px solid #243542;
  padding: 0 15px;
}

.flyout__field-dropdown-icon {
  width: 26px !important;
  height: 26px !important;
}

.modal-content .flyout__field-dropdown,
.form-field.form-field--light .flyout__field-dropdown {
  background: #00ADEF;
  border-color: #00ADEF;
  padding: 0 10px;
  cursor: pointer;
}

.modal-content .flyout__field-dropdown-icon,
.form-field.form-field--light .flyout__field-dropdown-icon {
  width: 36px !important;
  height: 36px !important;
  fill: #fff !important;
}

.form-field.form-field--xs .flyout__field-dropdown {
  padding: 0 5px;
}

.form-field.form-field--xs .flyout__field-dropdown-icon {
  width: 28px !important;
  height: 28px !important;
}

.flyout__suggestion {
  margin-top: 2.5rem;
  max-width: 325px;
}

.flyout__suggestion h4 {
  margin-bottom: 1.25rem;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
}

.flyout__suggestion-list {
  list-style: none;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.flyout__suggestion-list li {
  margin: inherit;
  padding: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.flyout__suggestion-list li a {
  display: block;
  padding: 1rem;
  color: #fff;
  font-size: 14.5px;
}

.flyout__suggestion-list li a .highlight-text {
  font-weight: bold;
}

.flyout__suggestion-list li a:hover {
  background-color: #00ADEF;
}

.flyout__suggestion-blurb {
  font-size: 12px;
  margin-top: 5px;
}

.flyout__close {
  position: absolute;
  cursor: pointer;
}

body.home .flyout__close, body.support .flyout__close {
  left: 2.25rem;
  right: auto;
  transform-origin: center;
  transform: rotate(180deg);
}

@media (min-width: 1100px) {
  .flyout__close {
    bottom: 1.75rem;
    right: 2.25rem;
  }
}

@media (max-width: 1099px) {
  .flyout__close {
    top: 1.125rem;
    left: 2.25rem;
  }
}

.flyout__close-icon {
  width: 40px;
  height: 40px;
  fill: #98A9BC;
}

@media (max-width: 1099px) {
  .flyout__close-icon {
    width: 34px;
    height: 34px;
  }
}

.flyout__close-icon--arrow {
  display: none;
}

@media (min-width: 1100px) {
  .flyout__close-icon--arrow {
    display: block;
  }
}

@media (min-width: 1100px) {
  .flyout__close-icon--close {
    display: none;
  }
}

button[class*='--no-style'] {
  background: none;
  border: none;
  outline: none;
}

.flyout__account {
  margin: 0 auto 50px;
}

@media (min-width: 1100px) {
  .flyout__account {
    min-width: 325px;
  }
}

.flyout__fielset {
  max-width: 325px;
  margin-top: 20px;
}

.flyout__field {
  position: relative;
  margin-top: 15px;
}

.flyout__field-label {
  color: #ffffff;
  display: block;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 5px;
}

.modal-content .flyout__field-label,
.form-field.form-field--light .flyout__field-label {
  color: #414141;
}

.account__buttons {
  margin-top: 60px;
  position: relative;
  text-align: center;
  z-index: 1;
}

.account__buttons > .btn-account:not(:first-child) {
  margin-left: 15px;
}

.account__buttons--main > .btn-account {
  min-width: 200px;
}

.account-info {
  margin-top: 40px;
}

.account-info__field {
  display: flex;
  padding: 15px 0;
  align-items: center;
}

.account-info__icon {
  margin-right: 10px;
  height: 38px;
  width: 38px;
  fill: rgba(255, 255, 255, 0.5);
}

.account-info__value {
  color: #ffffff;
  font-size: 14px;
  line-height: 18px;
}

.account-photo__block {
  position: relative;
  max-width: 80px;
  margin: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 1100px) {
  .account-photo__block {
    max-width: 120px;
  }
}

.account-photo__block:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #2D363C;
  border-radius: 50%;
  z-index: 1;
}

body.register .account-photo__block:before {
  background: #0d1a22;
}

.account-photo__block .svg-photo {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 140px;
  height: 140px;
  fill: rgba(255, 255, 255, 0.5);
  transform: translate(-50%, -50%);
  z-index: 2;
  pointer-events: none;
}

@media (min-width: 1100px) {
  .account-photo__block .svg-photo {
    width: 210px;
    height: 210px;
  }
}

.account-photo__edit {
  position: absolute;
  top: 0;
  left: -90%;
  display: flex;
  align-content: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background-color: rgba(255, 255, 255, 0.125);
  border-radius: 50%;
  cursor: pointer;
  z-index: 0;
}

@media (min-width: 1100px) {
  .account-photo__edit {
    width: 120px;
    height: 120px;
  }
}

.account-photo__edit__icon {
  align-self: center;
  width: 38px;
  height: 38px;
  fill: rgba(255, 255, 255, 0.325);
}

.account-photo__active {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  max-width: 80px;
  max-height: 80px;
  transform: translate(-50%, -50%);
  z-index: 2;
}

@media (min-width: 1100px) {
  .account-photo__active {
    max-width: 120px;
    max-height: 120px;
  }
}

.account-photo__update {
  position: relative;
  opacity: 0;
  visibility: hidden;
  z-index: 3;
}

.account-photo__update--active {
  opacity: 1;
  visibility: visible;
}

.btn-account {
  padding: 15px;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 2px;
  text-align: center;
  color: #00ADEF;
  height: 55px;
  border: 1px solid #00ADEF;
  min-width: 90px;
  text-transform: uppercase;
  background: none;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.btn-account:hover {
  color: #fff;
  background: #00ADEF;
}

.btn-account--alt {
  color: #fff;
  border-color: #fff;
  opacity: 0.5;
}

.btn-account--alt:hover {
  color: #fff;
  border-color: #fff;
  background: none;
  opacity: 1;
}

.image-crop {
  width: 80px;
  height: 80px;
}

@media (min-width: 1100px) {
  .image-crop {
    width: 120px;
    height: 120px;
  }
}

.image-crop img {
  border-radius: 0 !important;
}

.form-field--xs svg {
  width: 24px;
  height: 24px;
}

.sidebar-pusher--minimal ~ .flyout-ui .flyout--open {
  left: 75px;
}

.blog-list {
  margin: 0;
  padding: 0;
  list-style: none;
  color: #38444B;
}

.blog-list > li {
  margin: 0;
  padding-bottom: 15px;
  font-size: 20px;
}

.blog-list > li a {
  display: inline-block;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  border-bottom: 1px solid #38444B;
  line-height: 0.8;
}

.blog-list > li a:hover {
  border-bottom: 1px solid transparent;
}

.pagination-list {
  margin: 50px 0 0;
  padding: 0;
  list-style: none;
  font-size: 20px;
  text-align: center;
}

.pagination-list > li {
  display: inline-block;
  vertical-align: middle;
  font-weight: 600;
}

.pagination-list > li > span {
  display: block;
  padding: 3px;
  cursor: pointer;
}

.pagination-list > li > span:not(.active) {
  opacity: 0.75;
}

.pagination-list > li > span svg {
  width: 16px;
  height: 16px;
}

.pagination-list > li > span svg + svg {
  margin-left: -16px;
}

.team-block__alert {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1.25;
}

.team-block__alert.alert:before {
  top: 20px;
}

.team-block__alert .btn:before {
  display: none;
}

.team-block table .prevent-user-delete {
  pointer-events: none;
}

.team-block table .prevent-user-delete .checkbox {
  padding: 0;
  margin: 0;
}

.team-block table .prevent-user-delete .checkbox:before, .team-block table .prevent-user-delete .checkbox:after {
  display: none;
}

.team-block table td {
  padding: 15px;
  font-size: 16px;
  border: 2px solid #F5F6F5;
}

.team-block table td:first-child {
  background: #EBEBEB;
}

.team-block table td .checkbox {
  padding-left: 28px;
}

.team-block table td.td-current-user {
  position: relative;
  color: #00ADEF;
  font-weight: 600;
}

.team-block table td.td-current-user label {
  display: flex;
  align-items: flex-start;
}

.team-block table td.td-current-user svg {
  fill: #00ADEF;
  width: 36px;
  height: 36px;
  margin: -10px 5px -10px -5px;
  flex-shrink: 0;
}

.team-block table th {
  padding: 12px 15px;
  background: #E1E1E1;
  border: 2px solid #F5F6F5;
  font-size: 17px;
  font-weight: 600;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  color: #262626;
}

.team-block__member-count {
  list-style-type: none;
  margin: 0 0 40px;
  padding: 0;
  display: flex;
}

.team-block__member-count > li {
  margin: 0;
  padding: 0;
}

.team-block__member-count > li + li {
  margin-left: 40px;
}

.team-controls {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

@media (min-width: 1000px) {
  .team-controls {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.team-controls > li {
  margin: 0;
  width: 100%;
}

@media (min-width: 1000px) {
  .team-controls > li {
    width: auto;
    padding: 20px;
  }
}

.team-controls .btn {
  width: 100%;
  padding: 25px 50px;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  white-space: nowrap;
  font-weight: 600 !important;
}

.team-controls .btn-disabled {
  border-color: #bfbfbf !important;
  color: #bfbfbf !important;
  pointer-events: none;
}

.modal,
modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  z-index: 9999;
}

.modal-block {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.modal-container {
  position: relative;
  background: #ebebeb;
  max-width: 768px;
  width: calc(100% - 40px);
}

@media (min-width: 767px) {
  .modal-container {
    width: calc(100% - 10px);
  }
}

.modal-container .modal-content {
  padding: 15px;
}

@media (min-width: 767px) {
  .modal-container .modal-content {
    padding: 22px;
  }
}

@media (min-width: 1000px) {
  .modal-container .modal-content {
    padding: 36px;
  }
}

.modal-container .modal-body {
  max-height: 400px;
  overflow: hidden;
  overflow-y: auto;
}

@media (min-width: 767px) {
  .modal-container .modal-body {
    max-height: 580px;
  }
}

.modal-container table {
  width: auto;
}

.modal-container table label.no-label.radio:before,
.modal-container table label.no-label.checkbox:before,
.modal-container table label.no-label.radio:after,
.modal-container table label.no-label.checkbox:after {
  top: -15px;
  left: -1px;
}

.modal-container table tr {
  background: none;
}

.modal-close {
  position: absolute;
  top: 0;
  z-index: 99;
  right: 0;
  border: none;
  outline: 0;
  cursor: pointer;
}

.modal-close:hover:not(:disabled) svg {
  opacity: 0.5;
}

.modal-close svg {
  width: 36px;
  height: 36px;
  pointer-events: none;
  opacity: 0.35;
}

.list-unstyled {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.list-unstyled > li {
  margin-left: 0;
}

.list-inline {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.list-inline > li {
  display: inline-block !important;
  width: auto !important;
  border: unset !important;
}

confirm-user-delete-footer {
  margin-top: 15px;
  display: block;
}

@media (min-width: 767px) {
  confirm-user-delete-footer {
    display: flex;
  }
  confirm-user-delete-footer .form-note {
    margin-bottom: 0;
    max-width: 320px;
  }
}

confirm-user-delete-footer .form-note {
  font-size: 13px;
  color: #666;
  margin-right: auto;
  margin-bottom: 15px;
}

find-user-body {
  display: block;
  width: 100%;
}

find-user-body table {
  table-layout: auto;
}

@media (max-width: 999px) {
  find-user-body table {
    width: 567px !important;
  }
}

.modal-container find-user-body table {
  width: 100%;
  font-size: 14px;
}

@media (max-width: 999px) {
  .team-block thead {
    display: none;
  }
  .team-block table, .team-block tbody, .team-block tr, .team-block th, .team-block td {
    display: block;
  }
  .team-block td, .team-block th {
    font-size: 14px;
    padding: 12px 15px 10px;
  }
  .team-block tr {
    display: flex;
    margin-bottom: 10px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.024) 0px 2px 4px 0px;
    flex-wrap: wrap;
  }
  .team-block td {
    width: 100%;
  }
  .team-block td:before {
    content: attr(data-title);
    display: block;
    margin-bottom: 5px;
    opacity: 0.5;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }
  .team-block td[data-title='Name'] {
    font-weight: bold;
  }
  .find-user-form {
    margin-bottom: 10px;
  }
  .find-user-form .form-row {
    flex-flow: row nowrap;
  }
  .find-user-form .form-row .form-cell {
    width: auto;
  }
  .find-user-form .form-row .form-cell input[type="color"], .find-user-form .form-row .form-cell input[type="date"], .find-user-form .form-row .form-cell input[type="datetime"], .find-user-form .form-row .form-cell input[type="datetime-local"], .find-user-form .form-row .form-cell input[type="email"], .find-user-form .form-row .form-cell input[type="month"], .find-user-form .form-row .form-cell input[type="number"], .find-user-form .form-row .form-cell input[type="password"], .find-user-form .form-row .form-cell input[type="search"], .find-user-form .form-row .form-cell input[type="tel"], .find-user-form .form-row .form-cell input[type="text"], .find-user-form .form-row .form-cell input[type="time"], .find-user-form .form-row .form-cell input[type="url"], .find-user-form .form-row .form-cell input[type="week"], .find-user-form .form-row .form-cell input:not([type]), .find-user-form .form-row .form-cell textarea, .find-user-form .form-row .form-cell .inputElement, .find-user-form .form-row .form-cell [contenteditable=true] {
    min-width: auto;
  }
}

.tr-title {
  cursor: pointer;
}

.tr-title > td {
  position: relative;
  padding: 25px 15px 15px !important;
  font-weight: 600;
  font-size: 12px !important;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #909090;
  background: #f4f5f7 url("../assets/svg/Icons/Angle Down.svg") no-repeat right 15px top 23px/20px !important;
}

.tr-title--active > td {
  background-image: url("../assets/svg/Icons/Angle Up.svg") !important;
}

.find-user__controls {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 5px 0 0;
  justify-content: center;
  align-items: center;
}

@media (min-width: 767px) {
  .find-user__controls {
    margin: 20px 0 0;
  }
}

.find-user__controls > li {
  padding: 0;
  margin: 0;
}

.find-user__controls .btn {
  position: relative;
  padding: 5px;
  margin-left: 5px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 12px;
  text-indent: 2px;
}

.find-user__controls .btn.btn-disabled {
  pointer-events: none;
  border-color: #bfbfbf;
  color: #bfbfbf;
}

.find-user__controls .btn.btn-disabled svg {
  fill: #bfbfbf;
}

.find-user__controls .btn svg {
  fill: #00ADEF;
  width: 24px;
  height: 24px;
}

.find-user__controls .btn.btn-double-icon svg:nth-child(1) {
  transform: translateX(-3px);
}

.find-user__controls .btn.btn-double-icon svg:nth-child(2) {
  position: absolute;
  transform: translateX(3px);
}

.find-user__controls .btn:hover svg {
  fill: #fff;
}

.find-user__controls--pull-left {
  margin-right: auto !important;
}

.find-user__controls--pull-right {
  margin-left: auto !important;
}

.find-user__entries {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.find-user__entries .select-holder {
  margin: 0 8px;
  min-width: 60px;
}

.find-user__entries .select-holder:before {
  font-size: 12px;
  top: 20px;
  right: 8px;
}

.find-user__entries .select-holder select {
  height: 34px;
  padding: 0 10px;
}

.find-user__search-results table tbody::-webkit-scrollbar {
  width: 2px;
}

.find-user__search-results table tbody::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: white;
  border-radius: 10px;
}

.find-user__search-results table tbody::-webkit-scrollbar-thumb {
  background: rgba(0, 173, 239, 0.75);
  border-radius: 10px;
}

.find-user__search-results .page-alert {
  margin-top: 10px;
}

.find-user__search-results .page-alert:before {
  top: 15px;
}

.find-user__name {
  display: flex;
  margin-left: -15px;
}

.find-user__tick {
  display: block;
  margin-top: -2px;
}

@-moz-document url-prefix() {
  .find-user__search-results table tbody[style] {
    margin-right: -15px;
  }
}

.tr-team-title {
  display: flex;
  justify-content: space-between;
}

.tr-report-count {
  margin-right: 30px;
  font-size: 120%;
}

.modal-header h3 {
  display: flex;
  padding-top: 0;
  font-size: 18px !important;
  align-items: center;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  color: #38444B;
}

@media (max-width: 766px) {
  .modal-header h3 {
    margin-bottom: 0.25em !important;
  }
}

.modal-header h3 svg {
  margin-right: 2px;
  width: 42px;
  height: 42px;
  margin-left: -12px;
  fill: currentColor;
}

.modal-container .form-field {
  margin-bottom: 15px;
}

.modal-container table thead tr th {
  border-bottom: 1px solid #bfbfbf;
  font-size: 12px !important;
  text-transform: uppercase;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  font-weight: 600;
  letter-spacing: 1px;
}

.modal-container table tbody tr {
  border: none;
}

.modal-container .form-note {
  color: #898989;
  font-size: 12px;
  margin-bottom: 5px;
  margin-top: 12px;
}

@media (min-width: 767px) {
  .modal-container .form-note {
    font-size: 14px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

.modal-container .form-note .form-note__title {
  display: flex;
  margin-bottom: 5px;
  align-items: center;
  font-weight: 600;
}

.modal-container .form-note svg {
  fill: #898989;
  width: 30px;
  height: 30px;
  margin-right: -5px;
  margin-top: -8px;
  margin-left: -5px;
  margin-bottom: -7px;
}

.modal-container .form-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 766px) {
  .modal-container .form-buttons {
    display: grid;
    gap: 0px 11px;
    grid-template-columns: 1fr 1fr;
  }
}

.modal-container .form-buttons .btn {
  font-size: 10px;
  padding: 1.4em 1em;
}

@media (min-width: 767px) {
  .modal-container .form-buttons .btn {
    padding-top: 20px;
    padding-bottom: 20px;
    min-width: 160px;
    font-size: 12px;
  }
  .modal-container .form-buttons .btn + .btn {
    margin-left: 20px;
  }
}

.modal-container .form-buttons--column {
  flex-direction: column;
}

.modal-container .form-buttons__divider {
  margin: 1em 0;
  color: #777;
}

@media (min-width: 1100px) {
  .team-name {
    display: flex;
  }
  .team-name .form-field {
    margin-left: 1em;
    flex-grow: 1;
  }
}

@media (max-width: 1099px) {
  .team-name {
    margin-bottom: 2rem;
  }
  .team-name h3 {
    margin-bottom: 0.75rem;
  }
  .team-name .flyout__field-input-icon {
    display: none;
  }
  .team-name .flyout__field-input-model {
    padding: 0.75rem;
  }
}

.team-name .flyout__group-field {
  max-width: 600px;
  width: 100%;
}

.team-name button {
  padding: 0 1em !important;
  color: white;
  letter-spacing: 2px;
  font-size: 13px;
}

.table-scroll-wrap {
  overflow: auto;
}

.table-scroll thead {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.table-scroll tbody {
  max-height: 35vh;
  overflow-y: auto;
  display: block;
  width: 100%;
  table-layout: fixed;
}

.table-scroll tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.table-scroll td {
  height: 40px;
  overflow-wrap: break-word;
}

@media (min-width: 1100px) {
  .table-scroll td {
    height: 47px;
  }
}

body.findUser--open .modal-container {
  max-width: 1024px;
}

.find-user__search-results th:nth-child(1),
.find-user__search-results th:nth-child(2),
.find-user__search-results td:nth-child(1),
.find-user__search-results td:nth-child(2) {
  width: 22.5%;
}

modal-team-name .modal-container,
modal-team-name-required .modal-container {
  max-width: 420px;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(65, 65, 65, 0.125);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-overlay .loading {
  width: 75px;
  height: 75px;
}

.schedule-meeting-modal div.mce-tinymce-inline {
  position: absolute;
  right: 0;
  left: auto !important;
  top: -37px !important;
}

.schedule-meeting-modal .modal-container .modal-body {
  max-height: 500px;
}

.schedule-meeting-btn {
  display: inline-flex;
  align-items: center;
  line-height: 1.2;
}

.schedule-meeting-btn [class~="btn__icon"] {
  width: 1.8rem;
  height: 1.8rem;
  margin-right: -.25rem;
}

.view-level-widget {
  position: fixed;
  bottom: 80px;
  right: 20px;
  z-index: 10;
}

@media (min-width: 1100px) {
  .view-level-widget {
    bottom: 20px;
  }
}

.view-level-widget__button {
  width: 80px;
  height: 80px;
  background: white;
  color: #bfbfbf;
  border: none;
  border-radius: 50%;
  font-size: 8px;
  letter-spacing: 0.5px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 0 30px -15px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.view-level-widget__button:hover {
  color: #00ADEF !important;
}

.view-level-widget__button > span {
  display: block;
  margin-top: -7px;
  font-weight: bold;
}

.view-level-widget__button > svg {
  fill: currentColor !important;
  width: 60px;
  height: 60px;
  margin-top: -10px;
}

.view-level-widget__button--md {
  font-size: 7px;
  width: 56px;
  height: 56px;
}

.view-level-widget__button--md > span {
  margin-top: -5px;
}

.view-level-widget__button--md > svg {
  width: 42px;
  height: 42px;
}

.view-level-widget__options {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: -75%;
  left: 50%;
  transform: translateX(-50%) scale(0.75);
  transition: all 0.75s cubic-bezier(0.34, -0.02, 0, 1.31);
  transform-origin: center;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
}

.view-level-widget__options li {
  margin: 0 0 10px;
  padding: 0;
}

.view-level-widget:hover ul {
  bottom: 100%;
  visibility: visible;
  opacity: 1;
  transform: translateX(-50%) scale(1);
}

.s-suggestions .user-option {
  border-top: 1px solid #EBEBEB;
  padding: 0.325em 0.8em;
}

.s-suggestions {
  position: absolute;
  z-index: 2;
  top: 100%;
  left: 0;
  background: #fff;
  margin: 0;
  padding: 0;
  width: 100%;
  list-style-type: none;
  max-height: 300px;
  overflow: auto;
}

.s-suggestions > li {
  display: block;
  padding: 0.325em 0.8em;
  font-size: 0.8em;
  margin: 0;
  cursor: pointer;
  color: inherit;
  background-color: transparent;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.s-suggestions > li:hover {
  background-color: #00ADEF;
  color: white;
}

.s-suggestions > li.active .user-option {
  background-color: #00ADEF;
  color: white;
}

.s-suggestions > li.active .user-option .user-option {
  color: #333;
  background-color: white;
}

.s-suggestions .s-suggestions--inner {
  margin-right: -0.8em;
  margin-bottom: -0.325em;
  border-left: 1px solid #00ADEF;
  margin-top: 8px;
  flex-direction: column;
}

.s-suggestions .s-suggestions-space {
  width: 10px;
  border-right: 1px solid #00ADEF;
}

.s-suggestions option-user {
  display: flex;
  width: 100%;
}

.s-suggestions .user-option {
  flex: 1;
  font-size: 13px;
}

.s-suggestions .user-option:hover {
  background-color: #00ADEF;
  color: white;
}

.s-suggestions .user-option:hover .user-option {
  color: #333;
  background-color: white;
}

.s-suggestions .user-option-row {
  display: flex;
}

.s-suggestions .user-option-row.center {
  align-items: center;
}

.s-suggestions .user-option-badge {
  color: #333 !important;
  border-radius: 3px;
  font-size: 10px;
  font-weight: 600;
  padding: 2px 4px;
  margin-left: auto;
  text-align: right;
}

.s-suggestions .user-option-name {
  font-weight: 600;
}

.s-suggestions .user-option-function,
.s-suggestions .user-option-team {
  font-size: 10px;
  font-weight: 600;
}

.s-suggestions .user-option-team {
  display: block;
}

.s-suggestions .user-option-team:before {
  display: inline-block;
  content: '';
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background: #00ADEF;
  margin: 0 3px;
}

.s-suggestions .user-option-company {
  margin-left: auto;
}

.user-dropdown .s-suggestions > li {
  padding: 0;
}

.user-dropdown .s-suggestions > li:hover {
  color: inherit;
  background-color: transparent;
}

.legal {
  position: relative;
  background: #fff;
  overflow: auto;
  overscroll-behavior: none;
  width: 100%;
  height: 100%;
}

#block-spsystem-content .field--name-field-ptoc-sections > .field__item:first-child .paragraph--type--legal-section::after,
#block-spsystem-content .field--name-field-ptoc-sections > .field__item:last-child .paragraph--type--legal-section::after {
  display: none;
}

@media (max-width: 767px) {
  .case-study__bottom-grey-section .field--name-field-bottom-grey-section .paragraph--type--person-quote .field--name-field-person-name {
    font-size: 17px;
    line-height: 23px;
  }
  .case-study__bottom-grey-section .field--name-field-bottom-grey-section .paragraph--type--person-quote .field--name-field-person-title {
    font-size: 13px;
    line-height: 18px;
  }
  .case-study__bottom-grey-section .field--name-field-bottom-grey-section .paragraph--type--person-quote.person-image .quote__person .field--name-field-person-image img {
    height: auto;
    margin: -26px 27px 0 0;
    width: 94px;
  }
}

.case-study__bottom-grey-section .field--name-field-bottom-grey-section .paragraph--type--person-quote.person-image .quote__person .quote__person-text {
  text-align: left;
  width: 50%;
}

.case-study__bottom-grey-section .field--name-field-bottom-grey-section .paragraph--type--person-quote.person-image .quote__person .quote__person-text .field--name-field-person-name,
.case-study__bottom-grey-section .field--name-field-bottom-grey-section .paragraph--type--person-quote.person-image .quote__person .quote__person-text .field--name-field-person-title {
  color: #374455;
}

.legal #block-spsystem-page-title {
  background: #00ADEF;
  height: 400px;
  padding-top: 203px;
  text-align: center;
}

.legal #block-spsystem-page-title h1 {
  color: #fff;
  font-size: 60px;
  letter-spacing: .5px;
  line-height: 1;
  position: relative;
  margin: 0 auto;
  text-align: center;
  font-family: 'Panton-Light', Arial, Helvetica, sans-serif;
}

@media (max-width: 767px) {
  .legal #block-spsystem-page-title {
    height: 300px;
    padding-top: 152px;
  }
  .legal #block-spsystem-page-title h1 {
    font-size: 36px;
  }
}

.legal #block-spsystem-page-title h1::before {
  color: #fff;
  content: "Vantaset";
  font-size: 17px;
  letter-spacing: .25px;
  line-height: 23px;
  position: absolute;
  top: -38px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  white-space: nowrap;
}

#block-spsystem-content .field--name-field-ptoc-sections {
  counter-reset: section;
}

#block-spsystem-content .field--name-field-ptoc-sections > .field__item {
  padding: 120px 0 100px;
}

@media (max-width: 767px) {
  #block-spsystem-content .field--name-field-ptoc-sections > .field__item {
    padding: 74px 0 54px;
  }
}

#block-spsystem-content .field--name-field-ptoc-sections > .field__item:first-child {
  background: #f5f5f5;
  padding: 136px 0 170px;
}

@media (max-width: 767px) {
  #block-spsystem-content .field--name-field-ptoc-sections > .field__item:first-child {
    padding: 104px 0 120px;
  }
}

#block-spsystem-content .field--name-field-ptoc-sections > .field__item:last-child .paragraph--type--legal-section {
  padding-bottom: 70px;
}

#block-spsystem-content .field--name-field-ptoc-sections > .field__item .paragraph--type--legal-section {
  margin: 0 auto;
  position: relative;
  width: 780px;
}

#block-spsystem-content .field--name-field-ptoc-sections > .field__item .paragraph--type--legal-section::after {
  background: #ebebeb;
  bottom: -100px;
  content: "";
  height: 1px;
  position: absolute;
  width: 100%;
}

#block-spsystem-content .field--name-field-ptoc-sections > .field__item .paragraph--type--legal-section .field--name-field-icon {
  left: -120px;
  position: absolute;
  top: -23px;
}

#block-spsystem-content .field--name-field-ptoc-sections > .field__item .paragraph--type--legal-section .field--name-field-ptoc-title {
  font-family: 'Panton-Light', Arial, Helvetica, sans-serif;
  font-weight: 300;
  color: #00ADEF;
  font-size: 33px;
  letter-spacing: .45px;
  line-height: 43px;
  margin-bottom: 21px;
}

@media (max-width: 767px) {
  #block-spsystem-content .field--name-field-ptoc-sections > .field__item:last-child .paragraph--type--legal-section {
    padding-bottom: 54px;
  }
  #block-spsystem-content .field--name-field-ptoc-sections > .field__item .paragraph--type--legal-section {
    padding: 0 33px;
    width: 100%;
  }
  #block-spsystem-content .field--name-field-ptoc-sections > .field__item .paragraph--type--legal-section::after {
    bottom: -60px;
    width: calc(100% - 66px);
  }
  #block-spsystem-content .field--name-field-ptoc-sections > .field__item .paragraph--type--legal-section .field--name-field-ptoc-title {
    font-size: 30px;
    letter-spacing: .5px;
    line-height: 36px;
    margin-bottom: 17px;
  }
}

#block-spsystem-content .field--name-field-ptoc-sections > .field__item .paragraph--type--legal-section .field--name-field-ptoc-body h2 {
  font-family: 'Panton-SemiBold';
  font-weight: 400;
  font-size: 20px;
  letter-spacing: .25px;
  line-height: 28px;
  margin-bottom: 28px;
}

@media (max-width: 767px) {
  #block-spsystem-content .field--name-field-ptoc-sections > .field__item .paragraph--type--legal-section .field--name-field-ptoc-body h2 {
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 18px;
  }
}

#block-spsystem-content .field--name-field-ptoc-sections > .field__item .paragraph--type--legal-section .field--name-field-ptoc-body p {
  font-size: 17px;
  letter-spacing: .25px;
  line-height: 23px;
}

#block-spsystem-content .field--name-field-ptoc-sections > .field__item .paragraph--type--legal-section .field--name-field-ptoc-body p:not(:last-child) {
  margin-bottom: 17px;
}

@media (max-width: 767px) {
  #block-spsystem-content .field--name-field-ptoc-sections > .field__item .paragraph--type--legal-section .field--name-field-ptoc-body p {
    font-size: 13px;
    line-height: 18px;
  }
  #block-spsystem-content .field--name-field-ptoc-sections > .field__item .paragraph--type--legal-section .field--name-field-ptoc-body p:not(:last-child) {
    margin-bottom: 13px;
  }
}

#block-spsystem-content .field--name-field-ptoc-sections > .field__item .paragraph--type--legal-section .field--name-field-ptoc-body a {
  color: #00ADEF;
}

#block-spsystem-content .field--name-field-ptoc-sections > .field__item .paragraph--type--legal-section .field--name-field-ptoc-body li {
  font-size: 17px;
  letter-spacing: .25px;
  line-height: 23px;
  margin-bottom: 17px;
}

@media (max-width: 767px) {
  #block-spsystem-content .field--name-field-ptoc-sections > .field__item .paragraph--type--legal-section .field--name-field-ptoc-body li {
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 13px;
  }
}

#block-spsystem-content .field--name-field-ptoc-sections > .field__item .paragraph--type--legal-section .field--name-field-ptoc-body ul {
  list-style-type: disc;
  padding-left: 2em;
}

#block-spsystem-content .field--name-field-ptoc-sections > .field__item .paragraph--type--legal-section .field--name-field-ptoc-body > .field__item > ol > li,
#block-spsystem-content .field--name-field-ptoc-sections > .field__item .paragraph--type--legal-section .field--name-field-ptoc-body > .field__item > ol > li > ol > li {
  padding-left: 20px;
}

#block-spsystem-content .field--name-field-ptoc-sections > .field__item .paragraph--type--legal-section .field--name-field-ptoc-body strong {
  font-family: 'Panton-SemiBold';
  font-weight: 400;
}

#block-spsystem-content .field--name-field-ptoc-sections > .field__item .paragraph--type--legal-section .field--name-field-ptoc-body > .field__item > ol {
  list-style-type: none;
  counter-increment: section;
  counter-reset: subsection;
}

#block-spsystem-content .field--name-field-ptoc-sections > .field__item .paragraph--type--legal-section .field--name-field-ptoc-body > .field__item > ol > li::before {
  counter-increment: subsection;
  content: counter(subsection) ".";
  margin-left: -38px;
  position: absolute;
}

.terms-use #block-spsystem-content .field--name-field-ptoc-sections > .field__item .paragraph--type--legal-section .field--name-field-ptoc-body > .field__item > ol > li::before {
  counter-increment: subsection;
  content: counter(section) "." counter(subsection);
  margin-left: -38px;
  position: absolute;
}

#block-spsystem-content .field--name-field-ptoc-sections > .field__item .paragraph--type--legal-section .field--name-field-ptoc-body > .field__item > ol > li > ol {
  list-style-type: none;
  counter-reset: lettersection;
  margin-top: 17px;
}

#block-spsystem-content .field--name-field-ptoc-sections > .field__item .paragraph--type--legal-section .field--name-field-ptoc-body > .field__item > ol > li > ol > li::before {
  counter-increment: lettersection;
  content: counter(lettersection, lower-alpha) ".";
  margin-left: -38px;
  position: absolute;
}

body.login .layout-container main #block-spsystem-content,
body.login .layout-container main .status-messages,
body.password .layout-container main #block-spsystem-content,
body.password .layout-container main .status-messages,
body.user .layout-container main #block-spsystem-content,
body.user .layout-container main .status-messages {
  -ms-grid-column: 2;
  grid-column: 2;
}

body.myTemplates table {
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
}

body.myTemplates .sidebar-content > ul > li > ul {
  display: block;
}

@media (min-width: 1100px) {
  body.myTemplates main.articles-main > article {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  body.myTemplates main.articles-main > article > .article-body {
    flex-grow: 1;
  }
}

.template-title {
  margin-bottom: 1.25em;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  font-weight: 599;
  font-size: 24px;
  color: #262626;
}

.template-heading {
  padding: 4px 20px 2px;
  font-size: 14px;
  background: #00ADEF;
  color: white;
  font-family: 'Panton-SemiBold', Arial, Helvetica, sans-serif;
}

@keyframes rotation {
  to {
    transform: rotate(360deg);
  }
}

.template-block table {
  height: max-content;
  width: 100%;
  margin: 0;
  table-layout: auto;
}

.template-block table tr {
  position: relative;
  background: unset;
}

.template-block table td {
  position: relative;
  background: #f1f1f1;
  vertical-align: top;
  border: 2px solid #e8e9ea;
  padding: 0;
  font-family: Arial, serif !important;
}

.template-block table td.cell--view-only {
  background: unset;
}

@-moz-document url-prefix() {
  .template-block table td {
    height: 100%;
  }
}

@supports (-moz-appearance: none) {
  .template-block table td {
    height: 100%;
  }
}

.template-block table td .vertical-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #bfbfbf;
  transform: translate(-1em, -1em);
  font-weight: bold;
}

.template-block table td .vertical-cell > span {
  transform: rotate(-90deg);
}

.template-block table th {
  position: relative;
  padding: 1em;
  background: #dedede;
  color: #262626;
  font-family: Arial, serif !important;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  border: none;
  border-left: 2px solid white;
}

.template-block table th:first-child {
  border: none;
}

.template-block table th .tooltip {
  position: absolute;
  top: -2px;
  right: 0;
}

.template-block table th .tooltip__button svg {
  fill: currentColor;
  width: 26px;
  height: 26px;
  opacity: 0.5;
}

.template-block table th .tooltip__button:hover svg {
  opacity: 0.75;
}

.template-block table th .tooltip__content {
  text-transform: none;
  letter-spacing: 0;
  text-align: left;
  margin-left: -4px;
  margin-top: -10px;
}

.template-block table tbody {
  font-size: 14px;
}

.template-block table tbody .cell--has-inner {
  padding: 0;
}

.template-block table tbody .cell p {
  margin: 0 0 1.25em;
}

.template-block table tbody .cell p:last-child {
  margin-bottom: 0;
}

.template-block .template-table--inner td, .template-block .template-table--inner th {
  border-left: none;
  border-right: none;
}

.template-block .template-table--inner td {
  border-bottom: none;
}

.template-block .template-table--inner th {
  border-bottom: none;
  font-weight: 600;
  background-color: #dedede;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 1;
  color: #666;
  padding: 0.75em 0.875em;
}

.template-block .template-table__cell {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.template-block .template-table__cell [contenteditable], .template-block .template-table__cell textarea {
  white-space: pre-wrap;
  height: 100%;
  border: none;
  resize: none;
  margin: 0;
}

.template-block .template-table__cell [contenteditable]:read-only, .template-block .template-table__cell [contenteditable]:disabled, .template-block .template-table__cell textarea:read-only, .template-block .template-table__cell textarea:disabled {
  background: #bfbfbf;
}

.template-block .template-table__cell [contenteditable][name*="rating"] + .input-counter, .template-block .template-table__cell textarea[name*="rating"] + .input-counter {
  display: none !important;
}

.template-block .template-table__cell [contenteditable=true], .template-block .template-table__cell textarea {
  font-size: 15px;
  min-height: inherit;
  min-width: 40px;
  font-size: 14px;
  width: 100%;
  word-break: break-word;
  background: white;
}

.template-block .template-table__cell [contenteditable=true].ng-invalid ~ .validate, .template-block .template-table__cell textarea.ng-invalid ~ .validate {
  display: block;
}

.template-block .template-table__cell [contenteditable=true]:before, .template-block .template-table__cell textarea:before {
  font-size: 1em;
}

.template-block .template-table__cell [contenteditable=true].ng-invalid, .template-block .template-table__cell textarea.ng-invalid {
  background: rgba(255, 0, 0, 0.5) !important;
}

.template-block .template-table__cell [contenteditable=true].ng-invalid:focus, .template-block .template-table__cell textarea.ng-invalid:focus {
  box-shadow: 0 0 1.125rem rgba(237, 30, 35, 0.5);
}

.template-block .template-table__cell [contenteditable=true].ng-hide + .input-counter, .template-block .template-table__cell textarea.ng-hide + .input-counter {
  display: none !important;
}

.template-block .template-table__cell .mce-edit-focus ~ .input-counter {
  display: block;
}

.template-block .template-table__cell [contenteditable=false],
.template-block .template-table__cell [ng-bind-html] {
  padding: 1em;
}

.template-block .template-table__cell [ng-bind-html] .cell_heading {
  display: block;
  margin: -1.15em -1.4em;
  font-weight: 600;
  background-color: #dedede;
  font-size: 12px;
  color: #666;
  padding: 0.667em 0.875em;
  text-transform: uppercase;
}

.template-block .template-table__cell-content {
  display: flex;
  flex-grow: 1;
  width: 100%;
}

.template-block .template-table__cell-content > div {
  display: block;
  width: 100%;
  height: 100%;
}

.template-block .template-table__cell .tooltip {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.template-block .template-table__cell .tooltip__button svg {
  fill: #0abbff;
  width: 32px;
  height: 32px;
}

.template-block .template-table__cell .tooltip__button:hover svg {
  fill: #0076a3;
}

.template-block .template-table__cell .tooltip__content {
  top: calc(100% + 10px);
}

.template-block .template-table__cell .tooltip__content:before {
  margin-left: -10px;
}

.template-block .template-table__field {
  position: relative;
  height: 100%;
  min-height: 80px;
  user-select: auto;
}

.template-block .template-table__field * {
  user-select: inherit;
}

.template-block .template-table__field-value {
  padding: 1em 1.66667em;
  white-space: pre-wrap;
}

.template-block .template-table__field template-field {
  height: 100%;
  display: block;
  min-height: inherit;
}

.template-block .template-table__field template-field::placeholder {
  color: #AAB3BE;
}

.template-block .template-table__field template-field > div,
.template-block .template-table__field template-field .mce-content-body {
  height: 100%;
  min-height: inherit;
}

.template-block .template-table__field template-field .mce-content-body {
  padding: 1em;
}

.template-block .template-table__field > .mce-tinymce-inline {
  position: absolute;
  top: unset !important;
  bottom: 100%;
  z-index: 1;
}

.template-block .template-table__field .validate {
  display: block;
  font-size: 12px;
  padding: 5px 10px;
  top: 100%;
  z-index: 9;
}

.template-block .template-table__field .ng-invalid .mce-content-body,
.template-block .template-table__field .ng-invalid textarea {
  box-shadow: #ED1E23 0px 0px 0.75rem;
}

.template-block .template-table__field .ng-invalid.ng-invalid-max-lines .mce-content-body,
.template-block .template-table__field .ng-invalid.ng-invalid-max-lines textarea {
  box-shadow: #ffc048 0px 0px 0.75rem;
}

.template-block .template-table__field .ng-invalid.ng-invalid-max-lines + p.validate {
  background-color: #ffc048;
  color: #2D363C;
}

.template-block .template-table__field .ng-invalid.ng-invalid-max-lines + p.validate:before {
  border-bottom-color: #ffc048;
}

.template-block .template-table__cell-legend {
  display: flex;
  height: 100%;
}

.template-block .template-table__cell-legend > div {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.template-block .template-table__cell-legend > div > span {
  margin: auto;
}

.template-block .template-table__cell-legend [class*='__cell'] {
  flex-grow: 1;
  background: #f1f1f1;
  margin: -12px -12px -11px;
  text-align: left;
  text-transform: initial;
  font-weight: normal;
}

.template-block .template-table__legend[colspan]:not([colspan='1']) [class~='template-table__cell'] {
  min-height: 80px;
}

.template-block .template-table__set {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.template-block .template-table__set [class*='table__block'] {
  flex: 1;
}

.template-block .template-table__set [class*='table__block'] > table {
  height: 100%;
}

.template-block .select-holder {
  margin: 0;
  border-width: 1px;
  border-color: #bfbfbf;
}

.template-block .select-holder option.disabled {
  color: #bfbfbf;
}

.template-block .select-holder select {
  font-size: 1.0625em;
  font-weight: 600;
  padding: 0.411764705882353em 1.176470588235294em;
}

.progress-block {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
  z-index: 0;
}

.progress-block li {
  padding: 0;
  margin: 0;
  flex: 1 1 auto;
}

.progress-block li.active {
  flex-grow: 0;
}

.progress-block li.active [class*='__label'] {
  overflow: hidden;
  background: #999999;
  color: white;
  overflow: visible;
  border-radius: 0 2px 2px 0;
}

.progress-block li.active [class*='__label']:before {
  margin: 0;
  top: 0;
  left: calc(100% - 1px);
  width: 12px;
  height: 100%;
  background: #999999;
  transform: none;
  border: none;
  border-radius: 0;
  z-index: 1;
  clip-path: polygon(0 0, 0% 100%, 100% 50%);
}

.progress-block li.active + li [class*='__label'] {
  padding-left: 15px;
}

.progress-block li:last-child [class*='__label']:before {
  display: none;
}

.progress-block__label {
  position: relative;
  padding: 3px 5px 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: #EBEBEB;
  color: #333;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  font-weight: bold;
  letter-spacing: 1px;
}

.progress-block__label > span {
  font-size: 75%;
}

.progress-block__label:before {
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -6px;
  transform-origin: center;
  transform: rotate(45deg);
  z-index: 1;
  border-radius: 2px;
  border-top: 2px solid white;
  border-right: 2px solid white;
}

.template-docs table {
  width: 100% !important;
  table-layout: auto;
  font-size: 14px;
  border: none !important;
  margin-bottom: 0 !important;
}

.template-docs table tr {
  position: relative;
  background: none !important;
  border: none;
}

.template-docs table tbody tr td:first-child {
  background: #EBEBEB;
  font-weight: bold;
}

.template-docs table td {
  font-size: 14px;
  border: 1px solid #F5F5F5;
  background: white;
}

.template-docs table td[ng-click]:not([disable-click]) {
  cursor: pointer;
}

.template-docs table th {
  position: relative;
  background: #E1E1E1;
  color: #262626;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  font-weight: bold;
  padding: 12px 10px 12px 15px !important;
  font-size: 13px;
  text-align: left;
  border: 1px solid #F5F5F5;
  box-sizing: border-box;
}

.template-docs table th .tooltip {
  text-transform: none;
  letter-spacing: 0;
  position: absolute;
  top: calc(50% - 19px);
  right: 0;
}

.template-docs table th .tooltip__button svg {
  fill: #00ADEF;
}

.template-docs table th .tooltip__content {
  margin-left: -4px;
  margin-top: -10px;
}

@media (max-width: 1099px) {
  .template-docs table th:last-child .tooltip__content {
    left: unset;
    right: -100%;
    transform: none;
  }
  .template-docs table th:last-child .tooltip__content::before {
    display: none;
  }
  .template-docs table th:last-child .tooltip--open [class*='__content'] {
    right: 5px;
  }
}

.template-docs table td.no-style,
.template-docs table th.no-style {
  border: none !important;
  background: transparent !important;
}

@media (max-width: 1099px) {
  .template-docs table.dataTable tbody > .child .dtr-title .tooltip,
  .template-docs table.dataTable thead > .child .dtr-title .tooltip {
    display: none;
  }
  .template-docs table.dataTable tbody > .child .dtr-data,
  .template-docs table.dataTable thead > .child .dtr-data {
    display: inline-block;
  }
  .template-docs table.dataTable tbody td, .template-docs table.dataTable tbody th,
  .template-docs table.dataTable thead td,
  .template-docs table.dataTable thead th {
    padding: 10px 10px;
  }
}

.template-docs__actions {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.template-docs__actions > li {
  margin-left: 0;
  padding: 0;
}

.template-docs__actions > li:not(:first-child) {
  margin-left: 5px;
}

td .template-docs__actions > li > span, td
.template-docs__actions > li > a {
  color: #00ADEF;
}

.template-docs__actions svg {
  fill: currentColor;
  width: 24px;
  height: 24px;
}

.template-docs__actions svg:not([class*='__close']) {
  pointer-events: none;
}

.template-docs__actions .dropmenu-open .dropmenu-holder {
  top: 2.75rem;
}

@media (max-width: 1099px) {
  .template-docs__actions .dropmenu-open .dropmenu-holder {
    top: 0;
  }
  .template-megaform__toolbar-buttons .template-docs__actions .dropmenu-open .dropmenu-holder {
    top: 2.75rem;
  }
}

@media (max-width: 1099px) {
  .template-docs__actions .dropmenu-holder {
    left: 0;
    right: unset;
  }
  .template-megaform__toolbar-buttons .template-docs__actions .dropmenu-holder {
    left: unset;
    right: 0;
  }
}

.template-docs__actions .dropmenu-holder ul li:not(:first-child) {
  border-top: 1px solid #ddd;
}

.template-docs__actions .dropmenu-holder ul li a {
  display: block;
  padding: 1rem;
  background: transparent;
  font-size: 14px;
  color: unset;
}

.template-docs__actions .dropmenu-holder ul li a:hover {
  color: white;
}

.template-docs__actions .dropmenu-holder ul li a.link--active, .template-docs__actions .dropmenu-holder ul li a.link--disabled {
  pointer-events: none;
}

.template-docs__actions .dropmenu-holder ul li a.link--disabled {
  background: #bfbfbf;
  color: #737373;
}

.template-docs__actions .dropmenu-holder ul li[disabled] a {
  background: #aaa !important;
}

@media (max-width: 1099px) {
  .template-block [class*='__header'] .template-docs__actions,
  .template-block [class*='__footer'] .template-docs__actions {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
  }
  .template-block [class*='__header'] .template-docs__actions li:first-child,
  .template-block [class*='__footer'] .template-docs__actions li:first-child {
    margin-right: auto;
  }
}

.template-docs__badge {
  width: 32px;
  height: 32px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

@media (min-width: 1100px) {
  .template-docs__header {
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 1099px) {
  .template-docs__header [class$='__actions'] {
    display: flex;
    justify-content: flex-end;
  }
  .template-docs__header .dropmenu-holder {
    position: absolute;
    top: calc(100% + 15px) !important;
    min-width: 120px;
    height: unset;
  }
}

.template-docs__footer {
  margin-top: 1em;
  display: flex;
  justify-content: center;
}

.template-docs__footer li {
  padding: 10px;
}

.template-docs__footer .btn {
  min-width: 17.5em;
}

.template-docs__footer [class$='__actions'] {
  margin: auto;
}

.template-docs > .dataTables_wrapper {
  padding-top: 0;
}

@media (max-width: 1099px) {
  .template-docs > .dataTables_wrapper {
    overflow: auto;
  }
}

.template-docs > .dataTables_wrapper .dataTable.no-footer {
  margin-bottom: 0;
}

.template-docs__progress {
  margin: 0 -15px;
  padding: 1.25em 15px 0;
  background: white;
  border: 1px solid #EBEBEB;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}

@media (min-width: 1100px) {
  .template-docs__progress {
    padding: 2em 3.5em 1.5em;
    margin: 0.75em 0 1em;
  }
}

.template-docs__progress__title {
  margin: 0 0 1.75em;
  padding: 0;
  font-size: 17px;
  font-weight: 600;
}

.template-docs__progress__steps {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (min-width: 1100px) {
  .template-docs__progress__steps {
    margin-left: -6em;
    margin-right: -5.25em;
  }
}

.template-docs__progress__steps > li {
  padding: 0;
  margin: 0;
  text-align: center;
  flex: 1;
  font-size: 14px;
}

.template-docs__progress__steps > li:last-child [class*='__item']:before {
  opacity: 0;
}

.template-docs__progress__steps > li.disabled {
  pointer-events: none;
}

.template-docs__progress__steps > li.disabled [class*='__item'] {
  color: #AAB3BE;
}

.template-docs__progress__steps > li.disabled [class*='__item']:before {
  background-color: #EEF0F2;
}

.template-docs__progress__steps > li.disabled [class*='__item']:after {
  background-color: #EEF0F2;
}

.template-docs__progress__steps > li.active {
  pointer-events: none;
}

.template-docs__progress__steps > li.active [class*='__item']:before, .template-docs__progress__steps > li.active [class*='__item']:after {
  z-index: 1;
}

.template-docs__progress__steps > li.active ~ li [class*='__item']:after {
  background-image: url("../assets/svg/Icons/Do Sm-2.svg?v=4");
  background-size: 12px;
  background-position: center;
}

@media (min-width: 1100px) {
  .template-docs__progress__steps > li.active ~ li [class*='__item']:after {
    margin-top: -0.85em;
    background-size: 15px;
    background-position: center;
  }
}

.template-docs__progress__steps > li.active ~ li.disabled [class*='__item'] {
  color: #AAB3BE;
}

.template-docs__progress__steps > li.active ~ li.disabled [class*='__item']:before, .template-docs__progress__steps > li.active ~ li.disabled [class*='__item']:after {
  background: #EEF0F2;
}

.template-docs__progress__steps > li.active ~ li.disabled [class*='__item'] svg {
  filter: invert(74%) sepia(10%) saturate(299%) hue-rotate(173deg) brightness(95%) contrast(91%);
}

.template-docs__progress__steps > li.active ~ li [class*='__item']:before {
  background: #EEF0F2;
}

.template-docs__progress__steps > li.active ~ li:not(.disabled) [class*='__link'] {
  opacity: .5;
}

.template-docs__progress__steps > li.active ~ li:not(.disabled) [class*='__link']:hover {
  opacity: 1;
}

.template-docs__progress__steps > li.active span[class*='__item']:before {
  background: #EEF0F2;
}

.template-docs__progress__steps > li:nth-child(1) [class*='__item'] {
  color: #AAB3BE;
}

.template-docs__progress__steps > li:nth-child(1) [class*='__item']:hover {
  border-color: #AAB3BE !important;
}

.template-docs__progress__steps > li:nth-child(1) [class*='__item']:before, .template-docs__progress__steps > li:nth-child(1) [class*='__item']:after {
  background-color: #AAB3BE;
}

.template-docs__progress__steps > li:nth-child(1) [class*='__item'] svg {
  filter: invert(74%) sepia(10%) saturate(299%) hue-rotate(173deg) brightness(95%) contrast(91%);
}

.template-docs__progress__steps > li:nth-child(2) [class*='__item'] {
  color: #00ADEF;
}

.template-docs__progress__steps > li:nth-child(2) [class*='__item']:hover {
  border-color: #00ADEF !important;
}

.template-docs__progress__steps > li:nth-child(2) [class*='__item']:before, .template-docs__progress__steps > li:nth-child(2) [class*='__item']:after {
  background-color: #00ADEF;
}

.template-docs__progress__steps > li:nth-child(2) [class*='__item'] svg {
  filter: invert(72%) sepia(98%) saturate(4745%) hue-rotate(164deg) brightness(100%) contrast(102%);
}

.template-docs__progress__steps > li:nth-child(3) [class*='__item'] {
  color: #38444B;
}

.template-docs__progress__steps > li:nth-child(3) [class*='__item']:hover {
  border-color: #38444B !important;
}

.template-docs__progress__steps > li:nth-child(3) [class*='__item']:before, .template-docs__progress__steps > li:nth-child(3) [class*='__item']:after {
  background-color: #38444B;
}

.template-docs__progress__steps > li:nth-child(3) [class*='__item'] svg {
  filter: invert(24%) sepia(13%) saturate(673%) hue-rotate(158deg) brightness(95%) contrast(89%);
}

.template-docs__progress__steps > li:last-child [class*='__item'] {
  color: #7ED321;
}

.template-docs__progress__steps > li:last-child [class*='__item']:hover {
  border-color: #7ED321 !important;
}

.template-docs__progress__steps > li:last-child [class*='__item']:before, .template-docs__progress__steps > li:last-child [class*='__item']:after {
  background-color: #7ED321;
}

.template-docs__progress__steps > li:last-child [class*='__item'] svg {
  filter: invert(63%) sepia(49%) saturate(652%) hue-rotate(46deg) brightness(103%) contrast(96%);
}

.template-docs__progress__item {
  display: block;
  position: relative;
  font-weight: 600;
}

.template-docs__progress__item:before {
  content: '';
  display: block;
  width: 100%;
  height: 8px;
  background: #00ADEF;
  transform: translateX(50%);
  margin-bottom: 1.5em;
}

.template-docs__progress__item:after {
  content: '';
  display: block;
  position: absolute;
  height: 2.275em;
  width: 2.275em;
  border-radius: 50%;
  left: 50%;
  transition: transform .2s linear;
  top: 0;
  margin-left: -1em;
  margin-top: -0.70em;
  background: #00ADEF url("../assets/svg/Icons/Tick Sm-2.svg") no-repeat center -1px;
  z-index: 2;
}

@media (min-width: 1100px) {
  .template-docs__progress__item:after {
    margin-top: -0.85em;
    background-size: 40px;
    background-position: center -6px;
  }
}

.template-docs__progress__item__status {
  display: block;
}

.template-docs__progress__item__link {
  display: inline-block;
  position: relative;
  z-index: 3;
  transition: all .2s linear;
  border-bottom: 2px solid transparent;
}

.template-docs__progress__item__link:hover {
  border-bottom: 2px solid #ccc;
  transform: translateY(-2px);
}

.template-docs__progress__item__link:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 150%;
  height: 70px;
  background: none;
  opacity: 0;
}

.template-docs__info {
  font-size: 14px;
}

.template-docs__notes {
  font-size: 14px;
}

.template-docs__notes__block {
  min-width: 100vw;
  padding: 1.125em 1.5em 0;
  height: 100%;
}

@media (min-width: 1100px) {
  .template-docs__notes__block {
    min-width: 568px;
  }
}

@media (max-width: 1099px) {
  .template-docs__notes__block {
    display: flex;
    flex-direction: column;
  }
  .template-docs__notes__block > * {
    width: 100%;
  }
  .template-docs__notes__block [class*='__footer'] {
    margin-top: auto;
  }
}

.template-docs__notes__block--loading {
  position: relative;
}

.template-docs__notes__block--loading:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.25);
  z-index: 1;
}

.template-docs__notes__block--loading:after {
  content: '';
  display: block;
  animation: rotation 1s infinite linear;
  border: 2px solid rgba(51, 51, 51, 0.325);
  border-radius: 50%;
  border-top-color: rgba(0, 0, 0, 0.75);
  height: 3em;
  width: 3em;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -1.5em;
  margin-top: -1.5em;
  z-index: 2;
}

.template-docs__notes__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid #EBEBEB;
  font-size: 14px;
}

@media (min-width: 1100px) {
  .template-docs__notes__header {
    font-size: 17px;
  }
}

.template-docs__notes__header svg[class*='__close'] {
  cursor: pointer;
  width: 38px;
  height: 38px;
  fill: #bfbfbf;
}

@media (min-width: 1100px) {
  .template-docs__notes__header svg[class*='__close'] {
    margin: -10px -13px 0 0;
  }
}

.template-docs__notes__header svg[class*='__close']:hover {
  fill: #8c8c8c;
}

.template-docs__notes__header .dropdown-title {
  font-weight: bold;
  display: flex;
  align-items: center;
}

.template-docs__notes__header .dropdown-title svg {
  margin-right: 5px;
}

@media (min-width: 1100px) {
  .template-docs__notes__header .dropdown-title svg {
    margin-right: 10px;
    width: 32px;
    height: 32px;
  }
}

.template-docs__notes__header .note-owner small {
  display: block;
  margin-bottom: 5px;
  text-transform: uppercase;
  color: #AAB3BE;
  letter-spacing: 2px;
  font-weight: 600;
}

.template-docs__notes__header .note-owner strong {
  color: #38444B;
  font-weight: 600;
}

@media (max-width: 999px) {
  .template-docs__notes__header {
    flex-wrap: wrap;
    position: relative;
    padding-bottom: 0;
  }
  .template-docs__notes__header .dropdown-title {
    flex: 1 1 100%;
    max-width: 100%;
    font-size: 17px;
    justify-content: center;
    font-weight: 600;
    padding-bottom: 1em;
  }
  .template-docs__notes__header .dropdown-title svg {
    display: none;
  }
  .template-docs__notes__header .note-owner {
    padding-top: 1.5em;
    padding-left: 4em;
    flex: 1 0 0;
    margin-left: -1.5em;
    margin-right: -1.5em;
    background-color: #F6F7F8;
    margin-bottom: -1px;
  }
  .template-docs__notes__header .note-owner > strong {
    font-size: 15px;
  }
  .template-docs__notes__header .dropmenu-trigger__close {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(12px, -8px) !important;
    fill: #00ADEF !important;
  }
}

.template-docs__notes__body {
  counter-reset: commentCounter;
  display: flex;
  padding: 1.5em !important;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  background: #F6F7F8;
  margin-left: -1.5em;
  margin-right: -1.5em;
  scrollbar-width: thin;
  /* Firefox */
}

.template-docs__notes__body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(191, 191, 191, 0.3);
  border-radius: 1px;
  background-color: rgba(191, 191, 191, 0.5);
}

.template-docs__notes__body::-webkit-scrollbar {
  width: 2px;
  height: 0;
  background-color: #F5F5F5;
}

.template-docs__notes__body::-webkit-scrollbar-thumb {
  border-radius: 1px;
  -webkit-box-shadow: inset 0 0 6px rgba(191, 191, 191, 0.3);
  background-color: #00ADEF;
}

@media (min-width: 1100px) {
  .template-docs__notes__body {
    max-height: 32vh;
  }
}

@media (max-width: 999px) {
  .template-docs__notes__body {
    width: unset;
    padding-left: 2.5em;
    padding-right: 2.5em;
    background-color: #F6F7F8;
  }
}

.template-docs__notes__footer {
  border-top: 1px solid #EBEBEB;
  padding: 1.5em;
  margin-left: -1.5em;
  margin-right: -1.5em;
  background: #fff;
  width: unset;
}

@media (max-width: 999px) {
  .template-docs__notes__footer.focus {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0;
    width: 100%;
  }
}

.template-docs__notes__footer-field {
  position: relative;
}

.template-docs__notes__footer-field textarea {
  padding: 0.75rem 2rem 0.75rem 1rem;
  font-size: 14px;
  border: 1px solid #DDE0E5;
  resize: none;
}

.template-docs__notes__footer-field > span {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 10px;
  letter-spacing: 1px;
  color: #AAB3BE;
}

.template-docs__notes__footer-field .flyout__field-input-icon {
  left: 5px;
}

.template-docs__notes__footer-field .flyout__group-field {
  margin-top: 2px;
  width: unset;
}

.template-docs__notes__footer-field .flyout__field-dropdown {
  background: #00ADEF;
  border: none;
}

.template-docs__notes__footer-field .flyout__field-dropdown svg {
  fill: white !important;
}

.template-docs__notes__footer-field .note-selected-member {
  display: flex;
  margin-top: -6px;
  margin-left: 5px;
}

.template-docs__notes__footer-field .note-selected-member > span {
  display: flex;
  align-items: center;
  font-size: 12px;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  display: block;
  padding: 3px 2px 3px 5px;
  margin-right: 3px;
  color: #00ADEF;
  background: white;
}

.template-docs__notes__footer-field .note-selected-member > span svg {
  width: 20px;
  height: 20px;
  margin: -5px -2px -2px -3px;
  cursor: pointer;
  fill: #00ADEF;
}

.template-docs__notes__footer-field .flyout__field-input svg {
  fill: #00ADEF;
}

.template-docs__notes__footer-field .flyout__field-input input {
  padding: 0.5rem 2rem 0.5rem 1rem !important;
  font-size: 14px !important;
  border-color: #bfbfbf;
  background-color: white !important;
  color: #333;
  padding-left: 3em !important;
}

@media (min-width: 1100px) {
  .template-docs__notes.dropmenu-open .dropmenu-holder {
    top: 2.5rem;
  }
}

.template-docs__notes .dropmenu-holder {
  background: #EBEBEB;
}

.template-docs__notes .dropmenu-holder:before {
  border-bottom-color: #EBEBEB;
}

.template-docs__report {
  margin-bottom: 40px;
}

.template-docs__report [collapsable] .collapsable-trigger {
  top: 20px;
  right: 10px;
}

@media (max-width: 1099px) {
  .template-docs__report [collapsable] {
    padding-right: 50px;
  }
  .template-docs__report [collapsable] .collapsable-trigger {
    width: 38px;
    height: 39px;
    background-color: #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid #dedede;
  }
}

.template-docs__report thead th {
  height: 38px;
}

@media (max-width: 1099px) {
  .template-docs__report .template-table-holder {
    overflow: auto hidden;
  }
}

.template-docs__heading {
  display: flex;
  justify-content: space-between;
}

.template-docs__heading [class*='__actions'] .btn:not(.btn-xs) {
  font-size: 11px;
  letter-spacing: 2px;
  height: 44px;
  padding: 0 20px;
}

.template-docs__heading [class*='__actions'] .btn:not(.btn-xs) svg {
  height: 2.25em;
  width: 2.25em;
  margin-top: -0.75em;
  margin-right: -0.75em;
  margin-left: -0.25em;
}

.template-docs__heading [class*='__actions'] input {
  margin: 0;
  max-height: 40px;
  border: 1px solid #bfbfbf !important;
}

.template-docs h2 {
  border: none;
  margin: 0;
  padding-bottom: 0;
  font-size: 24px;
  display: flex;
  align-items: center;
}

.template-docs h2 svg {
  margin-right: 5px;
  fill: #00ADEF;
  width: 38px;
  height: 38px;
}

@media (min-width: 1100px) {
  .template-docs h2 svg {
    margin-right: 10px;
    width: 44px;
    height: 44px;
  }
}

.template-docs .dropmenu-trigger__btn svg {
  width: 24px;
  height: 24px;
  margin-left: 5px;
}

.template-docs .dataTables_info {
  font-size: 12px;
  letter-spacing: 0.5px;
  margin-left: 0;
}

.template-docs .dataTables_paginate {
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 2px;
  font-weight: bold;
}

.template-docs .dataTables_paginate .paginate_button.disabled {
  display: none;
}

.template-docs .dataTables_wrapper {
  padding-bottom: 0;
}

.template-megaform {
  margin-bottom: 1em;
}

.template-megaform__header {
  margin-top: 1.325em;
  padding-top: 1em;
  border-top: 1.5px solid #DDE0E5;
}

.template-megaform__toolbar {
  display: flex;
}

.template-megaform__toolbar-item {
  display: flex;
  font-size: 14px;
  flex-direction: column;
}

@media (min-width: 1000px) {
  .template-megaform__toolbar-item ~ [class*='__toolbar-item'] {
    margin-left: 2.75em;
  }
  .template-megaform__toolbar-item--right {
    align-items: flex-end;
    flex-direction: column;
    margin-left: auto !important;
  }
}

.template-megaform__toolbar-item [class*='__toolbar-item'] {
  margin-left: 20px;
}

.template-megaform__toolbar-item input[type="color"], .template-megaform__toolbar-item input[type="date"], .template-megaform__toolbar-item input[type="datetime"], .template-megaform__toolbar-item input[type="datetime-local"], .template-megaform__toolbar-item input[type="email"], .template-megaform__toolbar-item input[type="month"], .template-megaform__toolbar-item input[type="number"], .template-megaform__toolbar-item input[type="password"], .template-megaform__toolbar-item input[type="search"], .template-megaform__toolbar-item input[type="tel"], .template-megaform__toolbar-item input[type="text"], .template-megaform__toolbar-item input[type="time"], .template-megaform__toolbar-item input[type="url"], .template-megaform__toolbar-item input[type="week"], .template-megaform__toolbar-item input:not([type]), .template-megaform__toolbar-item textarea, .template-megaform__toolbar-item .inputElement, .template-megaform__toolbar-item [contenteditable=true] {
  padding: 0.25rem 3rem 0.25rem 0.5em;
  font-size: 14px;
  height: 32.15px;
}

.template-megaform__toolbar-item .editable-wrap .editable-controls {
  display: flex;
}

.template-megaform__toolbar-item .editable-wrap .editable-controls > label {
  margin-right: 5px;
  align-self: center;
  font-weight: bold;
}

.template-megaform__toolbar-item .editable-wrap .editable-controls .editable-buttons button {
  margin: 0 !important;
  padding: 0 !important;
  height: 100% !important;
  width: 70px !important;
  font-size: 10px !important;
}

.template-megaform__toolbar-item .editable-wrap .editable-controls .editable-buttons button > .fa {
  display: none;
}

.template-megaform__toolbar-item .editable-wrap .editable-controls .editable-buttons button[title="Submit"] {
  border: 1px solid transparent;
  border-radius: 0;
  background: none;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  text-align: center;
  position: relative;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  fill: currentColor;
  text-decoration: none;
  outline: none;
  text-align: center;
  line-height: 1.1;
  color: #fff;
  transition: all .1s linear;
  margin: .2em 0;
  padding: 16px 2.1rem;
  font-size: 13px;
  margin: .3rem 0 .3rem;
  background-color: #00ADEF;
  color: #ffffff;
  top: -1px;
}

.template-megaform__toolbar-item .editable-wrap .editable-controls .editable-buttons button[title="Submit"]:focus {
  transform: scale(0.98);
}

.template-megaform__toolbar-item .editable-wrap .editable-controls .editable-buttons button[title="Submit"]:disabled:not(.btn-success) {
  cursor: not-allowed;
  opacity: 0.3;
}

.template-megaform__toolbar-item .editable-wrap .editable-controls .editable-buttons button[title="Submit"]:hover:not(:disabled) {
  color: #ffffff;
  background-color: #006389;
}

.template-megaform__toolbar-item .editable-wrap .editable-controls .editable-buttons button[title="Submit"] i {
  margin-left: .1em;
  margin-right: .1em;
  margin-top: -2px;
  font-size: 85%;
}

.template-megaform__toolbar-item .editable-wrap .editable-controls .editable-buttons button[title="Cancel"] {
  background-color: transparent;
  color: #ffffff;
  color: #00ADEF;
  border-radius: 0;
  border: 1px solid #00ADEF;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.template-megaform__toolbar-item .editable-wrap .editable-controls .editable-buttons button[title="Cancel"]:hover:not(:disabled) {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
}

.template-megaform__toolbar-item .editable-wrap .editable-controls .editable-buttons button[title="Cancel"]:hover:not(:disabled), .template-megaform__toolbar-item .editable-wrap .editable-controls .editable-buttons button[title="Cancel"].active {
  background-color: #00ADEF !important;
  border-color: #00ADEF;
  color: #fff;
}

.template-megaform__toolbar-item .editable {
  color: currentColor;
  border-bottom: dashed 1px currentColor;
}

.template-megaform__toolbar-item .editable.editable--disabled {
  border-bottom: none !important;
  pointer-events: none;
}

.template-megaform__label {
  display: block;
  color: #bfbfbf;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 2px;
  white-space: nowrap;
  text-transform: uppercase;
}

@media (min-width: 1000px) {
  .template-megaform__label {
    font-size: 11px;
  }
}

.template-megaform__value {
  font-size: .95rem;
  font-weight: 600;
  padding-top: 0.3em;
  line-height: 1.2;
}

.template-megaform__value--sm {
  font-size: .875rem;
}

[collapsable] {
  position: relative;
}

[collapsable] .collapsable-trigger {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 1;
}

[collapsable] .collapsable-trigger svg {
  width: 32px;
  height: 32px;
}

[collapsable] .collapsable-trigger:not(.collapsed) svg {
  transform: rotate(180deg);
}

[collapsable] .collapsable-container {
  margin: -10px;
  padding: 10px;
  background-color: #f1f1f1;
  border: 1px solid #00afeb;
  box-shadow: 0 0 0.875rem rgba(0, 175, 235, 0.5);
}

[collapsable] .collapsable-container + .collapsable-container {
  border-top: 0;
  margin-top: -1px;
}

[collapsable-target-highlight].collapsable-active {
  position: relative;
}

[collapsable-target-highlight].collapsable-active > * {
  position: relative;
  z-index: 3;
}

[collapsable-target-highlight].collapsable-active:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: calc(100% + 1px);
  width: 100%;
  top: 0;
  left: 0;
  border: 1px solid #00afeb;
  box-shadow: rgba(0, 175, 235, 0.2) 0px -0.5rem 1rem -0.25rem, rgba(0, 175, 235, 0.15) 1rem 0rem 1rem -0.5rem, rgba(0, 175, 235, 0.15) -1rem 0rem 1rem -0.5rem;
}

[collapsable-target-highlight].collapsable-active:after {
  content: '';
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  z-index: 1;
  background-color: #f1f1f1;
  height: calc(100% + 20px);
  width: calc(100% - 2px);
  top: 1px;
  left: 1px;
}

.collapsable-container .reporting-inner-table td:first-child a {
  font-weight: normal;
  pointer-events: none;
}

report-workgroup .collapsable-container .reporting-inner-table td:first-child a {
  pointer-events: unset;
}

report-workgroup .collapsable-container .reporting-inner-table td:first-child a:hover {
  font-weight: 600;
}

.editable-empty,
.editable-click {
  font-style: unset !important;
  color: inherit !important;
  border-bottom: dashed 1px currentColor !important;
}

.editable.editable--error {
  color: #ED1E23 !important;
}

.templates-landing {
  padding: 0 10px;
  max-width: 90%;
  position: relative;
  text-align: center;
  margin: 0 auto 2.5rem;
}

@media (max-width: 1099px) {
  .templates-landing {
    max-width: 100%;
  }
}

.templates-landing .welcome-username {
  padding-bottom: 0;
}

.templates-landing__feature-image {
  max-width: 567px;
  display: block;
  margin: auto;
}

.templates-landing__footer {
  margin-top: 2.5em;
}

[template-id="1"] > thead > tr th:nth-child(1), [template-id="1"] > thead > tr th:nth-child(2) {
  width: 37.5%;
}

.link-icon {
  color: #00ADEF;
}

.link-icon--muted {
  color: #bfbfbf;
}

.link-icon--muted:hover {
  color: #8c8c8c;
}

.template-docs--history tbody {
  display: block;
}

.template-docs--notes {
  padding: 0 0.5rem 0 .5rem;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 0.75em 1em;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  padding: 0.75em 1em;
}

.previewDocumentPrompt .modal-container {
  max-width: 520px;
}

.template-chips {
  display: flex;
}

.template-chip {
  position: relative;
  background-color: #bfbfbf;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-right: 3px;
  font-size: 14px;
}

.template-chip--btn {
  background-color: #00ADEF;
}

.template-chip svg {
  fill: currentColor;
}

.template-chip__badge {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-size: 10px;
  width: 16px;
  height: 16px;
  background: white;
  border-radius: 50%;
  color: #00ADEF;
  top: 1px;
  right: -3px;
}

.popover {
  position: relative !important;
  cursor: pointer;
}

.popover-content {
  position: absolute;
  top: calc(100% + 20px);
  left: 50%;
  padding: 0.5rem 0.75rem 0.325rem;
  background-color: #ebebeb;
  border: 1px solid #ddd;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.125);
  z-index: 1;
  color: #38444B;
  font-size: 12px;
  transform: translateX(-50%) scale(0.2);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  opacity: 0;
  visibility: hidden;
}

.popover-content:before {
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  background: #ebebeb;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  position: absolute;
  top: -6px;
  left: 50%;
  margin-left: -5px;
  transform: rotate(45deg);
  box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.0325);
}

.popover:hover .popover-content {
  top: calc(100% + 5px);
  transform: translateX(-50%) scale(1);
  opacity: 1;
  visibility: visible;
}

.popover--right > [class*='-content'] {
  right: calc(-50% + 15px);
  left: unset;
  transform: scale(0);
}

.popover--right > [class*='-content']:before {
  left: unset;
  margin: 0;
  right: 10px;
}

.popover--right:hover > [class*='-content'] {
  transform: scale(1);
}

.template-sharing-list {
  max-height: 320px;
  overflow: auto;
  display: flex;
  list-style-type: none;
  margin: 0 0 40px;
  flex-wrap: wrap;
}

.template-sharing-list > li {
  padding: 0 20px;
  flex: 1 1 50%;
  max-width: 50%;
  margin: 20px 0 0 0;
}

.template-sharing-list .account-details {
  font-size: 13px;
}

.template-sharing-account {
  display: flex;
  align-items: flex-start;
}

.template-sharing-account > .image {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  background-color: #bfbfbf;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  margin-right: 20px;
  flex-shrink: 0;
}

.template-sharing-account .share-template-btn {
  margin-left: auto;
  cursor: pointer;
}

.template-sharing-account .share-template-btn svg {
  width: 1.75rem;
  height: 1.75rem;
  fill: #00ADEF;
}

.template-megaform__value .popover-content .share-template-to {
  display: flex;
  justify-content: space-between;
  min-width: 160px;
}

[collapsable] .dataTables_wrapper {
  padding: 0.5rem 0 0;
}

[collapsable] .dataTables_wrapper > table {
  margin: 0;
}

[collapsable] h3 {
  border: none;
  margin: 0;
  font-size: 16px;
  padding-top: 10px;
  display: flex;
  align-items: center;
}

[collapsable] h3 svg {
  margin-right: 5px;
  fill: #00ADEF;
}

@media (min-width: 1100px) {
  [collapsable] h3 svg {
    margin-right: 10px;
    width: 44px;
    height: 44px;
  }
}

[date-range-picker="t1"] {
  max-height: 44px;
  margin: .3rem 0 .3rem;
  padding: 0.75rem 1rem !important;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif !important;
  font-size: 12px !important;
  letter-spacing: 1px;
  border-width: 1px !important;
  width: 215px !important;
}

.moment-picker {
  z-index: 999999 !important;
}

.daterangepicker {
  flex-wrap: wrap;
  border: 1px solid #ddd;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.125);
}

.daterangepicker[style*='display: block;'] {
  display: flex !important;
  max-width: 567px;
  z-index: 99999;
}

.daterangepicker:not([style]) {
  display: none !important;
}

.daterangepicker > .calendar {
  display: block !important;
  float: none;
  flex: 1 1 50%;
  padding: 10px;
  margin: 0;
  max-width: 50%;
}

.daterangepicker > .calendar .calendar-table {
  padding: 0 !important;
}

.daterangepicker > .ranges {
  align-items: center;
  display: flex;
  flex-grow: 1;
  padding: 10px;
}

.daterangepicker > .ranges .range_inputs {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
}

.daterangepicker > .ranges .range_inputs .btn {
  margin: 5px;
}

.daterangepicker > .ranges > ul {
  margin: 0 auto 0 0;
  display: flex;
  width: auto;
}

.daterangepicker > .ranges > ul li {
  padding: 5px 1em;
  border-radius: 0;
  margin: 0;
  margin-right: 5px;
}

.daterangepicker > .ranges > ul li:last-child {
  display: none;
}

.daterangepicker table {
  table-layout: auto;
}

.daterangepicker table select {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  line-height: normal;
  font-size: 1rem;
  min-width: 10px;
  transition: all 260ms cubic-bezier(0.77, 0, 0.175, 1);
  color: #22292D;
  border-radius: 0;
  border: 2px solid #2D363C;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  background-color: #f9f9f9;
  font-weight: normal;
  margin-bottom: .4rem;
  padding: .95rem .9rem;
  font-size: 16px;
  padding: 0.25rem !important;
  display: inline-block;
  border-width: 1px;
  font-family: "Panton-Light", Arial, Helvetica, sans-serif;
  background-position: right center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../assets/svg/Icons/Angle Down Sm.svg");
  width: 60px !important;
}

.daterangepicker table select::-webkit-input-placeholder {
  color: #2D363C;
  font-style: italic;
}

.daterangepicker table select:-moz-placeholder {
  color: #2D363C;
  font-style: italic;
}

.daterangepicker table select::-moz-placeholder {
  color: #2D363C;
  font-style: italic;
}

.daterangepicker table select:-ms-input-placeholder {
  color: #2D363C;
  font-style: italic;
}

.daterangepicker table select:placeholder {
  color: #2D363C;
  font-style: italic;
}

.daterangepicker table select:hover {
  border-color: #aaa;
}

.daterangepicker table select:focus {
  outline: 0px solid transparent;
  border-color: #222;
  background-color: #f9f9f9;
}

.daterangepicker table select:disabled {
  opacity: 0.8;
}

.daterangepicker table select select {
  padding: .95rem .9rem;
  font-size: 16px;
  border: none;
  font-style: normal;
}

.daterangepicker table select select.field--sm {
  padding: 0.6rem !important;
}

.daterangepicker table select.field--sm {
  padding: 0.6rem !important;
}

.daterangepicker table td {
  padding: 10px 0;
  font-family: "Panton-Light", Arial, Helvetica, sans-serif;
  font-size: 12px;
}

.daterangepicker table th {
  padding: 10px 0;
}

.daterangepicker .glyphicon {
  position: relative;
  display: block;
  width: 18px;
  height: 18px;
  margin: auto;
}

.daterangepicker .glyphicon:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.daterangepicker .glyphicon.glyphicon-chevron-left:before {
  background-image: url("../assets/svg/Icons/Angle Back.svg");
}

.daterangepicker .glyphicon.glyphicon-chevron-right:before {
  background-image: url("../assets/svg/Icons/Angle Forward.svg");
}

.daterangepicker .daterangepicker_input {
  display: none;
}

.dot {
  position: absolute;
  display: block;
  background: red;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  z-index: 99999;
}

@media (max-width: 1249px) {
  .template-megaform__value {
    font-size: 1.05;
  }
}

@media (min-width: 1550px) {
  .template-megaform__toolbar-item--right {
    padding-top: 19px;
    flex-direction: row;
    align-items: flex-start;
    white-space: nowrap;
    padding-left: 2.5em;
  }
}

[collapsable-target] .collapsable-target--close {
  display: none;
}

[collapsable-target].collapsable-open .collapsable-target--open {
  display: none;
}

[collapsable-target].collapsable-open .collapsable-target--close {
  display: unset;
}

@media (min-width: 1000px) {
  .templates-datatable {
    table-layout: fixed !important;
  }
  .templates-datatable thead tr th:nth-child(1) {
    width: 18% !important;
  }
}

.dropmenu-trigger__btn.comments-count {
  display: flex;
  align-items: center;
}

.dropmenu-trigger__btn.comments-count > span {
  text-indent: 8px;
  min-width: 40px;
  display: block;
  font-size: 1.075em;
}

.dropmenu-trigger__btn.comments-count > svg {
  display: block;
  position: relative;
  margin: 0 -10px;
}

.template-table.f5-performance-reviewer .template-table__field {
  min-height: unset;
}

.template-table.f5-performance-reviewer .template-table__field .st-dropdown {
  display: flex;
  height: 100%;
}

.template-table.f5-performance-reviewer .template-table__field .st-dropdown .st-dropdown-trigger {
  height: 100%;
}

.template-table.f5-performance-reviewer .template-table__legend .template-table__field {
  min-height: 200px;
}

.template-table.f5-performance-reviewer .template-table__legend[colspan="3"] {
  border: 2px solid #e8e9ea;
}

.template-table.game-plan {
  table-layout: fixed;
  overflow: hidden;
}

.template-table.game-plan--comissioner .cell_heading {
  text-align: center;
}

.template-table.game-plan--comissioner > tbody > tr > td:nth-child(1) [ng-bind-html] {
  position: absolute;
  left: 0;
  top: 0;
  width: 200%;
  z-index: 1;
}

.template-table.game-plan--comissioner > tbody > tr > td:nth-child(1) > .template-table__cell,
.template-table.game-plan--comissioner > tbody > tr > td:nth-child(2) > .template-table__cell {
  padding-top: 31px;
}

.template-table .flex-cell--headings {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.template-table .flex-cell--headings li {
  padding: 0;
  margin: 0;
  flex: 1;
  text-align: center;
}

table[template-id="4"] > tbody td:last-child,
table[template-id="5"] > tbody td:last-child {
  position: relative;
}

table[template-id="4"] > tbody td:last-child:after,
table[template-id="5"] > tbody td:last-child:after {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-left: 22px solid #f1f1f1;
  border-bottom: 20px solid transparent;
  top: calc(50% - 20px);
  left: -1px;
}

table[template-id="4"] > tbody td:last-child:before,
table[template-id="5"] > tbody td:last-child:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: -2px;
  width: 2px;
  height: 100%;
  background: #f1f1f1;
  z-index: 1;
}

table[template-id="4"] > tbody td div[name*="rating_"],
table[template-id="5"] > tbody td div[name*="rating_"] {
  background: #f1f1f1 !important;
}

table[template-id="4"] > tbody td:last-child template-field > div,
table[template-id="5"] > tbody td:last-child template-field > div {
  padding-left: 20px;
}

table.f5-performance-reviewer {
  table-layout: auto !important;
}

table.f5-performance-reviewer > tbody tr:first-child > td:not(:first-child) {
  width: 50%;
}

table.f5-performance-reviewer > tbody .template-table__cell [ng-bind-html] {
  padding: 5px 1em;
}

table.f5-performance-reviewer > tbody .template-table__cell [ng-bind-html]::before {
  font-size: 13px;
  white-space: nowrap;
}

table.f5-performance-reviewer > tbody .template-table__cell [name*="rating_"] {
  padding: 5px 10px;
}

table.f5-performance-reviewer > tbody .template-table__cell [name*="rating_"]::before {
  font-size: 13px;
  white-space: nowrap;
}

table.f5-performance-reviewer > tbody .template-table__cell-legend {
  position: relative;
  padding: 5px 0;
  background: #dedede;
  color: #38444B;
  font-family: Arial, serif !important;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
}

table.f5-performance-reviewer > tbody .template-table__cell-legend [class*='__cell'] {
  margin: -17px -12px -17px;
}

table.f5-performance-reviewer > tbody [data-row-length="4"] td:first-child, table.f5-performance-reviewer > tbody [data-row-length="4"] td:nth-child(3) {
  background: #f1f1f1 !important;
}

table.f5-performance-reviewer > tbody [data-row-length="4"] td:nth-child(3) {
  width: 160px;
}

table.f5-performance-reviewer > tbody [data-row-length="6"] td:first-child {
  width: 160px;
}

table.f5-performance-reviewer > tbody [data-row-length="6"] td:first-child, table.f5-performance-reviewer > tbody [data-row-length="6"] td:nth-child(3), table.f5-performance-reviewer > tbody [data-row-length="6"] td:nth-child(4), table.f5-performance-reviewer > tbody [data-row-length="6"] td:last-child {
  background: #f1f1f1 !important;
}

table.f5-performance-reviewer > tbody [data-row-length="6"] td:nth-child(2), table.f5-performance-reviewer > tbody [data-row-length="6"] td:nth-child(5) {
  width: 168px;
}

table.f5-performance-reviewer > tbody .vertical-cell {
  transform: translate(-1em, -0.35em);
}

table.f5-performance-reviewer > tbody td {
  font-size: 13px;
}

table.reduced-rating-size + .progress-block li:not(.active) .progress-block__label {
  font-size: 8px;
}

[data="summary"].open-dropdown {
  z-index: 99999 !important;
}

@media (min-width: 1100px) {
  [dash-chart] {
    height: 500px;
  }
}

.template-diagram {
  opacity: 0;
  visibility: hidden;
  transform: scale(0.9, 0.9);
  transition: all .35s linear;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
}

.template-diagram.active {
  transform: scale(1, 1);
  visibility: visible;
  opacity: 1;
}

.template-diagram path.active {
  opacity: 0;
}

.template-diagram .st0 {
  fill: transparent;
}

.template-diagram > svg {
  width: 100%;
  height: 100%;
  max-width: 94vw;
  max-height: 60vh;
  object-fit: contain;
}

@media (min-width: 1100px) {
  .template-diagram > svg {
    max-height: 70vh;
    max-width: 70vw;
  }
}

.template-diagram .st0 {
  fill: #1BBCEE;
}

.template-diagram .st1 {
  fill: white;
}

.template-diagram .diagram-btn {
  transform-origin: center;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  filter: url(#diagram_shadow_light);
}

.template-diagram .diagram-btn#diagram_step1 {
  transform: translate(202.72373px, -0.00022px);
}

.template-diagram .diagram-btn#diagram_step2 {
  transform: translate(197.05566px, 202.81879px);
}

.template-diagram .diagram-btn#diagram_step3 {
  transform: translate(0.26548px, 197.05691px);
}

.template-diagram .diagram-btn#diagram_step4 {
  transform: translate(0px, 0.27394px);
}

.template-diagram .diagram-btn#diagram_center {
  transform: translate(124.94636px, 124.81048px);
}

.template-diagram .diagram-btn:hover {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform: scale(1.125);
  filter: url("#diagram_shadow");
  z-index: 10;
}

.template-diagram .diagram-btn#diagram_step1:hover {
  transform: translate(215px, 22px) scale(1.125);
}

.template-diagram .diagram-btn#diagram_step2:hover {
  transform: translate(206px, 218px) scale(1.125);
}

.template-diagram .diagram-btn#diagram_step3:hover {
  transform: translate(18px, 210px) scale(1.125);
}

.template-diagram .diagram-btn#diagram_step4:hover {
  transform: translate(20px, 20px) scale(1.125);
}

.template-diagram .diagram-btn#diagram_center:hover {
  transform: translate(141.7px, 145px) scale(1.125);
}

.template-diagram .diagram-btn:not(.disabled) {
  cursor: pointer;
}

.template-diagram .diagram-btn:not(.disabled) .inactive {
  display: none;
}

.template-diagram .diagram-btn.disabled .active {
  display: none;
}

.template-diagram .diagram-btn.disabled .st0 {
  fill: #666 !important;
}

.template-diagram .diagram-btn.disabled .st1 {
  fill: #444 !important;
}

.template-diagram .diagram-btn.disabled .icon > path {
  fill: #333 !important;
}

.template-diagram .diagram-btn > g {
  pointer-events: none;
}

.opt-phase-nav a:hover [class*='__btn-inner'], .framework-deployment-btn:hover [class*='__btn-inner'] {
  border: 1px solid #00ADEF !important;
  border-right: none !important;
  background: #00ADEF;
}

.opt-phase-nav a:hover [class*='__btn-inner']:before, .framework-deployment-btn:hover [class*='__btn-inner']:before, .opt-phase-nav a:hover [class*='__btn-inner']:after, .framework-deployment-btn:hover [class*='__btn-inner']:after {
  background: #00ADEF;
}

.opt-phase-nav {
  margin: 1em 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style-type: none;
}

@media (min-width: 1100px) {
  .opt-phase-nav {
    position: absolute;
    top: 5%;
    left: 0;
    margin: 0;
    padding: 2.5% 0;
    height: 100%;
    flex-direction: column;
    justify-content: space-around;
  }
  .opt-phase-nav--right {
    left: unset;
    right: 0;
  }
}

.opt-phase-nav > li {
  padding: 0;
  margin: 0 0 1.25em;
}

@media (max-width: 1099px) {
  .opt-phase-nav > li {
    margin-bottom: 0;
  }
  .opt-phase-nav > li:not(:first-child) [class*='__btn-inner'] {
    padding-left: 16px;
  }
  .opt-phase-nav > li:last-child [class*='__btn-inner'] {
    border-right: 1px solid white;
  }
  .opt-phase-nav > li:last-child [class*='__btn-inner']:before, .opt-phase-nav > li:last-child [class*='__btn-inner']:after {
    display: none;
  }
}

.opt-phase-nav__btn {
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 10px;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
}

.opt-phase-nav__btn > span {
  width: 100%;
}

@media (min-width: 1100px) {
  .opt-phase-nav__btn {
    font-size: 13px;
  }
}

.opt-phase-nav__btn-outer {
  margin-bottom: 5px;
}

.opt-phase-nav__btn-inner {
  position: relative;
  min-height: 40px;
  background: transparent;
  border: 1px solid white;
  border-right: none;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  padding: 2px 6px;
  justify-content: center;
  line-height: 1.125;
  transition: 0.125s all linear;
}

@media (min-width: 1100px) {
  .opt-phase-nav__btn-inner {
    min-width: 86px;
    min-height: 52px;
  }
}

.opt-phase-nav__btn-inner > .name {
  text-transform: uppercase;
  font-weight: bold;
}

.opt-phase-nav__btn-inner:before, .opt-phase-nav__btn-inner:after {
  content: '';
  position: absolute;
  top: -1px;
  left: calc(100% - 1px);
  height: calc(100% + 2px);
  padding-right: 18%;
  background: white;
  clip-path: polygon(0 0, 0% 100%, 100% 50%);
  transition: 0.125s all linear;
}

.opt-phase-nav__btn-inner:after {
  background: #22292D;
  left: calc(100% - 2px);
  border-radius: 2px;
  z-index: 1;
}

.opt-phase-nav__btn-inner > .channel-name {
  fill: currentColor;
  height: 26px;
  width: 100px;
}

.opt-phase-nav a {
  display: block;
  color: white;
}

.opt-phase-nav a.disabled {
  opacity: 0.25;
  pointer-events: none;
}

.opt-phase-nav--right [class*='__btn-inner'] {
  border-right: 1px solid white;
}

.opt-phase-nav--right [class*='__btn-inner']:before, .opt-phase-nav--right [class*='__btn-inner']:after {
  display: none;
}

.framework-deployment-btn {
  margin: 1em auto 0;
  display: block;
  color: white;
  max-width: 140px;
}

@media (min-width: 1100px) {
  .framework-deployment-btn {
    margin: unset;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.framework-deployment-btn .opt-phase-nav__btn-inner {
  border: 1px solid white !important;
  text-align: center;
  padding: 8px 25px;
}

.framework-deployment-btn .opt-phase-nav__btn-inner:before, .framework-deployment-btn .opt-phase-nav__btn-inner:after {
  display: none;
}

.templates-landing-block {
  position: relative;
  height: 100%;
}

@media (max-width: 1099px) {
  .templates-landing-block {
    padding: 0;
  }
}

table[class*='12month-progress-reviewer'] > tbody > tr:not([data-row-length="4"]) > td {
  width: 45%;
}

table[class*='12month-progress-reviewer'] > tbody > tr[data-row-length="4"] > td {
  width: 30%;
}

table[class*='12month-progress-reviewer'] > tbody > tr > td:first-child {
  width: 0;
}

body.articles-page.myTemplates .post-delivery-article-navitem > span:first-child {
  display: block;
  font-weight: bold;
}

.myTemplates .modal-body {
  border: none !important;
}

@media (min-width: 1000px) {
  .template-docs__footer .team-controls > .delete {
    display: none;
  }
}

@media (max-width: 999px) {
  .myTemplates article .article-body {
    padding-left: 0;
    padding-right: 0;
  }
  [data="summary"] .dataTables_wrapper .dataTables_info {
    display: none;
  }
  [data="summary"] .dataTables_wrapper > table {
    margin-top: 0;
  }
  .template-docs__heading {
    flex-direction: column-reverse;
  }
  .template-docs__heading > * {
    width: 100%;
  }
  .template-docs__heading .template-docs__actions {
    justify-content: flex-end;
  }
  .template-docs__heading .template-docs__actions .dropmenu-holder {
    top: 100%;
    left: unset;
    right: 0;
  }
}

.note__suggestion-list {
  border: 2px solid #bfbfbf;
  border-top: none;
}

.note__suggestion-list > li {
  border: none !important;
}

.note__suggestion-list > li span {
  display: block;
  padding: 0.5em;
  font-size: 14px;
  cursor: pointer;
}

.note__suggestion-list > li span:hover {
  background: #00ADEF;
  color: white;
}

.note-visible-label {
  margin-top: 12px;
  display: flex;
}

.note-visible-label .template-megaform__label {
  margin-bottom: 0;
}

.dataTables_wrapper thead .tooltip--open .tooltip__content {
  z-index: 8;
}

.ng-placeholder {
  color: #aaa;
  user-select: none;
  pointer-events: none;
}

.template-docs .dropmenu-holder,
.template-block .dropmenu-holder,
.admin-block .dropmenu-holder {
  background: #ebebeb;
  border: 1px solid #ddd;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.125);
}

@media (min-width: 1100px) {
  .template-docs .dropmenu-holder:before,
  .template-block .dropmenu-holder:before,
  .admin-block .dropmenu-holder:before {
    content: '';
    border-color: transparent;
    display: block;
    width: 20px;
    height: 20px;
    background: #ebebeb;
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
    transform: rotate(45deg);
    box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.0325);
    top: -0.5rem;
  }
  .template-docs .dropmenu-holder--top:before,
  .template-block .dropmenu-holder--top:before,
  .admin-block .dropmenu-holder--top:before {
    top: unset;
    bottom: -0.5rem;
    transform-origin: center;
    transform: rotate(220deg);
  }
}

.template-docs .dropmenu-holder--first-over:before,
.template-block .dropmenu-holder--first-over:before,
.admin-block .dropmenu-holder--first-over:before {
  background: #00ADEF;
}

.template-docs .dropmenu-holder .dataTables_wrapper,
.template-block .dropmenu-holder .dataTables_wrapper,
.admin-block .dropmenu-holder .dataTables_wrapper {
  padding: 0;
}

.template-docs .dropmenu-holder .dataTables_wrapper table td,
.template-block .dropmenu-holder .dataTables_wrapper table td,
.admin-block .dropmenu-holder .dataTables_wrapper table td {
  background: white;
}

.template-docs .dropmenu-holder .dataTables_wrapper table th, .template-docs .dropmenu-holder .dataTables_wrapper table td,
.template-block .dropmenu-holder .dataTables_wrapper table th,
.template-block .dropmenu-holder .dataTables_wrapper table td,
.admin-block .dropmenu-holder .dataTables_wrapper table th,
.admin-block .dropmenu-holder .dataTables_wrapper table td {
  font-size: 12px;
}

.template-docs .dropmenu-holder .dataTables_wrapper table th .btn-xs, .template-docs .dropmenu-holder .dataTables_wrapper table td .btn-xs,
.template-block .dropmenu-holder .dataTables_wrapper table th .btn-xs,
.template-block .dropmenu-holder .dataTables_wrapper table td .btn-xs,
.admin-block .dropmenu-holder .dataTables_wrapper table th .btn-xs,
.admin-block .dropmenu-holder .dataTables_wrapper table td .btn-xs {
  font-size: 10px;
  padding: 7px 10px 5px;
}

.reporting-head .template-docs__actions .dropmenu-holder ul li a.link--active {
  pointer-events: unset;
}

@media (max-width: 999px) {
  .template-docs__footer .team-controls {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
  }
  .template-docs__footer .team-controls > li {
    padding: 0;
  }
  .template-docs__footer .team-controls > li button, .template-docs__footer .team-controls > li .btn {
    margin: 0;
    padding: 1.4em 1em;
    font-size: 10px;
    min-width: unset;
  }
  .template-docs__footer .team-controls > li.span-2 {
    grid-column: span 2 / span 2;
  }
  .template-docs__footer .team-controls > li.ng-hide + .span-2 {
    grid-column: span 1 / span 1;
  }
}

:root {
  --scrolled-transition: all 0.5s cubic-bezier(.52,.04,.07,1.11);
  --bg-template: #F6F7F8;
}

@media (min-width: 1000px) {
  .mobile__template-save,
  .mobile__template-form {
    display: none !important;
  }
}

@media (max-width: 999px) {
  .template-page .main-header [ng-if*="currentArticle"] {
    display: block;
    max-width: 45%;
    white-space: nowrap;
  }
  .template-page .main-header [ng-if*="currentArticle"] > span {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .template-page [class*="template-docs__header"],
  .template-page .alert.alert-success,
  .template-page .alert.alert-error {
    display: none;
  }
  .template-page .articles-main {
    background: var(--bg-template);
    overflow: unset;
  }
  .template-page .template-docs__progress {
    transform: translateY(0px);
    border-top: none;
  }
  .template-page .template-docs__progress [class*="__steps"] {
    margin-left: -25px;
    margin-right: -10px;
    position: relative;
    z-index: 1;
  }
  .template-page .template-docs__progress [class*="title"] {
    font-size: 15px;
    letter-spacing: 0.25px;
    font-weight: 600;
    margin-top: 0;
  }
  .template-page .template-docs__progress [class*="link"] {
    font-size: 11px;
    letter-spacing: 0.25px;
    font-weight: 600;
    opacity: 1;
  }
  .template-page .template-docs__progress [class*="__item"]:after {
    width: 24px;
    height: 24px;
    background-size: 32px;
    background-position: -5px -5px;
  }
  .template-page .template-docs__progress span[class*="__item"]:before {
    height: 6px;
    margin-bottom: 1em;
  }
  .template-page .template-megaform__toolbar {
    position: relative;
    background: rgba(255, 255, 255, 0.9);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
  .template-page .template-megaform__toolbar [class*="megaform__label"] {
    white-space: unset;
  }
  .template-page .template-megaform__toolbar .template-chips > .template-chip {
    margin-right: -10px;
    width: 36px;
    height: 36px;
  }
  .template-page input[name*="period"] {
    margin: 0;
    padding: 7px 0 0;
    border: none;
    background: unset;
    height: unset;
    font-size: 15px;
    font-weight: 600;
  }
}

.template-page .template-chips .link:after {
  display: none;
}

.template-page .template-chips .link .btn__icon {
  transform: translateY(5px);
}

.mobile__template-header {
  background-color: rgba(255, 255, 255, 0.9);
  margin: -30px -15px 0;
  padding: 30px 15px 20px;
  border-bottom: 1px solid #DDE0E5;
}

@media (min-width: 1100px) {
  .mobile__template-header {
    position: relative;
    display: flex !important;
    justify-content: space-between;
    background: unset;
    border-bottom: none;
    padding: 0 15px;
    margin-top: -35px;
  }
  .mobile__template-header.indented {
    padding-right: 5em;
  }
  .mobile__template-header [ng-click*="deleteTemplateModal"] {
    position: absolute;
    right: 16px;
  }
  .mobile__template-header .subtitle {
    align-items: baseline;
    margin-top: 12px;
  }
  .mobile__template-header .subtitle [class*="__value"] {
    font-size: 0.9em;
  }
}

.mobile__template-header .title {
  display: flex;
  align-items: flex-start;
}

.mobile__template-header .title strong {
  font-size: 26px;
  line-height: 36px;
  letter-spacing: 0.25px;
  color: #38444B;
}

.mobile__template-header .subtitle {
  margin-top: 8px;
  display: flex;
  align-items: center;
}

.mobile__template-header [class*="label"],
.mobile__template-header [class*="value"] {
  margin: 0;
  padding: 0;
  display: inline-block;
  margin-right: 12px;
}

.mobile__template-toolbar-trigger {
  cursor: pointer;
  position: relative;
  z-index: 1;
  grid-column: span 2 / span 2;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

@media (min-width: 1100px) {
  .mobile__template-toolbar-trigger {
    display: flex !important;
    align-items: center;
    grid-column: span 4 / span 4;
    margin-bottom: -3.25em;
  }
}

.mobile__template-toolbar-trigger:before {
  content: '';
  display: block;
  width: 100px;
  height: 4px;
  margin: 0 auto;
  border-radius: 4px;
  background: rgba(221, 224, 229, 0.75);
}

@media (max-width: 999px) {
  .template-docs__notes.dropmenu-open .dropmenu-holder {
    transform: translateY(0px);
  }
  .template-docs__notes .dropmenu-holder {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    transform: translateY(100vh);
  }
}

.template-docs--notes {
  counter-reset: commentCount;
}

.template-docs__note {
  counter-increment: commentCount;
  display: flex;
  padding: 1em 0;
}

.template-docs__note:before {
  content: counter(commentCount) ".";
  display: block;
  min-width: 35px;
}

.template-docs__note__buttons {
  padding-left: 24px;
  width: 50px;
}

.template-docs__note__buttons .btn__icon {
  fill: #AAB3BE;
  width: 26px;
  height: 26px;
}

.template-docs__note__content {
  flex-grow: 1;
}

.template-docs__note__comment {
  position: relative;
  margin-bottom: 1.25em;
}

.template-docs__note__comment:before {
  content: '';
  display: block;
  position: absolute;
  inset: -10px -1em;
  border: 1px solid #DDE0E5;
}

.template-docs__note__sender {
  display: flex;
  justify-content: space-between;
  color: #38444B;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 16.5px;
}

.template-docs__note__sender > div {
  display: flex;
  flex-direction: column;
}

.template-docs__note__sender strong {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1.5px;
  color: #AAB3BE;
  letter-spacing: 1.5px;
}

.template-docs__note__sender .toggle {
  margin-top: -1.25rem;
  margin-right: -1.75em;
  display: block;
  position: relative;
  font-size: 10px;
  color: #AAB3BE;
  display: flex;
  align-items: center;
}

.template-docs__note__sender .toggle:after {
  content: '';
  margin-top: -1px;
  width: 24px;
  height: 24px;
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  filter: invert(79%) sepia(3%) saturate(1089%) hue-rotate(173deg) brightness(91%) contrast(90%);
  background-image: url("../assets/svg/Icons/Angle Down Sm.svg");
}

.template-docs__note__sender .toggle.active:after {
  background-image: url("../assets/svg/Icons/Angle Up Sm.svg");
}

.template-docs__note__details {
  color: #38444B;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 16.5px;
}

.template-docs__note__details > div {
  margin-top: 6px;
  display: flex;
  flex-direction: column;
}

.template-docs__note__details > div.visible-to {
  flex-direction: row;
  flex-wrap: wrap;
}

.template-docs__note__details > div.visible-to strong {
  display: block;
  width: 100%;
}

.template-docs__note__details > div.visible-to span + span {
  padding-left: 3px;
}

.template-docs__note__details strong {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1.5px;
  color: #AAB3BE;
  letter-spacing: 1.5px;
}

.template-docs__notes__footer-buttons {
  padding-left: 1em;
  display: flex;
  align-items: start;
}

.template-docs__notes__footer-buttons .btn {
  margin-top: 10px !important;
  min-width: 100px;
  min-height: 38px;
  transform: translate(-20px, 20px);
}

.template-docs__notes__footer-visibility {
  margin-right: auto;
}

.template-docs__notes__footer-visibility ul {
  display: flex;
  flex-direction: column;
}

.template-docs__notes__footer-visibility ul li {
  margin-top: 10px;
}

.template-docs__notes__footer-visibility ul li label {
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  color: #38444B;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 17px;
}

.template-docs__notes__footer-visibility ul .template-docs__note__details {
  margin-left: 25px;
}

.template-docs--notes-empty {
  min-height: 200px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1.5px;
  color: #AAB3BE;
  letter-spacing: 1.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -2.125em;
  margin-right: -2.125em;
}

@media (max-width: 999px) {
  .template-block--layout-component .progress-block {
    display: none;
  }
  .template-block--layout-component .template-megaform table {
    display: none;
  }
}

.form__label {
  color: #bfbfbf;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 2px;
  white-space: nowrap;
  text-transform: uppercase;
}

.pre-wrap {
  white-space: pre-wrap;
  line-height: 1.1 !important;
}

.mobile__template-form section + section {
  margin-top: 60px;
}

.mobile__template-form .heading {
  display: flex;
  padding-bottom: 3px;
  justify-content: space-between;
  color: #38444B;
  border-bottom: 1px solid #AAB3BE;
}

.mobile__template-form .heading h3 {
  padding: 0;
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 30px;
}

.mobile__template-form .heading > span {
  cursor: pointer;
}

.mobile__template-form .heading > span svg {
  width: 32px;
  height: 32px;
  fill: #38444B;
}

.mobile__template-form .heading > span.active svg {
  fill: #00ADEF;
}

.mobile__template-form .diagram {
  padding: 20px 25%;
  display: block;
  margin: 0 auto;
  border-bottom: 1px solid #DDE0E5;
}

.mobile__template-form .question p:last-child {
  margin-bottom: 0;
}

.mobile__template-form .question h4 {
  padding-top: 0;
}

.mobile__template-form .questionnaire {
  padding-top: 20px;
}

.mobile__template-form .questionnaire + .questionnaire {
  margin-top: 20px;
  border-top: 1px solid #DDE0E5;
}

.mobile__template-form .questionnaire-group {
  display: flex;
}

.mobile__template-form .questionnaire-group .question {
  padding-right: 20px;
  flex: 1;
}

.mobile__template-form .questionnaire-group .question h4 {
  margin: 0;
  padding: 0;
  color: #38444B;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 22.5px;
}

.mobile__template-form .questionnaire-group .question p {
  margin: 0;
  padding: 0;
  color: #38444B;
  font-size: 13px;
  letter-spacing: 0.25px;
  line-height: 19.5px;
}

.mobile__template-form .questionnaire-group .rating {
  position: relative;
  margin: 0;
  display: flex;
  flex-direction: column;
  max-width: 50px;
  text-align: center;
}

.mobile__template-form .questionnaire-group .rating .form__label {
  margin-bottom: 5px;
}

.mobile__template-form .questionnaire-group .rating textarea {
  margin: 0;
  padding: 10px 5px;
  text-align: center;
  background: #FFFFFF;
  border: unset;
  resize: none;
  color: #262626;
  font-size: 13px;
  letter-spacing: 0.25px;
  line-height: 19.5px;
  height: 42px;
}

.mobile__template-form .questionnaire-group .rating textarea:disabled {
  background: unset;
}

.mobile__template-form .questionnaire-group .rating template-field.ng-invalid {
  border: 1px solid #ED1E23;
  box-shadow: #ED1E23 0px 0px 0.75rem;
}

.mobile__template-form .questionnaire-group .rating .validate {
  display: block;
  width: 130px;
  font-size: 11px;
  left: unset;
  right: 0;
  padding: 10px;
}

.mobile__template-form .questionnaire-group .rating .validate:before {
  left: unset;
  right: 12px;
}

.mobile__template-form .questionnaire-table {
  margin: 10px 0 0;
}

.mobile__template-form .questionnaire-table dt {
  padding: 15px;
  background: #EEF0F2;
  color: #38444B;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 19.5px;
}

.mobile__template-form .questionnaire-table dd {
  position: relative;
}

.mobile__template-form .questionnaire-table dd .mce-content-body {
  margin: 0;
  padding: 15px;
  background: #FFFFFF;
  border: unset;
  resize: none;
  color: #262626;
  font-size: 13px;
  letter-spacing: 0.25px;
  line-height: 19.5px;
  min-height: 110px;
}

.mobile__template-form .questionnaire-table dd .mce-content-body:not([contenteditable]) {
  background: unset;
}

.mobile__template-form .questionnaire-table dd .validate {
  display: block;
  font-size: 11px;
  padding: 10px;
}

.mobile__template-form .questionnaire-table dd template-field {
  position: relative;
  display: block;
}

.mobile__template-form .questionnaire-table dd .mce-edit-focus ~ .input-counter {
  display: block;
}

.mobile__template-form .questionnaire-table dd template-field.ng-invalid .mce-content-body {
  border: 1px solid #ED1E23;
  box-shadow: #ED1E23 0px 0px 0.75rem;
}

.mobile__template-form .questionnaire-table dd template-field.ng-invalid-max-lines .mce-content-body,
.mobile__template-form .questionnaire-table dd template-field.ng-invalid-max-lines textarea {
  border: 1px solid #ffc048;
  box-shadow: #ffc048 0px 0px 0.75rem;
}

.mobile__template-form .questionnaire-table dd template-field.ng-invalid-max-lines + p.validate {
  background-color: #ffc048;
  color: #2D363C;
}

.mobile__template-form .questionnaire-table dd template-field.ng-invalid-max-lines + p.validate:before {
  border-bottom-color: #ffc048;
}

modal-delegate-authority .modal-body {
  overflow: unset !important;
}

@media (max-width: 999px) {
  body.template-page templates {
    display: block;
    overflow-x: hidden;
  }
  body.template-page [ng-show="showLastTemplateModal"] .form-buttons button > span {
    display: none;
  }
  body.template-page .modal-container .form-note {
    font-size: 12px;
    line-height: 1.6;
  }
  body.template-page modal-delegate-authority {
    display: block;
  }
  body.template-page modal-delegate-authority .modal-body {
    overflow: unset;
  }
  body.template-page modal-delegate-authority .modal-body .flexbox {
    flex-direction: column;
  }
  body.template-page modal-delegate-authority .modal-body .flexbox > div:last-child {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    margin: 10px 0 0 !important;
  }
  body.template-page modal-delegate-authority .modal-body .flexbox > div:last-child button {
    height: 36px;
  }
}

[name="templateForm"] .view-level-widget__button {
  background-color: white !important;
}

[name="templateForm"] .view-level-widget__button svg {
  fill: #00ADEF !important;
  margin-top: 0 !important;
}

.note-suggestions {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #F6F7F8;
  z-index: 1;
  transition: all 0.15s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.note-suggestions.active {
  opacity: 1;
  visibility: visible;
}

.note-suggestions .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ebebeb;
  font-size: 17px;
  font-weight: 600;
  padding: 12px 16px;
}

.note-suggestions .header .modal-close {
  position: relative;
  margin: -5px;
}

.note-suggestions .header .svg-icon {
  width: 38px;
  height: 38px;
}

.note-suggestions .header svg {
  cursor: pointer;
  fill: #00ADEF !important;
}

.note-suggestions .body {
  padding: 15px 20px 20px 20px;
  flex-grow: 1;
  overflow: auto;
}

@media (min-width: 1000px) {
  .note-suggestions .body {
    max-height: 500px;
    max-width: 500px;
    padding: 20px 25px 25px 25px;
  }
}

.note-suggestions .body .owner {
  color: #38444B;
  font-size: 15px;
}

.note-suggestions .body .owner strong {
  font-weight: 600;
}

.note-suggestions .footer {
  border-top: 1px solid #ccc;
  padding: 20px 25px 25px 25px;
}

.note-suggestions__members label.checkbox:before,
.note-suggestions__members label.checkbox:after {
  width: 26px;
  height: 26px;
  top: -5px;
  filter: unset;
}

.note-suggestions__members > li {
  display: flex !important;
  justify-content: space-between;
}

.note-suggestions__members > li.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.template-docs__notes__footer-field textarea {
  padding-top: 10px;
  padding-left: 12px;
  font-size: 15px;
  background: unset !important;
  border-width: 2px;
}

.template-docs__notes__footer-field textarea::placeholder {
  color: #AAB3BE;
}

.template-megaform__toolbar {
  padding-right: 35px;
}

@media (min-width: 1100px) {
  .template-megaform__toolbar {
    display: grid;
    gap: 1em;
    grid-template-columns: 30% 1fr 1fr 1fr;
  }
  .template-megaform__toolbar [class*='__toolbar-item'] {
    margin: 0;
  }
}

.template-megaform__toolbar .select-holder {
  margin-top: 3px;
  background-color: unset !important;
  border: unset !important;
  max-width: 190px;
}

@media (min-width: 1000px) {
  .template-megaform__toolbar .select-holder {
    margin-top: 4px;
  }
}

.template-megaform__toolbar .select-holder select {
  padding: 0;
  font-weight: 600;
  z-index: unset;
  font-size: .95rem;
  padding-right: 35px;
}

@media (max-width: 999px) {
  .template-megaform__toolbar .select-holder select {
    font-size: 15px;
  }
}

.template-megaform__toolbar .select-holder:before {
  width: 28px;
  height: 28px;
  margin-top: -14px;
}

.template-megaform__toolbar .further-textarea {
  height: 42px;
  border: 1px solid #dAe3eE;
  background-color: #fff;
}

.template-megaform__toolbar .further-textarea::placeholder {
  color: #AAB3BE;
}

.template-megaform__toolbar .period-data.input {
  display: flex;
  width: 100%;
  flex-direction: column;
  /*&:after {
      pointer-events: none;
      content: '';
      display: block;
      position: absolute;
      top: 2px;
      right: 1px;
      width: 28px;
      height: 28px;
      background: url('../assets/svg/Icons/Apply.svg') no-repeat center -1px !important;
      background-size: contain !important;
      filter: invert(85%) sepia(0%) saturate(255%) hue-rotate(151deg) brightness(86%) contrast(85%);

      @include media($tablet) { top: 4px; }
    }*/
}

.template-megaform__toolbar .period-data.input input {
  max-width: 150px;
  background: unset !important;
  border: unset !important;
  padding: 0 !important;
  font-weight: 600;
  margin-top: 6px;
  margin-bottom: 0;
  font-size: .9rem;
}

@media (min-width: 1000px) {
  .template-megaform__toolbar .period-data.input input {
    margin-top: 2px;
  }
}

.template-megaform__toolbar .period-data.input button {
  background: none;
  border: none;
  cursor: pointer;
}

.template-megaform__toolbar .period-data.input button svg {
  width: 22px;
  height: 22px;
  fill: #AAB3BE;
}

@media (min-width: 1100px) {
  .template-megaform__toolbar .period-data.input button svg {
    width: 26px;
    height: 26px;
  }
}

.template-megaform__toolbar .period-data.input .period-info {
  margin: 1px 0 -1px;
  line-height: 1;
  font-size: 12px;
}

.template-megaform__toolbar-item .delegate {
  display: flex;
  cursor: pointer;
}

.template-megaform__toolbar-item .delegate[ng-click*="show"] em {
  font-size: 18px;
  transform: translateY(2.5px);
}

@media (min-width: 1000px) {
  .template-megaform__toolbar-item .delegate[ng-click*="show"] em {
    transform: translateY(4px);
  }
}

.template-megaform__toolbar-item .delegate em {
  margin-right: 2px;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  color: #AAB3BE;
  font-weight: 700;
  font-size: 26px;
  margin-top: -5px;
  font-style: normal;
}

@media (min-width: 1000px) {
  .template-megaform__toolbar-item .delegate em {
    margin-left: 0.75em;
  }
}

.template-megaform__toolbar-item .delegate .template-chips {
  display: none;
}

@media (min-width: 1100px) {
  .template-megaform__toolbar-item .delegate:not([ng-click*="show"]) > span {
    display: none;
  }
  .template-megaform__toolbar-item .delegate .template-chips {
    display: flex;
  }
  .template-megaform__toolbar-item .delegate .template-chips ~ em {
    margin-top: -1px;
  }
}

.mobile__template-head-clone {
  background: rgba(255, 255, 255, 0.9);
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: var(--scrolled-transition);
  transition-duration: 0.25s;
  transform: translateY(0);
}

@media (min-width: 1100px) {
  .mobile__template-head-clone {
    top: 0;
    left: 340px;
    width: calc(100% - 340px);
  }
  .mobile__template-head-clone .template-head-clone {
    max-width: 1166px;
    margin: 0 auto;
    padding: 0 50px;
  }
}

.mobile__template-head-clone .template-megaform-holder {
  border-bottom: 1px solid #DDE0E5;
}

.mobile__template-head-clone .mobile__template-header {
  margin-top: 0;
}

.mobile__template-head-clone .mobile__template-header,
.mobile__template-head-clone .template-docs__progress,
.mobile__template-head-clone .template-megaform__toolbar {
  margin-left: 0;
  margin-right: 0;
  background: unset;
}

.mobile__template-head-clone .mobile__template-toolbar-trigger {
  margin-top: 15px;
  margin-bottom: 0;
}

[name="templateForm"].scrolled .mobile__template-head-clone {
  opacity: 1;
  visibility: visible;
  z-index: 2;
}

@media (min-width: 1100px) {
  [name="templateForm"].scrolled .mobile__template-head-clone {
    opacity: 0;
    z-index: -1;
    visibility: hidden;
  }
}

.mobile__template-head-clone.collapsed [class*='__toolbar-item'],
.mobile__template-head-clone.collapsed .mobile__template-header {
  opacity: 0;
  visibility: hidden;
}

.mobile__template-head-clone.collapsed .template-docs__progress [class*="__title"],
.mobile__template-head-clone.collapsed .template-docs__progress [class*="__steps"] a {
  opacity: 0;
  margin-top: -45px;
  visibility: hidden;
}

.mobile__template-head-clone.collapsed [class*='template-toolbar-trigger'] {
  margin-top: -0.5em;
  padding-top: 2.75em;
  padding-bottom: 10px;
}

.mobile__template-head-clone.animating .template-megaform__header,
.mobile__template-head-clone.animating .mobile__template-header,
.mobile__template-head-clone.animating .template-megaform-holder,
.mobile__template-head-clone.animating [class*='__toolbar-item'],
.mobile__template-head-clone.animating [class*='toolbar-trigger'],
.mobile__template-head-clone.animating .template-docs__progress [class*="__title"],
.mobile__template-head-clone.animating .template-docs__progress [class*="__title"] a {
  transition: var(--scrolled-transition);
}

body.template-list templates [ui-view],
body.archive-page templates {
  display: block;
}

body.template-list templates [ui-view] table-list[data="summary"],
body.archive-page templates table-list[data="summary"] {
  display: block;
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable {
  display: block;
  width: 100% !important;
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable thead,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable thead {
  display: none;
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable tbody,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable tbody {
  display: block;
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable tr,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable tr {
  display: flex;
  flex-wrap: wrap;
  background: #fff !important;
  margin-bottom: 15px;
  border-left: 3px solid #F6F7F8;
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable tr:after,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable tr:after {
  content: '';
  display: block;
  order: 4;
  width: calc(100% - 20px);
  height: 1px;
  transform: translateX(10px);
  background: #EEF0F2;
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable tr.draft,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable tr.draft {
  border-color: #AAB3BE;
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable tr.view, body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable tr.proposed,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable tr.view,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable tr.proposed {
  border-color: #00ADEF;
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable tr.review,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable tr.review {
  border-color: #38444B;
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable tr.approved, body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable tr.archived,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable tr.approved,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable tr.archived {
  border-color: #7ED321;
}

@media (min-width: 1100px) {
  body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable tr,
  body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable tr {
    padding: 0 10px;
  }
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable.empty tr,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable.empty tr {
  padding: 0;
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable.empty tr::after,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable.empty tr::after {
  display: none;
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('recipient')"],
body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"],
body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('comments')"] .comments-count,
body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('actions')"],
body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('date')"],
body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('modified')"],
body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('owner')"],
body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('manager')"],
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('recipient')"],
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"],
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('comments')"] .comments-count,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('actions')"],
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('date')"],
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('modified')"],
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('owner')"],
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('manager')"] {
  display: block !important;
  box-sizing: border-box;
  border: unset;
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('recipient')"]:before,
body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"]:before,
body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('comments')"] .comments-count:before,
body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('actions')"]:before,
body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('date')"]:before,
body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('modified')"]:before,
body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('owner')"]:before,
body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('manager')"]:before,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('recipient')"]:before,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"]:before,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('comments')"] .comments-count:before,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('actions')"]:before,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('date')"]:before,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('modified')"]:before,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('owner')"]:before,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('manager')"]:before {
  order: 0;
  width: 100%;
  display: block;
  color: #bfbfbf;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 2px;
  white-space: nowrap;
  text-transform: uppercase;
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('template')"], body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable th[ng-hide*="includes('template')"],
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('template')"],
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable th[ng-hide*="includes('template')"] {
  display: none;
}

@media (max-width: 1099px) {
  body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('owner')"], body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('manager')"], body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable th[ng-hide*="includes('owner')"], body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable th[ng-hide*="includes('manager')"],
  body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('owner')"],
  body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('manager')"],
  body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable th[ng-hide*="includes('owner')"],
  body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable th[ng-hide*="includes('manager')"] {
    display: none !important;
  }
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('recipient')"]:before,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('recipient')"]:before {
  content: 'Owner / Recipient';
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"]:before,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"]:before {
  content: 'Status';
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('date')"]:before,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('date')"]:before {
  content: 'Period';
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('modified')"]:before,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('modified')"]:before {
  content: 'Last Modified';
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('owner')"]:before,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('owner')"]:before {
  content: 'Started by:';
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('manager')"]:before,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('manager')"]:before {
  content: 'Manager:';
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('comments')"],
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('comments')"] {
  box-sizing: border-box;
  border: unset;
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('comments')"] .comments-count,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('comments')"] .comments-count {
  display: flex !important;
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('comments')"] .comments-count:before,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('comments')"] .comments-count:before {
  content: 'Comments:';
  width: unset !important;
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('recipient')"],
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('recipient')"] {
  order: 1;
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"],
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"] {
  order: 2;
}

@media (min-width: 1100px) {
  body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"],
  body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"] {
    order: 3;
  }
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('date')"],
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('date')"] {
  order: 3;
}

@media (min-width: 1100px) {
  body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('date')"],
  body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('date')"] {
    order: 2;
  }
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('modified')"],
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('modified')"] {
  order: 4;
  z-index: 1;
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('comments')"],
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('comments')"] {
  order: 5;
}

@media (min-width: 1100px) {
  body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('comments')"],
  body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('comments')"] {
    order: 7;
  }
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('actions')"],
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('actions')"] {
  order: 6;
}

@media (min-width: 1100px) {
  body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('actions')"],
  body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('actions')"] {
    order: 8;
  }
}

@media (min-width: 1100px) {
  body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('owner')"],
  body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('owner')"] {
    order: 5;
  }
}

@media (min-width: 1100px) {
  body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('manager')"],
  body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('manager')"] {
    order: 6;
  }
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('recipient')"],
body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('date')"],
body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('comments')"],
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('recipient')"],
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('date')"],
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('comments')"] {
  width: 60%;
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"],
body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('modified')"],
body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('actions')"],
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"],
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('modified')"],
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('actions')"] {
  width: 40%;
}

@media (min-width: 1100px) {
  body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('owner')"],
  body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('recipient')"],
  body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('owner')"],
  body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('recipient')"] {
    width: 32%;
  }
  body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('date')"],
  body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('manager')"],
  body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('date')"],
  body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('manager')"] {
    width: 30%;
  }
  body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"],
  body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('comments')"],
  body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"],
  body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('comments')"] {
    width: 18%;
  }
  body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('modified')"],
  body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('actions')"],
  body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('modified')"],
  body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('actions')"] {
    width: 18%;
    margin-left: 2%;
  }
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('date')"],
body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('modified')"],
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('date')"],
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('modified')"] {
  font-size: 13px;
  font-weight: 500;
  line-height: 19.5px;
  letter-spacing: 0.25px;
}

@media (max-width: 1099px) {
  body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('date')"],
  body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('modified')"],
  body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('date')"],
  body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('modified')"] {
    padding-top: 0;
  }
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('modified')"],
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('modified')"] {
  display: flex !important;
  flex-wrap: wrap;
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('modified')"]:before,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('modified')"]:before {
  flex: 1 0 100%;
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('modified')"] .template-docs__actions,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('modified')"] .template-docs__actions {
  margin-left: auto;
  margin-top: -4px;
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('owner')"],
body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('manager')"],
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('owner')"],
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('manager')"] {
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.25px;
}

@media (min-width: 1100px) {
  body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('owner')"],
  body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('manager')"],
  body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('owner')"],
  body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('manager')"] {
    display: flex !important;
  }
  body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('owner')"]:before,
  body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('manager')"]:before,
  body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('owner')"]:before,
  body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('manager')"]:before {
    flex: 0 1 0;
    margin-right: 5px;
  }
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('recipient')"],
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('recipient')"] {
  color: #38444B;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 19px;
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"],
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"] {
  position: relative;
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.25px;
  display: flex !important;
  flex-wrap: wrap;
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"] span,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"] span {
  order: 9;
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"][data-td='draft'],
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"][data-td='draft'] {
  color: #AAB3BE;
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"][data-td='draft']:after,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"][data-td='draft']:after {
  background-color: #AAB3BE;
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"][data-td='view'], body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"][data-td='proposed'],
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"][data-td='view'],
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"][data-td='proposed'] {
  color: #00ADEF;
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"][data-td='view']:after, body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"][data-td='proposed']:after,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"][data-td='view']:after,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"][data-td='proposed']:after {
  background-color: #00ADEF;
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"][data-td='review'],
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"][data-td='review'] {
  color: #38444B;
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"][data-td='review']:after,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"][data-td='review']:after {
  background-color: #38444B;
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"][data-td='approved'], body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"][data-td='archived'],
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"][data-td='approved'],
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"][data-td='archived'] {
  color: #7ED321;
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"][data-td='approved']:after, body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"][data-td='archived']:after,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"][data-td='approved']:after,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"][data-td='archived']:after {
  background-color: #7ED321;
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"]:after,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"]:after {
  content: '';
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 100%;
  order: 1;
  align-self: center;
  margin-right: 5px;
  transform: translateY(-2px);
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"] .template-docs__actions,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('status')"] .template-docs__actions {
  order: 10;
  margin-left: auto;
  transform: translateY(-2px);
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('actions')"] .template-docs__actions [title="Related archive"],
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('actions')"] .template-docs__actions [title="Related archive"] {
  border-bottom: 1px solid #38444B;
  color: #38444B;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 16.5px;
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('comments')"] .comments-count .btn__icon,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('comments')"] .comments-count .btn__icon {
  opacity: 0;
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('comments')"] .comments-count > span,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('comments')"] .comments-count > span {
  display: flex;
  position: relative;
  min-width: unset;
  align-items: center;
  flex-grow: 1;
}

body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('comments')"] .comments-count > span:after,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('comments')"] .comments-count > span:after {
  content: '';
  display: block;
  width: 24px;
  height: 24px;
  background: url("../assets/svg/Icons/Comment Plus.svg") no-repeat center;
  background-size: contain;
  filter: invert(72%) sepia(98%) saturate(4745%) hue-rotate(164deg) brightness(100%) contrast(102%);
  margin-left: 5px;
}

@media (min-width: 1100px) {
  body.template-list templates [ui-view] table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('comments')"] .comments-count > span:after,
  body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('comments')"] .comments-count > span:after {
    margin-right: -3px;
    margin-left: auto;
  }
}

body.template-list templates [ui-view] > .template-docs > .template-docs__heading h1,
body.archive-page templates > .template-docs > .template-docs__heading h1 {
  display: inline-flex;
}

body.template-list templates [ui-view] > .template-docs > .template-docs__heading h1 .tooltip__button,
body.archive-page templates > .template-docs > .template-docs__heading h1 .tooltip__button {
  fill: #00ADEF;
  margin-top: -1px;
  margin-left: 8px;
}

body.template-list templates [ui-view] > .template-docs > .template-docs__heading [class*="__actions"],
body.archive-page templates > .template-docs > .template-docs__heading [class*="__actions"] {
  width: unset;
  position: fixed;
  bottom: 80px;
  right: 20px;
  z-index: 10;
  flex-direction: column;
}

@media (min-width: 1100px) {
  body.template-list templates [ui-view] > .template-docs > .template-docs__heading [class*="__actions"],
  body.archive-page templates > .template-docs > .template-docs__heading [class*="__actions"] {
    bottom: unset;
    position: unset;
    flex-direction: row;
    gap: 15px;
  }
}

body.template-list templates [ui-view] > .template-docs > .template-docs__heading [class*="__actions"] .btn,
body.archive-page templates > .template-docs > .template-docs__heading [class*="__actions"] .btn {
  padding: 0;
  position: relative;
  border: unset;
  border-radius: 50%;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 30px -15px;
  width: 56px;
  height: 56px;
  text-indent: -1000vw;
  white-space: nowrap;
  overflow: hidden;
}

body.template-list templates [ui-view] > .template-docs > .template-docs__heading [class*="__actions"] .btn:before,
body.archive-page templates > .template-docs > .template-docs__heading [class*="__actions"] .btn:before {
  filter: invert(72%) sepia(98%) saturate(4745%) hue-rotate(164deg) brightness(100%) contrast(102%);
  content: '';
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

body.template-list templates [ui-view] > .template-docs > .template-docs__heading [class*="__actions"] .btn:hover,
body.archive-page templates > .template-docs > .template-docs__heading [class*="__actions"] .btn:hover {
  background: #fff !important;
}

body.template-list templates [ui-view] > .template-docs > .template-docs__heading [class*="__actions"] .btn.disabled,
body.archive-page templates > .template-docs > .template-docs__heading [class*="__actions"] .btn.disabled {
  pointer-events: none;
  background-color: #bfbfbf;
  opacity: 0.75;
}

body.template-list templates [ui-view] > .template-docs > .template-docs__heading [class*="__actions"] .btn.disabled:before,
body.archive-page templates > .template-docs > .template-docs__heading [class*="__actions"] .btn.disabled:before {
  filter: invert(26%) sepia(0%) saturate(1281%) hue-rotate(196deg) brightness(92%) contrast(89%);
}

body.template-list templates [ui-view] > .template-docs > .template-docs__heading [class*="__actions"] [ng-if*='hasDirectReport'] .btn:before,
body.archive-page templates > .template-docs > .template-docs__heading [class*="__actions"] [ng-if*='hasDirectReport'] .btn:before {
  background-image: url("../assets/svg/Icons/Users Trend Up.svg");
  position: absolute;
}

body.template-list templates [ui-view] > .template-docs > .template-docs__heading [class*="__actions"] li.action-btn--meeting,
body.archive-page templates > .template-docs > .template-docs__heading [class*="__actions"] li.action-btn--meeting {
  order: -2;
  margin: 0;
}

body.template-list templates [ui-view] > .template-docs > .template-docs__heading [class*="__actions"] li.action-btn--meeting .btn:before,
body.archive-page templates > .template-docs > .template-docs__heading [class*="__actions"] li.action-btn--meeting .btn:before {
  background-image: url("../assets/svg/Icons/Calendar Plus.svg");
  position: absolute;
}

body.template-list templates [ui-view] > .template-docs > .template-docs__heading [class*="__actions"] li.action-btn--add,
body.archive-page templates > .template-docs > .template-docs__heading [class*="__actions"] li.action-btn--add {
  order: -1;
  margin: 0;
}

body.template-list templates [ui-view] > .template-docs > .template-docs__heading [class*="__actions"] li.action-btn--add .btn:before,
body.archive-page templates > .template-docs > .template-docs__heading [class*="__actions"] li.action-btn--add .btn:before {
  background-image: url("../assets/svg/Icons/Add.svg");
  position: absolute;
}

.template-docs h2 {
  color: #38444B;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 30px;
}

.template-docs h2 svg {
  transform: translateY(-1px);
}

body.myTemplates.archive-page main {
  overflow: unset;
}

body.myTemplates.archive-page .template-docs__heading {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  margin: -30px -15px 20px;
  padding: 0 10px;
  background: #fff;
  border-bottom: 1px solid rgba(221, 224, 229, 0.9);
  min-height: 80px;
  justify-content: center;
  z-index: 1;
}

@media (min-width: 1100px) {
  body.myTemplates.archive-page .template-docs__heading {
    background: unset;
    border: unset;
    justify-content: start;
  }
}

body.myTemplates.archive-page .template-docs__heading h1 {
  margin: 0;
  padding: 0;
  color: #38444B;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0.25px;
}

body.myTemplates.archive-page .template-docs__heading h1 svg {
  width: 32px;
  height: 32px;
  transform: translateY(1px);
}

[ng-show="previewDocumentPrompt"] .form-buttons {
  display: flex;
  flex-direction: column;
}

template-revisions {
  display: block;
  height: 100%;
}

@media (min-width: 1100px) {
  template-revisions > [class*='notes'] {
    min-width: 568px;
  }
}

template-revisions .template-docs--history table {
  display: block;
  margin: 0;
}

template-revisions .template-docs--history table thead {
  display: none;
}

template-revisions .template-docs--history table tr {
  display: flex;
  flex-wrap: wrap;
  border-left: 3px solid transparent;
  margin-bottom: 15px;
}

template-revisions .template-docs--history table tr.draft {
  border-color: #AAB3BE !important;
}

template-revisions .template-docs--history table tr.view, template-revisions .template-docs--history table tr.shared, template-revisions .template-docs--history table tr.proposed {
  border-color: #00ADEF !important;
}

template-revisions .template-docs--history table tr.review, template-revisions .template-docs--history table tr.reviewed {
  border-color: #38444B !important;
}

template-revisions .template-docs--history table tr.approved {
  border-color: #7ED321 !important;
}

template-revisions .template-docs--history table tr.draft > td:nth-child(2) > span:before {
  background-color: #AAB3BE;
}

template-revisions .template-docs--history table tr.view > td:nth-child(2) > span:before,
template-revisions .template-docs--history table tr.shared > td:nth-child(2) > span:before {
  background-color: #00ADEF;
}

template-revisions .template-docs--history table tr.review > td:nth-child(2) > span:before,
template-revisions .template-docs--history table tr.reviewed > td:nth-child(2) > span:before {
  background-color: #38444B;
}

template-revisions .template-docs--history table tr.approved > td:nth-child(2) > span:before {
  background-color: #7ED321;
}

template-revisions .template-docs--history table tr.draft > td:nth-child(2) > span {
  font-weight: 500;
  color: #AAB3BE;
}

template-revisions .template-docs--history table tr.view > td:nth-child(2) > span,
template-revisions .template-docs--history table tr.shared > td:nth-child(2) > span {
  font-weight: 500;
  color: #00ADEF;
}

template-revisions .template-docs--history table tr.review > td:nth-child(2) > span,
template-revisions .template-docs--history table tr.reviewed > td:nth-child(2) > span {
  font-weight: 500;
  color: #38444B;
}

template-revisions .template-docs--history table tr.approved > td:nth-child(2) > span {
  font-weight: 500;
  color: #7ED321;
}

template-revisions .template-docs--history table tr td {
  border: unset;
  background-color: #fff !important;
  box-sizing: border-box;
  text-align: left;
}

template-revisions .template-docs--history table tr td:nth-child(1) {
  width: 60%;
}

template-revisions .template-docs--history table tr td:nth-child(2) {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 40%;
}

template-revisions .template-docs--history table tr td:nth-child(2) > span {
  text-transform: capitalize;
  order: 9;
  display: flex;
  align-items: center;
  gap: 6px;
}

template-revisions .template-docs--history table tr td:nth-child(2) .template-megaform__label {
  width: 100%;
}

template-revisions .template-docs--history table tr td:nth-child(2) a {
  display: block;
  position: absolute;
  bottom: 12px;
  right: 10px;
}

template-revisions .template-docs--history table tr td:nth-child(2) > span:before {
  content: '';
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 100%;
}

template-revisions .template-docs--history table tr td:nth-child(3) {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1099px) {
  template-revisions .template-docs--history table tr td:nth-child(3) [class*="__label"] {
    margin: 0;
  }
}

template-revisions .template-docs--history table tr td:nth-child(3):before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 20px);
  height: 1px;
  transform: translateX(10px);
  background: #EEF0F2;
}

@media (min-width: 1100px) {
  template-revisions .template-docs--history table tr td:nth-child(1) {
    width: 40%;
  }
  template-revisions .template-docs--history table tr td:nth-child(2) {
    width: 30%;
  }
  template-revisions .template-docs--history table tr td:nth-child(3) {
    width: 30%;
    display: block;
  }
}

.template-docs.archive .template-docs__heading {
  margin-bottom: 20px;
}

.template-docs.archive h1 {
  display: flex;
  align-items: center;
}

.template-docs.archive h1 a {
  display: block;
  transform: translateY(-3px);
  margin-right: 12px;
}

.template-docs.archive h1 a svg {
  pointer-events: none;
  filter: invert(72%) sepia(98%) saturate(4745%) hue-rotate(164deg) brightness(100%) contrast(102%);
}

.template-megaform__toolbar select {
  text-indent: 3px;
}

.template-megaform__toolbar select:required:invalid {
  color: #AAB3BE;
  font-style: italic;
}

.template-megaform__toolbar input::-webkit-input-placeholder {
  color: #AAB3BE;
  font-style: italic;
}

.template-megaform__toolbar input:-moz-placeholder {
  color: #AAB3BE;
  font-style: italic;
}

.template-megaform__toolbar input::-moz-placeholder {
  color: #AAB3BE;
  font-style: italic;
}

.template-megaform__toolbar input:-ms-input-placeholder {
  color: #AAB3BE;
  font-style: italic;
}

.template-megaform__toolbar input:placeholder {
  color: #AAB3BE;
  font-style: italic;
}

.empty-table {
  padding: 5px;
  background: #F6F7F8 !important;
  color: #AAB3BE;
  font-weight: 400;
  letter-spacing: 0.25px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.empty-table figure {
  position: relative;
  border: 1.5px solid #AAB3BE;
  width: unset;
  border-radius: 50%;
  margin-bottom: 8px;
}

.empty-table figure:before {
  content: '';
  width: 32px;
  height: 32px;
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../assets/svg/Icons/Template Fields.svg");
  filter: invert(76%) sepia(10%) saturate(307%) hue-rotate(173deg) brightness(94%) contrast(87%);
}

td.dataTables_empty {
  width: 100% !important;
  border: unset !important;
  padding: 0 !important;
}

table.empty.dataTable thead {
  display: none;
}

.template-megaform__toolbar-item .visibility {
  display: flex;
}

.template-megaform__toolbar-item .visibility i {
  font-style: unset;
  margin-left: 5px;
}

@media (min-width: 1000px) {
  .template-megaform__toolbar-item .visibility > .item + .item {
    margin-left: 2em;
  }
}

.template-megaform__toolbar-item .visibility .trigger {
  display: flex;
  position: relative;
  cursor: pointer;
}

.template-megaform__toolbar-item .visibility .trigger:after {
  content: '';
  display: block;
  width: 24px;
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../assets/svg/Icons/Add Sm.svg");
  filter: invert(77%) sepia(6%) saturate(498%) hue-rotate(173deg) brightness(93%) contrast(87%);
  margin-left: 10px;
  transform: translateY(-1px);
}

.template-megaform__toolbar-item .visibility > .item {
  display: flex;
  width: 100%;
  align-items: center;
}

.template-megaform__toolbar-item .visibility > .item > em {
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  color: #AAB3BE;
  font-weight: 700;
  font-size: 26px;
  margin-top: -5px;
  font-style: normal;
  font-size: 18px;
  transform: translateY(1.5px);
  margin-left: auto;
}

@media (min-width: 1000px) {
  .template-megaform__toolbar-item .visibility > .item > em {
    transform: translateY(2px);
    margin-left: 20px;
  }
}

@media (min-width: 1100px) {
  .template-megaform__toolbar-item .visibility {
    display: none;
  }
}

.template-megaform__toolbar-item.visibility .template-chips {
  display: none;
}

@media (min-width: 1100px) {
  .template-megaform__toolbar-item.visibility .template-chips {
    display: flex;
  }
}

.template-megaform__toolbar-item.visibility .template-chips em {
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  color: #AAB3BE;
  font-weight: 700;
  font-size: 26px;
  margin-top: -5px;
  font-style: normal;
  font-size: 18px;
  margin-left: 0.75em;
  margin-top: 0;
}

.note-suggestions__members {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;
  padding: 0;
}

.note-suggestions__members > li {
  margin: 10px 0 0;
  padding: 0;
  font-size: 0.85rem;
}

.note-suggestions__members > li > span {
  text-align: right;
}

.note-suggestions__members label {
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  color: #38444B;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 17px;
}

@media (max-width: 999px) {
  .st-dropdown-content.fixed {
    display: flex;
    position: fixed;
    top: 0 !important;
    left: 0 !important;
    width: 100%;
    height: 100%;
  }
  .st-dropdown-content.fixed > * {
    display: block;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('modified')"] .template-docs__actions,
body.archive-page templates table-list[data="summary"] .dataTables_wrapper > .dataTable td[ng-hide*="includes('actions')"] {
  display: none !important;
}

.mobile__template-save [type="submit"].danger {
  background-color: #fbc7c8 !important;
}

.mobile__template-save [type="submit"].danger .svg-icon {
  fill: #ED1E23 !important;
}

@media (max-width: 999px) {
  template-layout-performance-transformation-tool {
    display: block;
  }
  template-layout-performance-transformation-tool .mobile__template-form .mce-content-body {
    min-height: 400px !important;
  }
}

button[ng-click*="deleteTemplateModal"] {
  margin-left: auto;
  background: none;
  border: none;
  cursor: pointer;
}

button[ng-click*="deleteTemplateModal"] svg {
  width: 40px;
  height: 40px;
  fill: #AAB3BE;
}

@media (max-width: 1099px) {
  button[ng-click*="deleteTemplateModal"] svg {
    width: 34px;
    height: 34px;
  }
}

.template-megaform__toolbar-buttons {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  padding: 20px 0 0;
  grid-column: span 2 / span 2;
  background: var(--bg-template);
  margin-bottom: 10px;
}

.template-megaform__toolbar-buttons [ng-click*="ReferenceTool"] {
  margin-right: auto !important;
}

.template-megaform__toolbar-buttons [class*='trigger__btn'], .template-megaform__toolbar-buttons .schedule-meeting-btn {
  border: unset;
  color: unset;
  padding: 0;
  background: unset;
}

.template-megaform__toolbar-buttons [class*='trigger__btn'] h6, .template-megaform__toolbar-buttons [class*='trigger__btn'] p, .template-megaform__toolbar-buttons .schedule-meeting-btn h6, .template-megaform__toolbar-buttons .schedule-meeting-btn p {
  margin: 0;
}

.template-megaform__toolbar-buttons [class*='trigger__btn']:hover svg, .template-megaform__toolbar-buttons .schedule-meeting-btn:hover svg {
  fill: #2D363C;
}

.template-megaform__toolbar-buttons [class*='trigger__btn'] svg, .template-megaform__toolbar-buttons .schedule-meeting-btn svg {
  fill: #00ADEF;
  pointer-events: none;
  width: 40px;
  height: 40px;
}

@media (max-width: 1099px) {
  .template-megaform__toolbar-buttons [class*='trigger__btn'] svg, .template-megaform__toolbar-buttons .schedule-meeting-btn svg {
    width: 34px;
    height: 34px;
  }
}

@media (min-width: 1100px) {
  .template-megaform__toolbar-buttons {
    margin-left: 0;
    margin-right: 0;
  }
}

.template-megaform__toolbar-buttons > * + * {
  margin-left: 8px;
}

template-layout-culture-assessment-action-plan .questionnaire-group .question {
  display: flex;
  align-items: center;
  padding-top: 20px;
}

.st-dropdown-trigger.disabled {
  pointer-events: none;
  cursor: unset;
}

.st-dropdown-trigger template-field {
  display: block;
  pointer-events: none;
}

template-rating {
  display: block;
  min-width: 320px;
}

template-rating .note-suggestions .body {
  padding: 0;
}

template-rating .note-suggestions .body .body-desc {
  margin: 0;
  font-size: 16px;
  padding-bottom: .5rem;
  padding: .5rem 0;
  border-bottom: 1px solid #eee;
}

template-rating .note-suggestions .body ul {
  padding: 0;
  margin: 0;
}

template-rating .note-suggestions .body li {
  list-style: none;
  padding: 3px 12px 3px 22px;
  border-bottom: 1px solid #f5f5f5;
  margin: 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

template-rating .note-suggestions .body li:nth-child(2n) {
  background-color: #f2f2f2;
}

@media (min-width: 1100px) {
  template-rating .note-suggestions .body li:hover {
    background: #eee !important;
  }
}

template-rating .note-suggestions .body li.selected {
  color: #00ADEF;
}

template-rating .note-suggestions .body .option {
  cursor: pointer;
  padding: 2px 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

template-rating .note-suggestions .body .option label {
  display: flex;
  pointer-events: none;
  justify-content: space-between;
  flex-grow: 1;
  font-weight: 500;
  font-size: 16px;
  padding-right: 0.5em;
}

template-rating .note-suggestions .body .option p {
  font-size: 13px;
  opacity: .9;
  margin: 0;
  line-height: 1.15;
}

template-rating .note-suggestions .body svg {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  fill: currentColor;
  margin-left: 10px;
}

template-rating .note-suggestions .body .body-desc {
  padding: 10px 22px;
}

template-layout-performance-reviewer .question > p {
  font-weight: 500;
}

@media (min-width: 1100px) {
  .mobile__template-head-clone {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #EBEBEB;
    background-color: #fff;
    height: 6.8rem;
  }
  .mobile__template-head-clone.collapsed {
    opacity: 1;
    visibility: visible;
  }
  .mobile__template-head-clone.collapsed ~ .mobile__template-header,
  .mobile__template-head-clone.collapsed ~ .template-docs__progress {
    display: none !important;
  }
  .mobile__template-head-clone.collapsed ~ .template-megaform__toolbar-buttons {
    padding-top: 0;
    margin-top: -1.8em;
  }
  .mobile__template-head-clone .template-megaform__header {
    display: none !important;
  }
  .mobile__template-head-clone .template-head-clone {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
  }
  .mobile__template-head-clone .mobile__template-header {
    opacity: 1 !important;
    visibility: visible !important;
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas: "title . . subtitle";
    grid-area: 1 / 1 / 2 / 5;
  }
  .mobile__template-head-clone .mobile__template-header .title {
    grid-area: title;
  }
  .mobile__template-head-clone .mobile__template-header .title strong {
    line-height: 1;
  }
  .mobile__template-head-clone .mobile__template-header .subtitle {
    grid-area: subtitle;
    margin-top: 0;
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;
  }
  .mobile__template-head-clone .mobile__template-header .subtitle [class*="label"],
  .mobile__template-head-clone .mobile__template-header .subtitle [class*="value"] {
    margin-right: 0;
  }
  .mobile__template-head-clone .template-docs__progress {
    grid-area: 1 / 2 / 2 / 4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: unset;
    box-shadow: unset;
    margin: 0;
    padding-bottom: 0;
    padding-top: 0;
  }
  .mobile__template-head-clone .template-docs__progress [class*="__title"] {
    display: none;
  }
  .mobile__template-head-clone .template-docs__progress [class*="__steps"] a {
    opacity: 1 !important;
    margin-top: 0 !important;
    visibility: visible !important;
  }
  .mobile__template-head-clone .template-docs__progress [class*="trigger"] {
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
  }
}

@media (max-width: 1099px) {
  modal-delegate-authority > modal.searching delegate-authority > .flexbox {
    z-index: 99;
    background: white;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  modal-delegate-authority > modal.searching delegate-authority > .flexbox > .form-field,
  modal-delegate-authority > modal.searching delegate-authority > .flexbox .flyout__group-field,
  modal-delegate-authority > modal.searching delegate-authority > .flexbox field-suggestions {
    height: 100%;
  }
  modal-delegate-authority > modal.searching delegate-authority > .flexbox .flyout__field-input {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  modal-delegate-authority > modal.searching delegate-authority > .flexbox .flyout__field-input > .loading {
    top: 1.125em;
  }
  modal-delegate-authority > modal.searching delegate-authority > .flexbox .s-suggestions {
    position: relative;
    flex-grow: 1;
    max-height: unset;
    top: unset;
  }
  modal-delegate-authority > modal.searching delegate-authority > .flexbox > div:last-child {
    display: none !important;
  }
}

.reporting-table--group {
  padding: 0 1px;
  flex-basis: 0;
}

.reporting-table--group > h5 {
  background: #c8c8c8;
  font-size: 12px;
  text-align: center;
  padding: 10px;
  margin: 0 1px;
  font-weight: bold;
  height: 34px;
}

.reporting-table--group .template-table {
  margin-top: 0;
  table-layout: fixed;
  text-align: center;
}

.reporting-table--group .template-table thead th {
  font-size: 10px;
  width: 90px;
  padding: 7px 10px 5px !important;
  height: 26px;
}

.reporting-table--group .template-table tbody tr td:first-child {
  background: #fff;
  font-weight: normal;
}

.reporting-table--group .template-table th, .reporting-table--group .template-table td {
  text-align: center;
}

.reporting-table--group .template-table td {
  height: 40px;
  padding: 0 !important;
}

.reporting-table--group .template-table td > span {
  display: block;
  font-size: 75%;
  font-weight: bold;
}

.reporting-table--grouping {
  display: flex;
  margin-bottom: 15px;
}

.reporting-table--aside {
  flex: 1 0 30%;
  max-width: 30%;
}

.reporting-table--aside h4 {
  background: #bbbbbb;
  font-size: 16px;
  margin: 0;
  padding: 10px 15px;
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reporting-table--shelves {
  overflow-x: auto;
  display: flex;
  flex: 1 1 auto;
}

.reporting-table--container {
  flex: 1 1 70%;
  max-width: 70%;
}

.reporting-table--container h4 {
  font-size: 14px;
  font-weight: bold;
  background-color: #e4e4e4;
  margin: 0 2px;
  height: 30px;
  border-bottom: 2px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.reporting-table--labels {
  position: relative;
  background: #a1a1a1;
  display: flex;
  padding-left: 26px;
}

.reporting-table--labels h6 {
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 3px;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: 0;
  margin-left: -18px;
  transform: translateY(-50%) rotate(270deg);
  transform-origin: center;
}

.reporting-table--labels ul {
  background: #E1E1E1;
  flex-grow: 1;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.reporting-table--labels ul > li {
  margin: 0;
  padding: 0 0 0 10px;
  font-size: 14px;
  border-bottom: 1px solid #fff;
  height: 40px;
  display: flex;
  align-items: center;
}

.reporting-submenus > li > a {
  padding: 12px 10px !important;
  font-size: 18px !important;
}

.reporting-filters {
  display: flex;
  list-style-type: none;
  flex-direction: column;
  max-width: unset;
  min-width: unset;
  width: 200px;
}

.reporting-head {
  display: flex;
  align-items: center;
}

.reporting-head [class*="__actions"] {
  flex-grow: 1;
  margin-right: 5px;
}

.reporting-head filter-column {
  display: block;
  width: 100%;
  padding: 0.25rem 0 0.5rem;
}

.reporting-head filter-column .dropmenu-trigger__btn {
  margin: 0;
  font-size: 0.9rem;
}

.reporting-head filter-column .dropmenu-trigger__btn > * {
  padding: 5px;
  display: flex;
  justify-content: space-between;
}

.reporting-head filter-column .dropmenu-trigger__btn svg {
  margin-left: 5px;
  width: 16px !important;
  height: 16px !important;
  transform: translateY(2px);
}

.reporting-head filter-column .dropmenu-holder {
  top: calc(100% + 0.125rem) !important;
}

.reporting-head filter-column .dropmenu-holder ul li a {
  padding: 0.6rem;
}

.reporting-head filter-column .dropmenu-holder__menu {
  max-width: unset;
  min-width: unset;
  width: 200px;
}

.reporting-head .radio {
  font-size: 14px;
}

.reporting-head .userscope-radios {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 0.6rem;
}

.reporting-head .userscope-label {
  margin-top: 0.5rem;
  display: block;
  color: #AAB3BE;
  font-size: 11px !important;
  line-height: 14px;
  font-weight: 600;
  letter-spacing: 1.82px;
  font-style: normal;
  text-transform: uppercase;
}

.reporting-advance-filters .dropmenu-open > .dropmenu-holder {
  top: 3em;
}

.btn__icon.collapsable-target--close {
  width: 32px;
  height: 32px;
  fill: #ED1E23;
  transform: translateX(0.4em);
}

reporting-filter .template-docs__actions .user-dropdown .flyout__field-input-model:placeholder-shown {
  border: 1px solid #00ADEF;
  box-shadow: 0 0 5px 0 #23c2ff;
}

.usertable-footer {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.usertable-footer .controls {
  margin-left: auto;
}

.usertable-footer .controls .find-user__controls {
  margin-top: 0;
}

admin-users a {
  cursor: pointer;
}

@media (max-width: 1099px) {
  admin-users-table .template-docs {
    overflow: auto hidden;
  }
  admin-users-table .template-docs table {
    width: 960px !important;
  }
}

.form-branding .img-placeholder {
  background: white;
  margin-bottom: 1rem;
}

.form-branding .img-placeholder > img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.form-branding .img-placeholder .img-upload.croppie-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.125);
}

.form-branding .img-placeholder .img-upload.croppie-container .cr-slider-wrap {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  z-index: 1;
  width: 50%;
}

.form-branding .img-placeholder .img-upload.croppie-container .cr-boundary {
  background: white;
}

.form-branding .color-picker-swatch {
  width: 40px;
}

.form-branding .color-picker-input-wrapper {
  width: 100%;
}

.form-branding .color-picker-wrapper .input-group .input-group-addon:first-child {
  border-right-width: 1px;
}

.form-branding fieldset {
  margin-bottom: 2rem;
}

.form-branding .image-controls {
  display: flex;
  justify-content: flex-end;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  inset: 0;
  z-index: 1;
  padding: 3px;
  background: rgba(0, 0, 0, 0.125);
}

.form-branding .image-controls > li {
  margin: 0;
  padding: 0;
}

.form-branding .image-controls > li > .image-controls__btn {
  display: block;
  background: #00ADEF;
  cursor: pointer;
  margin: 2px;
  border-radius: 50%;
}

.form-branding .image-controls > li > .image-controls__btn svg {
  width: 40px;
  height: 40px;
  fill: white;
}

@media (max-width: 1099px) {
  .form-branding .image-controls > li > .image-controls__btn svg {
    width: 34px;
    height: 34px;
  }
}

.form-branding .image-controls--action {
  z-index: 3;
  pointer-events: none;
  background: unset;
}

.form-branding .image-controls--action > li .image-controls__btn {
  pointer-events: auto;
  background: #3eb44a;
}

.form-branding .image-controls--action > li:first-child .image-controls__btn {
  background: #ED1E23;
}

.table-controls .flyout__field {
  margin: 0;
  display: flex;
  align-items: center;
}

.table-controls .flyout__field-label {
  color: #414141;
}

.table-controls .flyout__field-input {
  margin: 0 8px;
}

.table-controls .select-holder {
  background-color: transparent;
  color: #38444B;
  border-color: #00ADEF;
  border-width: 1px;
  height: 36px;
  width: 60px;
  min-width: unset;
}

.table-controls .select-holder:before {
  width: 32px;
  height: 32px;
  right: -3px;
  margin-top: -16px;
}

.table-controls .select-holder select {
  padding: 0.4em !important;
}

.col-filter {
  display: flex;
}

filter-column .dropmenu-trigger__btn {
  margin: -1rem 0 -1rem 0.5rem;
}

filter-column .dropmenu-trigger__btn svg {
  width: 18px !important;
  height: 18px !important;
}

filter-column .dropmenu-trigger__btn svg.active {
  fill: #00ADEF;
}

filter-column .dropmenu-holder {
  background: #ebebeb;
  border: 1px solid #ddd;
  box-shadow: 0 0 20px rgba(39, 26, 26, 0.125);
  top: calc(100% + 1rem) !important;
  right: -1rem;
}

filter-column .dropmenu-holder ul {
  max-height: 260px;
  overflow: hidden auto;
}

filter-column .dropmenu-holder ul li:not(:first-child) {
  border-top: 1px solid #ddd;
}

filter-column .dropmenu-holder ul li a {
  display: flex !important;
  padding: 0.5rem;
  background: transparent;
  font-size: 13px;
  color: unset;
  min-height: unset;
  font-weight: normal;
}

filter-column .dropmenu-holder ul li a.link--disabled {
  pointer-events: none;
}

filter-column .dropmenu-holder ul li a.link--disabled {
  background: #bfbfbf;
  color: #737373;
}

filter-column .dropmenu-holder ul li a > .checkbox {
  margin: 0 0 0 auto;
  top: -8px;
  left: 20px;
}

filter-column .dropmenu-holder ul li a:hover, filter-column .dropmenu-holder ul li a.link--active {
  color: white;
  font-weight: 600;
}

filter-column .dropmenu-holder ul li a:hover > .checkbox:before, filter-column .dropmenu-holder ul li a:hover > .checkbox:after, filter-column .dropmenu-holder ul li a.link--active > .checkbox:before, filter-column .dropmenu-holder ul li a.link--active > .checkbox:after {
  filter: invert(94%) sepia(77%) saturate(3933%) hue-rotate(187deg) brightness(159%) contrast(100%);
}

filter-column .dropmenu-holder ul li[disabled] a {
  background: #aaa !important;
}

filter-column .dropmenu-holder:before {
  content: '';
  border-color: transparent;
  display: block;
  width: 20px;
  height: 20px;
  background: #ebebeb;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  transform: rotate(45deg);
  box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.0325);
  top: -0.5rem;
}

filter-column .dropmenu-holder--first-over:before {
  background: #00ADEF;
}

/* @import "assets/svg/combined/svg-symbols"; */
