.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($dark-grey, 0.125);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  .loading {
    width: 75px;
    height: 75px;
  }
}

.schedule-meeting-modal{
  div.mce-tinymce-inline{
    position:absolute;
    right:0;
    left:auto !important;
    top:-37px !important;
  }

  .modal-container .modal-body{
    max-height: 500px;
  }
}

.schedule-meeting-btn{
  display:inline-flex;
  align-items: center;
  line-height:1.2;
  [class~="btn__icon"] {
    width: 1.8rem;
    height: 1.8rem;
    margin-right:-.25rem;
  }
}
