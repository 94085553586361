/*flexbox*/

@mixin flexbox() {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($flex-grow, $flex-shrink:"", $flex-basis:"") {
   -moz-box-flex:  $flex-grow #{$flex-shrink} #{$flex-basis};
   -webkit-flex:  $flex-grow #{$flex-shrink} #{$flex-basis};
   -ms-flex:  $flex-grow #{$flex-shrink} #{$flex-basis};
   flex: $flex-grow #{$flex-shrink} #{$flex-basis};

    //Hack for IE
    @if $flex-basis == "" {
      $flex-check:append($flex-grow, "false");
      $flex-check:append($flex-check, "false");
      @if nth($flex-check, 3) != "false"{
          $flex-width: nth($flex-check, 3);
          max-width: $flex-width;
          width: 100%;
      }
    }
}


@mixin order($val) {
  -webkit-box-ordinal-group: $val;  
  -moz-box-ordinal-group: $val;     
  -ms-flex-order: $val;     
  -webkit-order: $val;  
  order: $val;
}

@mixin centerer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@mixin centerer-horizontal {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
@mixin centerer-vertical {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}




/*CSS3 Columns*/
@mixin columns($column-count: 3, $column-gap: 3rem, $column-rule:none) {
    column-count:$column-count;
    column-gap:$column-gap;
    column-rule: $column-rule;
}

@mixin columns-break($val:avoid) {
	 -webkit-column-break-inside: $val;
    -moz-column-break-inside:$val;
    column-break-inside:$val;
    page-break-inside: $val;
    break-inside: $val;
    display:table;   
}

@mixin empty-height {
    padding-top: 0!important;
    padding-bottom: 0!important;
    border-top-width: 0!important;
    max-height: 0;
    overflow: hidden!important;
}
