article.article-detail{
    border:0;
    .article-body{
        max-width:950px;
        margin:-2rem auto;
		padding: 5%;
		border:1px solid #ddd;
    }

}


.videoWrapper,
.responsive-object {
	position: relative;
	padding-bottom: 53.75%; /* 16:9 */
	padding-top: 25px;
	height: 0;
	overflow: hidden;

	> .responsive-object {
		position: unset;
		padding-bottom: 0;
		padding: 0;
		overflow: unset;
		height: unset;
	}
}
.videoWrapper iframe,
.responsive-object iframe,
.responsive-object .content {
	position: absolute;
	top: 0;
	left: 0;
	width: 100% !important;
	height: 100% !important;

	& ~ .video-content {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		color: $main-color;
		font-weight: bold;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		pointer-events: none;
		background-size: cover;
		padding: 10px;

		@media only screen and (max-width: 766px) { display: none; }

		&:before {
			@include bg-icon;
			content: '';
			display: block;
			width: 5rem;
			height: 5rem;
			background: #e65c0b url("#{$svg-path}Icons/Do.svg") no-repeat center / 2.75rem;
			-webkit-filter: invert(100%);
			filter: invert(100%);
			border-radius: 50%;
			position: absolute;
			left: 50%;
			top: 50%;
			margin-left: -2.5rem;
			margin-top: -2.5rem;
		}
		h3 {
			background-color: #333;
			font-size: 0.8em;
			padding: 0.6em 1em;
			line-height: 1.25;
			color: inherit;
			margin-bottom: 0.5em;
			-webkit-font-smoothing: subpixel-antialiased;
		}

		h4 {
			background: #333;
			font-size: 0.9em;
			padding: 1em 1.5em 0.75em;
			line-height: 1.25;
			color: inherit;
			margin-left: 2em;
			display: inline-block;
			max-width: 35%;
			-webkit-font-smoothing: subpixel-antialiased;
		}
	}


}

.videoWrapper.playing .video-content {
	display: none;
}

.articles-page h2 {
	margin-bottom: 1rem;
	border-bottom: 1px solid #AAB3BE;
	font-family: $reg-font;
	font-weight: 700;
	font-size: 26px;
	-webkit-font-smoothing: subpixel-antialiased;
	@include media($mobile-menu-break-max) {
		font-weight: 700;
		font-size: 20px;
	}
}

.articles-page .Rtable-head h2 {
	font-size: 20px;
	font-weight: 600;
	color: $content-font-color;
	display: flex;
	align-items: center;
	margin: 0;
	padding: 0;
	border: 0;
}

.articles-page {
	h3 {
		font-size: 24px;
		padding-bottom: 0;
		margin-bottom: 1em;

		@include media($mobile-menu-break-max) {
			font-size: 20px;
		}
	}

	h4 {
		font-size: 18px;
		padding-top: 15px;
		padding-bottom: 0;
		margin-bottom: 0.8em;
		font-family: $reg-font;

		@include media($mobile-menu-break-max) {
			font-size: 16px;
		}
	}
}

.step-block {
	padding: 1.5rem 2rem;
	background: #fff;
	box-shadow: 0 0 5px 0 rgba(#000, 0.125);

	@media only screen and (min-width: 767px) {
		padding: 3rem 4rem;
	}
}
.step-block__title {
	margin-bottom: 20px;
	font-size: 22px;
}
.step-block__detail {
	margin: 0;
	list-style-type: none;

	a {
		color: $main-color;
		text-decoration: none;
	}
}
.step-block__detail-item {
	display: flex;
	margin: 0;
	padding-bottom: 1rem;
}

%theme-icon-style {
	fill: $main-color;
	flex: 0 0 48px;
	width: 60px;
	height: 60px;
	margin-top: -16px;
}

.step-block__detail-icon {
	@extend %theme-icon-style;
}

.step-block__detail-content {
	padding-left: 20px;
	h4 {
		font-weight: bold;
		font-size: 1rem;
		color: #333;
		padding: 0;
		margin: 0 0 0.125rem;
	}
	p {
		line-height: 1.25;
		margin: 0;
	}

	ul {
		margin: 0;
		list-style-type: disc;
		li {
			padding-bottom: 0;
		}
	}
}

.articles-page .step-block__detail-content {
	h3{
		padding-top: 0;
		margin-bottom: .3em;
	}
}

.highlight-text {
	.article-body & {
		background: yellow;
	}
}

.timeline-block {
	table-layout: auto;

	thead {
		display: none;
		tr {
			th {
				background: #EEF0F2;
				font-size: 16px;
				@include media($lg-phablet) {
					font-size: 17px;
				}
			}
		}
	}

	tbody {
		tr {
			th, td {
				font-size: 14px;
				padding: 12px 15px 10px;
				&[data-title="Action"] {
					background-color: #eef0f2;
				}

				&:before {
					content: attr(data-title);
					display: block;
					margin-bottom: 0.75em;
					font-size: 11px;
					font-weight: 600;
					letter-spacing: 1.5px;
					text-transform: uppercase;
					color: #AAB3BE;

				}

				@include media($lg-phablet) {
					padding: 17px 20px 15px;
					font-size: 15px;
				}
			}
			th {
				white-space: nowrap;
				@include media($sm-tablet) {
					min-width: 240px;
					font-size: 17px;

					&:first-child {
						min-width: 240px;
					}
				}

				@include media($lg-phablet) {
					&.timeline-date {
						background: #ebebeb;
					}
				}

				&.timeline-date {
					display: none; /* Hide as we don't sure the timeline column anymore */
				}


			}

			td {
				width: 100%;
				p {
					margin-top: 0;
					margin-bottom: 10px;
				}
				ul {
					padding-bottom: 5px;
				}
			}
		}
	}

	tr {
		td, th {
			padding: 10px 15px;
			vertical-align: top;
			border: 1px solid #F6F7F8;
			background: #ffffff;
			font-family: $reg-font;
			color: $content-font-color;

			@include media($lg-phablet) {
				padding: 10px 20px;
			}
		}
		th {
			font-weight: 600;
		}

		svg {
			margin: -10px 5px -8px -5px;
			@include iconSize();
			fill: $main-color;

			@include media($lg-phablet) {
				margin: -15px 8px -8px -8px;
			}
		}
	}

	@include media($lg-phablet-max) {
		display: block;
		thead { display: none; }
		tbody, tr, th, td {
			display: block;
		}

		tr {
			position: relative;
			margin-bottom: 10px;
			border: none;
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.025);
		}

		%from-date {
			position: absolute;
			left: 0;
			bottom: 0;
			padding-left: 15px;
			max-width: 75px;
			width: 100%;
			height: 100%;
			background: #ebebeb;
			z-index: 0;
		}

		tbody {
			tr {
				// td, th {
				// 	// padding-left: 90px;

				// }


				th {
					&[data-title="From"] {
						@extend %from-date;
					}
				}

				&[data-multiple-row='start'] {
					overflow: hidden;
					margin: 10px 0 0 !important;

					& ~ tr {
						margin: 0;
						&:before {
							@extend %from-date;
							display: block;
							height: calc(100% + 2px);
						}

					}
					& ~ [data-multiple-row='end'] {
						& ~ tr:not([data-multiple-row]) {
							margin-top: 10px;

							&:before {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}

.current-user-viewlevel {
	position: absolute;
	top: 100%;
	display: flex;
	align-items: center;
	font-size: 10px;
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: 2px;
	color: $grey;
	padding: 3px 10px 3px 4.5rem;
	right: 0;

	svg {
		width: 32px;
		height: 32px;
		fill: $grey;
	}

	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		background: $dark-blue;
		z-index: -1;
		transform: skew(25deg);
		right: -68px;

	}

	@include media($mobile-menu-break) {
		width: 100%;
	}
}

.custom-content-selector {
	position: relative;
	text-align: right;
	margin-bottom: -10px;

	.select-holder {
		width: auto;
		display: inline-block;
		border-color: $grey;
		margin-bottom: 0;

		&:before {
			right: 0;
		}

		select {
			padding: 5px;
			font-size: 10px;
			font-weight: bold;
			text-transform: uppercase;
			letter-spacing: 1px;
			color: $grey;
			line-height: 1;
			padding-right: 40px;
		}

		&:hover {
			border-color: $dark-grey;
			select { color: $dark-grey; }
		}
	}
}
