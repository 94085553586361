@keyframes bounce {
  0%,
  30%,
  70%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

/* Fade in animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* Fade out animation */
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes shake {
  0%,
  10%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  1%,
  9% {
    transform: translate3d(-1px, 0, 0);
  }
  2%,
  8% {
    transform: translate3d(2px, 0, 0);
  }
  3%,
  5%,
  7% {
    transform: translate3d(-4px, 0, 0);
  }
  4%,
  6% {
    transform: translate3d(4px, 0, 0);
  }
}

    @keyframes click {
      0% {
        transform: scale(1.0);
      }
      50% {
        transform: scale(.9);
      }
      100% {
        transform: scale(1);
      }
    }
