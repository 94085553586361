$sidebar-transition: all 0.4s linear;

html,
body,
ui-view,
.sidebar-pusher,
.content-container {
  @include media($mobile-menu-break-max) {
    height: 100%;
  }
}

.content-container {
  //overflow-y: auto;
  //overflow-x: hidden;
  //-webkit-overflow-scrolling: touch;
}

.content-container,
.content-container-inner {
  position: relative;
}



$miniSider-width: 75px;
%minimalSidebar {

  @include media($mobile-menu-break) {
    transform: translate3d($miniSider-width, 0, 0) !important;
    left: 0 !important;
    width: calc(100% - 75px) !important;
    overflow: hidden;
  }

  & ~ .sidebar {
    width: $miniSider-width;

    .header {
      padding: 0;
    }

    .sidebar-content {
      width: $miniSider-width;
      overflow: auto;

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba($dark-blue, 0.3);
        border-radius: 1px;
        background-color: rgba($dark-blue, 0.5);
      }

      &::-webkit-scrollbar {
        width: 2px;
        height: 0;
        background-color: #F5F5F5;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 1px;
        -webkit-box-shadow: inset 0 0 6px rgba($dark-blue, .3);
        background-color: $main-color;
      }
    }

    .sidebar-footer {
      > ul {
        padding: 0;
        width: $miniSider-width;
        > li {
          > a {
            padding-left: 15px;
            padding-right: 15px;
            > span {
              display: none;
            }
            > svg {
              margin: 0;
            }
          }
        }
      }

      @include media($sm-tablet) {
        .myaccount > a > img.svg-icon {
          left: 12px;
        }
      }
    }


  }

  & ~ .profile-flyout,
  & ~ .search-component,
  & ~ .flyout {
    @include media($mobile-menu-break) {
      .flyout--open {
        left: $miniSider-width;
      }
    }
  }

  .articles-main {
    max-width: 1360px;
  }
}

.sidebar-pusher {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1000;
  transition: $sidebar-transition;
  overflow: hidden;

  @include media($mobile-menu-break-max) {
    display: flex;
    flex-flow: column nowrap;

    > * {
      width: 100%;
    }
  }

  &--minimal {
    @extend %minimalSidebar;
  }

  .default-layout & {
    .content-container {
      background: $dark-blue;
      min-height: 100vh;
    }
  }
}

@include media($mobile-menu-break-max) {
  .sidebar-pusher::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background: rgba(#000, 0.5);
    content: '';
    opacity: 0;
    visibility: hidden;
    transition: $sidebar-transition;
  }
  [class*='sidebar-open-'] .sidebar-pusher::after {
    opacity: 1;
    visibility: visible;
  }
}

.sidebar {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  width: 300px;
  height: 100%;
  z-index: 1;
  transition: $sidebar-transition;
  display: none;

  .articles-page & {
    display: block;
  }

}

.sidebar-open.cart-open .sidebar {
  //z-index: 9999;
}

.sidebar.sidebar-right {
  left: auto;
  right: 0;
}

.sidebar.sidebar-right::after {
  right: auto;
  left: 0;
}

[class*='sidebar-open-'] .sidebar {
  visibility: visible;
  transition: $sidebar-transition;
  transform: scale(1, 1);
}

/* Effect: Push-Left*/
@include media($mobile-menu-break) {
  .sidebar {
    z-index: 9999;
  }
  .sidebar-open-left .sidebar.sidebar-left {
    transform: translate3d(0, 0, 0);
  }
  .sidebar-open-left .sidebar.sidebar-right {
    transform: translate3d(100%, 0, 0);
  }
  .sidebar-open-right .sidebar.sidebar-left {
    transform: translate3d(-100%, 0, 0);
  }
  .sidebar-open-right .sidebar.sidebar-right {
    transform: translate3d(0, 0, 0);
  }
  .sidebar-left {
    transform: translate3d(-100%, 0, 0);
  }
  .sidebar-right {
    transform: translate3d(100%, 0, 0);
  }
}

@include media($mobile-menu-break-max) {
  [class*='sidebar-open-'] .sidebar {
    z-index: 0;
  }
  /* Effect: Push-Right*/
  .sidebar-open-left .sidebar-pusher {
    transform: translate3d($sidebar-width, 0, 0);
  }
  .sidebar-open-right .sidebar-pusher {
    transform: translate3d(-$sidebar-width, 0, 0);
  }
  .sidebar {
    transform: translate3d(0, 0, 0) scale(0.9, 0.9);
  }
  .sidebar.cart {
    transform: translate3d(0, 0, 0) scale(0.9, 0.9);
  }
}

[class*='sidebar-open-'] .sidebar {
  visibility: visible;
  transition: $sidebar-transition;
}

.sidebar::after {
  display: none;
}
/* Fallback example for browsers that don't support 3D transforms (and no JS fallback) */

.no-csstransforms3d .sidebar-pusher,
.no-js .sidebar-pusher {
  padding-left: 300px;
}

@include media($mobile-menu-break) {
  .articles-page {
    .sidebar {
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
    .sidebar-pusher {
      // transform: translateX($sidebar-width);
      left: $sidebar-width;
      width: calc(100% - #{$sidebar-width});

      &.modal-open {

        z-index: 10000;
      }
    }
  }
}

@include media($mobile-menu-break-max) {
  #freshworks-container{
    opacity:1;
    transition: all .2s linear;
    visibility: visible;
  }

  .hide-fresworkssupport #freshworks-container{
    visibility:hidden;
    opacity: 0;
  }
}

.modal-open-body{
  overflow: hidden;
}