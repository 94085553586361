.browser-alert {
    img {
        display: block;
        margin: 0 auto;
        text-align: center;
        width:300px;
    }
    margin: 0;
    background:  #454545;
    color: #fff;
    display: none;
    font-size: 16px;
    position: relative;
    z-index: 1111000;
    text-align: center;
    padding:10px 25px;
    top:0;
    width:100%;
    z-index: 99999;
    a {
        color: #fff;
        text-decoration: underline;
    }
}