@mixin btn-color($bg-color, $text-color:#ffffff) {
    background-color: $bg-color;
    //border:1px solid darken($bg-color,5%);
    color:$text-color;
    &:hover:not(:disabled){
        color:$text-color;
        background-color: darken($bg-color, 20%);
    }
}

@mixin btn {
    @include btn-base($main-color);
}

@mixin btn-sec {
    @include btn-color($sec-color);
}

@mixin btn-success {
    @include btn-color($success-color);
}

@mixin btn-highlight {
    @include btn-color($sale-color);
}

@mixin btn-danger {
    @include btn-color($danger-color);
}

@mixin btn-invert($color:$main-color, $hover-text-color:#fff) {
    @include btn-color(transparent);
    color: $color;
    border-radius:$button-radius;
    // font-weight:normal;
    border:1px solid $color;
    &:hover:not(:disabled),
    &.active {
      background-color: $color !important;
      border-color: $color;
      color:$hover-text-color;
    }
}

@mixin btn-xl {
    padding: 20px 4.6rem;
    font-size: .8rem;
    margin: .8rem 0 1rem;
    //height:57px;
}

@mixin btn-lg {
    padding: 20px 3.8rem;
    font-size: .775rem;
    //height:49px;
    margin-top: .5rem;
    margin-bottom: 1rem;
}

@mixin btn-md {
    padding: 16px 2.1rem;
    font-size: 13px;
    //height:42px;
    margin: .3rem 0 .3rem;
}

@mixin btn-sm {
    padding: 0.6rem 0.8rem;
    font-size: .725rem;
    //height:39px;
    margin: .1rem 0;
    line-height: normal;
}

@mixin btn-xs {
    padding: 1em 1.125em;
    font-size: .6rem;
    //height:33px;
    margin: 0;
}

@mixin btn-rounded{
    border-radius:50px !important;
}

@mixin btn-base($bg-color, $text-color:#ffffff) {
    border: 1px solid transparent;
    border-radius: $button-radius;
    background: none;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: $reg-font;
    text-align: center;
    position: relative;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    fill: currentColor;
    &:focus {
          transform:scale(.98);
    }
    text-decoration: none;
    //font-weight: $title-font-weight;
    outline:none;
    text-align: center;
    line-height: 1.1;
    color: #fff;
    transition: all .1s linear;
    margin: .2em 0;
    @include btn-md;
    &:disabled:not(.btn-success) {
        cursor: not-allowed;
        opacity: 0.3;
    }
    @include btn-color($bg-color, $text-color);
    i{margin-left:.1em;margin-right:.1em;margin-top:-2px;font-size:85%}
}


button,
input[type="button"],
input[type="reset"],
input[type="submit"],
button,
a,
label,
div,
span {
    &.btn {
        @include btn;
    }
    //Button Types
    &:disabled{
        cursor: not-allowed;
        filter: brightness(80%);
        opacity: .8;
        position: relative;
        svg.icon-lock{
            height:30px;
            margin-top: -3px;
            width:30px;
            fill:white !important;
        }
    }

    .btn-submit {
        min-width:13rem;
        margin-left:auto;
        margin-right:auto;
        background-color:$main-color;
    }
    .form-loading .btn-submit:before{
        display:none;
    }
    /*&.btn-loading{
        &:before {
            margin-top:-1px;
            font-size: 150%;
            content:$icon-loading !important;
            animation:spin3d .75s infinite;
        }
    }*/
    .success-message{display:none;}
    &.btn-success{
        @include btn-success;
        span{display:none;}
        span.success-message{display:inline-block;}
        &:before {
            position: absolute;
            top:30%;
            left:1.2rem;
            @include icon($icon-tick-thick);
            font-size: 110%;
        }
    }

    &.btn-next {
        margin: 2.5em 0 1em;
        &:after {
            @include icon($icon-arrow-right);
            margin-left: .4em;
            line-height: .6;margin-top:-1px;
        }
        &:hover{ background-color:darken($green,10%);}
    }
    &.btn-prev {
        margin: 2.5em 0 1em;
        &:before {
            @include icon($icon-arrow-left);
            margin-right: .4em;
            line-height: .6!important;
            margin-top:-1px;
        }
    }

    .btn-group.btn-set{
        margin:0;
        .btn{height:100%;@include btn-sm;@include flex(1);align-self:center;@include btn-color(#eee);height:42px;margin:0 1px 0 0;
            &:before,&:after,i{color:#999;}
        }
        .btn:last-child{margin:0;}
    }

    .btn-cancel{
        @include btn-invert;
        margin: 2.5em 0 1em;
        max-width:140px;
    }


    .btn-delete{
        @include btn-base(lighten($red,30%));
    }


    &.btn.btn-block {
        display: block;
        width: 100%;
    }
    //Button Colors
    &.btn.btn-sec {
        @include btn-sec;
    }
    &.btn.btn-highlight {
        @include btn-highlight;
    }
    &.btn.btn-invert {
        @include btn-invert;

        &--muted {
            border-color: $grey;
            color: $grey;

            &:hover:not(:disabled) {
                background: transparent !important;
                border-color: $main-color;
                color: $main-color;

            }
        }
    }
    //Button Sizes
    &.btn.btn-xl {
        @include btn-xl;
    }
    &.btn.btn-lg {
        @include btn-lg;
    }
    &.btn.btn-sm {
        @include btn-sm;
    }
    &.btn.btn-xs {
        @include btn-xs;

        svg {
            width: 20px;
            height: 20px;
            margin-right: -0.5em;
        }
    }
    &.btn.btn-dropdown {
        padding-left: .85em;
        padding-right: .7em;
        margin-left: 1px!important;
        display: inline-block;
        height:100%;
        min-width:40px;
        vertical-align: middle;
        &:before {
            @include icon($icon-arrow-left);
            transition: transform .2s ease;
            display: inline-block;
            margin:0px;
            margin-bottom:2px;
            line-height:1.1;
        }
        &:hover:before {
            transform: rotate(-90deg);
        }
    }
    .reveal-trigger:after {}
    &.btn.btn-cart-remove {
        &:before {
            //@include icon($icon-cart-remove);
            padding-right: 10px;
            color: $sale-color;
            float: left;
            font-size: 135%;
            line-height:.9;
        }
        font-family: $semi-font;
    }
    &.btn.btn-cart-add {
        &:before {
            //@include icon($icon-cart-add);
            padding-right: 10px;
            color: #aaa;
            float: left;
            font-size: 135%;
            line-height:.9;
        }
    }
    &.btn.btn-facebook {
        @include btn-xl;
        @include btn-color(#49639f);
        color: #fff;
        text-shadow: 0 0 1px rgba(#000, .2);
        margin: .8em 0 1em;
        padding-left: 2.5em;
        padding-right: 0;
        width:100%;
        max-width:400px;
        height: auto;
        display: table;
        position: relative;
        vertical-align: middle;
        &:before {
            position: absolute;
            height: 100%;
            left: 0;
            top: 0;
            padding: .4em .5em;
            display: table-cell;
            //@include icon($icon-facebook);
            padding-right: 10px;
            color: #fff;
            font-size: 31px;
            margin: 0;
            border-right: 1px solid rgba(#000, .2);
        }
    }

    &.btn-danger {
        @include btn-danger();
    }

    [class~="btn__icon"] {
        width: 1.5em;
        height: 1.5em;
        color: inherit;
        margin-top: -0.5em;
        margin-bottom: -0.5em;
    }
}

.btn-rounded{
    @include btn-rounded;
}

.btn-group {
    position: relative;
    clear: both;
    @include flexbox;flex-flow:row wrap;
    width:100%;
    //border-top:1px solid #ccc;
    display:flex;
    align-items: center;

    justify-content: center;
    [class*=btn],.dropdown-holder{
        //float: left;
    }
    @include clearfix;
    margin:.9rem 0 2rem;
    padding:.45rem 0 1rem;

    .btn-next, .btn-prev{height:54px;}

    .btn{width:100%;margin:.2rem 0 .5rem;}


    .btn-next,.btn-cancel{align-self:flex-end;margin-left:auto!important;}
    .btn{width:100%;}
    .add-checked-to-cart{@include flex(1);}
    @include media($phablet){
        .btn{width:auto;margin:1rem 0;}
        .add-checked-to-cart{@include flex(0 0 auto);margin-left:auto;}
        .btn + .btn{margin-left:5px;}
        .btn + .btn.btn-block{margin-left:0;}
    }

}

.btn-down-arrow{
    width:100%;
    display: block;
    &:before{
        @include icon($icon-arrow-down);
        font-size: 30px;
        padding:30px;
        display: block;
        color:$main-color;
    }
    &:hover:before{
        color:$sec-color;
    }
}

.aux-link{display:inline-block;vertical-align:middle;border-left:1px solid #eee;margin:0 0 0 5%;padding:0 0 0 5%;}

.button--disabled{
    pointer-events: none;
    opacity: .2;
}