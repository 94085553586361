.team-block {
  &__alert {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1.25;

    &.alert:before {
      top: 20px;
    }

    .btn {
      &:before {
        display: none;
      }
    }
  }
  table {
    .prevent-user-delete {
      pointer-events: none;

      .checkbox {
        padding: 0;
        margin: 0;
        &:before,
        &:after {
          display: none;
        }
      }
    }
    td {
      padding: 15px;
      font-size: 16px;
      border: 2px solid #F5F6F5;

      &:first-child {
        background: #EBEBEB;
      }

      .checkbox {
        padding-left: 28px;

        // &:before {
        //   opacity: 0.25;
        // }

        // &:hover {
        //   &:before{
        //     opacity: 0.325;
        //   }
        // }

        // &:checked {
        //   + .checkbox {
        //     &:before {
        //       opacity: 0;
        //     }
        //     &:after {
        //       opacity: 0.5;
        //     }
        //   }
        // }
      }

      &.td-current-user {
        position: relative;
        color: $main-color;
        font-weight: 600;

        label {
          display: flex;
          align-items: flex-start;
        }
        svg {
          fill: $main-color;
          width: 36px;
          height: 36px;
          margin: -10px 5px -10px -5px;
          flex-shrink: 0;
        }
      }
    }
    th {
      padding: 12px 15px;
      background: #E1E1E1;
      border: 2px solid #F5F6F5;
      font-size: 17px;
      font-weight: 600;
      font-family: $reg-font;
      color: $content-font-color;
    }
  }

  &__member-count {
    list-style-type: none;
    margin: 0 0 40px;
    padding: 0;
    display: flex;

    > li {
      margin: 0;
      padding: 0;
      & + li {
        margin-left: 40px;
      }
    }
  }
}
.team-controls {
  padding: 0;
  margin: 0;
  list-style-type: none;

  @include media($tablet) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  > li {
    margin: 0;
    width: 100%;
    @include media($tablet) {
      width: auto;
      padding: 20px;
    }
  }
  .btn {
    width: 100%;
    padding: 25px 50px;
    font-family: $reg-font;
    white-space: nowrap;
    font-weight: 600 !important;

    &-disabled {
      border-color: $grey !important;
      color: $grey !important;
      pointer-events: none;
    }
  }
}

.modal,
modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.25);
  // display: none;
  z-index: 9999;
}

.modal-block {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.modal-container {
  position: relative;
  background: #ebebeb;
  max-width: 768px;
  width: calc(100% - 40px);


  @include media($sm-tablet) {
    width: calc(100% - 10px);
  }

  .modal-content{
    padding: 15px;
    @include media($sm-tablet) {
      padding: 22px;
    }
    @include media($tablet) {
      padding: 36px;
    }
  }

  .modal-body{
    max-height: 400px;
    overflow: hidden;
    overflow-y: auto;
    @include media($sm-tablet) {
      max-height: 580px;
    }

  }

  table {
    width: auto;

    label.no-label.radio:before,
    label.no-label.checkbox:before,
    label.no-label.radio:after,
    label.no-label.checkbox:after {
      top: -15px;
      left: -1px;
    }

    tr {
      background: none;
    }
  }
}

.modal-close {
  position: absolute;
  top: 0;
  z-index: 99;
  right: 0;
  border: none;
  outline: 0;
  cursor: pointer;

  &:hover:not(:disabled) {
    svg {
      opacity: 0.5;
    }
  }

  svg {
    width: 36px;
    height: 36px;
    pointer-events: none;
    opacity: 0.35;
  }
}

.list-unstyled {
  list-style-type: none;
  margin: 0;
  padding: 0;
  > li {
    margin-left: 0
  }
}

.list-inline {
  list-style-type: none;
  margin: 0;
  padding: 0;

  > li {
    display: inline-block !important;
    width: auto !important;
    border: unset !important;
  }
}

confirm-user-delete-footer {
  margin-top: 15px;
  display: block;

  @include media($sm-tablet) {
    display: flex;

    .form-note {
      margin-bottom: 0;
      max-width: 320px;
    }
  }

  .form-note {
    font-size: 13px;
    color: #666;
    margin-right: auto;
    margin-bottom: 15px;
  }
}

find-user-body {
  display: block;
  width: 100%;

  table {
    table-layout: auto;
    @include media($tablet-max) { width: 567px !important; }
    .modal-container & {
      width: 100%;
      font-size: 14px;
    }
  }
}

@include media($tablet-max) {

  .team-block {
    thead { display: none; }
    table, tbody, tr, th, td {
      display: block;
    }

    td, th {
      font-size: 14px;
      padding: 12px 15px 10px;
    }

    tr {
      display: flex;
      margin-bottom: 10px;
      border: none;
      box-shadow: rgba(0, 0, 0, 0.024) 0px 2px 4px 0px;
      flex-wrap: wrap;
    }

    td {
      width: 100%;
      &:before {
        content: attr(data-title);
        display: block;
        margin-bottom: 5px;
        opacity: 0.5;
        font-size: 11px;
        font-weight: 600;
        letter-spacing: 1.5px;
        text-transform: uppercase;
      }

      &[data-title='Name'] {
        font-weight: bold;
      }
    }
  }

  .find-user-form {
    margin-bottom: 10px;
    .form-row {
      flex-flow: row nowrap;

      .form-cell {
        width: auto;

        #{$text-inputs-list} {
          min-width: auto;
        }
      }
    }
  }
}

.tr-title {
  cursor: pointer;
  > td {
    position: relative;
    padding: 25px 15px 15px !important;
    font-weight: 600;
    font-size: 12px !important;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #909090;
    background: $background url('#{$svg-path}Icons/Angle Down.svg') no-repeat right 15px top 23px / 20px !important;

    // &:before {
    //   content: '';
    //   display: block;
    //   width: 100%;
    //   height: 1px;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   background: #e5e5e5;
    // }
  }

  &--active {
    > td {
      background-image: url('#{$svg-path}Icons/Angle Up.svg') !important;
    }
  }
}


.find-user {
  &__controls {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 5px 0 0;
@include media($sm-tablet) {
    margin: 20px 0 0;
}
    justify-content: center;
    align-items: center;

    > li {
      padding: 0;
      margin: 0;
    }

    .btn {
      position: relative;
      padding: 5px;
      margin-left: 5px;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      font-family: $reg-font;
      font-weight: normal;
      font-size: 12px;
      text-indent: 2px;

      &.btn-disabled {
        pointer-events: none;
        border-color: $grey;
        color: $grey;

        svg {
          fill: $grey;
        }
      }

      svg {
        fill: $main-color;
        width: 24px;
        height: 24px;

      }

      &.btn-double-icon {
        svg:nth-child(1) {
          transform: translateX(-3px);
        }
        svg:nth-child(2) {
          position: absolute;
          transform: translateX(3px);
        }
      }

      &:hover {
        svg {
          fill: #fff;
        }
      }
    }

    &--pull-left {
      margin-right: auto !important;
    }

    &--pull-right {
      margin-left: auto !important;
    }
  }

  &__entries {
    display: flex;
    align-items: center;
    font-size: 14px;

    .select-holder {
      margin: 0 8px;
      min-width: 60px;
      &:before {
        font-size: 12px;
        top: 20px;
        right: 8px;
      }
      select {
        height: 34px;
        padding: 0 10px;
      }
    }
  }

  &__search-results {
    table {
      // display: block;
      // max-height: 45vh;
      // overflow: hidden;

      thead:not([style]) {
        // display: table;
        // width: 100%;
        // tr {
        //   display: flex;
        //   td, th {
        //     &:not(:last-child) {
        //       flex-grow: 1;
        //     }

        //     &:nth-child(1),
        //     &:nth-child(2) {
        //       flex-grow: 1.5;
        //     }
        //   }
        // }
      }

      tbody {
        // overflow: hidden;

        &::-webkit-scrollbar {
          width: 2px;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
          background: rgba(#fff, 1);
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          background: rgba($main-color, 0.75);
          border-radius: 10px;
        }
      }
    }

    .page-alert {
      margin-top: 10px;
      &:before {
        top: 15px;
      }
    }
  }

  &__name {
    display: flex;
    margin-left: -15px;
  }

  &__tick {
    display: block;
    margin-top: -2px;
    // opacity: 0.25;
  }
}

@-moz-document url-prefix() {
  .find-user {
    &__search-results {
      table {
        tbody[style] {
          margin-right: -15px;
        }
      }
    }
  }
}


.tr-team-title {
  display: flex;
  justify-content: space-between;
}

.tr-report-count {
  margin-right: 30px;
  font-size: 120%;
}

.modal-header {
  h3 {
    display: flex;
    padding-top: 0;
    font-size: 18px !important;
    align-items: center;
    font-family: $reg-font;
    color: $black;

    @include media($sm-tablet-max) {
      margin-bottom: 0.25em !important;
    }

    svg {
      margin-right: 2px;
      width: 42px;
      height: 42px;
      margin-left: -12px;
      fill: currentColor;
    }
  }
}

.modal-container {
  .form-field {
    margin-bottom: 15px;
  }

  table {
    thead {
      tr {
        th {
          border-bottom: 1px solid $grey;
          font-size: 12px !important;
          text-transform: uppercase;
          font-family: $reg-font;
          font-weight: 600;
          letter-spacing: 1px;
        }
      }
    }

    tbody {
      tr {
        border: none;
      }
    }
  }

  .form-note {
    color: #898989;
    font-size: 12px;
    margin-bottom: 5px;
    margin-top: 12px;
    @include media($sm-tablet) {
      font-size: 14px;
      margin-bottom: 20px;
      margin-top: 20px;
    }


    .form-note__title {
      display: flex;
      margin-bottom: 5px;
      align-items: center;
      font-weight: 600;
    }
    svg {
      fill: #898989;
      width: 30px;
      height: 30px;
      margin-right: -5px;
      margin-top: -8px;
      margin-left: -5px;
      margin-bottom:-7px;
    }
  }

  .form-buttons {
    display: flex;
    align-items: center;
    justify-content: center;

    @include media($sm-tablet-max) {
      display: grid;
      gap: 0px 11px;
      grid-template-columns: 1fr 1fr;
    }

    .btn {
      font-size: 10px;
      padding: 1.4em 1em;

      @include media($sm-tablet) {
        padding-top: 20px;
        padding-bottom: 20px;
        min-width: 160px;
        font-size: 12px;

        + .btn {
          margin-left: 20px;
        }
      }
    }

    &--column {
      flex-direction: column;
    }

    &__divider {
      margin: 1em 0;
      color: #777;
    }
  }
}

// .btn-invert {
//   &--muted {
//     border-color: darken($color: $grey, $amount: 15);
//     color: darken($color: $grey, $amount: 15);
//   }
// }

.team-name {
  @include media($mobile-menu-break) {
    display: flex;

    .form-field {
      margin-left: 1em;
      flex-grow: 1;
    }
  }
  @include media($mobile-menu-break-max) {
    margin-bottom: 2rem;
    h3 { margin-bottom: 0.75rem; }
    .flyout__field-input-icon { display: none; }
    .flyout__field-input-model { padding: 0.75rem; }
  }

  .flyout__group-field {
    max-width: 600px;
    width: 100%;
  }

  button {
    padding: 0 1em !important;
    color: $white;
    letter-spacing: 2px;
    font-size: 13px;
  }
}

.table-scroll-wrap{overflow: auto;}

.table-scroll thead {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.table-scroll tbody {
  max-height: 35vh;
  overflow-y: auto;
  display: block;
  width: 100%;
  table-layout: fixed;
}

.table-scroll tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.table-scroll td {
  height: 40px;
  @include media($mobile-menu-break) {
  height: 47px; // needed in order to keep rows from collapsing
  }
  overflow-wrap: break-word;
}

body.findUser--open {
  .modal-container {
    max-width: 1024px;
  }
}

.find-user__search-results {
  th:nth-child(1),
  th:nth-child(2),
  td:nth-child(1),
  td:nth-child(2) {
    width: 22.5%;
  }
}

modal-team-name,
modal-team-name-required {
  .modal-container {
    max-width: 420px;
  }
}
