.ngdialog.ngdialog-theme-default {
  padding: 0;
}

.ngdialog.ngdialog-theme-default .ngdialog-content {
  min-width: 310px;
  max-width: 600px;
  width: 95%;
  padding: 1.5rem 0.8rem 0 0.8rem;
  @include media($phablet) {
    padding: 1.5rem 1.8rem 0 1.8rem;
  }
  .btn-submit {
    width: 100%;
  }
}
.ngdialog.ngdialog-theme-default .ngdialog-close {
  padding: 0.2rem 0.6rem;
  &:before {
    font-size: 2rem;
  }
  &:focus {
    outline: none;
  }
}

.ngdialog.ngdialog-theme-default .ngdialog-content {
  overflow: auto;
  top: 20px;
  border-radius: 0;

  .ngdialog-close {
    &:before {
      content: "";
      background: url("#{$svg-path}Icons/Close Circle.svg") no-repeat center;
      background-size: contain;
      -webkit-background-size: contain;
      width: 38px;
      height: 38px;
      filter: invert(75%) sepia(29%) saturate(0%) hue-rotate(150deg)
        brightness(88%) contrast(98%);
    }
    &:hover {
      &:before {
        filter: invert(63%) sepia(0%) saturate(0%) hue-rotate(111deg)
          brightness(95%) contrast(93%);
      }
    }
	}

	.ngdialog-buttons {
		padding-bottom: 20px;
		> div {
			display: flex;
			justify-content: center;

			button {
				margin: 0 5px;
				min-width: 120px;
				padding: 15px 10px;

				// &[class*='--muted'] {
				// 	border-color: darken($color: $grey, $amount: 15);
				// 	color: darken($color: $grey, $amount: 15);

				// 	&:hover {
				// 		background: none !important;
				// 		border-color: $main-color;
				// 		color: $main-color;
				// 	}
				// }
			}
		}
	}
}

.form-prompt-message {
	display: flex;
	svg {
		width: 42px;
		height: 42px;
		margin-top: -10px;
	}
}