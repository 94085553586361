#glyphs {
    border-bottom: 1px solid #ccc;
    padding: 2em 0;
    text-align: center;
}
.glyph {
    display: inline-block;
    width: 9em;
    margin: 1em;
    text-align: center;
    vertical-align: top;
    background: #FFF;
    user-select: unset;
}
.glyph .glyph-icon {
    padding: 10px;
    display: block;
    font-size: 64px;
    line-height: 1;
}
.glyph .glyph-icon .svg-icon {
    max-width: 100%;
    fill: red !important;
}
.glyph .glyph-icon:before {
    font-size: 64px;
    color: #222;
    margin-left: 0;
}
.glyph-icon-id {
    font-size: 0.65em;
    background-color: #222;
    color: #fff;
    border-radius: 4px;
    padding: 0.5em;
    color: #FFFF99;
    user-select: text;
    font-family: Consolas,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New, monospace;
}