.legal {
  position: relative;
  background: #fff;
  overflow: auto;
  overscroll-behavior: none;
  width: 100%;
  height: 100%;
}
#block-spsystem-content .field--name-field-ptoc-sections>.field__item:first-child .paragraph--type--legal-section::after,
#block-spsystem-content .field--name-field-ptoc-sections>.field__item:last-child .paragraph--type--legal-section::after {
 display:none
}
@media (max-width:767px) {
 .case-study__bottom-grey-section .field--name-field-bottom-grey-section .paragraph--type--person-quote .field--name-field-person-name {
  font-size:17px;
  line-height:23px
 }
 .case-study__bottom-grey-section .field--name-field-bottom-grey-section .paragraph--type--person-quote .field--name-field-person-title {
  font-size:13px;
  line-height:18px
 }
 .case-study__bottom-grey-section .field--name-field-bottom-grey-section .paragraph--type--person-quote.person-image .quote__person .field--name-field-person-image img {
  height:auto;
  margin:-26px 27px 0 0;
  width:94px
 }
}
.case-study__bottom-grey-section .field--name-field-bottom-grey-section .paragraph--type--person-quote.person-image .quote__person .quote__person-text {
 text-align:left;
 width:50%
}
.case-study__bottom-grey-section .field--name-field-bottom-grey-section .paragraph--type--person-quote.person-image .quote__person .quote__person-text .field--name-field-person-name,
.case-study__bottom-grey-section .field--name-field-bottom-grey-section .paragraph--type--person-quote.person-image .quote__person .quote__person-text .field--name-field-person-title {
 color:#374455
}
.legal #block-spsystem-page-title {
 background:$main-color;
 height:400px;
 padding-top:203px;
 text-align: center;
}
.legal #block-spsystem-page-title h1 {
  color:#fff;
  font-size:60px;
  letter-spacing:.5px;
  line-height:1;
  position:relative;
  margin:0 auto;
  text-align:center;
  font-family: 'Panton-Light', Arial, Helvetica, sans-serif;
}
@media (max-width:767px) {
 .legal #block-spsystem-page-title {
  height:300px;
  padding-top:152px
 }
 .legal #block-spsystem-page-title h1 {
  font-size:36px
 }
}
.legal #block-spsystem-page-title h1::before {
 color:#fff;
 content:"Vantaset";
 font-size:17px;
 letter-spacing:.25px;
 line-height:23px;
 position:absolute;
 top:-38px;
 left:50%;
 -webkit-transform:translateX(-50%);
 -ms-transform:translateX(-50%);
 transform:translateX(-50%);
 white-space: nowrap;
}
#block-spsystem-content .field--name-field-ptoc-sections {
 counter-reset:section
}
#block-spsystem-content .field--name-field-ptoc-sections>.field__item {
 padding:120px 0 100px
}
@media (max-width:767px) {
 #block-spsystem-content .field--name-field-ptoc-sections>.field__item {
  padding:74px 0 54px
 }
}
#block-spsystem-content .field--name-field-ptoc-sections>.field__item:first-child {
 background:#f5f5f5;
 padding:136px 0 170px
}
@media (max-width:767px) {
 #block-spsystem-content .field--name-field-ptoc-sections>.field__item:first-child {
  padding:104px 0 120px
 }
}
#block-spsystem-content .field--name-field-ptoc-sections>.field__item:last-child .paragraph--type--legal-section {
 padding-bottom:70px
}
#block-spsystem-content .field--name-field-ptoc-sections>.field__item .paragraph--type--legal-section {
 margin:0 auto;
 position:relative;
 width:780px
}
#block-spsystem-content .field--name-field-ptoc-sections>.field__item .paragraph--type--legal-section::after {
 background:#ebebeb;
 bottom:-100px;
 content:"";
 height:1px;
 position:absolute;
 width:100%
}
#block-spsystem-content .field--name-field-ptoc-sections>.field__item .paragraph--type--legal-section .field--name-field-icon {
 left:-120px;
 position:absolute;
 top:-23px
}
#block-spsystem-content .field--name-field-ptoc-sections>.field__item .paragraph--type--legal-section .field--name-field-ptoc-title {
 font-family:'Panton-Light', Arial, Helvetica, sans-serif;
 font-weight:300;
 color:$main-color;
 font-size:33px;
 letter-spacing:.45px;
 line-height:43px;
 margin-bottom:21px
}
@media (max-width:767px) {
 #block-spsystem-content .field--name-field-ptoc-sections>.field__item:last-child .paragraph--type--legal-section {
  padding-bottom:54px
 }
 #block-spsystem-content .field--name-field-ptoc-sections>.field__item .paragraph--type--legal-section {
  padding:0 33px;
  width:100%
 }
 #block-spsystem-content .field--name-field-ptoc-sections>.field__item .paragraph--type--legal-section::after {
  bottom:-60px;
  width:calc(100% - 66px)
 }
 #block-spsystem-content .field--name-field-ptoc-sections>.field__item .paragraph--type--legal-section .field--name-field-ptoc-title {
  font-size:30px;
  letter-spacing:.5px;
  line-height:36px;
  margin-bottom:17px
 }
}
#block-spsystem-content .field--name-field-ptoc-sections>.field__item .paragraph--type--legal-section .field--name-field-ptoc-body h2 {
 font-family:'Panton-SemiBold';
 font-weight:400;
 font-size:20px;
 letter-spacing:.25px;
 line-height:28px;
 margin-bottom:28px
}
@media (max-width:767px) {
 #block-spsystem-content .field--name-field-ptoc-sections>.field__item .paragraph--type--legal-section .field--name-field-ptoc-body h2 {
  font-size:13px;
  line-height:18px;
  margin-bottom:18px
 }
}
#block-spsystem-content .field--name-field-ptoc-sections>.field__item .paragraph--type--legal-section .field--name-field-ptoc-body p {
 font-size:17px;
 letter-spacing:.25px;
 line-height:23px
}
#block-spsystem-content .field--name-field-ptoc-sections>.field__item .paragraph--type--legal-section .field--name-field-ptoc-body p:not(:last-child) {
 margin-bottom:17px
}
@media (max-width:767px) {
 #block-spsystem-content .field--name-field-ptoc-sections>.field__item .paragraph--type--legal-section .field--name-field-ptoc-body p {
  font-size:13px;
  line-height:18px
 }
 #block-spsystem-content .field--name-field-ptoc-sections>.field__item .paragraph--type--legal-section .field--name-field-ptoc-body p:not(:last-child) {
  margin-bottom:13px
 }
}
#block-spsystem-content .field--name-field-ptoc-sections>.field__item .paragraph--type--legal-section .field--name-field-ptoc-body a {
 color:$main-color
}
#block-spsystem-content .field--name-field-ptoc-sections>.field__item .paragraph--type--legal-section .field--name-field-ptoc-body li {
 font-size:17px;
 letter-spacing:.25px;
 line-height:23px;
 margin-bottom:17px
}
@media (max-width:767px) {
 #block-spsystem-content .field--name-field-ptoc-sections>.field__item .paragraph--type--legal-section .field--name-field-ptoc-body li {
  font-size:13px;
  line-height:18px;
  margin-bottom:13px
 }
}
#block-spsystem-content .field--name-field-ptoc-sections>.field__item .paragraph--type--legal-section .field--name-field-ptoc-body ul {
 list-style-type:disc;
 padding-left:2em
}
#block-spsystem-content .field--name-field-ptoc-sections>.field__item .paragraph--type--legal-section .field--name-field-ptoc-body>.field__item>ol>li,
#block-spsystem-content .field--name-field-ptoc-sections>.field__item .paragraph--type--legal-section .field--name-field-ptoc-body>.field__item>ol>li>ol>li {
 padding-left:20px
}
#block-spsystem-content .field--name-field-ptoc-sections>.field__item .paragraph--type--legal-section .field--name-field-ptoc-body strong {
 font-family:'Panton-SemiBold';
 font-weight:400
}
#block-spsystem-content .field--name-field-ptoc-sections>.field__item .paragraph--type--legal-section .field--name-field-ptoc-body>.field__item>ol {
list-style-type: none;
 counter-increment:section;
 counter-reset:subsection
}
#block-spsystem-content .field--name-field-ptoc-sections>.field__item .paragraph--type--legal-section .field--name-field-ptoc-body>.field__item>ol>li::before {
 counter-increment:subsection;
 content:counter(subsection) ".";
 margin-left:-38px;
 position:absolute
}
.terms-use #block-spsystem-content .field--name-field-ptoc-sections>.field__item .paragraph--type--legal-section .field--name-field-ptoc-body>.field__item>ol>li::before {
 counter-increment:subsection;
 content:counter(section) "." counter(subsection);
 margin-left:-38px;
 position:absolute
}
#block-spsystem-content .field--name-field-ptoc-sections>.field__item .paragraph--type--legal-section .field--name-field-ptoc-body>.field__item>ol>li>ol {
  list-style-type: none;
 counter-reset:lettersection;
 margin-top:17px
}
#block-spsystem-content .field--name-field-ptoc-sections>.field__item .paragraph--type--legal-section .field--name-field-ptoc-body>.field__item>ol>li>ol>li::before {
 counter-increment:lettersection;
 content:counter(lettersection,lower-alpha) ".";
 margin-left:-38px;
 position:absolute
}

body.login .layout-container main #block-spsystem-content,
body.login .layout-container main .status-messages,
body.password .layout-container main #block-spsystem-content,
body.password .layout-container main .status-messages,
body.user .layout-container main #block-spsystem-content,
body.user .layout-container main .status-messages {
 -ms-grid-column:2;
 grid-column:2
}