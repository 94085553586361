#header .main-header, .article-header__container  {
  background: $main-color;
}
@include media($mobile-menu-break) {
  #header {
    position: sticky;
    top:0;
    width:100%;
    z-index: 9999;
  }
}

.sidebar{
  position:fixed;
}

.main-header {
  position: relative;
  width: 100%;
  transition: all .2s ease;
  z-index: 100;
  /*&:before{
    content:'';
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background: linear-gradient(to top, rgba(68,48,46,.55), rgba(46,30,29,.85));
  }*/
  /*&:after{
    content:'';
    position: absolute;
    top:0;
    opacity:1;
    left:0;
    width:100%;
    height:100%;
    z-index: -1;
    background-image: url(/assets/images/driver-new.jpg);
    background-size: 100%;
    background-position: top center;
    @include media($mobile-menu-break) {
      background-size: 100%;
    }
    background-attachment: fixed;
    background-repeat: no-repeat;
  }*/
  background: transparent;
  /*height:100px;
  @include media($tablet) {
    height:150px;
  }*/
  //background-color:#000;
  @include media($mobile-menu-break) {
    box-shadow: 0 0 6rem rgba(46, 30, 29, .9);
  }
  /*fix for CMS Area*/

  .container {
    height: $header-height-mobile;
    @include media($mobile-menu-break) {
      height: $header-height-desktop;
    }
    @include media($mobile-menu-break-max) {
      padding-left: 15px;
      padding-right: 15px;
    }
    position: relative;
    z-index: 100;
    .title {
      position: absolute;
      width: 100%;
      padding: 0;
      left: 0;
      display: none;
      text-align: center;
      z-index: 1;
      @include media($mobile-max) {
        @include ellipsis(12rem);
        left: 50%;
        font-size: 1.05rem;
        margin-left: -6rem;
      }
      line-height: $header-height-mobile;
      @include media($mobile-menu-break) {
        line-height: $header-height-desktop;
      }
      color:#fff;
      font-size:1.25rem;
    }
    .offline-mode {
      position: absolute;
      display: block;
      top: 40px;
      margin-left: -10px;
      @include media($mobile-menu-break) {
        top: 60px;
        margin-left: 0px;
      }
      text-align: center;
      width:100%;
      span {
        @include alert-theme-inverted($error-color, $icon-error);
        &:before {
          margin: -.1rem .2rem 0 .1rem;
          display: inline-block;
        }
        font-size:.75em;
        padding:.3em .4em;
        width:auto;
        height:auto;
        margin:3.1rem auto 0;
      }
    }
  }
  width: 100%;
  .logo {
    pointer-events: none;
  }
  .logo,
  [on-scroll-logo] {
    @include centerer;
    text-align: center;
    z-index: 2;
    overflow: hidden;
    align-self: baseline;
    margin:0;
    .logo-icon {
      transition: all .2s ease;
      height: 29px;
    }
    .logo-text {
      width: 220px;
      height: 150px;
    }
  }
  .searcher {
    display: inline-block;
    padding: 6.5em 3% 0 3%;
    &:before {
      @include icon($icon-search);
      font-size: 150%;
      color: #fff;
      @include media($tablet) {
        padding-top: 15px;
        padding-bottom: 15px;
      }
      display: block;
    }
  }
  .menu {
    float:left;
    text-align: center;
    z-index: 2;
    position: relative;
    svg{
      fill:#fff;
      width: 42px;
      height: 42px;
    }
    /*&:after{
      content:"Menu";
      color:#666;
      font-size:.65rem;
      display: block;
      margin-top:.1rem;
    }*/
  }
  .mobile-menu {
    display: block;
    margin: -10px auto 0 auto;
    width: 100%;
    text-align: center;
    z-index: 2;
    display: inline-block;
    &:before {
      @include icon($icon-menu);
      font-size: 150%;
      color: $sec-color;
      padding-top: 15px;
      padding-bottom: 10px;
      display: block;
    }
    @include media($mobile-menu-break) {
      display: none;
    }
  }
  .mysps {
    &:before {
      content: $icon-account;
      font-size: 175%;
    }
    /*&&:after{
      content:"My SPS";
    }*/
  }
  .blog {
    &:before {
      content: $icon-newspaper;
      font-size: 165%;
    }
    &:after {
      content: "Blog";
    }
  }
  .myaccount {
    display: inline-block;
    &--search,
    &--blog {

      @include media($sm-tablet) {
        padding-right: 0;
      }
    }
    a{
      img {
        border-radius: 50%;
        width: 30px;
        height: 30px;
      }
    }
    span {
      display: none;
      font-size: .95rem;
      /*&:after {
        @include icon($icon-arrow-down);
        font-size: .6rem;
        display: inline-block;
        margin: 0 .6rem 0 .5rem;
      }*/
    }
    & > a > svg {
      width: 34px;
      height: 34px;
      fill:#fff;

      .articles-page & {
        fill: #fff !important;
      }
      @include media($mobile-menu-break) {
        width: 42px;
        height: 42px;
      }
    }
    & > a.account-profile {
      position: relative;
      > img {
        position: absolute;
        margin: 0 !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        & + .svg-icon {
          opacity: 0;
        }
      }
    }
  }
  &.mobile-menu-open {
    nav {
      max-height: 400px;
    }
    .mobile-menu {
      &:before {
        content: $icon-cross;
      }
    }
  }
  nav {
    position: absolute;
    left:0;
    width:100%;
    text-align: right;
    height: 100%;
    position: relative;
    display: block;
    color:#fff;

    &>ul {
      @include list-reset();
      height: 100%;
      &>li {
        height: 100%;
        cursor:pointer;
        @include media($mobile-menu-break) {
          padding: 0 5px;
        }

        a {
          height: 100%;
          display: flex;
          align-items: center;
        }
      }
    }
    a {
      opacity: .9;
      transition: all .2s ease;
    }
    a:hover {
      opacity: 1;
      text-decoration: none;
    }
  }
}

.home {
  .title {
    display: none !important;
  }
  header .logo {
    display: block !important;
  }
  .back-btn {
    display: none;
  }
  @include media($mobile-menu-break) {
    .menu {
      display:none;
    }
    &.changepassword,
    &.profile {
      .menu {
        display: block;
      }
    }
  }
}

.back-btn {
  display:none;
  font-family: $title-font;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  line-height: $header-height-mobile;
  @include media($mobile-menu-break) {
    line-height: $header-height-desktop;
  }
  padding:0 .8rem;
  font-size:1.2rem;
  i {
    font-size: 1.9rem;
    display: inline-block;
    margin: -.2rem .1rem 0 0;
    color: #fff;
  }
  &:hover {
    color: #555;
  }
}

@include media($mobile-menu-break) {
  header .back-btn {
    display: none;
  }
}


.dropmenu-holder--submenu {
  display: none;
  border-top: 1px solid #333;
  background: $dark-dark-blue;
  white-space: nowrap;

  .has-submenu.dropmenu-open & {
    display: block;
    &:before { @extend %iconMenuSize; }
  }

  @include media($mobile-menu-break) {
    .myaccount--menu & {
      .svg-icon {display: none;}
    }
  }
  @include media($mobile-menu-break-max) {
    .myaccount--menu & {
      border-top: 0;
      display: block;
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      top: 80px;
      left: 0;
      transform: translateX(100%);
      background: $dropmenu-bg;
      transition: all 0.5s ease-in-out;
      li a {
        padding-left: 20px !important;
      }
    }
    .myaccount--menu .has-submenu.dropmenu-open & {
      transform: translateX(0%);

      &:after {
        content: '';
        z-index: 0;
        background: $dropmenu-bg;
        display: block;
        position: fixed;
        top: -80px;
        width: 100%;
        height: 80px;
      }
      &:before {
        z-index: 1;
        content: '';
        display: block;
        position: fixed;
        background: url("#{$svg-path}Icons/Arrow%20Back.svg") no-repeat center / contain;
        filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(45deg) brightness(111%) contrast(101%);
        top: -64px;
        left: 15px;
      }
    }
  }
}

.dropmenu-holder__menu,
.sidebar-myaccount {
  .has-submenu {

    &.dropmenu-open {
      > a:after {
        background-image: url("#{$svg-path}Icons/Minus.svg");
      }
    }
    > a {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        background: url("#{$svg-path}Icons/Angle%20Forward.svg") no-repeat center / contain;
        width: 20px;
        height: 20px;
        filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(45deg) brightness(111%) contrast(101%);
        top: 50%;
        margin-top: -12px;
        right: 15px;

        @include media($mobile-menu-break) {
          background-image: url('#{$svg-path}Icons/Add.svg');
          width: 24px;
          height: 24px
        }
      }
    }
  }
}

[on-scroll-logo] {
  color: #fff;
  font-size: 2rem;
  // display: none;
  text-align: center;
  line-height: 0.875;
  font-weight: 600;

  .channel-name {
    fill: #fff;
    width: 62px;
    height: 32px;
    margin-bottom: 5px;
  }

  body.home & {
    display: none !important;
  }

  span {
    font-size: 1.25rem;
    display: block;
    // text-transform: uppercase;
  }
}

.home.MySPS .menu {
  display: block !important;
}

.header-clientname {
  color: #fff;
  font-size: 1.25rem;
  letter-spacing: 0.2px;
  font-weight: 600;
  line-height: 1;
}

.disable-header {
  &--home {
    .home-button {
      display: none;
    }
  }

  &--search {
    [class*='--search'] {
      display: none;
    }
  }

  &--menu:not(.register) {
    [class*='--menu'] {
      display: none;
    }
  }
}

.main-header .myaccount .dropmenu-holder__menu .dropmenu-holder--submenu a > span {
  display: block;
}

.menu.menu-button.home-button.f-tag {
  display: none !important;
  body.home.opt-dashboard & {
    display: block !important;
  }
}


@include media($mobile-menu-break-max) {
  .myaccount--menu {
    .dropmenu-holder {
      display: flex;
      flex-direction: column;
      position: fixed;

      account-menu {
        width: 100%;
        display: block;
        flex-grow: 1;
        overflow-y: auto;
      }
    }
    .dropmenu-holder__head {
      width: 100%;
      background-color: $dark-blue;
    }
  }
}

.articles-page .main-header .logo{
  display:none;
}